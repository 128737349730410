import React, { useMemo } from 'react';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../_helpers';
import { useSelector } from 'react-redux';
import { SubscriberList, UpgradeList } from '../../../../app/Setting';

export function Footer() {
    const today = new Date().getFullYear();
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(
        () => {
            return {
                footerLayout: objectPath.get(uiService.config, 'footer.layout'),
                footerClasses: uiService.getClasses('footer', true),
                footerContainerClasses: uiService.getClasses('footer_container', true)
            };
        },
        [ uiService ]
    );

    const location = useLocation();

    const { user } = useSelector((state) => state.auth);

    return (
        <div
            className={`footer py-4 d-flex flex-lg-column footer-mobile-fixed ${layoutProps.footerClasses}`}
            id='kt_footer'
        >
            <div className='testimonial-group vw-100'>
                <div className='overflow-auto'>
                    <ul className='mx-auto d-lg-none c-header-nav'>
                        <li className='px-3 c-header-nav-item'>
                            <NavLink className='text-dark text-center row c-header-nav-link' to='/dashboard'>
                                <div className='col-12'>
                                    <span
                                        className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                            location,
                                            '/dashboard'
                                        )
                                            ? 'svg-icon-danger'
                                            : ''}`}
                                    >
                                        <SVG title='ภาพรวม' src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                                    </span>
                                </div>
                                <p
                                    className={`col-12 font-weight-bolder p-0 ${checkIsActive(location, '/dashboard')
                                        ? 'text-danger'
                                        : ''}`}
                                >
                                    ภาพรวม
                                </p>
                            </NavLink>
                        </li>
                        <li className='px-3 c-header-nav-item'>
                            <NavLink className='text-dark text-center row c-header-nav-link' to='/registermenu'>
                                <div className='col-12'>
                                    <span
                                        className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                            location,
                                            '/registermenu'
                                        )
                                            ? 'svg-icon-danger'
                                            : ''}`}
                                    >
                                        <SVG
                                            title='สมัครสมาชิกใหม่'
                                            src={toAbsoluteUrl('/media/svg/icons/Communication/Add-user.svg')}
                                        />
                                    </span>
                                </div>
                                <p
                                    className={`col-12 font-weight-bolder p-0 ${checkIsActive(location, '/registermenu')
                                        ? 'text-danger'
                                        : ''}`}
                                >
                                    สมัครสมาชิกใหม่
                                </p>
                            </NavLink>
                        </li>
                        {/* <li className='px-3 c-header-nav-item'>
                            <a
                                className='text-dark text-center row c-header-nav-link'
                                href={`https://reg.simk4.com/agreement?mobile=${user.main_phonenumber}`}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <div className='col-12'>
                                    <span className='svg-icon svg-icon-2x menu-icon'>
                                        <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Add-user.svg')} />
                                    </span>
                                </div>
                                <p className='col-12 font-weight-bolder p-0'>สมัครดีลเลอร์ใหม่</p>
                            </a>
                        </li> */}
                        {SubscriberList.includes(user.main_position) ? (
                            <li className='px-3 c-header-nav-item'>
                                <NavLink
                                    className='text-dark text-center row c-header-nav-link'
                                    to='/dealer/upgrade-contact'
                                >
                                    <div className='col-12'>
                                        <span
                                            className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                                location,
                                                '/dealer/upgrade-contact'
                                            )
                                                ? 'svg-icon-danger'
                                                : ''}`}
                                        >
                                            <SVG
                                                title='อัพเกรดดีลเลอร์'
                                                src={toAbsoluteUrl('/media/svg/icons/Files/Uploaded-file.svg')}
                                            />
                                        </span>
                                    </div>
                                    <p
                                        className={`col-12 font-weight-bolder p-0 ${checkIsActive(
                                            location,
                                            '/dealer/upgrade-contact'
                                        )
                                            ? 'text-danger'
                                            : ''}`}
                                    >
                                        อัพเกรดดีลเลอร์
                                    </p>
                                </NavLink>
                            </li>
                        ) : (
                            <li className='px-3 c-header-nav-item'>
                                <NavLink className='text-dark text-center row c-header-nav-link' to='/dealermenu'>
                                    <div className='col-12'>
                                        <span
                                            className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                                location,
                                                '/dealermenu'
                                            )
                                                ? 'svg-icon-danger'
                                                : ''}`}
                                        >
                                            <SVG
                                                title='การอัพเกรดและเปิดสิทธิ'
                                                src={toAbsoluteUrl('/media/svg/icons/Files/Compiled-file.svg')}
                                            />
                                        </span>
                                    </div>
                                    <p
                                        className={`col-12 font-weight-bolder p-0 ${checkIsActive(
                                            location,
                                            '/dealermenu'
                                        )
                                            ? 'text-danger'
                                            : ''}`}
                                    >
                                        การอัพเกรดและเปิดสิทธิ
                                    </p>
                                </NavLink>
                            </li>
                        )}
                        <li className='px-3 c-header-nav-item'>
                            <NavLink className='text-dark text-center row c-header-nav-link' to='/menu'>
                                <div className='col-12'>
                                    <span
                                        className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(location, '/menu')
                                            ? 'svg-icon-danger'
                                            : checkIsActive(location, '/profile/edit')
                                              ? 'svg-icon-danger'
                                              : checkIsActive(location, '/agreement')
                                                ? 'svg-icon-danger'
                                                : checkIsActive(location, '/payment/report') ? 'svg-icon-danger' : ''}`}
                                    >
                                        <SVG title='ฉัน' src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
                                    </span>
                                </div>
                                <p
                                    className={`col-12 font-weight-bolder p-0 ${checkIsActive(location, '/menu')
                                        ? 'text-danger'
                                        : checkIsActive(location, '/profile/edit')
                                          ? 'text-danger'
                                          : checkIsActive(location, '/agreement')
                                            ? 'text-danger'
                                            : checkIsActive(location, '/payment/report') ? 'text-danger' : ''}`}
                                >
                                    ฉัน
                                </p>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>

            {/* begin::Container */}
            <div
                className={`${layoutProps.footerContainerClasses} d-none d-lg-flex flex-column flex-md-row align-items-center justify-content-between`}
            >
                {/* begin::Copyright */}
                <div className='text-dark order-2 order-md-1'>
                    <span className='text-dark-75 font-weight-bold '>{today} &copy;</span>
                    {` `}
                    <a
                        href='https://lin.ee/1ePfrsrbA'
                        rel='noopener noreferrer'
                        target='_blank'
                        className='text-dark-75 text-hover-primary'
                    >
                        K4 Communication Co., LTD.
                    </a>
                </div>
                {/* end::Copyright */}
                {` `}
                {/* begin::Nav */}
                <div className='nav nav-dark order-1 order-md-2'>
                    <a
                        href='https://cooperative.simk4.com/'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='nav-link pr-3 pl-0'
                    >
                        สหกรณ์ปันสุข
                    </a>
                    <a
                        href='http://line.me/ti/p/~@dealersimsupport'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='nav-link px-3'
                    >
                        Line
                    </a>
                    <a
                        href='https://www.facebook.com/DealerSimPunsook'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='nav-link px-3'
                    >
                        Facebook
                    </a>
                    <a
                        href='https://line.me/ti/p/~@dealersimsupport'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='nav-link pl-3 pr-0'
                    >
                        Contact
                    </a>
                </div>
                {/* end::Nav */}
            </div>
            {/* end::Container */}
        </div>
    );
}
