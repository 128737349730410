import React, { useRef } from "react";

import QRCodeReact from "qrcode.react";

import SVG from "react-inlinesvg";

import NumberFormat from "react-number-format";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function RFSummary(prop) {
    const ref = useRef(null);
    // console.log(prop);
    const download = () => {
        const canvas: any = document.querySelector(".qrimg > canvas");
        // console.log(ref);
        ref.current.href = canvas.toDataURL();
        ref.current.download = `${prop.response.order.ordernumber}.png`;
    };
    return (
        <>
            {prop.paymenttype === "2" ? (
                <div className="row d-flex justify-content-center pb-5">
                    {prop.response.responsecode === 0 ? (
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="text-center rounded bg-payment p-1">
                                    <div className="text-white text-center">
                                        หมายเลขคำสั่งซื้อ{" "}
                                        {prop.response.order.ordernumber}
                                    </div>
                                    <div className="qrimg">
                                        <QRCodeReact
                                            value={prop.response.order.qrcode}
                                            size={256}
                                            id="qrcode"
                                            includeMargin={true}
                                        />
                                    </div>
                                    <div className="text-white text-center">
                                        เบอร์ที่ทำรายการ :{" "}
                                        {prop.phonenumber.slice(0, 3) +
                                            "-" +
                                            prop.phonenumber.slice(3, 6) +
                                            "-" +
                                            prop.phonenumber.slice(6)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="text-center">
                                    <div className="qrimg">
                                        <img
                                            alt="error"
                                            src={toAbsoluteUrl(
                                                "/media/error/qr-error.png"
                                            )}
                                            className="logo-sticky max-h-250px"
                                        />
                                        {/* <img
                                                className="result-img"
                                                src="assets/media/images/qr-error.png"
                                            /> */}
                                    </div>
                                    <h4 className="text-center text-color-main mb-0 mt-5">
                                        {prop.response.responsecode === "102"
                                            ? "ขั้นตอนการเพิ่มสิทธิ์ไม่สมบูรณ์ กรุณาติดต่อเจ้าหน้าที่"
                                            : "คิวอาร์โค้ดไม่สมบูรณ์"}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    )}
                    {prop.response.responsecode === 0 && (
                        <>
                            <div className="col-12 mt-50 text-center">
                                <a
                                    href={"#qrcode"}
                                    ref={ref}
                                    className="btn btn-success font-weight-bold text-uppercase px-6 py-2"
                                    onClick={download}
                                >
                                    บันทึกภาพ
                                </a>
                                {/* <button type="button" (click)="renewQRCode()" className="btn btn-primary font-weight-bold text-uppercase px-6 py-2 mr-3">
    ขอคิวอาร์โค้ดใหม่
    </button> */}
                            </div>
                            <div className="col-12 mt-50 text-center">
                                <h5 className="text-center text-color-main mb-0">
                                    หมายเลขคำสั่งซื้อ{" "}
                                    {prop.response.order.ordernumber}
                                    <br /> เบอร์ที่ทำรายการ{" "}
                                    {prop.phonenumber.slice(0, 3) +
                                        "-" +
                                        prop.phonenumber.slice(3, 6) +
                                        "-" +
                                        prop.phonenumber.slice(6)}
                                </h5>
                                <p className="text-center">
                                    กรุณาบันทึกภาพคิวอาร์โค้ดและทำการชำระเงินที่แอพ
                                    <span> ธนาคาร </span> ของท่าน
                                    <b className="text-danger">
                                        ภายใน 1 วัน
                                    </b>{" "}
                                    เมื่อชำระเงินเรียบร้อยแล้วรอรับ SMS
                                    เพื่อยืนยันการทำรายการ ประมาณ 5 - 10 นาที
                                </p>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className="card card-custom position-relative overflow-hidden col-12 mx-auto p-0">
                    <div className="position-absolute opacity-30">
                        <span className="svg-icon svg-icon-10x svg-logo-white">
                            <SVG
                                // title="แชร์"
                                src={toAbsoluteUrl(
                                    "/media/svg/shapes/abstract-8.svg"
                                )}
                            />
                        </span>
                    </div>
                    <div className="row justify-content-center py-8 px-8 py-md-36 px-md-0 bg-primary">
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between align-items-md-center flex-column flex-md-row">
                                <div className="d-flex flex-column px-0 order-2 order-md-1">
                                    <div className="py-4 d-flex flex-row text-white">
                                        <img
                                            alt="Logo"
                                            src="https://cdn.simk4.com/payment/wallet2.png"
                                            className="logo-sticky max-h-60px"
                                        />
                                        <h2 className="my-auto">
                                            <b>เมืองปันสุข</b>
                                        </h2>
                                        {/* <span className="pl-2 my-auto font-size-h3">
                                            ซิม
                                        </span> */}
                                    </div>
                                    <span className="d-flex flex-column font-size-h5 font-weight-bold text-white">
                                        {/* <span>
                                                                        Cecilia
                                                                        Chapman,
                                                                        711-2880
                                                                        Nulla
                                                                        St,
                                                                        Mankato
                                                                    </span>
                                                                    <span>
                                                                        Mississippi
                                                                        96522
                                                                    </span> */}
                                        {/* <span>
                                                                        รายการ
                                                                        XXXX
                                                                    </span> */}
                                    </span>
                                </div>
                                <h1 className="display-3 font-weight-boldest text-white order-1 order-md-2">
                                    สรุปรายการ
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center py-8 px-8 py-md-30 px-md-0 bg-gray-300">
                        <div className="col-md-9">
                            {prop.response.responsecode === 0 ? (
                                <div className="row pb-26">
                                    <div className="col-md-12 pr-md-10 py-md-10">
                                        <div className="custom-title">
                                            <div className="title-text text-dark-50 font-size-lg font-weight-bold my-auto">
                                                หมายเลขคำสั่งซื้อ
                                            </div>
                                            <div className="title-line bg-gray-500 my-auto"></div>
                                        </div>
                                        <div className="font-size-lg font-weight-bold text-right mb-10">
                                            {prop.response.order.ordernumber}
                                        </div>
                                        <div className="custom-title">
                                            <div className="title-text text-dark-50 font-size-lg font-weight-bold my-auto">
                                                เบอร์ที่ทำรายการ
                                            </div>
                                            <div className="title-line bg-gray-500 my-auto"></div>
                                        </div>
                                        <div className="font-size-lg font-weight-bold text-right mb-10">
                                            {prop.phonenumber.slice(0, 3) +
                                                "-" +
                                                prop.phonenumber.slice(3, 6) +
                                                "-" +
                                                prop.phonenumber.slice(6)}
                                        </div>
                                        <div className="custom-title">
                                            <div className="title-text text-dark-50 font-size-lg font-weight-bold my-auto">
                                                ประเภทการทำรายการ
                                            </div>
                                            <div className="title-line bg-gray-500 my-auto"></div>
                                        </div>
                                        <div className="font-size-lg font-weight-bold text-right mb-10">
                                            {prop.producttype === "1"
                                                ? "เติมเงิน"
                                                : "สมัครแพ็กเกจ"}
                                        </div>
                                        {/* <div className="custom-title">
                                            <div className="title-text text-dark-50 font-size-lg font-weight-bold my-auto">
                                                เบอร์ที่สั่งซื้อ
                                            </div>
                                            <div className="title-line bg-gray-500 my-auto"></div>
                                        </div>
                                        <div className="font-size-lg font-weight-bold text-right mb-10">
                                            {prop.response.phonenumber.slice(
                                                0,
                                                3
                                            ) +
                                                "-" +
                                                prop.response.phonenumber.slice(
                                                    3,
                                                    6
                                                ) +
                                                "-" +
                                                prop.response.phonenumber.slice(
                                                    6
                                                )}
                                        </div> */}
                                        {/* <div className="custom-title">
                                        <div className="title-text text-dark-50 font-size-lg font-weight-bold my-auto">
                                            วันที่ทำรายการ
                                        </div>
                                        <div className="title-line bg-gray-500 my-auto"></div>
                                    </div>
                                    <div className="font-size-lg font-weight-bold text-right mb-10">
                                        05/04/2021 xx:xx:xx
                                    </div> */}
                                        <div className="custom-title">
                                            {/* <div className="title-text text-dark-50 font-size-lg font-weight-bold my-auto">
                                    วันที่ทำรายการ
                                </div> */}
                                            <div className="title-line bg-gray-500 my-auto"></div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row d-flex justify-content-center pb-26">
                                    <div className="col-12">
                                        <div className="row justify-content-center">
                                            <div className="text-center">
                                                <div className="qrimg">
                                                    <img
                                                        alt="error"
                                                        src={toAbsoluteUrl(
                                                            "/media/error/qr-error.png"
                                                        )}
                                                        className="logo-sticky max-h-250px"
                                                    />
                                                    {/* <img
                                                className="result-img"
                                                src="assets/media/images/qr-error.png"
                                            /> */}
                                                </div>
                                                <h4 className="text-center text-color-main mb-0 mt-5">
                                                    {prop.response
                                                        .responsecode === "102"
                                                        ? "ขั้นตอนการเพิ่มสิทธิ์ไม่สมบูรณ์ กรุณาติดต่อเจ้าหน้าที่"
                                                        : "คิวอาร์โค้ดไม่สมบูรณ์"}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                {/* <div className="col-md-5 border-top pt-14 pb-10 pb-md-18">
                                    <div className="d-flex flex-column flex-md-row">
                                        <div className="d-flex flex-column">
                                            <div className="font-weight-bold font-size-h6 mb-3">
                                                ค่าบริการ
                                            </div>
                                            <div className="d-flex justify-content-between font-size-lg mb-3">
                                                <span className="font-weight-bold mr-15">
                                                    จำนวนเงินที่ต้องการถอน:
                                                </span>
                                                <span className="text-right">
                                                    Barclays UK
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between font-size-lg mb-3">
                                                <span className="font-weight-bold mr-15">
                                                    ค่าธรรมเนียม:
                                                </span>
                                                <span className="text-right">
                                                    1234567890934
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between font-size-lg">
                                                <span className="font-weight-bold mr-15">
                                                    ภาษีหัก ณ ที่จ่าย 3 %:
                                                </span>
                                                <span className="text-right">
                                                    BARC0032UK
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="ml-auto col-md-7 pt-md-25">
                                    <div className="bg-primary rounded d-flex align-items-center justify-content-between text-white max-w-350px position-relative ml-auto p-7">
                                        <div className="position-absolute opacity-30 top-0 right-0">
                                            <span className="svg-icon svg-icon-2x svg-logo-white svg-icon-flip">
                                                <SVG
                                                    // title="แชร์"
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/shapes/abstract-8.svg"
                                                    )}
                                                />
                                            </span>
                                        </div>
                                        <div className="font-weight-boldest font-size-h5">
                                            ยอดรวม
                                        </div>
                                        <div className="text-right d-flex flex-column">
                                            <span className="font-weight-boldest font-size-h3 line-height-sm">
                                                <NumberFormat
                                                    value={parseFloat(
                                                        prop.amount
                                                    )}
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                    suffix={" บาท"}
                                                />
                                            </span>
                                            <span className="font-size-sm">
                                                รวมค่าบริการทั้งหมดแล้ว
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row justify-content-center border-top py-8 px-8 py-md-28 px-md-0 bg-gray-300">
                <div className="col-md-9">
                    <div className="d-flex font-size-sm flex-wrap">
                        <button
                            type="button"
                            className="btn btn-primary font-weight-bolder py-4 mr-3 mr-sm-14 my-1"
                            onclick="window.print();"
                        >
                            Print Invoice
                        </button>
                        <button
                            type="button"
                            className="btn btn-light-primary font-weight-bolder mr-3 my-1"
                        >
                            Download
                        </button>
                        <button
                            type="button"
                            className="btn btn-warning font-weight-bolder ml-sm-auto my-1"
                        >
                            Create Invoice
                        </button>
                    </div>
                </div>
            </div> */}
                </div>
            )}
        </>
    );
}
