import React, { useEffect, useState, useRef } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import NumberFormat from 'react-number-format';

const maxValueCheck = (object) => {
    if (object.target.value > object.target.maxLength) {
        object.target.value = object.target.maxLength;
    }
};

export function SubWalletWithdrawList(props) {
    const ref = useRef(null);

    const [ loading, setLoading ] = useState(false);
    const [ accounts, setAccounts ] = useState({});

    const [ maxwidth, setMaxwidth ] = useState(0);
    const [ currentwidth, setCurrentwidth ] = useState(0);

    const scroll = (size, option) => {
        const osInstance = ref.current.osInstance();
        if (osInstance) {
            osInstance.scrollStop().scroll(
                {
                    x: `${option}= ${size}px`
                },
                1000,
                'easeOutCubic'
            );
            setTimeout(() => {
                setCurrentwidth(Math.ceil(osInstance.scroll().position.x));
            }, 1000);
        }
    };

    useEffect(() => {
        getWallets();
    }, []);

    // useEffect(
    //     () => {
    //         if (ref.hasOwnProperty('osInstance')) {
    //             const osInstance = ref.current.osInstance();
    //             if (osInstance) {
    //                 setTimeout(() => {
    //                     // const osInstance = ref.current.osInstance();
    //                     // setMaxwidth(osInstance.scrollStop().scroll().max.x);
    //                     setMaxwidth(osInstance.scrollStop().scroll().max.x);
    //                     setDisable(false);
    //                 }, 1000);
    //             }
    //         }
    //     },
    //     [ accounts ]
    // );

    const getWallets = () => {
        setLoading(true);
        fetch(props.config.py_url + props.config.version + '/wallet/list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: props.config.secret
            },
            body: JSON.stringify({
                userid: props.dealerid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setAccounts(result);
                if (result.responsecode === 0) {
                    setMaxwidth(250 * result['lists'].length);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <div className='row col-12 p-0 m-0'>
            <div className='card card-custom shadow-none col-12 p-0' id='sub-wallet-card'>
                <div className='card-header bg-danger text-white'>
                    <div className='card-title'>
                        <span className='card-icon'>
                            <i className='fas fa-wallet text-white' />
                        </span>
                        <h3 className='card-label text-white'>กระเป๋าที่คุณมี</h3>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='wallet-list-2 bg-light col-12 p-0'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            {loading ? (
                                <div className='row align-items-center col-12 p-0 m-0 min-h-200px'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-spinner fa-pulse fa-5x' />
                                        <h1 className='col mt-5'>กรุณารอสักครู่</h1>
                                    </div>
                                </div>
                            ) : (
                                <div className='col-12 row p-0 m-0'>
                                    {Object.keys(accounts).length <= 0 ? (
                                        <div className='row align-items-center col-12 p-0 m-0 min-h-200px'>
                                            <div className='text-center col-12 p-0'>
                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='col-12 row p-0 m-0'>
                                            {accounts.responsecode === 102 ? (
                                                <div className='row align-items-center col-12 p-0 m-0 min-h-200px'>
                                                    <div className='text-center col-12 p-0'>
                                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                        <h1 className='col mt-5'>ไม่มีพบกระเป๋าอื่นๆของคุณ</h1>
                                                    </div>
                                                </div>
                                            ) : accounts.responsecode !== 0 ? (
                                                <div className='row align-items-center col-12 p-0 m-0 min-h-200px'>
                                                    <div className='text-center col-12 p-0'>
                                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                        <h1 className='col mt-5'>
                                                            มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง
                                                        </h1>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='col-12 p-0 group-list w-md-500px w-300px'>
                                                    <div className='slider inline-scroll'>
                                                        <div
                                                            className={`slider__arrow-left d-none ${currentwidth === 0
                                                                ? 'd-md-none'
                                                                : 'd-md-flex'}`}
                                                        >
                                                            <span className='arrow' onClick={() => scroll('200', '-')}>
                                                                <i className='fa fa-angle-left fa-3x' />
                                                            </span>
                                                        </div>
                                                        <OverlayScrollbarsComponent
                                                            className='os-theme-light wv-100 mx-5'
                                                            ref={ref}
                                                        >
                                                            <ul className={`d-flex flex-row p-0`}>
                                                                {accounts.lists.map((object, index) => {
                                                                    return (
                                                                        <li
                                                                            className='group-item'
                                                                            key={index}
                                                                            onClick={() => props.setSelect(object)}
                                                                        >
                                                                            <div
                                                                                className={`card cursor-pointer p-3${object ===
                                                                                props.select
                                                                                    ? ' bg-danger'
                                                                                    : ''}`}
                                                                            >
                                                                                <div className='d-flex'>
                                                                                    <div className='flex-shrink-0 mr-3'>
                                                                                        <div className='symbol symbol-40 symbol-light-white'>
                                                                                            <div className='symbol-label'>
                                                                                                <i className='fas fa-wallet fa-2x' />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='flex-grow-1 my-auto'>
                                                                                        <p className='card-number m-0'>
                                                                                            {object.name}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                                    <div className='symbol-label'>
                                                                                        <i className='fas fa-wallet fa-2x' />
                                                                                    </div>
                                                                                </div>
                                                                                <p className='card-number'>
                                                                                    {object.name}
                                                                                </p> */}
                                                                                <p className='card-number m-0'>
                                                                                    <NumberFormat
                                                                                        value={parseFloat(
                                                                                            object.balance
                                                                                        )}
                                                                                        displayType={'text'}
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale={true}
                                                                                        thousandSeparator={true}
                                                                                        suffix={' บาท'}
                                                                                    />
                                                                                </p>
                                                                                {/* <p className='validity-date p-0 mb-0'>
                                                                            {object.feels_kyc === 1 ? (
                                                                                <div>
                                                                                    กำหนดรักษาสิทธิ์:{' '}
                                                                                    <span>{object.preserve_date}</span>
                                                                                </div>
                                                                            ) : (
                                                                                <span className='menu-label'>
                                                                                    <span className='label label-warning label-inline'>
                                                                                        ยังไม่ลงทะเบียนซิม
                                                                                    </span>
                                                                                </span>
                                                                            )}
                                                                        </p> */}
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                    // if (arr.length - 1 === index) {
                                                                    //     if (count === 0) {
                                                                    //         return (
                                                                    //             <div className='row align-items-center col-12 p-0 m-0 min-h-200px'>
                                                                    //                 <div className='text-center col-12 p-0'>
                                                                    //                     <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                                    //                     <h1 className='col mt-5'>
                                                                    //                         ไม่มีพบกระเป๋าอื่นๆของคุณ
                                                                    //                     </h1>
                                                                    //                 </div>
                                                                    //             </div>
                                                                    //         );
                                                                    //     }
                                                                    // }
                                                                })}
                                                            </ul>
                                                        </OverlayScrollbarsComponent>
                                                        <div
                                                            className={`slider__arrow-right d-none ${currentwidth >=
                                                            maxwidth
                                                                ? 'd-md-none'
                                                                : 'd-md-flex'}`}
                                                        >
                                                            <span className='arrow' onClick={() => scroll('200', '+')}>
                                                                <i className='fa fa-angle-right fa-3x' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {Object.keys(props.select).length !== 0 ? (
                <div className='flex-row-fluid col-12 p-0 mt-5'>
                    {props.select.status === 'enable' ? (
                        <div className='card m-0'>
                            <div className='card-body'>
                                <div className='header col-12 w-100 h-100 mb-5 p-0 px-md-4'>
                                    <h2>ยอดเงินในกระเป๋าของคุณ</h2>
                                    <p className='total-balance'>
                                        <NumberFormat
                                            value={parseFloat(props.select.balance)}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                        />
                                    </p>
                                </div>
                                <div className='form-group row justify-content-center'>
                                    <label className='col-xl-3 col-lg-3 col-form-label'>ชื่อบัญชี</label>
                                    <div className='col-lg-9 col-xl-6 row m-0'>
                                        <p className='my-auto'>{props.account}</p>
                                    </div>
                                </div>
                                <div className='form-group row justify-content-center'>
                                    <label className='col-xl-3 col-lg-3 col-form-label'>ชื่อธนาคาร</label>
                                    <div className='col-lg-9 col-xl-6 row m-0'>
                                        <p className='my-auto'>{props.bank}</p>
                                    </div>
                                </div>
                                <div className='form-group row justify-content-center'>
                                    <label className='col-xl-3 col-lg-3 col-form-label'>เลขที่บัญชี</label>
                                    <div className='col-lg-9 col-xl-6 row m-0'>
                                        <p className='my-auto'>{props.bankno}</p>
                                    </div>
                                </div>
                                <div className='form-group row justify-content-center'>
                                    <label className='col-xl-3 col-lg-3 col-form-label'>จำนวนเงินที่ต้องการถอน</label>
                                    <div className='col-lg-9 col-xl-6'>
                                        <input
                                            className='form-control form-control-lg form-control-solid'
                                            type='text'
                                            inputMode='tel'
                                            id='amount'
                                            value={props.amount}
                                            maxLength={
                                                parseFloat(props.select.balance) > 10000 ? (
                                                    10000 - props.select.daliy_withdraw
                                                ) : (
                                                    parseFloat(props.select.balance)
                                                )
                                            }
                                            onInput={maxValueCheck}
                                            onChange={(e) => props.setAmount(e.target.value)}
                                            onKeyPress={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                    if (e.key !== 'Enter') {
                                                        e.preventDefault();
                                                    }
                                                }
                                            }}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                        <small>ถอนได้สูงสุดวันละ 10,000 บาท</small>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer'>
                                <div className='d-flex justify-content-between'>
                                    <div className='mr-2' />
                                    <div>
                                        <button
                                            type='button'
                                            className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                            onClick={props.btnNext}
                                        >
                                            ตกลง
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='card card-custom shadow-none col-12 p-0 mt-0' id='sub-wallet-card'>
                            <div className='card-body'>
                                <div className='row col-12 justify-content-center align-items-center bg-white cart-box min-h-400px p-0'>
                                    <div className='col-12 text-center'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h3 className='col mt-5'>กระเป๋าที่ต้องการถอนเงินถูกปิดบริการอยู่ชั่วคราว</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className='card card-custom shadow-none col-12 p-0 mt-5' id='sub-wallet-card'>
                    <div className='card-body'>
                        <div className='row col-12 justify-content-center align-items-center bg-white cart-box min-h-400px p-0'>
                            <div className='col-12 text-center'>
                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                <h3 className='col mt-5'>กรุณาเลือกกระเป๋าที่ต้องการถอนเงิน</h3>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
