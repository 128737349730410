import React from 'react';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export function ShopInsurancePhonenumber(props) {
    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    return (
        <div className='row justify-content-center align-items-center m-0 h-500px bg-white rounded-lg col-lg-10 col-12 p-0'>
            <div className='col-12 col-lg-8 p-0 text-center'>
                <img className='max-h-150px' src={toAbsoluteUrl('/media/logos/new-logo.png')} alt='punsook-logo' />
                <div className='form-group fv-plugins-icon-container text-left'>
                    <label className='font-size-h6 font-weight-bolder'>เบอร์ซิมปันสุข</label>
                    <input
                        // placeholder="Email"
                        type='text'
                        inputMode='tel'
                        className='form-control h-auto py-5 px-6 rounded-lg'
                        name='phonenumber'
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                if (event.key !== 'Enter') {
                                    event.preventDefault();
                                }
                            }
                        }}
                        value={props.walletnumber}
                        onChange={(e) => props.setWalletnumber(e.target.value)}
                        maxLength='10'
                        onInput={maxLengthCheck}
                    />
                </div>
                <div className='form-group d-flex flex-wrap flex-center'>
                    <button
                        type='button'
                        className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                        onClick={props.btnNext}
                    >
                        <span>ถัดไป</span>
                        {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                    </button>
                </div>
            </div>
        </div>
    );
}
