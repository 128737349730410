const config = {
    // py_url: 'http://localhost:5000/',
    // py_url: 'https://beta-api.simk4.com/',
    // py_url: 'https://api.illusion.solutions/',
    py_url: 'https://api-v2.simk4.com/',
    shop_url: 'https://shop-api.feels.co.th/v1_1',
    // simkit_url : 'https://beta-api.simk4.com',
    // punsook_shop_url: 'http://localhost:5005/',
    punsook_shop_url: 'https://api-shop.simk4.com/',
    // punsook_shop_url: 'https://beta-punsook-shop-api.simk4.com/',
    // laravel_url: "https://beta-punsook-api.illusion.solutions",
    laravel_url: 'https://api.simk4.com',
    version: 'v1',
    secret: '***',
    maintain: false
};

export default config;
