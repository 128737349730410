export const myCustomLocale = {
    // months list by order
    months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
    ],
    // week days by order
    weekDays: [
        {
            name: 'อาทิตย์', // used for accessibility
            short: 'อา', // displayed at the top of days' rows
            isWeekend: true // is it a formal weekend or not?
        },
        {
            name: 'จันทร์',
            short: 'จ'
        },
        {
            name: 'อังคาร',
            short: 'อ'
        },
        {
            name: 'พุธ',
            short: 'พ'
        },
        {
            name: 'พฤหัสบดี',
            short: 'พฤ'
        },
        {
            name: 'ศุกร์',
            short: 'ศ'
        },
        {
            name: 'เสาร์',
            short: 'ส',
            isWeekend: true
        }
    ],
    weekStartingIndex: 0, // just play around with this number between 0 and 6
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },
    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },
    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },
    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },
    nextMonth: 'Next Month', // texts in the date picker
    previousMonth: 'Previous Month', // texts in the date picker
    openMonthSelector: 'Open Month Selector', // texts in the date picker
    openYearSelector: 'Open Year Selector', // texts in the date picker
    closeMonthSelector: 'Close Month Selector', // texts in the date picker
    closeYearSelector: 'Close Year Selector', // texts in the date picker
    defaultPlaceholder: 'Select...', // texts in the date picker
    from: 'from', // for input range value
    to: 'to', // for input range value
    digitSeparator: ',', // used for input value when multi dates are selected
    yearLetterSkip: 0, // if your provide -2 for example, year will be 2 digited
    isRtl: false // is your language rtl or ltr?
};

export const minimumDate = {
    year: 2021,
    month: 10,
    day: 8
};
