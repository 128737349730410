import React from "react";

export default function Maintain() {
    return (
        <div className="row">
            <div className="col-12 p-0 m-0">
                <img
                    alt="maintain-pic"
                    src="https://cdn.simk4.com/maintain/2021-04-18.jpg"
                    className="w-100"
                />
            </div>
        </div>
        // <div id="maintain">
        //     <div className="container vh-100">
        //         <div className="row align-items-center justify-content-center vh-100">
        //             <div className="box col-10 text-center">
        //                 <div className="animation">
        //                     <div className="one spin-one" />
        //                     <div className="two spin-two" />
        //                     <div className="three spin-one" />
        //                 </div>
        //                 <h1>Under maintenance</h1>
        //                 <p>
        //                     Update in progress. Please run installer to finish
        //                     update.
        //                 </p>
        //                 <p>
        //                     Maintenance screen for
        //                     <a
        //                         href="https://www.ladesk.com/?utm_source=maintenance&utm_campaign=maintenance/"
        //                         target="_blank"
        //                     >
        //                         LiveAgent
        //                     </a>
        //                 </p>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}
