import React, { useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';

import { injectIntl } from 'react-intl';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { ToastContainer, toast } from 'react-toastify';

import { ShopSwitchAccount, ShopProductHome, ShopProductProduct } from './components';

import ShopAccountLogin from './components/Login';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import * as auth from '../../../modules/Auth/_redux/authRedux';

import config from '../../environments/config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

function ShopProductMain(props) {
    const { user } = useSelector((state) => state.auth);

    const [ mainloading, setMainLoading ] = useState(false);
    const [ showtoast, setShowToast ] = useState(false);
    const [ page, setPage ] = useState('premain');
    const [ phonenumber, setPhonenumber ] = useState('');
    const [ catid, setCatid ] = useState('');
    const [ keyword, setKeyword ] = useState('');

    const getQueryVariable = (variable) => {
        var query = props.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return false;
    };

    useEffect(() => {
        let queryparam = getQueryVariable('page');
        if (queryparam) {
            if (queryparam === 'login') {
                setPage('switch');
            }
        }
        if (user.shop_account !== undefined && user.shop_account !== null && user.shop_account !== '') {
            memberLogin(user.shop_account);
        }
    }, []);

    const btnSwitchAccount = () => {
        setPage('switch');
    };

    const memberLogin = (phonenumber) => {
        setMainLoading(true);
        fetch(config.punsook_shop_url + 'member/user/login', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret
            },
            body: JSON.stringify({
                username: phonenumber,
                password: user.id + phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setMainLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    let edited = user;
                    edited['shop_account'] = phonenumber;
                    edited['shop_token'] = result.info.token;
                    props.newLogin(edited);
                    setPage('premain');
                } else if (result.responsecode === 101) {
                    setPhonenumber(phonenumber);
                    setPage('login');
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setMainLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };
    return (
        <div className='custom-aside'>
            {showtoast && <ToastContainer />}
            {mainloading && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            <div className='row justify-content-between m-0 mb-7'>
                {(page === 'premain' || page === 'main') && (
                    <div>
                        <div className='d-none d-md-block mb-7'>
                            <img
                                className='w-100'
                                src={toAbsoluteUrl('/media/bg/punsook-shop-2.jpg')}
                                alt='shop-banner'
                            />
                        </div>
                        <div className='d-block d-md-none mb-7'>
                            <img
                                className='w-100'
                                src={toAbsoluteUrl('/media/bg/punsook-shop-mobile-2.jpg')}
                                alt='shop-banner'
                            />
                        </div>
                    </div>
                )}
                {(page === 'main' || page === 'switch') && (
                    <button type='button' className='btn btn-link-primary my-auto' onClick={() => setPage('premain')}>
                        <i className='fas fa-angle-double-left icon-md py-2' />
                        ย้อนกลับ
                    </button>
                )}
                {page !== 'switch' &&
                page !== 'login' && (
                    <div className='ml-auto my-auto'>
                        {user.shop_account === undefined || user.shop_account === null || user.shop_account === '' ? (
                            <h6 className='d-inline'>
                                ใช้งานด้วยผู้ใช้งานทั่วไป{' '}
                                <span
                                    className='d-inline text-danger text-hover-danger cursor-pointer'
                                    onClick={btnSwitchAccount}
                                >
                                    เลือกบัญชีดีลเลอร์
                                </span>
                            </h6>
                        ) : (
                            <h6 className='d-inline'>
                                ใช้งานด้วยเบอร์ {user.shop_account}{' '}
                                <span
                                    className='d-inline text-danger text-hover-danger cursor-pointer'
                                    onClick={btnSwitchAccount}
                                >
                                    เปลี่ยน
                                </span>
                            </h6>
                        )}
                    </div>
                )}
            </div>
            {page === 'switch' ? (
                <ShopSwitchAccount
                    memberLogin={memberLogin}
                    setPage={setPage}
                    setPhonenumber={setPhonenumber}
                    mainloading={mainloading}
                    setMainLoading={setMainLoading}
                />
            ) : page === 'login' ? (
                <ShopAccountLogin
                    setPage={setPage}
                    phonenumber={phonenumber}
                    setMainLoading={setMainLoading}
                    user={user}
                />
            ) : page === 'premain' ? (
                <ShopProductHome
                    user={user}
                    setMainLoading={setMainLoading}
                    setShowToast={setShowToast}
                    toast={toast}
                    setCatid={setCatid}
                    setPage={setPage}
                    setKeyword={setKeyword}
                />
            ) : page === 'main' ? (
                <ShopProductProduct
                    user={user}
                    btnSwitchAccount={btnSwitchAccount}
                    setMainLoading={setMainLoading}
                    setShowToast={setShowToast}
                    toast={toast}
                    catid={catid}
                    keyword={keyword}
                />
            ) : null}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(ShopProductMain));
