import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';

import NumberFormat from 'react-number-format';

import { TextareaAutosize } from '@material-ui/core';

import { NavLink } from 'react-router-dom';

import Carousel from 'react-multi-carousel';

import { ToastContainer, toast } from 'react-toastify';

import config from '../../../environments/config';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 4
    }
};

export function ShopProductDetail(props) {
    const { user } = useSelector((state) => state.auth);
    const history = useHistory();

    const [ loading, setLoading ] = useState(false);
    const [ showtoast, setShowToast ] = useState(false);
    const [ details, setDetails ] = useState({});
    const [ tabs, setTabs ] = useState('description');
    const [ mainimage, setMainimage ] = useState('');

    useEffect(() => {
        getProductDetail(props.match.params.id);
    }, []);

    const getProductDetail = (pid) => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'guest/product/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                code: pid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                setDetails(result);
                if (result.responsecode === 0) {
                    if (Object.keys(result.product.gallery).length > 0) {
                        setMainimage(result.product.gallery.cover_image);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                // console.log(error);
            });
    };

    const addCart = (pid, amount, checkout = false) => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/cart/add', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.shop_token,
                product_id: pid.toString(),
                quantity: amount
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setShowToast(true);
                // console.log(result);
                if (result.responsecode === 0) {
                    if (!checkout) {
                        toast.success('เพิ่มสินค้าลงตะกร้าเรียบร้อยแล้ว', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    } else {
                        history.push('/shop/cart');
                    }
                } else {
                    toast.warn(`${result.responsecode}! ไม่สามารถเพิ่มสินค้าลงตะกร้าได้ กรุณาลองใหม่อีกครั้ง`, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
                // setProducts(result);
            })
            .catch((error) => {
                setLoading(false);
                setShowToast(true);
                toast.error('ไม่สามารถเพิ่มสินค้าลงตะกร้าได้ กรุณาลองใหม่อีกครั้ง', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            });
    };

    return (
        <div className='product-detail'>
            {showtoast && <ToastContainer />}
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='container mt-5 mb-5'>
                    <div className='card'>
                        {Object.keys(details).length <= 0 ? (
                            <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                <div className='text-center col-12 p-0'>
                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                    <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {details.responsecode !== 0 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className='row g-0'>
                                            <div className='col-md-6 border-end'>
                                                <div className='d-flex flex-column justify-content-center'>
                                                    <div className='main_image'>
                                                        <img
                                                            src={`data:image/png;base64,${mainimage}`}
                                                            id='main_product_image'
                                                            className='w-350px'
                                                        />
                                                    </div>
                                                    <div className='thumbnail_images slider'>
                                                        <Carousel
                                                            responsive={responsive}
                                                            infinite={true}
                                                            containerClass={`${Object.keys(details.product.gallery)
                                                                .length > 0 &&
                                                            details.product.gallery.images.length + 1 >= 4
                                                                ? 'justify-content-start'
                                                                : 'justify-content-center'}`}
                                                        >
                                                            {/* <ul
                                                                id='thumbnail'
                                                                className={`${Object.keys(details.product.gallery)
                                                                    .length > 0 &&
                                                                details.product.gallery.images.length + 1 > 1
                                                                    ? 'justify-content-start'
                                                                    : 'justify-content-center'}`}
                                                            > */}
                                                            {Object.keys(details.product.gallery).length > 0 &&
                                                            details.product.gallery.images.length > 0 && (
                                                                <img
                                                                    onClick={() =>
                                                                        setMainimage(
                                                                            details.product.gallery.cover_image
                                                                        )}
                                                                    src={`data:image/png;base64,${details.product
                                                                        .gallery.cover_image}`}
                                                                    className='w-100'
                                                                />
                                                            )}
                                                            {Object.keys(details.product.gallery).length > 0 &&
                                                                details.product.gallery.images.length > 0 &&
                                                                details.product.gallery.images.map((object, index) => {
                                                                    return (
                                                                        <img
                                                                            onClick={() => setMainimage(object)}
                                                                            src={`data:image/png;base64,${object}`}
                                                                            className='w-100'
                                                                            key={index}
                                                                        />
                                                                    );
                                                                })}
                                                            {/* </ul> */}
                                                        </Carousel>
                                                        {/* <div
                                                            className={`slider__arrow-right d-none ${currentwidth >=
                                                            maxwidth
                                                                ? 'd-md-none'
                                                                : 'd-md-flex'}`}
                                                        >
                                                            <span className='arrow' onClick={() => scroll('200', '+')}>
                                                                <i className='fa fa-angle-right fa-3x' />
                                                            </span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='p-7 right-side'>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <h3>{details.product.name}</h3>
                                                        {/* <span className='heart'>
                                                            <i className='far fa-heart' />
                                                        </span> */}
                                                    </div>
                                                    <div className='mt-2 pr-3 content'>
                                                        <p className='font-kanit'>{details.product.description}</p>
                                                    </div>
                                                    <h3>
                                                        <NumberFormat
                                                            value={parseFloat(details.product.price)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}
                                                            suffix={' บาท'}
                                                        />
                                                    </h3>
                                                    {/* <div className='ratings d-flex flex-row align-items-center'>
                                                        <div className='d-flex flex-row'>
                                                            {' '}
                                                            <i className='fas fa-star' /> <i className='fas fa-star' />{' '}
                                                            <i className='fas fa-star' />{' '}
                                                            <i className='fas fa-star-half-alt' />{' '}
                                                            <i className='far fa-star' />{' '}
                                                        </div>{' '}
                                                        <span>441 reviews</span>
                                                    </div> */}
                                                    {/* <div className='mt-5'>
                                    {' '}
                                    <span className='fw-bold'>Color</span>
                                    <div className='colors'>
                                        <ul id='marker'>
                                            <li id='marker-1' />
                                            <li id='marker-2' />
                                            <li id='marker-3' />
                                            <li id='marker-4' />
                                            <li id='marker-5' />
                                        </ul>
                                    </div>
                                </div> */}
                                                    {user.shop_account === undefined ||
                                                    user.shop_account === null ||
                                                    user.shop_account === '' ? (
                                                        <NavLink
                                                            to='/shop/product?page=login'
                                                            className='buttons d-flex flex-row mt-5 gap-3'
                                                        >
                                                            <button className='btn btn-outline-dark'>
                                                                สมัครสมาชิก
                                                            </button>
                                                        </NavLink>
                                                    ) : (
                                                        <div className='buttons d-flex flex-row mt-5 gap-3'>
                                                            <button
                                                                className='btn btn-outline-dark'
                                                                onClick={() => addCart(details.product.id, '1', true)}
                                                            >
                                                                ซื้อเลย
                                                            </button>
                                                            <button
                                                                className='btn btn-dark'
                                                                onClick={() => addCart(details.product.id, '1')}
                                                            >
                                                                เพิ่มลงตะกร้า
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='classic-tabs border px-4 pt-1'>
                                            <ul
                                                className='nav tabs-primary nav-justified d-none'
                                                id='advancedTab'
                                                role='tablist'
                                            >
                                                <li className='nav-item d-none'>
                                                    <div
                                                        className={`cursor-pointer nav-link${tabs === 'description'
                                                            ? ' show active'
                                                            : ''}`}
                                                        onClick={() => setTabs('description')}
                                                    >
                                                        รายละเอียดสินค้า
                                                    </div>
                                                </li>
                                                <li className='nav-item d-none'>
                                                    <a
                                                        className={`cursor-pointer nav-link${tabs === 'information'
                                                            ? ' show active'
                                                            : ''}`}
                                                        onClick={() => setTabs('information')}
                                                    >
                                                        ข้อมูลจำเพาะของสินค้า
                                                    </a>
                                                </li>
                                                <li className='nav-item d-none'>
                                                    <a
                                                        className={`cursor-pointer nav-link${tabs === 'review'
                                                            ? ' show active'
                                                            : ''}`}
                                                        onClick={() => setTabs('review')}
                                                    >
                                                        คะแนนของสินค้า (1)
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className='tab-content' id='advancedTabContent'>
                                                <div
                                                    className={`tab-pane p-5 fade${tabs === 'description'
                                                        ? ' show active'
                                                        : ''}`}
                                                >
                                                    <h5>รายละเอียดสินค้า</h5>
                                                    <h3 className='mb-2'>{details.product.name}</h3>
                                                    <p className='small text-muted text-uppercase mb-2'>
                                                        {details.product.category_title}
                                                    </p>
                                                    <h6>
                                                        <NumberFormat
                                                            value={parseFloat(details.product.price)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}
                                                            suffix={' บาท'}
                                                        />
                                                    </h6>
                                                    <p className='pt-1 font-kanit'>{details.product.description}</p>
                                                </div>
                                                <div
                                                    className={`tab-pane p-5 fade${tabs === 'information'
                                                        ? ' show active'
                                                        : ''}`}
                                                >
                                                    <h5>ข้อมูลจำเพาะของสินค้า</h5>
                                                    <table className='table table-striped table-bordered mt-3'>
                                                        <thead>
                                                            <tr>
                                                                <th scope='row' className='w-150 dark-grey-text h6'>
                                                                    Weight
                                                                </th>
                                                                <td>
                                                                    <em>0.3 kg</em>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope='row' className='w-150 dark-grey-text h6'>
                                                                    Dimensions
                                                                </th>
                                                                <td>
                                                                    <em>50 × 60 cm</em>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div
                                                    className={`tab-pane p-5 fade${tabs === 'review'
                                                        ? ' show active'
                                                        : ''}`}
                                                >
                                                    <h5>
                                                        <span>1</span> รีวิวสินค้า <span>{details.product.name}</span>
                                                    </h5>
                                                    <div className='media mt-3 mb-4'>
                                                        <img
                                                            className='d-flex mr-3 z-depth-1'
                                                            src='https://mdbootstrap.com/img/Photos/Others/placeholder1.jpg'
                                                            width={62}
                                                            alt='Generic placeholder image'
                                                        />
                                                        <div className='media-body'>
                                                            <div className='d-sm-flex justify-content-between'>
                                                                <p className='mt-1 mb-2'>
                                                                    <strong>Marthasteward </strong>
                                                                    <span>– </span>
                                                                    <span>January 28, 2020</span>
                                                                </p>
                                                                <div className='ratings d-flex flex-row align-items-center'>
                                                                    <div className='d-flex flex-row'>
                                                                        <i className='fas fa-star' />
                                                                        <i className='fas fa-star' />
                                                                        <i className='fas fa-star' />
                                                                        <i className='fas fa-star-half-alt' />
                                                                        <i className='far fa-star' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className='mb-0'>Nice one, love it!</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <h5 className='mt-4'>เขียนรีวิวสินค้า</h5>
                                                    <p>อีเมลของคุณจะไม่ถูกเผยแพร่สู่ภายนอก</p>
                                                    <div className='my-3'>
                                                        <div className='ratings d-flex flex-row align-items-center'>
                                                            <div className='d-flex flex-row'>
                                                                <i className='fas fa-star' />
                                                                <i className='fas fa-star' />
                                                                <i className='fas fa-star' />
                                                                <i className='fas fa-star-half-alt' />
                                                                <i className='far fa-star' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='form-group'>
                                                            <label>ชื่อของคุณ</label>
                                                            <input type='text' name='name' className='form-control' />
                                                        </div>
                                                        <div className='form-group'>
                                                            <label>อีเมลของคุณ</label>
                                                            <input type='text' name='email' className='form-control' />
                                                        </div>
                                                        <div className='form-group'>
                                                            <label>รีวิวของคุณ</label>
                                                            <TextareaAutosize
                                                                name='review'
                                                                className='form-control h-100px'
                                                            />
                                                        </div>
                                                        <div className='text-right pb-2'>
                                                            <button type='button' className='btn btn-primary px-12'>
                                                                ยืนยัน
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
