import React, { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';

import NumberFormat from 'react-number-format';

import QRCodeReact from 'qrcode.react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import config from '../../environments/config';

import { DealerTable } from './Table';
import { SubDealerTable } from './SubTable';
import { SubscriberList } from '../../../Setting';

const myCustomLocale = {
    // months list by order
    months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
    ]
};

export function DealerGroupReport() {
    const [ loading, setLoading ] = useState(true);
    const [ numberlist, setNumberList ] = useState([]);
    // const [phonenumber, setPhonenumber] = useState("");

    const [ requestdate, setRequestDate ] = useState('');
    const [ result, setResult ] = useState(false);
    const [ dealername, setDealername ] = useState('');
    const [ dealerposition, setDealerposition ] = useState('');
    // const [tieramount, setTieramount] = useState(0);
    const [ dealeramount, setDealeramount ] = useState(0);
    const [ greencard, setGreencard ] = useState(0);
    const [ redcard, setRedcard ] = useState(0);
    const [ blackcard, setBlackcard ] = useState(0);
    const [ freedom, setFreedom ] = useState(0);
    const [ traineedealer, setTraineeDealer ] = useState(0);
    const [ basicdealer, setBasicDealer ] = useState(0);
    const [ stardealer, setStarDealer ] = useState(0);
    const [ superstardealer, setSuperstarDealer ] = useState(0);
    const [ elitedealer, setEliteDealer ] = useState(0);
    const [ undealeramount, setUndealeramount ] = useState(0);
    const [ child, setChild ] = useState({});
    const [ selected, setSelected ] = useState(0);
    const [ active, setActive ] = useState(null);

    const [ qrcodetype, setQrcodeType ] = useState('');
    const [ qrcode, setQrcode ] = useState('');
    const [ ordernumber, setOrdernumber ] = useState('');
    const [ price, setPrice ] = useState('');
    const [ orderstatus, setOrderstatus ] = useState('');
    const [ regisnumber, setRegisNumber ] = useState('');
    const [ payment, setPayment ] = useState('');
    const [ contactnumber, setContactnumber ] = useState('');
    const [ showModal, setShow ] = useState(false);
    const [ showDealerModal, setShowDealer ] = useState(false);

    const [ subdealerres, setSubdealerres ] = useState({});
    const [ subdealername, setSubdealername ] = useState('');
    const [ subdealerphonenumber, setSubdealerphonenumber ] = useState('');
    const [ subdealerposition, setSubdealerposition ] = useState('');
    const [ subdealernextposition, setSubdealernextposition ] = useState('');
    const [ subdealerrequireunit, setSubdealerrequireunit ] = useState('');
    const [ subdealerchild, setSubdealerchild ] = useState({});
    const [ subdealerselected, setSubdealerselected ] = useState(0);
    const [ greencardsubdealer, setGreencardSubDealer ] = useState(0);
    const [ redcardsubdealer, setRedcardSubDealer ] = useState(0);
    const [ blackcardsubdealer, setBlackcardSubDealer ] = useState(0);
    const [ freedomsubdealer, setFreedomSubDealer ] = useState(0);
    const [ traineesubdealer, setTraineeSubDealer ] = useState(0);
    const [ basicsubdealer, setBasicSubDealer ] = useState(0);
    const [ starsubdealer, setStarSubDealer ] = useState(0);
    const [ superstarsubdealer, setSuperstarSubDealer ] = useState(0);
    const [ elitesubdealer, setEliteSubDealer ] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDealerClose = () => setShowDealer(false);
    const handleDealerShow = () => setShowDealer(true);

    const ref = useRef(null);

    const refqrcode = useRef(null);

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        getNumberList(user.id);
    }, []);

    const download = () => {
        const canvas: any = document.querySelector('.qrimg > canvas');
        refqrcode.current.href = canvas.toDataURL();
        refqrcode.current.download = `${ordernumber}.png`;
    };

    const scroll = (size, option) => {
        const osInstance = ref.current.osInstance();
        if (osInstance) {
            osInstance.scrollStop().scroll(
                {
                    x: `${option}= ${size}px`
                },
                1000,
                'easeOutCubic'
            );
        }
    };

    const navigate = (id, phonenumber) => {
        setActive(id);
        setSelected(0);
        getOrganize(phonenumber);
    };

    const getNumberList = (memberid) => {
        fetch(config.py_url + config.version + '/profile/phonenumber_list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    // let numbers = [];
                    // let numbers = [
                    //     {
                    //         phonenumber: "0976935555",
                    //         position: "SS",
                    //         feels_kyc: 1,
                    //     },
                    //     {
                    //         phonenumber: "0976515977",
                    //         position: "S",
                    //         feels_kyc: 1,
                    //     },
                    // ];
                    // let index = 0;
                    // let index = 2;
                    // for (const prop in result.numbers) {
                    //     // numbers[index] = {
                    //     //     value: result.numbers[prop]["phonenumber"],
                    //     //     label: result.numbers[prop]["phonenumber"],
                    //     // };
                    //     numbers[index] = {
                    //         phonenumber: result.numbers[prop]["phonenumber"],
                    //         position: result.numbers[prop]["position"],
                    //         feels_kyc: result.numbers[prop]["feels_kyc"],
                    //         preserve_date:
                    //             result.numbers[prop]["preserve_date"],
                    //     };
                    //     index += 1;
                    // }
                    // setNumberList(numbers);
                    // console.log(numbers);
                    setNumberList(result.numbers);
                    // console.log(numberlist);
                    // setNumberList
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    // const selectAccount = (item) => {
    //     if (item) {
    //         setPhonenumber(item);
    //     }
    // };

    const isNumeric = (num) => {
        return !isNaN(num);
    };

    const viewData = (index) => {
        if (selected === index) {
            setSelected(0);
        } else {
            setSelected(index);
        }
    };

    const viewDealerData = (index) => {
        if (subdealerselected === index) {
            setSubdealerselected(0);
        } else {
            setSubdealerselected(index);
        }
    };

    const getOrganize = (phonenumber) => {
        setLoading(true);
        // setTree({});
        let request = new Date();
        request.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
        let requestdatestr =
            'ข้อมูล ณ วันที่ ' +
            request.getDate() +
            ' ' +
            myCustomLocale.months[request.getMonth()] +
            ' ' +
            request.getFullYear() +
            ' เวลา ' +
            request.getHours() +
            ':' +
            request.getMinutes() +
            ':' +
            request.getSeconds() +
            ' น.';
        setRequestDate(requestdatestr);
        let postparam = {};
        // if (phonenumber === "0976935555") {
        //     postparam = {
        //         userid: "1",
        //         phonenumber: phonenumber,
        //         max_level: "5",
        //     };
        // } else if (phonenumber === "0976515977") {
        //     postparam = {
        //         userid: "7782",
        //         phonenumber: phonenumber,
        //         max_level: "5",
        //     };
        // } else {
        postparam = {
            userid: user.id,
            phonenumber: phonenumber,
            max_level: '5'
        };
        // }
        fetch(config.py_url + config.version + '/organize/getchild', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResult(true);
                // console.log(result);
                if (result.responsecode === 0) {
                    setDealername(Object.values(result.organize['0'].detail)[0].name);
                    setDealerposition(Object.values(result.organize['0'].detail)[0].position);
                    // setTieramount(result.organize.max_level);
                    setDealeramount(result.organize.total_count);
                    setUndealeramount(result.organize.total_incomplete_count);
                    setGreencard(Object.values(result.organize['0'].detail)[0].team.team['GC']);
                    setRedcard(Object.values(result.organize['0'].detail)[0].team.team['RC']);
                    setBlackcard(Object.values(result.organize['0'].detail)[0].team.team['BC']);
                    setFreedom(Object.values(result.organize['0'].detail)[0].team.team['F']);
                    setTraineeDealer(Object.values(result.organize['0'].detail)[0].team.team['T']);
                    setBasicDealer(Object.values(result.organize['0'].detail)[0].team.team['B']);
                    setStarDealer(Object.values(result.organize['0'].detail)[0].team.team['S']);
                    setSuperstarDealer(Object.values(result.organize['0'].detail)[0].team.team['SS']);
                    setEliteDealer(Object.values(result.organize['0'].detail)[0].team.team['E']);
                    setChild(result.organize);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getRegisDetail = (phonenumber) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/order/get_register_order', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                phonenumber: phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // setResult(true);
                // console.log(result);
                if (result.responsecode === 0) {
                    setOrdernumber(result.list['0'].order_number);
                    if (result.list['0'].qrcode.includes('base64,')) {
                        setQrcodeType('base64');
                    }
                    setQrcode(result.list['0'].qrcode);
                    setPrice(result.list['0'].total);
                    setOrderstatus(result.list['0'].status);
                    setPayment(result.list['0'].payment);
                    setRegisNumber(phonenumber);
                    setContactnumber(result.list['0'].contactnumber);
                    handleShow();
                } else {
                    Swal.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'ไม่พบรายการสั่งซื้อของหมายเลขนี้',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getDealerList = (postparam) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/organize/getchild', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setSubdealerres(result);
                    setSubdealername(Object.values(result.organize['0'].detail)[0].name);
                    setSubdealerphonenumber(Object.values(result.organize['0'].detail)[0].phonenumber);
                    setSubdealerposition(Object.values(result.organize['0'].detail)[0].position);
                    setSubdealernextposition(result.organize.performance.next);
                    setSubdealerrequireunit(result.organize.performance.require_unit);
                    setGreencardSubDealer(Object.values(result.organize['0'].detail)[0].team.team['GC']);
                    setRedcardSubDealer(Object.values(result.organize['0'].detail)[0].team.team['RC']);
                    setBlackcardSubDealer(Object.values(result.organize['0'].detail)[0].team.team['BC']);
                    setFreedomSubDealer(Object.values(result.organize['0'].detail)[0].team.team['F']);
                    setTraineeSubDealer(Object.values(result.organize['0'].detail)[0].team.team['T']);
                    setBasicSubDealer(Object.values(result.organize['0'].detail)[0].team.team['B']);
                    setStarSubDealer(Object.values(result.organize['0'].detail)[0].team.team['S']);
                    setSuperstarSubDealer(Object.values(result.organize['0'].detail)[0].team.team['SS']);
                    setEliteSubDealer(Object.values(result.organize['0'].detail)[0].team.team['E']);
                    setSubdealerchild(result.organize);
                    handleDealerShow();
                } else {
                    Swal.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'ไม่ดึงข้อมูลผังของผู็ใช้งานนี้ได้ กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <div>
            {!loading ? (
                <div className='row'>
                    <div className='flex-row-fluid col-12 mb-5'>
                        <div className='card card-custom card-stretch' id='wallet-info'>
                            <div className='card-header bg-danger text-white'>
                                <div className='card-title'>
                                    <span className='card-icon'>
                                        <i className='fas fa-sim-card text-white' />
                                    </span>
                                    <h3 className='card-label text-white'>เบอร์ที่คุณมี</h3>
                                </div>
                            </div>
                            <div className='card-body pt-4'>
                                <div className='d-flex align-items-center'>
                                    <div className='cards-menu w-100'>
                                        <div className='card-list testimonial-group'>
                                            <div className='text-right d-none d-md-block'>
                                                <button
                                                    className='btn btn-secondary mr-3'
                                                    onClick={() => scroll('200', '-')}
                                                >
                                                    <i className='fas fa-chevron-left' />
                                                </button>
                                                <button
                                                    className='btn btn-secondary'
                                                    onClick={() => scroll('200', '+')}
                                                >
                                                    <i className='fas fa-chevron-right' />
                                                </button>
                                            </div>
                                            <div className='inline-scroll'>
                                                <OverlayScrollbarsComponent className='wv-100' ref={ref}>
                                                    {numberlist.map((object, index) => {
                                                        if (
                                                            object.feels_kyc === 1 ||
                                                            (object.feels_kyc === 0 && object.position !== '')
                                                        ) {
                                                            return (
                                                                <div
                                                                    className='inline-card'
                                                                    key={index}
                                                                    onClick={() => navigate(index, object.phonenumber)}
                                                                >
                                                                    <div
                                                                        className={`card cursor-pointer ${active ===
                                                                        index
                                                                            ? 'sim-active'
                                                                            : ''}`}
                                                                    >
                                                                        <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                            <div className='symbol-label'>
                                                                                <i className='fas fa-sim-card fa-2x' />
                                                                            </div>
                                                                        </div>
                                                                        <p className='card-number'>
                                                                            {object.phonenumber}
                                                                        </p>
                                                                        <p className='validity-date p-0 mb-0'>
                                                                            {object.feels_kyc === 1 ? (
                                                                                <div>
                                                                                    กำหนดรักษาสิทธิ์:{' '}
                                                                                    <span>{object.preserve_date}</span>
                                                                                </div>
                                                                            ) : (
                                                                                <span className='menu-label'>
                                                                                    <span className='label label-warning label-inline'>
                                                                                        ยังไม่ลงทะเบียนซิม
                                                                                    </span>
                                                                                </span>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div className='inline-card' key={index}>
                                                                    <div className='card cursor-disable card-disable'>
                                                                        <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                            <div className='symbol-label'>
                                                                                <i className='fas fa-sim-card fa-2x' />
                                                                            </div>
                                                                        </div>
                                                                        <p className='card-number'>
                                                                            {object.phonenumber}
                                                                        </p>
                                                                        <p className='validity-date p-0'>
                                                                            {object.position === '' && (
                                                                                <span className='menu-label'>
                                                                                    <span className='label label-danger label-inline'>
                                                                                        ไม่พร้อมใช้งาน
                                                                                    </span>
                                                                                </span>
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </OverlayScrollbarsComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12 result-body'>
                        {result && (
                            <div>
                                <div className='doctor-card rounded'>
                                    <div className='info'>
                                        <div className='details'>
                                            <div className='name'>{dealername}</div>
                                        </div>
                                    </div>
                                    <div className='actions mb-0'>
                                        <div className='position'>
                                            <span className='position-amount'>
                                                <strong>
                                                    {dealerposition === 'E' ? (
                                                        'Elite Dealer'
                                                    ) : dealerposition === 'SS' ? (
                                                        'Super Star Dealer'
                                                    ) : dealerposition === 'S' ? (
                                                        'Star Dealer'
                                                    ) : dealerposition === 'B' ? (
                                                        'Basic Dealer'
                                                    ) : dealerposition === 'T' ? (
                                                        'Trainee Dealer'
                                                    ) : dealerposition === 'F' ? (
                                                        'Freedom'
                                                    ) : dealerposition === 'BC' ? (
                                                        'Black Card'
                                                    ) : dealerposition === 'RC' ? (
                                                        'Red Card'
                                                    ) : dealerposition === 'GC' ? (
                                                        'Green Card'
                                                    ) : (
                                                        '-'
                                                    )}
                                                </strong>{' '}
                                                ระดับผลงานปัจจุบัน
                                            </span>
                                        </div>
                                        <div className='comments'>
                                            <span className='comment-count'>
                                                <strong>
                                                    {dealerposition === 'E' || dealerposition === 'SS' ? (
                                                        '7'
                                                    ) : dealerposition === 'S' ? (
                                                        '5'
                                                    ) : dealerposition === 'B' ||
                                                    dealerposition === 'T' ||
                                                    dealerposition === 'F' ? (
                                                        '3'
                                                    ) : (
                                                        '-'
                                                    )}
                                                </strong>
                                                ผลประโยชน์ตามผลงาน
                                            </span>
                                        </div>
                                        <div className='consultation'>
                                            <span className='fee'>
                                                <strong>
                                                    {dealeramount - undealeramount}
                                                    <span className='text-primary'>{' (' + undealeramount + ')'}</span>
                                                </strong>
                                                จำนวน{!SubscriberList.includes(user.main_position) ? (
                                                    'ดีลเลอร์'
                                                ) : (
                                                    'ผู้ใชช้งาน'
                                                )}ที่มีทั้งหมด
                                            </span>
                                        </div>
                                    </div>
                                    <div className='actions'>
                                        <div className='greencard'>
                                            <span className='greencard-count'>
                                                <strong>{greencard}</strong>
                                                GC
                                            </span>
                                        </div>
                                        <div className='redcard'>
                                            <span className='redcard-count'>
                                                <strong>{redcard}</strong>
                                                RC
                                            </span>
                                        </div>
                                        <div className='blackcard'>
                                            <span className='blackcard-count'>
                                                <strong>{blackcard}</strong>
                                                BC
                                            </span>
                                        </div>
                                        <div className='freedom'>
                                            <span className='freedom-count'>
                                                <strong>{freedom}</strong>
                                                F
                                            </span>
                                        </div>
                                        <div className='trainee'>
                                            <span className='trainee-count'>
                                                <strong>{traineedealer}</strong>
                                                T
                                            </span>
                                        </div>
                                        <div className='basic'>
                                            <span className='basic-count'>
                                                <strong>{basicdealer}</strong>
                                                B
                                            </span>
                                        </div>
                                        <div className='star'>
                                            <span className='star-count'>
                                                <strong>{stardealer}</strong>
                                                S
                                            </span>
                                        </div>
                                        <div className='superstar'>
                                            <span className='superstar-count'>
                                                <strong>{superstardealer}</strong>
                                                SS
                                            </span>
                                        </div>
                                        <div className='elite'>
                                            <span className='elite-count'>
                                                <strong>{elitedealer}</strong>
                                                E
                                            </span>
                                        </div>
                                    </div>
                                    <div className='locations text-right'>{requestdate}</div>
                                </div>
                                {Object.keys(child).map((object, index) => {
                                    if (isNumeric(object)) {
                                        if (object !== '0') {
                                            if (SubscriberList.includes(child['performance']['current'])) {
                                                if (parseInt(object) <= 1) {
                                                    return (
                                                        <div className='doctor-card rounded' key={index}>
                                                            <div className='info'>
                                                                <div className='details'>
                                                                    <div className='name'>
                                                                        Sec
                                                                        {' ' + object}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='actions'>
                                                                <div className='sub-count'>
                                                                    <span className='sub-count-amount'>
                                                                        <strong>
                                                                            {child[object]['summary']['count'] -
                                                                                child[object]['summary'][
                                                                                    'incomplete_count'
                                                                                ]}
                                                                            <span className='text-primary'>
                                                                                {' (' +
                                                                                    child[object]['summary'][
                                                                                        'incomplete_count'
                                                                                    ] +
                                                                                    ')'}
                                                                            </span>
                                                                        </strong>{' '}
                                                                        จำนวนดีลเลอร์ที่มีทั้งหมด
                                                                    </span>
                                                                </div>
                                                                <div className='view'>
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-lg mx-auto my-auto'
                                                                        onClick={() => viewData(index)}
                                                                    >
                                                                        เพิ่มเติม
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className='locations'>
                                                                {selected === index && (
                                                                    <div className='card-body p-0 p-md-5'>
                                                                        <DealerTable
                                                                            subdealer={false}
                                                                            data={Object.values(
                                                                                child[object]['detail']
                                                                            )}
                                                                            sec={object}
                                                                            getRegisDetail={getRegisDetail}
                                                                            getDealerList={getDealerList}
                                                                            permission={
                                                                                !SubscriberList.includes(
                                                                                    user.main_position
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            } else {
                                                return (
                                                    <div className='doctor-card rounded' key={index}>
                                                        <div className='info'>
                                                            <div className='details'>
                                                                <div className='name'>
                                                                    Sec
                                                                    {' ' + object}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='actions'>
                                                            <div className='sub-count'>
                                                                <span className='sub-count-amount'>
                                                                    <strong>
                                                                        {child[object]['summary']['count'] -
                                                                            child[object]['summary'][
                                                                                'incomplete_count'
                                                                            ]}
                                                                        <span className='text-primary'>
                                                                            {' (' +
                                                                                child[object]['summary'][
                                                                                    'incomplete_count'
                                                                                ] +
                                                                                ')'}
                                                                        </span>
                                                                    </strong>{' '}
                                                                    จำนวนดีลเลอร์ที่มีทั้งหมด
                                                                </span>
                                                            </div>
                                                            <div className='view'>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-lg mx-auto my-auto'
                                                                    onClick={() => viewData(index)}
                                                                >
                                                                    เพิ่มเติม
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='locations'>
                                                            {selected === index && (
                                                                <div className='card-body p-0 p-md-5'>
                                                                    <DealerTable
                                                                        subdealer={false}
                                                                        data={Object.values(child[object]['detail'])}
                                                                        sec={object}
                                                                        getRegisDetail={getRegisDetail}
                                                                        getDealerList={getDealerList}
                                                                        permission={
                                                                            !SubscriberList.includes(user.main_position)
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                    }
                                })}
                                {Object.keys(child).length === 4 && (
                                    <div className='doctor-card rounded'>
                                        <div className='info'>
                                            <div className='col-xl-12 text-center m-0 my-25 p-0'>
                                                <i className='fas fa-users fa-5x mb-2 text-primary' />
                                                <h4 className='mt-3'>ไม่พบข้อมูลดีลเลอร์</h4>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}

            <Modal
                show={showDealerModal}
                onHide={handleDealerClose}
                size='xl'
                centered
                scrollable={true}
                aria-labelledby='unactive-number-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id='unactive-number-modal' className='col-11 p-0'>
                        รายละเอียดผัง{!SubscriberList.includes(user.main_position) ? 'ดีลเลอร์' : 'ผู้ใชช้งาน'}
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleDealerClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center pb-5'>
                        <div className='col-md-10 col-12'>
                            <div className='bg-light rounded d-flex flex-column p-3 mb-5'>
                                <div className='p-2 d-flex'>
                                    <div className='col-7'>ชื่อ - นามสกุล</div>
                                    <div className='ml-auto text-right'>{subdealername}</div>
                                </div>
                                <div className='p-2 d-flex'>
                                    <div className='col-7'>หมายเลขซิมปันสุข</div>
                                    <div className='ml-auto text-right'>{subdealerphonenumber}</div>
                                </div>
                                <div className='p-2 d-flex'>
                                    <div className='col-7'>ตำแหน่งปัจจุบัน</div>
                                    <div className='ml-auto text-right'>
                                        <span
                                            className={`label label-${subdealerposition === 'E'
                                                ? 'info'
                                                : subdealerposition === 'SS'
                                                  ? 'warning'
                                                  : subdealerposition === 'S'
                                                    ? 'success'
                                                    : subdealerposition === 'B'
                                                      ? 'dark'
                                                      : subdealerposition === 'T'
                                                        ? 'cus-trainee'
                                                        : subdealerposition === 'F'
                                                          ? 'cus-freedom'
                                                          : subdealerposition === 'GC'
                                                            ? 'cus-greencard'
                                                            : subdealerposition === 'RC'
                                                              ? 'cus-redcard'
                                                              : subdealerposition === 'BC'
                                                                ? 'cus-blackcard'
                                                                : 'primary'} label-pill label-inline mx-auto my-auto`}
                                        >
                                            {subdealerposition === 'E' ? (
                                                'Elite Dealer'
                                            ) : subdealerposition === 'SS' ? (
                                                'Super Star Dealer'
                                            ) : subdealerposition === 'S' ? (
                                                'Star Dealer'
                                            ) : subdealerposition === 'B' ? (
                                                'Basic Dealer'
                                            ) : subdealerposition === 'T' ? (
                                                'Trainee Dealer'
                                            ) : subdealerposition === 'F' ? (
                                                'Freedom'
                                            ) : subdealerposition === 'BC' ? (
                                                'Black Card'
                                            ) : subdealerposition === 'RC' ? (
                                                'Red Card'
                                            ) : subdealerposition === 'GC' ? (
                                                'Green Card'
                                            ) : (
                                                'รอการชำระเงิน'
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className='doctor-card col-12 rounded-0 bg-transparent shadow-none'>
                                    <div className='actions'>
                                        <div className='greencard'>
                                            <span className='greencard-count'>
                                                <strong>{greencardsubdealer}</strong>
                                                GC
                                            </span>
                                        </div>
                                        <div className='redcard'>
                                            <span className='redcard-count'>
                                                <strong>{redcardsubdealer}</strong>
                                                RC
                                            </span>
                                        </div>
                                        <div className='blackcard'>
                                            <span className='blackcard-count'>
                                                <strong>{blackcardsubdealer}</strong>
                                                BC
                                            </span>
                                        </div>
                                        <div className='freedom'>
                                            <span className='freedom-count'>
                                                <strong>{freedomsubdealer}</strong>
                                                F
                                            </span>
                                        </div>
                                        <div className='trainee'>
                                            <span className='trainee-count'>
                                                <strong>{traineesubdealer}</strong>
                                                T
                                            </span>
                                        </div>
                                        <div className='basic'>
                                            <span className='basic-count'>
                                                <strong>{basicsubdealer}</strong>
                                                B
                                            </span>
                                        </div>
                                        <div className='star'>
                                            <span className='star-count'>
                                                <strong>{starsubdealer}</strong>
                                                S
                                            </span>
                                        </div>
                                        <div className='superstar'>
                                            <span className='superstar-count'>
                                                <strong>{superstarsubdealer}</strong>
                                                SS
                                            </span>
                                        </div>
                                        <div className='elite'>
                                            <span className='elite-count'>
                                                <strong>{elitesubdealer}</strong>
                                                E
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-2 d-flex'>
                                    <div className='col-7'>จำนวนดีลเลอร์ที่มีทั้งหมด</div>
                                    <div className='ml-auto text-right'>
                                        {Object.keys(subdealerres).length > 0 ? (
                                            subdealerres['organize']['total_count'] -
                                            subdealerres['organize']['total_incomplete_count']
                                        ) : null}
                                        <span className='text-primary'>
                                            {Object.keys(subdealerres).length > 0 ? (
                                                ' (' + subdealerres['organize']['total_incomplete_count'] + ')'
                                            ) : null}
                                        </span>
                                    </div>
                                </div>
                                <div className='p-2 d-flex'>
                                    <div className='col-7'>ตำแหน่งถัดไป</div>
                                    <div className='ml-auto text-right'>
                                        <span
                                            className={`label label-${subdealernextposition === 'E'
                                                ? 'info'
                                                : subdealernextposition === 'SS'
                                                  ? 'warning'
                                                  : subdealernextposition === 'S'
                                                    ? 'success'
                                                    : subdealernextposition === 'B'
                                                      ? 'dark'
                                                      : 'primary'} label-pill label-inline mx-auto my-auto`}
                                        >
                                            {subdealernextposition === 'E' ? (
                                                'Elite Dealer'
                                            ) : subdealernextposition === 'SS' ? (
                                                'Super Star Dealer'
                                            ) : subdealernextposition === 'S' ? (
                                                'Star Dealer'
                                            ) : subdealernextposition === 'B' ? (
                                                'Basic Dealer'
                                            ) : (
                                                'รอการชำระเงิน'
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className='p-2 d-flex'>
                                    <div className='col-7'>จำนวนผู้แนะนำใหม่ที่ต้องการ</div>
                                    <div className='ml-auto text-right'>
                                        {Object.keys(subdealerres).length > 0 ? (
                                            subdealerres.organize.performance.score +
                                            '/' +
                                            subdealerres.organize.performance.max +
                                            ' '
                                        ) : null}
                                        <span
                                            className={`label label-${subdealerrequireunit === 'E'
                                                ? 'info'
                                                : subdealerrequireunit === 'SS'
                                                  ? 'warning'
                                                  : subdealerrequireunit === 'S'
                                                    ? 'success'
                                                    : subdealerrequireunit === 'B'
                                                      ? 'dark'
                                                      : 'primary'} label-pill label-inline mx-auto my-auto`}
                                        >
                                            {subdealerrequireunit === 'E' ? (
                                                'Elite Dealer'
                                            ) : subdealerrequireunit === 'SS' ? (
                                                'Super Star Dealer'
                                            ) : subdealerrequireunit === 'S' ? (
                                                'Star Dealer'
                                            ) : subdealerrequireunit === 'B' ? (
                                                'Basic Dealer'
                                            ) : (
                                                'รอการชำระเงิน'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {Object.keys(subdealerchild).map((object, index) => {
                                if (isNumeric(object)) {
                                    if (object !== '0') {
                                        if (!SubscriberList.includes(child['performance']['current'])) {
                                            return (
                                                <div className='doctor-card rounded' key={index}>
                                                    <div className='info'>
                                                        <div className='details'>
                                                            <div className='name'>
                                                                Sec
                                                                {' ' + object}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='actions'>
                                                        <div className='sub-count'>
                                                            <span className='sub-count-amount'>
                                                                <strong>
                                                                    {subdealerchild[object]['summary']['count'] -
                                                                        subdealerchild[object]['summary'][
                                                                            'incomplete_count'
                                                                        ]}
                                                                    <span className='text-primary'>
                                                                        {' (' +
                                                                            subdealerchild[object]['summary'][
                                                                                'incomplete_count'
                                                                            ] +
                                                                            ')'}
                                                                    </span>
                                                                </strong>{' '}
                                                                จำนวนดีลเลอร์ที่มีทั้งหมด
                                                            </span>
                                                        </div>
                                                        <div className='view'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-lg mx-auto my-auto'
                                                                onClick={() => viewDealerData(index)}
                                                            >
                                                                เพิ่มเติม
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='locations'>
                                                        {subdealerselected === index && (
                                                            <div className='card-body p-0 p-md-5'>
                                                                <SubDealerTable
                                                                    subdealer={true}
                                                                    data={Object.values(
                                                                        subdealerchild[object]['detail']
                                                                    )}
                                                                    sec={object}
                                                                    getRegisDetail={getRegisDetail}
                                                                    getDealerList={getDealerList}
                                                                    permission={
                                                                        !SubscriberList.includes(user.main_position)
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }
                                }
                            })}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleClose} size='lg' centered aria-labelledby='unactive-number-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='unactive-number-modal' className='col-11 p-0'>
                        คิวอาร์โค้ด
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-12'>
                            <div className='row justify-content-center col-12 p-0 m-0'>
                                <div className='text-center rounded bg-payment p-1'>
                                    <div className='text-white text-center'>หมายเลขคำสั่งซื้อ {ordernumber}</div>
                                    {payment === 'QR-Code' ? (
                                        <div className='qrimg p-0 col-12'>
                                            {qrcodetype === '' ? (
                                                <QRCodeReact
                                                    value={qrcode}
                                                    size={256}
                                                    id='qrcode'
                                                    includeMargin={true}
                                                />
                                            ) : (
                                                <img alt='qrcode' src={qrcode} className='p-2 w-100' />
                                            )}
                                        </div>
                                    ) : (
                                        <div className='wallet'>
                                            <img
                                                alt='qrcode'
                                                src='https://cdn.simk4.com/payment/wallet.png'
                                                className='p-2 max-h-250px'
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mt-5'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='bg-light rounded d-flex flex-column p-3'>
                                        <div className='p-2 d-flex'>
                                            <div className='col-7'>เบอร์ที่สมัครสมาชิก</div>
                                            <div className='ml-auto text-right'>{regisnumber}</div>
                                        </div>
                                        <div className='p-2 d-flex'>
                                            <div className='col-8'>สถานะคำสั่งซื้อ</div>
                                            <div className='ml-auto'>
                                                {orderstatus === 'pending' ? (
                                                    <span
                                                        className={`badge badge-${payment === 'QR-Code' && qrcode === ''
                                                            ? 'warning'
                                                            : 'secondary'} mx-auto my-auto`}
                                                    >
                                                        {payment === 'QR-Code' && qrcode === '' ? (
                                                            'มีปัญหา'
                                                        ) : (
                                                            'รอการชำระเงิน'
                                                        )}
                                                    </span>
                                                ) : orderstatus === 'paid' ? (
                                                    <span className='badge badge-success mx-auto my-auto'>
                                                        ชำระเรียบร้อยแล้ว
                                                    </span>
                                                ) : (
                                                    <span className='badge badge-danger mx-auto my-auto'>หมดอายุ</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className='p-2 d-flex'>
                                            <div className='col-8'>ชำระเงินผ่าน</div>
                                            <div className='ml-auto'>
                                                {payment === 'QR-Code' ? 'Thai QR Payment' : 'กระเป๋าซิมปันสุข'}
                                            </div>
                                        </div>
                                        <div className='p-2 d-flex'>
                                            <div className='col-8'>เบอร์ติดต่อ</div>
                                            <div className='ml-auto'>{contactnumber}</div>
                                        </div>
                                        <div className='border-top px-4 mx-3' />
                                        <div className='p-2 d-flex pt-3'>
                                            <div className='col-8'>
                                                <b>ยอดรวม</b>
                                            </div>
                                            <div className='ml-auto'>
                                                <b className='green'>
                                                    <NumberFormat
                                                        value={parseFloat(price)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        suffix={' บาท'}
                                                    />
                                                </b>
                                            </div>
                                        </div>
                                        <div className='m-3'>
                                            <a
                                                href={'#qrcode'}
                                                ref={refqrcode}
                                                className={`btn btn-success ${orderstatus === 'pending' &&
                                                payment === 'QR-Code' &&
                                                qrcode !== ''
                                                    ? ''
                                                    : 'disabled'} btn-block font-weight-bold text-uppercase px-6 py-2`}
                                                onClick={() => {
                                                    if (
                                                        orderstatus === 'pending' &&
                                                        payment === 'QR-Code' &&
                                                        qrcode !== ''
                                                    ) {
                                                        download();
                                                    } else {
                                                        return false;
                                                    }
                                                }}
                                            >
                                                บันทึกภาพ
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
