import React, { useState, useEffect, useRef } from "react";

import Select, { components } from "react-select";

import { useSelector } from "react-redux";

import { Modal } from "react-bootstrap";

import NumberFormat from "react-number-format";

import QRCodeReact from "qrcode.react";

import DatePicker, { utils } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

// import $ from "jquery";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import config from "../../environments/config";

import { OrderTable } from "./Table";

const myCustomLocale = {
    // months list by order
    months: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
    ],

    // week days by order
    weekDays: [
        {
            name: "อาทิตย์", // used for accessibility
            short: "อา", // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: "จันทร์",
            short: "จ",
        },
        {
            name: "อังคาร",
            short: "อ",
        },
        {
            name: "พุธ",
            short: "พ",
        },
        {
            name: "พฤหัสบดี",
            short: "พฤ",
        },
        {
            name: "ศุกร์",
            short: "ศ",
        },
        {
            name: "เสาร์",
            short: "ส",
            isWeekend: true,
        },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },

    // texts in the date picker
    nextMonth: "Next Month",
    previousMonth: "Previous Month",
    openMonthSelector: "Open Month Selector",
    openYearSelector: "Open Year Selector",
    closeMonthSelector: "Close Month Selector",
    closeYearSelector: "Close Year Selector",
    defaultPlaceholder: "Select...",

    // for input range value
    from: "from",
    to: "to",

    // used for input value when multi dates are selected
    digitSeparator: ",",

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
};

const minimumDate = {
    year: 2020,
    month: 12,
    day: 31,
};

const controlStyles = {
    borderRadius: "0.85rem",
    padding: "5px",
    background: "#F3F6F9",
    color: "white",
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#F3F6F9",
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
    }),
    // singleValue: (provided, state) => {
    //     const opacity = state.isDisabled ? 0.5 : 1;
    //     const transition = "opacity 300ms";
    //     return { ...provided, opacity, transition };
    // },
};

const selectList = [
    {
        label: "เติมเงิน/แพ็กเกจ",
        value: "Q",
    },
    {
        label: "สมัครสมาชิก/เพิ่มสิทธิ",
        value: "A",
    },
];

export function OrderReport() {
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState({
        from: null,
        to: null,
    });
    const [numberlist, setNumberList] = useState([]);
    const [phonenumber, setPhonenumber] = useState("");
    const [ordertype, setOrderType] = useState("");
    const [title, setTitle] = useState("");
    const [rawdata, setRawData] = useState(null);
    const { user } = useSelector((state) => state.auth);

    const [qrcodetype, setQrcodeType] = useState("");
    const [qrcode, setQrcode] = useState("");
    const [ordernumber, setOrdernumber] = useState("");
    const [price, setPrice] = useState("");
    const [orderstatus, setOrderstatus] = useState("");
    // const [regisnumber, setRegisNumber] = useState("");
    const [payment, setPayment] = useState("");
    const [showModal, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const ref = useRef(null);

    useEffect(() => {
        getNumberList(user.id);
    }, []);

    // useEffect(() => {
    //     $(document).ready(function () {
    //         // if (ordertype.value === "Q") {
    //         $("#kt_datatable").DataTable({
    //             data: rawdata,
    //             lengthChange: false,
    //             responsive: true,
    //             dom: "Bfrtip",
    //             buttons: ["excel"],
    //             order: [[1, "asc"]],
    //             language: {
    //                 paginate: {
    //                     previous: "ก่อนหน้า",
    //                     next: "ถัดไป",
    //                 },
    //                 info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
    //                 infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
    //                 emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
    //                 infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
    //                 zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
    //             },
    //             oLanguage: {
    //                 sSearch: "คำสำคัญ",
    //             },
    //             columns: [
    //                 {
    //                     title: "หมายเลขคำสั่งซื้อ",
    //                     data: "order_number",
    //                 },
    //                 {
    //                     title: "วันที่สั่งซื้อ",
    //                     data: "order_date",
    //                 },
    //                 {
    //                     title: "ชื่อสมาชิก",
    //                     data: function (value) {
    //                         if (value.name !== null && value.name !== "") {
    //                             return value.name;
    //                         } else {
    //                             return "-";
    //                         }
    //                     },
    //                 },
    //                 {
    //                     title: "ช่องทางชำระเงิน",
    //                     data: function (value) {
    //                         if (value.payment === "QR-Code") {
    //                             return "คิวอาร์โค้ด";
    //                         } else {
    //                             return "กระเป๋าซิมปันสุข";
    //                         }
    //                     },
    //                 },
    //                 {
    //                     title: "สถานะคำสั่งซื้อ",
    //                     data: function (value) {
    //                         if (value.status === "cancel") {
    //                             return '<span class="badge badge-danger mx-auto my-auto">หมดอายุ</span>';
    //                         } else if (value.status === "paid") {
    //                             return '<span class="badge badge-success mx-auto my-auto">ชำระเรียบร้อยแล้ว</span>';
    //                         } else if (value.status === "pending") {
    //                             if (
    //                                 value.payment === "QR-Code" &&
    //                                 value.qrcode === ""
    //                             ) {
    //                                 return '<span class="badge badge-warning mx-auto my-auto">มีปัญหา</span>';
    //                             } else {
    //                                 return '<span class="badge badge-secondary mx-auto my-auto">รอการชำระเงิน</span>';
    //                             }
    //                         }
    //                         // if (value.invoice_number !== 0) {
    //                         //     return '<span class="badge badge-success mx-auto my-auto">ชำระเรียบร้อยแล้ว</span>';
    //                         // } else if (value.invoice_number === 0) {
    //                         //     if (value.status === "cancel") {
    //                         //         return '<span class="badge badge-danger mx-auto my-auto">หมดอายุ</span>';
    //                         //     } else {
    //                         //         if (
    //                         //             value.payment === "QR-Code" &&
    //                         //             value.qrcode === ""
    //                         //         ) {
    //                         //             return '<span class="badge badge-warning mx-auto my-auto">มีปัญหา</span>';
    //                         //         } else {
    //                         //             return '<span class="badge badge-secondary mx-auto my-auto">รอการชำระเงิน</span>';
    //                         //         }
    //                         //     }
    //                         // }
    //                     },
    //                 },
    //                 {
    //                     title: "หมายเลข",
    //                     data: function (value) {
    //                         if (
    //                             value.phonenumber !== null &&
    //                             value.phonenumber !== ""
    //                         ) {
    //                             return (
    //                                 value.phonenumber.slice(0, 3) +
    //                                 "-" +
    //                                 value.phonenumber.slice(3, 6) +
    //                                 "-" +
    //                                 value.phonenumber.slice(6)
    //                             );
    //                         } else {
    //                             return "-";
    //                         }
    //                     },
    //                 },
    //                 {
    //                     title: "ราคา",
    //                     data: "total",
    //                     // data: null,
    //                     // render: function (data, type, row) {
    //                     //     return (
    //                     //         <NumberFormat
    //                     //             value={parseFloat(data.total)}
    //                     //             displayType={"text"}
    //                     //             decimalScale={2}
    //                     //             fixedDecimalScale={true}
    //                     //             thousandSeparator={true}
    //                     //             suffix={" บาท"}
    //                     //         />
    //                     //     );
    //                     // },
    //                 },
    //                 {
    //                     title: "ดูรายละเอียด",
    //                     className: "dt-body-center",
    //                     data: null,
    //                     render: function (data, type, row) {
    //                         if (
    //                             data.status === "pending" &&
    //                             data.payment === "QR-Code" &&
    //                             data.qrcode !== ""
    //                         ) {
    //                             return `<button
    //                                             type="button"
    //                                             class="btn btn-icon btn-primary view-info"
    //                                             data-qrcode=${data.qrcode}
    //                                             data-order=${data.order_number}
    //                                             data-price=${data.total}
    //                                             >
    //                                                 <i class="fa fa-search"></i>
    //                                         </button>`;
    //                         } else {
    //                             return "";
    //                         }
    //                     },
    //                 },
    //                 // {
    //                 //     title: "เปอร์เซ็นต์",
    //                 //     data: "percent",
    //                 // },
    //                 // {
    //                 //     title: "คอมมิชชั่น",
    //                 //     data: "comm",
    //                 // },
    //             ],
    //         });
    //         // }
    //     });
    // }, [rawdata]);

    // useEffect(() => {
    //     $(document).ready(function () {
    //         $(".view-info").click(function () {
    //             // console.log(event.target.value);
    //             setOrdernumber($(this).attr("data-order"));
    //             setQrcode($(this).attr("data-qrcode"));
    //             setPrice($(this).attr("data-price"));
    //             handleShow();
    //         });
    //     });
    // });

    const download = () => {
        const canvas: any = document.querySelector(".qrimg > canvas");
        ref.current.href = canvas.toDataURL();
        ref.current.download = `${ordernumber}.png`;
    };

    const getNumberList = (memberid) => {
        fetch(config.py_url + config.version + "/profile/phonenumber_list", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                userid: memberid,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    let index = 0;
                    let numbers = [];
                    for (const prop in result.numbers) {
                        numbers[index] = {
                            value: result.numbers[prop]["phonenumber"],
                            label: result.numbers[prop]["phonenumber"],
                        };
                        index += 1;
                    }
                    setNumberList(numbers);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const selectAccount = (item) => {
        if (item) {
            setPhonenumber(item);
        }
    };

    const selectType = (item) => {
        if (item) {
            setOrderType(item);
        }
    };

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to
                    ? date.from.year +
                      "/" +
                      ("0" + date.from.month).slice(-2) +
                      "/" +
                      ("0" + date.from.day).slice(-2) +
                      " - " +
                      date.to.year +
                      "/" +
                      ("0" + date.to.month).slice(-2) +
                      "/" +
                      ("0" + date.to.day).slice(-2)
                    : ""
            }
            className="form-control form-control-lg form-control-solid"
            type="text"
            id="dateperiod"
            name="dateperiod"
        />
    );

    const handleSearch = () => {
        if (phonenumber === "") {
            Swal.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกเบอร์ที่ต้องการดูรายการสั่งซื้อ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        if (ordertype === "") {
            Swal.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกประเภทรายการสั่งซื้อ",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        let postparam = {
            userid: user.id,
            filters: {
                phonenumber: phonenumber.value,
                type: ordertype.value,
            },
            options: ["detail"],
        };
        if (date.from !== null && date.to !== null) {
            postparam["filters"]["start"] =
                date.from.year +
                "-" +
                ("0" + date.from.month).slice(-2) +
                "-" +
                ("0" + date.from.day).slice(-2);
            postparam["filters"]["end"] =
                date.to.year +
                "-" +
                ("0" + date.to.month).slice(-2) +
                "-" +
                ("0" + date.to.day).slice(-2);
        }
        // if (ordertype.value === "A") {
        setTitle(ordertype.label);
        // } else if (ordertype.value === "Q") {
        //     setTitle("การเติมเงิน");
        // }
        setLoading(true);
        getOrder(postparam);
    };

    const getOrder = (postparam) => {
        setRawData(null);
        fetch(config.py_url + config.version + "/order/getmemberorder", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setRawData(result.list);
                    //     let index = 0;
                    //     let numbers = [];
                    //     for (const prop in result.numbers) {
                    //         numbers[index] = {
                    //             value: result.numbers[prop]["phonenumber"],
                    //             label: result.numbers[prop]["phonenumber"],
                    //         };
                    //         index += 1;
                    //     }
                    //     setNumberList(numbers);
                } else {
                    setRawData({});
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    // console.log(rawdata);

    return (
        <>
            {!loading ? (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-custom">
                            <div className="card-header bg-danger text-white">
                                <div className="card-title">
                                    <span className="card-icon">
                                        <i className="fa fa-search text-white" />
                                    </span>
                                    <h3 className="card-label text-white">
                                        ค้นหา
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="name">บัญชี</label>
                                        <Select
                                            components={{
                                                Control: ControlComponent,
                                            }}
                                            noOptionsMessage={() =>
                                                "ไม่พบข้อมูล"
                                            }
                                            value={phonenumber}
                                            options={numberlist}
                                            placeholder="เลือก"
                                            id="account"
                                            onChange={selectAccount}
                                            // isClearable={true}
                                            menuPortalTarget={document.body}
                                            styles={customStyles}
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="filterorderstatus">
                                            ประเภทรายการสั่งซื้อ
                                        </label>
                                        <Select
                                            components={{
                                                Control: ControlComponent,
                                            }}
                                            noOptionsMessage={() =>
                                                "ไม่พบข้อมูล"
                                            }
                                            value={ordertype}
                                            options={selectList}
                                            placeholder="เลือก"
                                            id="account"
                                            onChange={selectType}
                                            // isClearable={true}
                                            menuPortalTarget={document.body}
                                            styles={customStyles}
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="filterorderdate">
                                            วันที่ทำธุรกรรม
                                        </label>
                                        <DatePicker
                                            value={date}
                                            onChange={setDate}
                                            renderInput={renderCustomInput}
                                            minimumDate={minimumDate}
                                            maximumDate={utils().getToday()}
                                            locale={myCustomLocale}
                                            wrapperClassName="col-12 p-0"
                                            calendarClassName="responsive-calendar"
                                            calendarPopperPosition="bottom"
                                            shouldHighlightWeekends
                                        />
                                        <span className="form-text text-muted">
                                            หากไม่ได้เลือกจะดึงข้อมูลของวันนี้
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button
                                    type="button"
                                    onClick={handleSearch}
                                    className="btn btn-sm btn-primary"
                                >
                                    <i className="far fa-dot-circle"></i> ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                    {rawdata !== null && (
                        <div className="col-lg-12">
                            <div className="card card-custom">
                                <div className="card-header bg-danger text-white">
                                    <div className="card-title">
                                        <h3 className="card-label text-white">
                                            รายงาน {title}
                                            {/* {commissiontype.value ===
                                            "faststart"
                                                ? "ค่าแนะนำ"
                                                : commissiontype.value ===
                                                  "topup"
                                                ? "การเติมเงิน"
                                                : "One Time Reward"} */}
                                        </h3>
                                    </div>
                                    {/* <div className="card-toolbar">
                                        <div className="row">
                                            <h3 className="card-label text-white col-12 p-0 text-right">
                                                ยอดรวม{" "}
                                                <span>
                                                    <NumberFormat
                                                        value={parseFloat(
                                                            comtotal
                                                        )}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                    />
                                                </span>
                                            </h3>
                                            <p className="card-label text-white col-12 p-0 m-0 text-right">
                                                ยอดรวมที่คาดว่าจะได้วันนี้{" "}
                                                <span>
                                                    <NumberFormat
                                                        value={parseFloat(
                                                            dailycomtotal
                                                        )}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                    />
                                                </span>
                                            </p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="card-body">
                                    {Object.keys(rawdata).length > 0 ? (
                                        <OrderTable
                                            data={rawdata}
                                            ordertype={ordertype.value}
                                            setQrcodeType={setQrcodeType}
                                            setOrdernumber={setOrdernumber}
                                            setOrderstatus={setOrderstatus}
                                            setQrcode={setQrcode}
                                            setPrice={setPrice}
                                            setPayment={setPayment}
                                            handleShow={handleShow}
                                        />
                                    ) : (
                                        <div className="col-xl-12 text-center m-0 my-25 p-0">
                                            <i className="fas fa-exclamation-circle fa-5x mb-2 text-primary"></i>
                                            <h4 className="mt-3">
                                                ไม่พบข้อมูลที่ต้องการค้นหา
                                            </h4>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}

            <Modal
                show={showModal}
                onHide={handleClose}
                size="lg"
                centered
                aria-labelledby="unactive-number-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="unactive-number-modal"
                        className="col-11 p-0"
                    >
                        คิวอาร์โค้ด
                    </Modal.Title>
                    <button
                        type="button"
                        className="close col-1 text-right pr-3"
                        onClick={handleClose}
                    >
                        <i className="ki ki-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center pb-5">
                        <div className="col-12">
                            <div className="row justify-content-center col-12 p-0 m-0">
                                <div className="text-center rounded bg-payment p-1">
                                    <div className="text-white text-center">
                                        หมายเลขคำสั่งซื้อ {ordernumber}
                                    </div>
                                    {payment === "QR-Code" ? (
                                        <div className="qrimg p-0 col-12">
                                            {qrcodetype === "" ? (
                                                <QRCodeReact
                                                    value={qrcode}
                                                    size={256}
                                                    id="qrcode"
                                                    includeMargin={true}
                                                />
                                            ) : (
                                                <img
                                                    alt="qrcode"
                                                    src={qrcode}
                                                    className="p-2 w-100"
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="wallet">
                                            <img
                                                alt="qrcode"
                                                src="https://cdn.simk4.com/payment/wallet.png"
                                                className="m-1 max-h-250px"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className="bg-light rounded d-flex flex-column p-3">
                                        {/* <div className="p-2 d-flex">
                                            <div className="col-7">
                                                เบอร์ที่สมัครสมาชิก
                                            </div>
                                            <div className="ml-auto text-right">
                                                {regisnumber}
                                            </div>
                                        </div> */}
                                        <div className="p-2 d-flex">
                                            <div className="col-8">
                                                สถานะคำสั่งซื้อ
                                            </div>
                                            <div className="ml-auto">
                                                {orderstatus === "pending" ? (
                                                    <span
                                                        className={`badge badge-${
                                                            payment ===
                                                                "QR-Code" &&
                                                            qrcode === ""
                                                                ? "warning"
                                                                : "secondary"
                                                        } mx-auto my-auto`}
                                                    >
                                                        {payment ===
                                                            "QR-Code" &&
                                                        qrcode === ""
                                                            ? "มีปัญหา"
                                                            : "รอการชำระเงิน"}
                                                    </span>
                                                ) : orderstatus === "paid" ? (
                                                    <span className="badge badge-success mx-auto my-auto">
                                                        ชำระเรียบร้อยแล้ว
                                                    </span>
                                                ) : (
                                                    <span className="badge badge-danger mx-auto my-auto">
                                                        หมดอายุ
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="p-2 d-flex">
                                            <div className="col-8">
                                                ชำระเงินผ่าน
                                            </div>
                                            <div className="ml-auto">
                                                {payment === "QR-Code"
                                                    ? "Thai QR Payment"
                                                    : "กระเป๋าซิมปันสุข"}
                                            </div>
                                        </div>
                                        <div className="border-top px-4 mx-3" />
                                        <div className="p-2 d-flex pt-3">
                                            <div className="col-8">
                                                <b>ยอดรวม</b>
                                            </div>
                                            <div className="ml-auto">
                                                <b className="green">
                                                    <NumberFormat
                                                        value={parseFloat(
                                                            price
                                                        )}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        suffix={" บาท"}
                                                    />
                                                </b>
                                            </div>
                                        </div>
                                        <div className="m-3">
                                            <a
                                                href={"#qrcode"}
                                                ref={ref}
                                                className={`btn btn-success ${
                                                    orderstatus === "pending" &&
                                                    payment === "QR-Code" &&
                                                    qrcode !== ""
                                                        ? ""
                                                        : "disabled"
                                                } btn-block font-weight-bold text-uppercase px-6 py-2`}
                                                onClick={() => {
                                                    if (
                                                        orderstatus ===
                                                            "pending" &&
                                                        payment === "QR-Code" &&
                                                        qrcode !== ""
                                                    ) {
                                                        download();
                                                    } else {
                                                        return false;
                                                    }
                                                }}
                                            >
                                                บันทึกภาพ
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <h5 className="text-center text-color-main mb-0">
                                สถานะคำสั่งซื้อ{" "}
                                {orderstatus === "pending" ? (
                                    <span
                                        className={`badge badge-${
                                            payment === "QR-Code" &&
                                            qrcode === ""
                                                ? "warning"
                                                : "secondary"
                                        } mx-auto my-auto`}
                                    >
                                        {payment === "QR-Code" && qrcode === ""
                                            ? "มีปัญหา"
                                            : "รอการชำระเงิน"}
                                    </span>
                                ) : orderstatus === "paid" ? (
                                    <span className="badge badge-success mx-auto my-auto">
                                        ชำระเรียบร้อยแล้ว
                                    </span>
                                ) : (
                                    <span className="badge badge-danger mx-auto my-auto">
                                        หมดอายุ
                                    </span>
                                )}
                            </h5> */}
                        </div>
                        {/* <div className="col-12 mt-50 text-center">
                            {orderstatus === "pending" &&
                                payment === "QR-Code" &&
                                qrcode !== "" && (
                                    <a
                                        href={"#qrcode"}
                                        ref={refqrcode}
                                        className="btn btn-success font-weight-bold text-uppercase px-6 py-2"
                                        onClick={download}
                                    >
                                        บันทึกภาพ
                                    </a>
                                )}
                        </div> */}
                        {/* <div className="col-12 mt-50 text-center">
                            <h5 className="text-center text-color-main mb-0">
                                ราคารวม{" "}
                                <NumberFormat
                                    value={parseFloat(price)}
                                    displayType={"text"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    suffix={" บาท"}
                                />
                            </h5>
                        </div> */}
                    </div>
                </Modal.Body>
            </Modal>

            {/* <Modal
                show={showModal}
                onHide={handleClose}
                size="lg"
                centered
                aria-labelledby="unactive-number-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="unactive-number-modal"
                        className="col-11 p-0"
                    >
                        คิวอาร์โค้ด
                    </Modal.Title>
                    <button
                        type="button"
                        className="close col-1 text-right pr-3"
                        onClick={handleClose}
                    >
                        <i className="ki ki-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center pb-5">
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="text-center rounded bg-payment p-1">
                                    <div className="text-white text-center">
                                        หมายเลขคำสั่งซื้อ {ordernumber}
                                    </div>
                                    <div className="qrimg">
                                        <QRCodeReact
                                            value={qrcode}
                                            size={256}
                                            id="qrcode"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-50 text-center">
                            <a
                                href={"#qrcode"}
                                ref={ref}
                                className="btn btn-success font-weight-bold text-uppercase px-6 py-2"
                                onClick={download}
                            >
                                บันทึกภาพ
                            </a>
                        </div>
                        <div className="col-12 mt-50 text-center">
                            <h5 className="text-center text-color-main mb-0">
                                ราคารวม{" "}
                                <NumberFormat
                                    value={parseFloat(price)}
                                    displayType={"text"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    suffix={" บาท"}
                                />
                            </h5>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}
        </>
    );
}
