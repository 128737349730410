import React from 'react';

import Countdown from 'react-countdown';

import OtpInput from 'react-otp-input';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export function ShopInsuranceOtpcode(props) {
    return (
        <div className='row justify-content-center align-items-center m-0 h-600px bg-white rounded-lg col-lg-10 col-12 p-0'>
            <div className='col-12 col-lg-8 p-0 text-center'>
                <img
                    className='max-h-150px'
                    src={toAbsoluteUrl('/media/logos/logo_sim_punsuk_final.png')}
                    alt='punsook-logo'
                />
                <div className='col-12 text-center'>
                    <h4>กรุณากรอกรหัส OTP ที่ส่งไปยังเบอร์ที่ต้องการใช้เครดิต</h4>
                    <h6>(Ref : {props.refcode})</h6>
                </div>
                <div className='col-12 row justify-content-center m-0 p-0 py-15'>
                    <OtpInput
                        value={props.otpcode}
                        onChange={props.handleOtp}
                        numInputs={6}
                        isInputNum={true}
                        containerStyle='col-12 row justify-content-center form-group digit-group'
                    />
                    {/* <p>กรุณาลองใหม่ในอีก</p> */}
                    <Countdown key={props.expiretime.toString()} date={props.expiretime} renderer={props.renderer} />
                    {/* {!props.loading && <Countdown date={props.expiretime} renderer={props.renderer} />} */}
                </div>
                <div className='form-group d-flex flex-wrap flex-center'>
                    <button
                        type='button'
                        className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                        onClick={props.btnNext}
                        disabled={props.disablebtn}
                    >
                        <span>ถัดไป</span>
                        {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                    </button>
                </div>
            </div>
        </div>
    );
}
