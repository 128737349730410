import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import NumberFormat from "react-number-format";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

import SVG from "react-inlinesvg";

import config from "../../environments/config";

export function WDSummary(prop) {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState({});
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        const withdraw = () => {
            setLoading(true);
            fetch(config.py_url + config.version + "/wallet/withdraw", {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    secret: config.secret,
                },
                body: JSON.stringify({
                    // userid: "807",
                    userid: user.id,
                    phonenumber: prop.phonenumber,
                    ref_code: prop.refcode,
                    otp: prop.otp,
                    amount: prop.amount,
                    remark: "Withdraw Bank",
                }),
            })
                .then((response) => response.json())
                .then((result) => {
                    setLoading(false);
                    // console.log(result);
                    if (result.responsecode === 0) {
                        setResponse(result);
                        //     setNumberList(result.numbers);
                        //     getInfo(memberid);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        };
        withdraw();
    }, []);
    return (
        <>
            {loading ? (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="card card-custom position-relative overflow-hidden col-11 mx-auto p-0">
                    <div className="position-absolute opacity-30">
                        <span className="svg-icon svg-icon-10x svg-logo-white">
                            <SVG
                                // title="แชร์"
                                src={toAbsoluteUrl(
                                    "/media/svg/shapes/abstract-8.svg"
                                )}
                            />
                        </span>
                    </div>
                    <div className="row justify-content-center py-8 px-8 py-md-36 px-md-0 bg-primary">
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between align-items-md-center flex-column flex-md-row">
                                <div className="d-flex flex-column px-0 order-2 order-md-1">
                                    <div className="py-4 d-flex flex-row text-white">
                                        <img
                                            alt="Logo"
                                            src={toAbsoluteUrl(
                                                "/media/logos/logo_sim_punsuk_final.png"
                                            )}
                                            className="logo-sticky max-h-60px"
                                        />
                                        <h2 className="my-auto">
                                            <b>ปันสุข</b> |{" "}
                                        </h2>
                                        <span className="pl-2 my-auto font-size-h3">
                                            ซิม
                                        </span>
                                    </div>
                                    <span className="d-flex flex-column font-size-h5 font-weight-bold text-white">
                                        {/* <span>
                                                                            Cecilia
                                                                            Chapman,
                                                                            711-2880
                                                                            Nulla
                                                                            St,
                                                                            Mankato
                                                                        </span>
                                                                        <span>
                                                                            Mississippi
                                                                            96522
                                                                        </span> */}
                                        {/* <span>
                                                                            รายการ
                                                                            XXXX
                                                                        </span> */}
                                    </span>
                                </div>
                                <h1 className="display-3 font-weight-boldest text-white order-1 order-md-2">
                                    สรุปรายการ
                                </h1>
                            </div>
                        </div>
                    </div>
                    {response.responsecode === 0 ? (
                        <div className="row justify-content-center py-8 px-8 py-md-30 px-md-0 bg-gray-300">
                            <div className="col-md-9">
                                <div className="row pb-26">
                                    <div className="col-md-12 pr-md-10 py-md-10">
                                        <div className="custom-title">
                                            <div className="title-text text-dark-50 font-size-lg font-weight-bold my-auto">
                                                รายการที่
                                            </div>
                                            <div className="title-line bg-gray-500 my-auto"></div>
                                        </div>
                                        <div className="font-size-lg font-weight-bold text-right mb-10">
                                            {response.txref}
                                        </div>
                                        <div className="custom-title">
                                            <div className="title-text text-dark-50 font-size-lg font-weight-bold my-auto">
                                                ถอนเงินไปยัง
                                            </div>
                                            <div className="title-line bg-gray-500 my-auto"></div>
                                        </div>
                                        <div className="font-size-h2 font-weight-bold text-right">
                                            บัญชี {prop.bank}
                                        </div>
                                        <div className="font-size-lg font-weight-bold text-right">
                                            {prop.bankno}
                                        </div>
                                        <div className="font-size-lg font-weight-bold text-right mb-10">
                                            {prop.account}
                                        </div>
                                        <div className="custom-title">
                                            <div className="title-text text-dark-50 font-size-lg font-weight-bold my-auto">
                                                วันที่ทำรายการ
                                            </div>
                                            <div className="title-line bg-gray-500 my-auto"></div>
                                        </div>
                                        <div className="font-size-lg font-weight-bold text-right mb-10">
                                            {/* 05/04/2021 xx:xx:xx */}
                                            {response.txdate}
                                        </div>
                                        <div className="custom-title">
                                            {/* <div className="title-text text-dark-50 font-size-lg font-weight-bold my-auto">
                                        วันที่ทำรายการ
                                    </div> */}
                                            <div className="title-line bg-gray-500 my-auto"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 border-top pt-14 pb-10 pb-md-18">
                                        <div className="d-flex flex-column flex-md-row">
                                            <div className="d-flex flex-column">
                                                <div className="font-weight-bold font-size-h6 mb-3">
                                                    ค่าบริการ
                                                </div>
                                                <div className="d-flex justify-content-between font-size-lg mb-3">
                                                    <span className="font-weight-bold mr-15">
                                                        จำนวนเงินที่ต้องการถอน:
                                                    </span>
                                                    <span className="text-right">
                                                        <NumberFormat
                                                            value={parseFloat(
                                                                prop.amount
                                                            )}
                                                            displayType={"text"}
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            // suffix={
                                                            //     " บาท"
                                                            // }
                                                        />
                                                    </span>
                                                </div>
                                                <div className="d-flex justify-content-between font-size-lg mb-3">
                                                    <span className="font-weight-bold mr-15">
                                                        ค่าธรรมเนียม:
                                                    </span>
                                                    <span className="text-right">
                                                        <NumberFormat
                                                            value={parseFloat(
                                                                prop.fee
                                                            )}
                                                            displayType={"text"}
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            // suffix={
                                                            //     " บาท"
                                                            // }
                                                        />
                                                    </span>
                                                </div>
                                                <div className="d-flex justify-content-between font-size-lg">
                                                    <span className="font-weight-bold mr-15">
                                                        ภาษีหัก ณ ที่จ่าย 3 %:
                                                    </span>
                                                    <span className="text-right">
                                                        <NumberFormat
                                                            value={parseFloat(
                                                                parseFloat(
                                                                    prop.tag
                                                                ) *
                                                                    parseFloat(
                                                                        prop.amount
                                                                    )
                                                            )}
                                                            displayType={"text"}
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            // suffix={
                                                            //     " บาท"
                                                            // }
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 pt-md-25">
                                        <div className="bg-primary rounded d-flex align-items-center justify-content-between text-white max-w-350px position-relative ml-auto p-7">
                                            <div className="position-absolute opacity-30 top-0 right-0">
                                                <span className="svg-icon svg-icon-2x svg-logo-white svg-icon-flip">
                                                    <SVG
                                                        // title="แชร์"
                                                        src={toAbsoluteUrl(
                                                            "/media/svg/shapes/abstract-8.svg"
                                                        )}
                                                    />
                                                </span>
                                            </div>
                                            <div className="font-weight-boldest font-size-h5">
                                                จำนวนเงินที่ได้รับ
                                            </div>
                                            <div className="text-right d-flex flex-column">
                                                <span className="font-weight-boldest font-size-h3 line-height-sm">
                                                    <NumberFormat
                                                        value={parseFloat(
                                                            parseFloat(
                                                                prop.amount
                                                            ) -
                                                                (prop.fee +
                                                                    parseFloat(
                                                                        prop.amount
                                                                    ) *
                                                                        parseFloat(
                                                                            prop.tag
                                                                        ))
                                                        )}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        // suffix={
                                                        //     " บาท"
                                                        // }
                                                    />
                                                </span>
                                                <span className="font-size-sm">
                                                    รวมค่าบริการทั้งหมดแล้ว
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row d-flex justify-content-center align-items-center h-200px">
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <div className="text-center">
                                        {/* <div className="qrimg">
                                            <img
                                                alt="error"
                                                src={toAbsoluteUrl(
                                                    "/media/error/qr-error.png"
                                                )}
                                                className="logo-sticky max-h-250px"
                                            /> */}
                                        {/* <img
                                                className="result-img"
                                                src="assets/media/images/qr-error.png"
                                            /> */}
                                        {/* </div> */}
                                        <h4 className="text-center text-color-main mb-0 mt-5">
                                            ทำรายการไม่สำเร็จ
                                            กรุณาติดต่อเจ้าหน้าที่
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className="row justify-content-center border-top py-8 px-8 py-md-28 px-md-0 bg-gray-300">
                    <div className="col-md-9">
                        <div className="d-flex font-size-sm flex-wrap">
                            <button
                                type="button"
                                className="btn btn-primary font-weight-bolder py-4 mr-3 mr-sm-14 my-1"
                                onclick="window.print();"
                            >
                                Print Invoice
                            </button>
                            <button
                                type="button"
                                className="btn btn-light-primary font-weight-bolder mr-3 my-1"
                            >
                                Download
                            </button>
                            <button
                                type="button"
                                className="btn btn-warning font-weight-bolder ml-sm-auto my-1"
                            >
                                Create Invoice
                            </button>
                        </div>
                    </div>
                </div> */}
                </div>
            )}
        </>
    );
}
