import React, { useState } from 'react';
// import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
// import { login, endUserLogin } from "../_redux/authCrud";
// import axios from "axios";
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import config from '../../../pages/environments/config';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    // email: "admin@demo.com",
    // password: "demo",
    username: '',
    password: ''
};

function Login(props) {
    const { intl } = props;
    const [ loading, setLoading ] = useState(false);
    const [ passwordShown, setPasswordShown ] = useState(false);
    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            // .email("Wrong email format")
            // .min(3, "Minimum 3 symbols")
            // .max(50, "Maximum 50 symbols")
            .required(
                // "จำเป็นต้องกรอก"
                intl.formatMessage({
                    id: 'LOGIN.REQUIRED_FIELD'
                })
            ),
        password: Yup.string()
            // .min(3, "Minimum 3 symbols")
            // .max(50, "Maximum 50 symbols")
            .required(
                // "จำเป็นต้องกรอก"
                intl.formatMessage({
                    id: 'LOGIN.REQUIRED_FIELD'
                })
            )
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid';
        }

        // if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        //   return "is-valid";
        // }

        return '';
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            fetch(config.py_url + config.version + '/auth/login', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    secret: config.secret
                },
                body: JSON.stringify({
                    username: values.username,
                    password: values.password
                })
            })
                .then((response) => response.json())
                .then((result) => {
                    disableLoading();
                    // console.log(result);
                    if (result.responsecode === 100) {
                        setSubmitting(false);
                        setStatus(
                            intl.formatMessage({
                                id: 'AUTH.VALIDATION.INVALID_LOGIN'
                            })
                        );
                    } else if (result.responsecode === 101) {
                        setSubmitting(false);
                        setStatus(
                            intl.formatMessage({
                                id: 'AUTH.VALIDATION.INVALID_LOGIN'
                            })
                        );
                    } else if (result.responsecode === 102) {
                        setSubmitting(false);
                        setStatus(
                            intl.formatMessage({
                                id: 'AUTH.VALIDATION.INVALID_LOGIN'
                            })
                        );
                    } else {
                        if (result.info.pic === '' || result.info.pic === undefined) {
                            result.info.pic = toAbsoluteUrl('/media/users/default.jpg');
                        }
                        setSubmitting(false);
                        props.newLogin(result.info);
                    }
                })
                .catch((error) => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                        intl.formatMessage({
                            id: 'AUTH.VALIDATION.INVALID_LOGIN'
                        })
                    );
                });
            // setTimeout(() => {
            //     login(values.email, values.password)
            //         .then(({ data: { accessToken } }) => {
            //             disableLoading();
            //             // console.log(accessToken);
            //             props.login(accessToken);
            //         })
            //         .catch(() => {
            //             disableLoading();
            //             setSubmitting(false);
            //             setStatus(
            //                 intl.formatMessage({
            //                     id: "AUTH.VALIDATION.INVALID_LOGIN",
            //                 })
            //             );
            //         });
            // }, 1000);
        }
    });

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    return (
        <div className='login-form login-signin' id='kt_login_signin_form'>
            {/* begin::Head */}
            <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1'>
                    เมืองปันสุข
                    {/* <FormattedMessage id="LOGIN.TITLE" /> */}
                    {/* โครงการซิมปันสุข */}
                </h3>
                {/* <p className="text-muted font-weight-bold"> */}
                {/* <FormattedMessage id="LOGIN.SUBTITLE" /> */}
                {/* กรุณากรอกชื่อผู้ใช้และรหัสผ่านเพื่อเข้าสู่ระบบ */}
                {/* </p> */}
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form onSubmit={formik.handleSubmit} className='form fv-plugins-bootstrap fv-plugins-framework'>
                {formik.status && (
                    <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                )}

                <div className='form-group fv-plugins-icon-container'>
                    <label className='font-size-h6 font-weight-bolder'>
                        <FormattedMessage id='LOGIN.USERNAME' />
                    </label>
                    <input
                        // placeholder="Email"
                        type='text'
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('username')}`}
                        name='username'
                        maxLength='13'
                        onInput={maxLengthCheck}
                        {...formik.getFieldProps('username')}
                    />
                    {formik.touched.username && formik.errors.username ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.username}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group fv-plugins-icon-container'>
                    <label className='font-size-h6 font-weight-bolder'>
                        <FormattedMessage id='LOGIN.PASSWORD' />
                        <i
                            className={`fa ${!passwordShown ? 'fa-eye' : 'fa-eye-slash'} cursor-pointer ml-3`}
                            onClick={() => setPasswordShown(!passwordShown)}
                            aria-hidden='true'
                        />
                    </label>
                    <input
                        // placeholder="Password"
                        type={!passwordShown ? 'password' : 'text'}
                        inputMode='tel'
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses('password')}`}
                        name='password'
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                if (event.key !== 'Enter') {
                                    event.preventDefault();
                                }
                            }
                        }}
                        maxLength='10'
                        onInput={maxLengthCheck}
                        {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
                    {/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
                    <button
                        id='kt_login_signin_submit'
                        type='submit'
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3 ml-auto`}
                    >
                        <span>
                            <FormattedMessage id='LOGIN.SIGNIN' />
                        </span>
                        {loading && <span className='ml-3 spinner spinner-white' />}
                    </button>
                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
