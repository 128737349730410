import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import config from '../environments/config';
import { DealerDetail } from './Detail';

export function DealerSupport() {
    const { user } = useSelector((state) => state.auth);
    const history = useHistory();
    const [ loading, setLoading ] = useState(false);
    const [ response, setResponse ] = useState(false);
    const [ phonenumber, setPhonenumber ] = useState('');
    useEffect(() => {
        if (!(user.role !== undefined && user.role.length > 0 && user.role.includes('dealer-service'))) {
            history.push('/');
        }
    }, []);

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    const handleSearch = () => {
        if (phonenumber === '') {
            Swal.fire({
                html: 'กรุณากรอกเบอร์ที่ต้องการดูรายละเอียด',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        } else {
            if (phonenumber.charAt(0) !== '0') {
                Swal.fire({
                    html: 'กรุณากรอกเบอร์ที่ต้องการดูรายละเอียด ให้ถูกต้อง',
                    icon: 'warning',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            } else if (phonenumber.length !== 10) {
                Swal.fire({
                    html: 'กรุณากรอกเบอร์ที่ต้องการดูรายละเอียด ให้ครบ 10 ตัว',
                    icon: 'warning',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
        }
        setLoading(true);
        fetch(config.py_url + config.version + '/dealer-service/numberinformation', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                searcher: user.main_phonenumber,
                phonenumber: phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    html: 'ระบบขัดข้อง กรุณาลองใหม่อีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='d-flex flex-row row m-0'>
                    <div className='col-lg-12 mb-5'>
                        <div className='card card-custom card-stretch' id='wallet-info'>
                            <div className='card-header bg-danger text-white'>
                                <div className='card-title'>
                                    <span className='card-icon'>
                                        <i className='fas fa-sim-card text-white' />
                                    </span>
                                    <h3 className='card-label text-white'>เบอร์ที่ต้องการดูรายละเอียด</h3>
                                </div>
                            </div>
                            <div className='card-body pt-4 pb-4'>
                                <div className='row justify-content-center my-5'>
                                    <div className='row col-xl-9 col-7 justify-content-center'>
                                        <div className='col-xl-12'>
                                            <div className='form-group fv-plugins-icon-container my-auto'>
                                                <input
                                                    type='text'
                                                    inputMode='tel'
                                                    className='form-control form-control-solid form-control-lg border-lg border-dark'
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    value={phonenumber}
                                                    maxLength='10'
                                                    onInput={maxLengthCheck}
                                                    onChange={(e) => setPhonenumber(e.target.value)}
                                                    placeholder='กรอกเบอร์ที่ต้องการดูรายละเอียด'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-xl-3 col-5 d-flex justify-content-center align-items-center'>
                                        <button
                                            type='button'
                                            className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                            onClick={handleSearch}
                                        >
                                            ตรวจสอบ
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {Object.keys(response).length > 0 ? (
                        <div className='col-lg-12'>
                            <div className='card card-custom card-stretch gutter-b rounded-lg'>
                                <div className='card-header bg-primary text-white rounded-lg rounded-bottom-0'>
                                    <div className='card-title'>
                                        <h3 className='card-label text-white'>รายงาน</h3>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    {response.responsecode === 0 ? (
                                        <DealerDetail data={response} />
                                    ) : response.responsecode === 101 ? (
                                        <div className='col-xl-12 text-center m-0 my-25 p-0'>
                                            <i className='fas fa-exclamation-circle fa-5x mb-2 text-dark-70' />
                                            <h4 className='mt-3'>
                                                ไม่ได้รับสิทธิ์ในการใช้งานฟังก์ชั่นนี้
                                                กรุณาติดต่อเจ้าหน้าที่เพื่อเริ่มใช้งาน
                                            </h4>
                                        </div>
                                    ) : (
                                        <div className='col-xl-12 text-center m-0 my-25 p-0'>
                                            <i className='fas fa-exclamation-circle fa-5x mb-2 text-dark-70' />
                                            <h4 className='mt-3'>ไม่พบข้อมูลที่ต้องการค้นหา</h4>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
}
