import React, { useState, useEffect, useRef } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { WDConfirm } from './step/Confirm';
import { WDSummary } from './step/Summary';
import './Withdraw.scss';
import config from '../environments/config';
import { SubscriberList } from '../../Setting';

const maxValueCheck = (object) => {
    if (object.target.value > object.target.maxLength) {
        object.target.value = object.target.maxLength;
    }
};

export function WalletWithdraw() {
    const history = useHistory();
    const [ step, setStep ] = useState(1);
    const [ loading, setLoading ] = useState(true);
    const [ active, setActive ] = useState(null);
    const [ phonenumber, setPhonenumber ] = useState('');
    const [ numberlist, setNumberList ] = useState([]);
    const [ account, setAccount ] = useState('');
    const [ bank, setBank ] = useState('');
    const [ bankno, setBankno ] = useState('');
    const [ balance, setBalance ] = useState('');
    const [ amount, setAmount ] = useState(0);
    const [ refcode, setRefcode ] = useState('');
    const [ expired, setExpired ] = useState('');
    const [ otp, setOtp ] = useState('');
    const [ fee, setFee ] = useState(0);
    const [ tag, setTag ] = useState(0);
    const { user } = useSelector((state) => state.auth);
    const ref = useRef(null);
    const scroll = (size, option) => {
        const osInstance = ref.current.osInstance();
        if (osInstance) {
            osInstance.scrollStop().scroll(
                {
                    x: `${option}= ${size}px`
                },
                1000,
                'easeOutCubic'
            );
        }
    };
    useEffect(() => {
        if (SubscriberList.includes(user.main_position)) {
            history.push('/');
        } else {
            getNumberList(user.id);
        }
    }, []);

    const navigate = (id, number) => {
        setLoading(true);
        setActive(id);
        setPhonenumber(number);
        getBalance(number);
    };

    const nextStep = () => {
        if (step === 1) {
            if (amount === 0) {
                Swal.fire({
                    html: '<div class="alert-text">กรุณากรอกจำนวนเงินที่ต้องการถอน ให้เรียบร้อย</div>',
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then((result) => {});
                return;
            } else if (amount < 100) {
                Swal.fire({
                    html: '<div class="alert-text">กรุณากรอกจำนวนเงินขั้นต่ำมากกว่า 100 บาท</div>',
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then((result) => {});
                return;
            } else if (amount > parseFloat(balance)) {
                Swal.fire({
                    html: '<div class="alert-text">กรุณากรอกจำนวนเงินไม่เกิน ' + parseFloat(balance) + ' บาท</div>',
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then((result) => {});
                return;
            }
        }
        setStep(step + 1);
    };

    const getNumberList = (memberid) => {
        fetch(config.py_url + config.version + '/profile/phonenumber_list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    setNumberList(result.numbers);
                    getInfo(memberid);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getInfo = (memberid) => {
        fetch(config.py_url + config.version + '/profile/info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    setAccount(result.bank.account_name);
                    setBank(result.bank.bank_name);
                    setBankno(result.bank.account_no);
                    getServiceFee();
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getServiceFee = () => {
        fetch(config.py_url + config.version + '/wallet/get-service-fee', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setFee(result.fee);
                    setTag(result.tag);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getBalance = (phonenumber) => {
        fetch(config.py_url + config.version + '/wallet/balance', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: user.id,
                phonenumber: phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setBalance(result.total);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleLoading = (loading) => {
        setLoading(loading);
    };

    const handleRefcode = (refcode) => {
        setRefcode(refcode);
    };

    const handleExpired = (time) => {
        setExpired(time);
    };

    const handleOtp = (otp) => {
        setOtp(otp);
    };
    return (
        <>
            {!loading ? (
                <div className='card card-custom card-shadowless bg-white rounded'>
                    <div className='wizard wizard-1' id='kt_wizard'>
                        <div className='wizard-nav border-bottom'>
                            <div className='wizard-steps p-8 p-lg-10'>
                                <div
                                    className={`wizard-step d-md-flex ${step !== 1 && 'd-none'}`}
                                    data-wizard-type='step'
                                    data-wizard-state={`${step >= 1 ? 'current' : 'pending'}`}
                                >
                                    <div className='wizard-label'>
                                        <i className='wizard-icon flaticon-list' />
                                        <h3 className='wizard-title'>กรอกรายละเอียด</h3>
                                    </div>
                                    <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                        <SVG
                                            title='ขั้นตอนที่1'
                                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`wizard-step d-md-flex ${step !== 2 && 'd-none'}`}
                                    data-wizard-type='step'
                                    data-wizard-state={`${step >= 2 ? 'current' : 'pending'}`}
                                >
                                    <div className='wizard-label'>
                                        <i className='wizard-icon flaticon-questions-circular-button' />
                                        <h3 className='wizard-title'>ยืนยันการทำรายการ</h3>
                                    </div>
                                    <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                        <SVG
                                            title='ขั้นตอนที่2'
                                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`wizard-step d-md-flex ${step !== 3 && 'd-none'}`}
                                    data-wizard-type='step'
                                    data-wizard-state={`${step >= 3 ? 'current' : 'pending'}`}
                                >
                                    <div className='wizard-label'>
                                        <i className='wizard-icon flaticon-statistics' />
                                        <h3 className='wizard-title'>สรุปรายการ</h3>
                                    </div>
                                    <span className='svg-icon svg-icon-xl wizard-arrow last'>
                                        <SVG
                                            title='ขั้นตอนที่3'
                                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-body p-0'>
                        <div className='row justify-content-center'>
                            <div className={`col-xl-12 ${step === 4 ? 'col-xxl-12' : 'col-xxl-12'}`}>
                                <div
                                    className='form mt-0 mt-lg-10 fv-plugins-bootstrap fv-plugins-framework'
                                    id='kt_form'
                                >
                                    <div className='d-flex flex-row row m-0'>
                                        {step === 1 ? (
                                            <>
                                                <div className='flex-row-fluid col-12 p-0 m-5'>
                                                    <div className='card card-custom card-stretch' id='wallet-info'>
                                                        <div className='card-header bg-danger text-white'>
                                                            <div className='card-title'>
                                                                <span className='card-icon'>
                                                                    <i className='fas fa-wallet text-white' />
                                                                </span>
                                                                <h3 className='card-label text-white'>กระเป๋าที่คุณมี</h3>
                                                            </div>
                                                        </div>
                                                        <div className='card-body pt-4'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='cards-menu w-100'>
                                                                    <div className='card-list testimonial-group'>
                                                                        <div className='text-right d-none d-md-block'>
                                                                            <button
                                                                                className='btn btn-secondary mr-3'
                                                                                onClick={() => scroll('200', '-')}
                                                                            >
                                                                                <i className='fas fa-chevron-left' />
                                                                            </button>
                                                                            <button
                                                                                className='btn btn-secondary'
                                                                                onClick={() => scroll('200', '+')}
                                                                            >
                                                                                <i className='fas fa-chevron-right' />
                                                                            </button>
                                                                        </div>
                                                                        <div className='inline-scroll'>
                                                                            <OverlayScrollbarsComponent
                                                                                className='wv-100'
                                                                                ref={ref}
                                                                            >
                                                                                {numberlist.map((object, index) => {
                                                                                    if (object.feels_kyc === 1) {
                                                                                        return (
                                                                                            <div
                                                                                                className='inline-card'
                                                                                                key={index}
                                                                                                onClick={() =>
                                                                                                    navigate(
                                                                                                        index,
                                                                                                        object.phonenumber
                                                                                                    )}
                                                                                            >
                                                                                                <div
                                                                                                    className={`card cursor-pointer ${active ===
                                                                                                    index
                                                                                                        ? 'sim-active'
                                                                                                        : ''}`}
                                                                                                >
                                                                                                    <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                                                        <div className='symbol-label'>
                                                                                                            <i className='fas fa-sim-card fa-2x' />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p className='card-number'>
                                                                                                        {object.phonenumber}
                                                                                                    </p>
                                                                                                    <p className='validity-date p-0 mb-0'>
                                                                                                        กำหนดรักษาสิทธิ์:{' '}
                                                                                                        <span>{object.preserve_date}</span>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div
                                                                                                className='inline-card'
                                                                                                key={index}
                                                                                            >
                                                                                                <div className='card cursor-disable card-disable'>
                                                                                                    <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                                                        <div className='symbol-label'>
                                                                                                            <i className='fas fa-sim-card fa-2x' />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p className='card-number'>
                                                                                                        {object.phonenumber}
                                                                                                    </p>
                                                                                                    <p className='validity-date p-0 mb-0'>
                                                                                                        {object.feels_kyc ===
                                                                                                            0 &&
                                                                                                        object.position !==
                                                                                                            '' && (
                                                                                                            <span className='menu-label'>
                                                                                                                <span className='label label-warning label-inline'>
                                                                                                                    ยังไม่ลงทะเบียนซิม
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        )}
                                                                                                        {object.position ===
                                                                                                            '' && (
                                                                                                            <span className='menu-label'>
                                                                                                                <span className='label label-danger label-inline'>
                                                                                                                    ไม่พร้อมใช้งาน
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        )}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                })}
                                                                            </OverlayScrollbarsComponent>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {active !== null && (
                                                    <div className='flex-row-fluid col-12 p-0 mx-5 my-0'>
                                                        <div className='card card-custom'>
                                                            <div className='card-body'>
                                                                <div className='header col-12 w-100 h-100 mb-5'>
                                                                    <h2>ยอดเงินในกระเป๋าของคุณ</h2>
                                                                    <p className='total-balance'>
                                                                        <NumberFormat
                                                                            value={parseFloat(balance)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                        />
                                                                    </p>
                                                                </div>
                                                                <div className='form-group row justify-content-center'>
                                                                    <label className='col-xl-3 col-lg-3 col-form-label'>
                                                                        ชื่อบัญชี
                                                                    </label>
                                                                    <div className='col-lg-9 col-xl-6 row m-0'>
                                                                        <p className='my-auto'>{account}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='form-group row justify-content-center'>
                                                                    <label className='col-xl-3 col-lg-3 col-form-label'>
                                                                        ชื่อธนาคาร
                                                                    </label>
                                                                    <div className='col-lg-9 col-xl-6 row m-0'>
                                                                        <p className='my-auto'>{bank}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='form-group row justify-content-center'>
                                                                    <label className='col-xl-3 col-lg-3 col-form-label'>
                                                                        เลขที่บัญชี
                                                                    </label>
                                                                    <div className='col-lg-9 col-xl-6 row m-0'>
                                                                        <p className='my-auto'>{bankno}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='form-group row justify-content-center'>
                                                                    <label className='col-xl-3 col-lg-3 col-form-label'>
                                                                        จำนวนเงินที่ต้องการถอน
                                                                    </label>
                                                                    <div className='col-lg-9 col-xl-6'>
                                                                        <input
                                                                            className='form-control form-control-lg form-control-solid'
                                                                            type='tel'
                                                                            id='amount'
                                                                            name='amount'
                                                                            value={amount}
                                                                            onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    if (event.key !== 'Enter') {
                                                                                        event.preventDefault();
                                                                                    } else {
                                                                                        nextStep();
                                                                                    }
                                                                                }
                                                                            }}
                                                                            onChange={(e) => setAmount(e.target.value)}
                                                                            maxLength={10000}
                                                                            onInput={maxValueCheck}
                                                                        />
                                                                        <small>ถอนได้สูงสุดวันละ 10,000 บาท</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='card-footer'>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div className='mr-2' />
                                                                    <div>
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                                                            onClick={nextStep}
                                                                        >
                                                                            ตกลง
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : step === 2 ? (
                                            <WDConfirm
                                                nextStep={nextStep}
                                                userid={user.id}
                                                phonenumber={phonenumber}
                                                handleLoading={handleLoading}
                                                bank={bank}
                                                bankno={bankno}
                                                account={account}
                                                amount={amount}
                                                refcode={refcode}
                                                handleRefcode={handleRefcode}
                                                expired={expired}
                                                handleExpired={handleExpired}
                                                otp={otp}
                                                handleOtp={handleOtp}
                                                fee={fee}
                                                tag={tag}
                                            />
                                        ) : step === 3 ? (
                                            <WDSummary
                                                amount={amount}
                                                phonenumber={phonenumber}
                                                refcode={refcode}
                                                otp={otp}
                                                bank={bank}
                                                bankno={bankno}
                                                account={account}
                                                fee={fee}
                                                tag={tag}
                                            />
                                        ) : (
                                            <div className='col-12 d-flex align-items-center justify-content-center position-relative empty min-h-500px'>
                                                <div className='code'>404 </div>
                                                <div className='message' style={{ padding: 10 }}>
                                                    ไม่พบหน้านี้
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
