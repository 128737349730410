import axios from "axios";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const PUNSOOK_URL = "http://192.168.159.44:5003/";

export const ME_URL = "api/me";

export function login(email, password) {
    return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
    return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
}

export function endUserLogin(username, password) {
    let payload = {
        username: username,
        password: password,
    };
    let header = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        secret: "JHD769GasI8MgQE4s0C0kloRvIZ2gdPO",
    };
    // console.log(payload);
    // console.log(
    //     axios.post(PUNSOOK_URL + "v1/auth/login", { username, password })
    // );
    // console.log(PUNSOOK_URL + "v1/auth/login");
    axios
        .post("http://192.168.159.44:5003/v1/auth/login", payload, header)
        .then((response) => {
            console.log(response);
            // disableLoading();
            // console.log(accessToken);
            // props.login(accessToken);
        })
        .catch(() => {
            // disableLoading();
            // setSubmitting(false);
            // setStatus(
            //     intl.formatMessage({
            //         id: "AUTH.VALIDATION.INVALID_LOGIN",
            //     })
            // );
        });
    // return axios.post(
    //     "http://192.168.159.44:5003/v1/auth/login",
    //     payload,
    //     header
    // );
    return "test";
}
