import React, { useState, useEffect, useRef } from "react";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import GoogleMapReact from "google-map-react";

import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

import useOnclickOutside from "react-cool-onclickoutside";

import { useSelector } from "react-redux";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

// import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import "./autocomplete.scss";

import config from "../environments/config";

// import { PlacesAutocomplete } from "../environments/autocomplete";

const Marker = ({ children }) => children;

const createMapOptions = {
    scrollwheel: true,
};

export function MapSetMarker() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [loadingMap, setLoadingMap] = useState(true);
    const [numberlist, setNumberList] = useState([]);
    const [active, setActive] = useState(null);
    const [phonenumber, setPhonenumber] = useState("");

    const [center, setCenter] = useState({
        lat: 59.95,
        lng: 30.33,
    });

    const zoom = 16.5;

    const ref = useRef(null);

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        getNumberList(user.id);
        getLocation();
    }, []);

    const scroll = (size, option) => {
        const osInstance = ref.current.osInstance();
        if (osInstance) {
            osInstance.scrollStop().scroll(
                {
                    x: `${option}= ${size}px`,
                },
                1000,
                "easeOutCubic"
            );
        }
    };

    const navigate = (id, number) => {
        setActive(id);
        setPhonenumber(number);
        getDBLocation(number);
    };

    const getDBLocation = (phonenumber) => {
        setLoadingMap(true);
        fetch(config.py_url + config.version + "/profile/get_location", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: phonenumber,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoadingMap(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    setCenter({
                        lat: result.location.latitude,
                        lng: result.location.longtitude,
                    });
                } else {
                    navigator.geolocation.getCurrentPosition((position) => {
                        setCenter({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    });
                }
            })
            .catch((error) => {
                setLoadingMap(false);
                // setError(true);
            });
    };

    const saveLocation = () => {
        setLoading(true);
        fetch(config.py_url + config.version + "/profile/set_location", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: phonenumber,
                latitude: center.lat.toString(),
                longtitude: center.lng.toString(),
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    Swal.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: "บันทึกเรียบร้อย",
                        icon: "success",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const resetLocation = () => {
        setLoading(true);
        fetch(config.py_url + config.version + "/profile/set_location", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: phonenumber,
                latitude: "0",
                longtitude: "0",
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    Swal.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: "ยกเลิกเรียบร้อย",
                        icon: "success",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getLocation = () => {
        setLoadingMap(true);
        navigator.geolocation.getCurrentPosition((position) => {
            setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            });
            setLoadingMap(false);
        });
    };

    const getNumberList = (memberid) => {
        fetch(config.py_url + config.version + "/profile/phonenumber_list", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                userid: memberid,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    setNumberList(result.numbers);
                } else {
                    setError(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
            });
    };

    const PlacesAutocomplete = () => {
        const {
            ready,
            value,
            suggestions: { status, data },
            setValue,
            clearSuggestions,
        } = usePlacesAutocomplete({
            // requestOptions: { /* Define search scope here */ }
            debounce: 300,
        });
        const registerRef = useOnclickOutside(() => {
            // When user clicks outside of the component, we can dismiss
            // the searched suggestions by calling this method
            clearSuggestions();
        });

        const handleInput = (e) => {
            // Update the keyword of the input element
            setValue(e.target.value);
        };

        const handleSelect = ({ description }) => () => {
            setLoading(true);
            // When user selects a place, we can replace the keyword without request data from API
            // by setting the second parameter as "false"
            setValue(description, false);
            clearSuggestions();

            // Get latitude and longitude via utility functions
            getGeocode({ address: description })
                .then((results) => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    // console.log("📍 Coordinates: ", { lat, lng });
                    setCenter({
                        lat: lat,
                        lng: lng,
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    // console.log("😱 Error: ", error);
                });
        };

        const renderSuggestions = () =>
            data.map((suggestion, index) => {
                const {
                    // id,
                    structured_formatting: { main_text, secondary_text },
                } = suggestion;

                return (
                    <li
                        key={index}
                        onClick={handleSelect(suggestion)}
                        className="cursor-pointer"
                    >
                        <strong>{main_text}</strong>{" "}
                        <small>{secondary_text}</small>
                    </li>
                );
            });

        return (
            <div className="form-group row mt-5" ref={registerRef}>
                <label className="col-12">ค้นหาที่อยู่</label>
                <div className="col-12">
                    <input
                        className="form-control form-control-lg form-control-solid"
                        value={value}
                        onChange={handleInput}
                        disabled={!ready}
                        //   placeholder="Where are you going?"
                    />
                    {/* We can use the "status" to decide whether we should display the dropdown or not */}
                    {status === "OK" && (
                        <ul className="cus-dropdown p-0">
                            {renderSuggestions()}
                        </ul>
                    )}
                </div>
            </div>
        );
    };
    // console.log(loading);
    // console.log(loadingMap);

    return (
        <>
            {!loading && !loadingMap ? (
                <div className="d-flex flex-row row m-0">
                    <div className="flex-row-fluid ml-lg-8 col-12 p-0 mb-5">
                        <div
                            className="card card-custom card-stretch"
                            id="wallet-info"
                        >
                            <div className="card-header bg-danger text-white">
                                <div className="card-title">
                                    <span className="card-icon">
                                        <i className="fas fa-sim-card text-white"></i>
                                    </span>
                                    <h3 className="card-label text-white">
                                        เบอร์ที่คุณมี
                                    </h3>
                                </div>
                            </div>
                            <div
                                className={`card-body ${!error ? "pt-4" : ""}`}
                            >
                                {!error ? (
                                    <div className="d-flex align-items-center">
                                        <div className="cards-menu w-100">
                                            <div className="card-list testimonial-group">
                                                <div className="text-right d-none d-md-block">
                                                    <button
                                                        className="btn btn-secondary mr-3"
                                                        onClick={() =>
                                                            scroll("200", "-")
                                                        }
                                                    >
                                                        <i className="fas fa-chevron-left"></i>
                                                    </button>
                                                    <button
                                                        className="btn btn-secondary"
                                                        onClick={() =>
                                                            scroll("200", "+")
                                                        }
                                                    >
                                                        <i className="fas fa-chevron-right"></i>
                                                    </button>
                                                </div>
                                                <div className="inline-scroll">
                                                    <OverlayScrollbarsComponent
                                                        className="wv-100"
                                                        ref={ref}
                                                    >
                                                        {numberlist.map(
                                                            (object, index) => {
                                                                // console.log(index);
                                                                // console.log(
                                                                //     object.feels_kyc
                                                                // );
                                                                if (
                                                                    object.feels_kyc ===
                                                                    1
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            className="inline-card"
                                                                            key={
                                                                                index
                                                                            }
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    index,
                                                                                    object.phonenumber
                                                                                )
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={`card cursor-pointer ${
                                                                                    active ===
                                                                                    index
                                                                                        ? "sim-active"
                                                                                        : ""
                                                                                }`}
                                                                            >
                                                                                {/* <img
                                                                                src="https://svgshare.com/i/Kzr.svg"
                                                                                className="card-logo"
                                                                            /> */}
                                                                                <div className="symbol symbol-40 symbol-light-white mr-5">
                                                                                    <div className="symbol-label">
                                                                                        <i className="fas fa-sim-card fa-2x"></i>
                                                                                        {/* <img src="images/icons/icon_menu_topup.png" className="h-75 align-self-end" alt /> */}
                                                                                    </div>
                                                                                </div>
                                                                                <p className="card-number">
                                                                                    {
                                                                                        object.phonenumber
                                                                                    }
                                                                                </p>
                                                                                <p className="validity-date p-0">
                                                                                    กำหนดรักษาสิทธิ์:{" "}
                                                                                    <span>
                                                                                        {
                                                                                            object.preserve_date
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <div
                                                                            className="inline-card"
                                                                            key={
                                                                                index
                                                                            }
                                                                            // onClick={() =>
                                                                            //     navigate(
                                                                            //         index
                                                                            //     )
                                                                            // }
                                                                        >
                                                                            <div className="card cursor-disable card-disable">
                                                                                {/* <img
                                                                                src="https://svgshare.com/i/Kzr.svg"
                                                                                className="card-logo"
                                                                            /> */}
                                                                                <div className="symbol symbol-40 symbol-light-white mr-5">
                                                                                    <div className="symbol-label">
                                                                                        <i className="fas fa-sim-card fa-2x"></i>
                                                                                        {/* <img src="images/icons/icon_menu_topup.png" className="h-75 align-self-end" alt /> */}
                                                                                    </div>
                                                                                </div>
                                                                                <p className="card-number">
                                                                                    {
                                                                                        object.phonenumber
                                                                                    }
                                                                                </p>
                                                                                <p className="validity-date p-0">
                                                                                    {object.feels_kyc ===
                                                                                        0 &&
                                                                                        object.position !==
                                                                                            "" && (
                                                                                            <span className="menu-label">
                                                                                                <span className="label label-warning label-inline">
                                                                                                    ยังไม่ลงทะเบียนซิม
                                                                                                </span>
                                                                                            </span>
                                                                                        )}
                                                                                    {object.position ===
                                                                                        "" && (
                                                                                        <span className="menu-label">
                                                                                            <span className="label label-danger label-inline">
                                                                                                ไม่พร้อมใช้งาน
                                                                                            </span>
                                                                                        </span>
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        )}
                                                    </OverlayScrollbarsComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex align-items-center">
                                        <div className="col-xl-12 text-center m-0 my-15 p-0">
                                            <i className="fas fa-exclamation-circle fa-5x mb-2 text-secondary"></i>
                                            <h4 className="mt-3">
                                                ไม่สามารถดึงข้อมูลได้ชั่วคราว
                                                กรุณาลองใหม่อีกครั้ง
                                            </h4>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {active !== null && (
                        <div className="flex-row-fluid col-12 p-0 ml-lg-8">
                            <div className="card card-custom">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div
                                                className="w-100"
                                                style={{ height: "60vh" }}
                                            >
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{
                                                        key:
                                                            "AIzaSyC50yts_KbsIWY7NLOiiitRDjC_8YsJfzM",
                                                        language: "th",
                                                        region: "th",
                                                        libraries: [
                                                            "places",
                                                            "geometry",
                                                            "drawing",
                                                            "visualization",
                                                        ],
                                                    }}
                                                    defaultCenter={center}
                                                    defaultZoom={zoom}
                                                    options={createMapOptions}
                                                    yesIWantToUseGoogleMapApiInternals
                                                >
                                                    <Marker
                                                        key={"your-location"}
                                                        lat={center.lat}
                                                        lng={center.lng}
                                                    >
                                                        <img
                                                            alt="client-marker"
                                                            src="https://cdn.simk4.com/punsook/marker/client.png"
                                                            className="max-h-50px"
                                                        />
                                                    </Marker>
                                                </GoogleMapReact>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 mt-10 mt-md-0">
                                            <div className="card border-0 h-100">
                                                <div className="card-body p-0">
                                                    <button
                                                        className="btn btn-block btn-primary"
                                                        onClick={() =>
                                                            getLocation()
                                                        }
                                                    >
                                                        ตำแหน่งปัจจุบันของคุณ
                                                    </button>
                                                    <PlacesAutocomplete></PlacesAutocomplete>
                                                </div>
                                                <div className="card-footer pl-0 pr-0">
                                                    <button
                                                        className="btn btn-light-primary"
                                                        onClick={() =>
                                                            resetLocation()
                                                        }
                                                    >
                                                        ยกเลิกตำแหน่งของคุณ
                                                    </button>
                                                    <button
                                                        className="btn btn-success float-right"
                                                        onClick={() =>
                                                            saveLocation()
                                                        }
                                                    >
                                                        บันทึกตำแหน่ง
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
