import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import config from '../../../environments/config';

export function NCPackages(props) {
    const [ loading, setLoading ] = useState(false);
    const [ packages, setPackages ] = useState({});
    useEffect(() => {
        getDealerPackage();
    }, []);

    const getDealerPackage = () => {
        setLoading(true);
        fetch(config.py_url + config.version + '/product/getproduct', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                packs_id: [ '6', '103' ]
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setPackages(result.product);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    return (
        <div>
            <div className='form-group fv-plugins-icon-container'>
                <label>เลือกประเภทเบอร์เข้าร่วมโครงการ</label>
                <div className='row'>
                    <div className='col-lg-6'>
                        <label className='option'>
                            <span className='option-control'>
                                <span className='radio'>
                                    <input
                                        type='radio'
                                        name='packagetype'
                                        value='PT2'
                                        checked={props.type === 'PT2'}
                                        onChange={() => props.handleType('PT2')}
                                    />
                                    <span />
                                </span>
                            </span>
                            <span className='option-label'>
                                <span className='option-head'>
                                    <span className='option-title'>เปิดเบอร์ใหม่</span>
                                </span>
                            </span>
                        </label>
                    </div>
                    <div className='col-lg-6'>
                        <label className='option'>
                            <span className='option-control'>
                                <span className='radio'>
                                    <input
                                        type='radio'
                                        name='packagetype'
                                        value='PT1'
                                        checked={props.type === 'PT1'}
                                        onChange={() => props.handleType('PT1')}
                                    />
                                    <span />
                                </span>
                            </span>
                            <span className='option-label'>
                                <span className='option-head'>
                                    <span className='option-title'>ใช้เบอร์ที่ท่านเป็นเจ้าของอยู่แล้ว</span>
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div className='form-group fv-plugins-icon-container'>
                <label>เลือกขนาดแพ็กเกจที่ต้องการใช้งาน</label>
                {!loading ? (
                    <div className='row'>
                        {Object.keys(packages).length > 0 ? (
                            Object.keys(packages).map((object, index) => {
                                return (
                                    <div className='col-lg-6' key={index}>
                                        <label className='option'>
                                            <span className='option-control'>
                                                <span className='radio'>
                                                    <input
                                                        type='radio'
                                                        name='packageprice'
                                                        value={object}
                                                        checked={props.price === object}
                                                        onChange={() => {
                                                            props.handlePrice(object);
                                                            props.handlePackages(packages[object]);
                                                        }}
                                                    />
                                                    <span />
                                                </span>
                                            </span>
                                            <span className='option-label'>
                                                <span className='option-head'>
                                                    <span className='option-title'>
                                                        <NumberFormat
                                                            value={parseFloat(packages[object].price)}
                                                            displayType={'text'}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            thousandSeparator={true}
                                                        />
                                                        {/* {packages[object].title} */}
                                                    </span>
                                                    {/* <span className='option-focus'>
                                                    <NumberFormat
                                                        value={parseFloat(packages[object].price)}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                    />
                                                </span> */}
                                                </span>
                                                <span className='option-body'>{packages[object].detail}</span>
                                            </span>
                                        </label>
                                    </div>
                                );
                            })
                        ) : (
                            <div className='col-lg-12 text-center py-15'>
                                <p>ไม่มีแพ็กเกจในตอนนี้</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className='row'>
                        <div className='spinner-border mx-auto text-success mt-25 mb-10 p-5' role='status'>
                            <span className='sr-only'>Loading...</span>
                        </div>
                        <h1 className='d-block col-12 text-center mb-15'>กรุณารอสักครู่</h1>
                    </div>
                )}
            </div>
        </div>
    );
}
