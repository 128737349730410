import React, { useState, useEffect, useRef } from "react";

import NumberFormat from "react-number-format";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import { useSelector } from "react-redux";

import { Modal } from "react-bootstrap";

// import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import { RFSummary } from "./step/Summary";

import config from "../environments/config";

export function RefillFunction() {
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(true);
    const [numberlist, setNumberList] = useState([]);
    const [producttype, setProducttype] = useState("1");
    const [active, setActive] = useState(null);
    const [topupprofile, setTopupprofile] = useState({});
    const [amount, setAmount] = useState(null);
    const [packages, setPackages] = useState({});
    const [simstatus, setSimstatus] = useState({});
    const [phonenumber, setPhonenumber] = useState("");
    const [paymentchannel, setPaymentchannel] = useState("2");
    const [productcode, setProductcode] = useState("");
    const [orderresult, setOrderresult] = useState({});

    const [showModal, setShow] = useState(false);

    const handleClose = () => {
        setStep(1);
        setAmount(null);
        setPaymentchannel("2");
        setProductcode("");
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const ref = useRef(null);

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        getNumberList(user.id);
    }, []);

    const backStep = () => {
        setStep(step - 1);
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const scroll = (size, option) => {
        const osInstance = ref.current.osInstance();
        if (osInstance) {
            osInstance.scrollStop().scroll(
                {
                    x: `${option}= ${size}px`,
                },
                1000,
                "easeOutCubic"
            );
        }
    };

    const navigate = (id, number) => {
        setActive(id);
        setPhonenumber(number);
        getNumberStatus(number);
    };

    const getNumberList = (memberid) => {
        fetch(config.py_url + config.version + "/profile/phonenumber_list", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                // userid: "807",
                userid: memberid,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                // setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    setNumberList(result.numbers);
                    getProducts("2");
                    getProducts("3");
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getProducts = (producttype) => {
        fetch(config.py_url + config.version + "/product/getproduct", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                packs_id: [producttype],
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                // setLoading(false);
                // console.log(result);
                // setInit(true);
                if (result.responsecode === 0) {
                    if (producttype === "2") {
                        setTopupprofile(result.product);
                    } else if (producttype === "3") {
                        setPackages(result.product);
                    }
                    //     setNumberList(result.numbers);
                }
            })
            .catch((error) => {
                setLoading(false);
                // setInit(true);
            });
    };

    const getNumberStatus = (phonenumber) => {
        setLoading(true);
        fetch(config.py_url + config.version + "/product/get-sim-profile", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: phonenumber,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                setSimstatus(result.simprofile);
            })
            .catch((error) => {
                setLoading(false);
                // setInit(true);
            });
    };

    const createOrder = () => {
        setLoading(true);
        fetch(config.py_url + config.version + "/order/create", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                userid: user.id,
                phonenumber: phonenumber,
                payment: paymentchannel,
                order_items: [
                    {
                        productcode: productcode,
                        quantity: "1",
                    },
                ],
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                setOrderresult(result);
                setStep(step + 1);
                // setSimstatus(result.simprofile);
            })
            .catch((error) => {
                setLoading(false);
                // setInit(true);
            });
    };

    return (
        <>
            {!loading ? (
                <div className="d-flex flex-row row m-0">
                    {step < 3 ? (
                        <>
                            <div className="flex-row-fluid ml-lg-8 col-12 p-0 mb-5">
                                <div
                                    className="card card-custom card-stretch"
                                    id="wallet-info"
                                >
                                    <div className="card-header bg-danger text-white">
                                        <div className="card-title">
                                            <span className="card-icon">
                                                <i className="fas fa-sim-card text-white"></i>
                                            </span>
                                            <h3 className="card-label text-white">
                                                เบอร์ที่คุณมี
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="card-body pt-4">
                                        <div className="d-flex align-items-center">
                                            <div className="cards-menu w-100">
                                                <div className="card-list testimonial-group">
                                                    <div className="text-right d-none d-md-block">
                                                        <button
                                                            className="btn btn-secondary mr-3"
                                                            onClick={() =>
                                                                scroll(
                                                                    "200",
                                                                    "-"
                                                                )
                                                            }
                                                        >
                                                            <i className="fas fa-chevron-left"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-secondary"
                                                            onClick={() =>
                                                                scroll(
                                                                    "200",
                                                                    "+"
                                                                )
                                                            }
                                                        >
                                                            <i className="fas fa-chevron-right"></i>
                                                        </button>
                                                    </div>
                                                    <div className="inline-scroll">
                                                        <OverlayScrollbarsComponent
                                                            className="wv-100"
                                                            ref={ref}
                                                        >
                                                            {numberlist.map(
                                                                (
                                                                    object,
                                                                    index
                                                                ) => {
                                                                    // console.log(index);
                                                                    if (
                                                                        object.feels_kyc ===
                                                                        1
                                                                    ) {
                                                                        return (
                                                                            <div
                                                                                className="inline-card"
                                                                                key={
                                                                                    index
                                                                                }
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        index,
                                                                                        object.phonenumber
                                                                                    )
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={`card cursor-pointer ${
                                                                                        active ===
                                                                                        index
                                                                                            ? "sim-active"
                                                                                            : ""
                                                                                    }`}
                                                                                >
                                                                                    {/* <img
                                                                            src="https://svgshare.com/i/Kzr.svg"
                                                                            className="card-logo"
                                                                        /> */}
                                                                                    <div className="symbol symbol-40 symbol-light-white mr-5">
                                                                                        <div className="symbol-label">
                                                                                            <i className="fas fa-sim-card fa-2x"></i>
                                                                                            {/* <img src="images/icons/icon_menu_topup.png" className="h-75 align-self-end" alt /> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    <p className="card-number">
                                                                                        {
                                                                                            object.phonenumber
                                                                                        }
                                                                                    </p>
                                                                                    <p className="validity-date p-0 mb-0">
                                                                                        กำหนดรักษาสิทธิ์:{" "}
                                                                                        <span>
                                                                                            {
                                                                                                object.preserve_date
                                                                                            }
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <div
                                                                                className="inline-card"
                                                                                key={
                                                                                    index
                                                                                }
                                                                                // onClick={() =>
                                                                                //     navigate(
                                                                                //         index
                                                                                //     )
                                                                                // }
                                                                            >
                                                                                <div className="card cursor-disable card-disable">
                                                                                    {/* <img
                                                                                src="https://svgshare.com/i/Kzr.svg"
                                                                                className="card-logo"
                                                                            /> */}
                                                                                    <div className="symbol symbol-40 symbol-light-white mr-5">
                                                                                        <div className="symbol-label">
                                                                                            <i className="fas fa-sim-card fa-2x"></i>
                                                                                            {/* <img src="images/icons/icon_menu_topup.png" className="h-75 align-self-end" alt /> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    <p className="card-number">
                                                                                        {
                                                                                            object.phonenumber
                                                                                        }
                                                                                    </p>
                                                                                    <p className="validity-date p-0 mb-0">
                                                                                        {object.feels_kyc ===
                                                                                            0 &&
                                                                                            object.position !==
                                                                                                "" && (
                                                                                                <span className="menu-label">
                                                                                                    <span className="label label-warning label-inline">
                                                                                                        ยังไม่ลงทะเบียนซิม
                                                                                                    </span>
                                                                                                </span>
                                                                                            )}
                                                                                        {object.position ===
                                                                                            "" && (
                                                                                            <span className="menu-label">
                                                                                                <span className="label label-danger label-inline">
                                                                                                    ไม่พร้อมใช้งาน
                                                                                                </span>
                                                                                            </span>
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </OverlayScrollbarsComponent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {active !== null && (
                                <div className="flex-row-fluid col-12 p-0 ml-lg-8">
                                    <div className="card card-custom">
                                        <div className="card-body">
                                            {/* <div className="form-group row justify-content-center">
                                    <label className="col-9 font-weight-bolder">หมายเลขที่ต้องการทำรายการ</label>
                                    <div className="col-9">
                                        <input
                                            className="form-control form-control-lg form-control-solid"
                                        />
                                    </div>
                                </div> */}
                                            <div className="form-group row justify-content-center">
                                                <label className="col-xl-9 col-12 p-0 font-weight-bolder">
                                                    ประเภทการทำรายการ
                                                </label>
                                                <div className="col-xl-9 col-12 p-0">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <label className="option">
                                                                <span className="option-control">
                                                                    <span className="radio">
                                                                        <input
                                                                            type="radio"
                                                                            name="packagetype"
                                                                            value="1"
                                                                            checked={
                                                                                producttype ===
                                                                                "1"
                                                                            }
                                                                            onChange={() =>
                                                                                setProducttype(
                                                                                    "1"
                                                                                )
                                                                            }
                                                                        />
                                                                        <span />
                                                                    </span>
                                                                </span>
                                                                <span className="option-label">
                                                                    <span className="option-head">
                                                                        <span className="option-title font-weight-normal">
                                                                            เติมเงิน
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label
                                                                className={`option ${
                                                                    simstatus.responsecode !==
                                                                        0 ||
                                                                    simstatus[
                                                                        "sim state"
                                                                    ] !==
                                                                        "ACTIVE"
                                                                        ? "bg-gray-300"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <span className="option-control">
                                                                    <span className="radio">
                                                                        <input
                                                                            type="radio"
                                                                            name="packagetype"
                                                                            value="2"
                                                                            checked={
                                                                                producttype ===
                                                                                "2"
                                                                            }
                                                                            onChange={() =>
                                                                                setProducttype(
                                                                                    "2"
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                simstatus.responsecode !==
                                                                                    0 ||
                                                                                simstatus[
                                                                                    "sim state"
                                                                                ] !==
                                                                                    "ACTIVE"
                                                                            }
                                                                        />
                                                                        <span
                                                                            className={
                                                                                simstatus.responsecode !==
                                                                                    0 ||
                                                                                simstatus[
                                                                                    "sim state"
                                                                                ] !==
                                                                                    "ACTIVE"
                                                                                    ? "bg-gray-300"
                                                                                    : ""
                                                                            }
                                                                        />
                                                                    </span>
                                                                </span>
                                                                <span className="option-label">
                                                                    <span className="option-head">
                                                                        <span className="option-title font-weight-normal col-12 p-0">
                                                                            <div className="row col-12">
                                                                                สมัครแพ็กเกจ
                                                                                {simstatus.responsecode !==
                                                                                    0 ||
                                                                                    (simstatus[
                                                                                        "sim state"
                                                                                    ] !==
                                                                                        "ACTIVE" && (
                                                                                        <div className="ml-auto">
                                                                                            <span className="menu-label">
                                                                                                <span className="label label-danger label-inline">
                                                                                                    ไม่พร้อมใช้งาน
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row justify-content-center">
                                                <div className="col-xl-9 col-12">
                                                    <div className="row">
                                                        <div className="card col-12">
                                                            <div className="card-body">
                                                                <div className="row justify-content-center">
                                                                    <div className="col-12 text-center">
                                                                        {producttype ===
                                                                        "1" ? (
                                                                            <>
                                                                                <label className="font-weight-bolder">
                                                                                    จำนวนเงินที่ต้องการเติม
                                                                                </label>
                                                                                <div className="row">
                                                                                    {Object.keys(
                                                                                        topupprofile
                                                                                    )
                                                                                        .length >
                                                                                    0 ? (
                                                                                        Object.keys(
                                                                                            topupprofile
                                                                                        ).map(
                                                                                            (
                                                                                                object,
                                                                                                index
                                                                                            ) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className="col-lg-4"
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        <label className="option">
                                                                                                            <span className="option-control">
                                                                                                                <span className="radio">
                                                                                                                    <input
                                                                                                                        type="radio"
                                                                                                                        name="amount"
                                                                                                                        value={
                                                                                                                            topupprofile[
                                                                                                                                object
                                                                                                                            ]
                                                                                                                                .pv
                                                                                                                        }
                                                                                                                        checked={
                                                                                                                            amount ===
                                                                                                                            topupprofile[
                                                                                                                                object
                                                                                                                            ]
                                                                                                                                .pv
                                                                                                                        }
                                                                                                                        onChange={() =>
                                                                                                                            setAmount(
                                                                                                                                topupprofile[
                                                                                                                                    object
                                                                                                                                ]
                                                                                                                                    .pv
                                                                                                                            )
                                                                                                                        }
                                                                                                                        onClick={() => {
                                                                                                                            setProductcode(
                                                                                                                                topupprofile[
                                                                                                                                    object
                                                                                                                                ]
                                                                                                                                    .code
                                                                                                                            );
                                                                                                                            handleShow();
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <span />
                                                                                                                </span>
                                                                                                            </span>
                                                                                                            <span className="option-label cursor-pointer">
                                                                                                                <span className="option-head">
                                                                                                                    <span className="option-title font-weight-normal">
                                                                                                                        <NumberFormat
                                                                                                                            value={parseFloat(
                                                                                                                                topupprofile[
                                                                                                                                    object
                                                                                                                                ]
                                                                                                                                    .pv
                                                                                                                            )}
                                                                                                                            displayType={
                                                                                                                                "text"
                                                                                                                            }
                                                                                                                            decimalScale={
                                                                                                                                2
                                                                                                                            }
                                                                                                                            fixedDecimalScale={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            thousandSeparator={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            // suffix={
                                                                                                                            //     " บาท"
                                                                                                                            // }
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )
                                                                                    ) : (
                                                                                        <div className="col-lg-12 text-center py-15">
                                                                                            <p>
                                                                                                ไม่มีแพ็กเกจในตอนนี้
                                                                                            </p>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <label className="font-weight-bolder">
                                                                                    แพ็กเกจที่ต้องการสมัคร
                                                                                </label>
                                                                                <div className="testimonial-group">
                                                                                    <div className="inline-scroll">
                                                                                        <OverlayScrollbarsComponent
                                                                                            ref={
                                                                                                ref
                                                                                            }
                                                                                        >
                                                                                            <div className="row flex-nowrap">
                                                                                                {Object.keys(
                                                                                                    packages
                                                                                                ).map(
                                                                                                    (
                                                                                                        object,
                                                                                                        index
                                                                                                    ) => {
                                                                                                        return (
                                                                                                            <div
                                                                                                                className="col-xl-6 col-12"
                                                                                                                key={
                                                                                                                    index
                                                                                                                }
                                                                                                            >
                                                                                                                <div className="refill">
                                                                                                                    <div className="brand">
                                                                                                                        <h2>
                                                                                                                            {
                                                                                                                                packages[
                                                                                                                                    object
                                                                                                                                ]
                                                                                                                                    .title
                                                                                                                            }
                                                                                                                        </h2>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        className="discount alizarin"
                                                                                                                        // className={`discount ${
                                                                                                                        //     object.discount_type ===
                                                                                                                        //     "value"
                                                                                                                        //         ? "peter-river"
                                                                                                                        //         : "amethyst"
                                                                                                                        // }`}
                                                                                                                    >
                                                                                                                        <NumberFormat
                                                                                                                            value={parseFloat(
                                                                                                                                packages[
                                                                                                                                    object
                                                                                                                                ]
                                                                                                                                    .pv
                                                                                                                            )}
                                                                                                                            displayType={
                                                                                                                                "text"
                                                                                                                            }
                                                                                                                            decimalScale={
                                                                                                                                2
                                                                                                                            }
                                                                                                                            fixedDecimalScale={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            thousandSeparator={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            // suffix={
                                                                                                                            //     " บาท"
                                                                                                                            // }
                                                                                                                        />
                                                                                                                        <div className="type">
                                                                                                                            บาท
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="descr row">
                                                                                                                        <div className="col-12">
                                                                                                                            <strong>
                                                                                                                                {
                                                                                                                                    packages[
                                                                                                                                        object
                                                                                                                                    ]
                                                                                                                                        .detail
                                                                                                                                }
                                                                                                                            </strong>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="ends row">
                                                                                                                        {/* <strong className="col-12 p-0 m-0">
                                                                                                                    ใช้ได้ถึง{" "}
                                                                                                                    {
                                                                                                                        object.expire_date
                                                                                                                    }
                                                                                                                </strong> */}
                                                                                                                        <small className="col-12 text-right text-danger">
                                                                                                                            *
                                                                                                                            ราคาไม่รวมภาษีมูลค่าเพิ่ม
                                                                                                                        </small>
                                                                                                                    </div>
                                                                                                                    <div className="coupon midnight-blue">
                                                                                                                        <div
                                                                                                                            className="cursor-pointer open-code"
                                                                                                                            onClick={() => {
                                                                                                                                setAmount(
                                                                                                                                    packages[
                                                                                                                                        object
                                                                                                                                    ]
                                                                                                                                        .price
                                                                                                                                );
                                                                                                                                setProductcode(
                                                                                                                                    packages[
                                                                                                                                        object
                                                                                                                                    ]
                                                                                                                                        .code
                                                                                                                                );
                                                                                                                                handleShow();
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            ซื้อเลย
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                            </div>
                                                                                        </OverlayScrollbarsComponent>
                                                                                    </div>
                                                                                    <div className="mt-5 d-none d-md-block">
                                                                                        <button
                                                                                            className="btn btn-secondary mr-3"
                                                                                            onClick={() =>
                                                                                                scroll(
                                                                                                    "200",
                                                                                                    "-"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-chevron-left"></i>
                                                                                        </button>
                                                                                        <button
                                                                                            className="btn btn-secondary"
                                                                                            onClick={() =>
                                                                                                scroll(
                                                                                                    "200",
                                                                                                    "+"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="fas fa-chevron-right"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="flex-row-fluid ml-lg-8 col-12 p-0 mb-5">
                            <div className="card card-custom card-stretch">
                                <div className="card-header bg-danger text-white">
                                    <div className="card-title">
                                        {/* <span className="card-icon">
                                                <i className="fas fa-sim-card text-white"></i>
                                            </span> */}
                                        <h3 className="card-label text-white">
                                            สรุปการทำรายการ
                                        </h3>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <RFSummary
                                        response={orderresult}
                                        paymenttype={paymentchannel}
                                        producttype={producttype}
                                        phonenumber={phonenumber}
                                        amount={amount}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                show={showModal}
                onHide={handleClose}
                size="lg"
                centered
                aria-labelledby="unactive-number-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="unactive-number-modal"
                        className="col-11 p-0"
                    >
                        รายละเอียดเติมเงิน/แพ็กเกจ
                    </Modal.Title>
                    <button
                        type="button"
                        className="close col-1 text-right pr-3"
                        onClick={handleClose}
                    >
                        <i className="ki ki-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {step === 1 ? (
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <h2 className="text-center mb-5">
                                        เลือกช่องทางการชำระเงิน
                                    </h2>
                                    <div className="row d-flex justify-content-center text-center mx-auto">
                                        <div className="payment-icon col-xl-3 col-6 d-inline">
                                            <label>
                                                <img
                                                    alt="thaiqr-logo"
                                                    src="https://cdn.simk4.com/payment/thatqr.png"
                                                    className={`payment-img${
                                                        paymentchannel === "2"
                                                            ? "-active"
                                                            : ""
                                                    } max-h-120px max-w-120px`}
                                                />
                                                <input
                                                    type="radio"
                                                    name="payment-method"
                                                    value="2"
                                                    checked={
                                                        paymentchannel === "2"
                                                    }
                                                    onChange={() =>
                                                        setPaymentchannel("2")
                                                    }
                                                    // className="ng-untouched ng-pristine ng-valid"
                                                />
                                            </label>
                                        </div>
                                        <div className="payment-icon col-xl-3 col-6 d-inline">
                                            <label>
                                                <img
                                                    alt="wallet-logo"
                                                    src="https://cdn.simk4.com/payment/wallet2.png"
                                                    className={`payment-img${
                                                        paymentchannel === "3"
                                                            ? "-active"
                                                            : ""
                                                    } max-h-120px max-w-120px`}
                                                />
                                                <input
                                                    type="radio"
                                                    name="payment-method"
                                                    value="3"
                                                    checked={
                                                        paymentchannel === "3"
                                                    }
                                                    onChange={() =>
                                                        setPaymentchannel("3")
                                                    }
                                                    // className="ng-untouched ng-pristine ng-valid"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="form-group">
                                    <h2 className="text-center mb-5">
                                        สรุปการทำรายการ
                                    </h2>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="bg-light rounded d-flex flex-column p-3">
                                                <div className="p-2 d-flex">
                                                    <div className="col-7">
                                                        เบอร์ที่ทำรายการ
                                                    </div>
                                                    <div className="ml-auto text-right">
                                                        {phonenumber}
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex">
                                                    <div className="col-8">
                                                        ประเภทการทำรายการ
                                                    </div>
                                                    <div className="ml-auto">
                                                        {producttype === "1"
                                                            ? "เติมเงิน"
                                                            : "สมัครแพ็กเกจ"}
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex">
                                                    <div className="col-8">
                                                        ชำระเงินผ่าน
                                                    </div>
                                                    <div className="ml-auto">
                                                        {paymentchannel === "2"
                                                            ? "Thai QR Payment"
                                                            : "กระเป๋าปันสุข"}
                                                    </div>
                                                </div>
                                                <div className="border-top px-4 mx-3" />
                                                <div className="p-2 d-flex pt-3">
                                                    <div className="col-8">
                                                        <b>ยอดรวม</b>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <b className="green">
                                                            <NumberFormat
                                                                value={parseFloat(
                                                                    amount
                                                                )}
                                                                displayType={
                                                                    "text"
                                                                }
                                                                decimalScale={2}
                                                                fixedDecimalScale={
                                                                    true
                                                                }
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                suffix={" บาท"}
                                                            />
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="m-3">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setShow(false);
                                                            createOrder();
                                                        }}
                                                        className="btn btn-primary btn-block"
                                                    >
                                                        ชำระเงิน
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between col-12">
                        <div className="mr-2">
                            {step > 1 && (
                                <button
                                    type="button"
                                    onClick={backStep}
                                    className="btn btn-light-primary btn-cus-rounded font-weight-bold text-uppercase px-9 py-4"
                                >
                                    ก่อนหน้า
                                </button>
                            )}
                        </div>
                        <div>
                            {step < 2 && (
                                <button
                                    type="button"
                                    onClick={nextStep}
                                    className="btn btn-success btn-cus-rounded font-weight-bold text-uppercase px-9 py-4"
                                >
                                    ถัดไป
                                </button>
                            )}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
