import React from 'react';
import SVG from 'react-inlinesvg';
import { QRCode } from 'react-qrcode-logo';
import Moment from 'moment';
import html2canvas from 'html2canvas';
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';

export function ShareOrderOperationResult(props) {
    const btnDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `${props.response.reference1}-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    return (
        <div>
            <div className='card card-custom gutter-b min-h-500px'>
                <div className='card-body d-flex rounded bg-white p-md-12 p-0 justify-content-center'>
                    {props.payment === 'bank' || props.payment === 'credit' ? (
                        <div className='row d-flex justify-content-center pb-5'>
                            {props.response.responsecode === 0 ? (
                                <div className='col-12'>
                                    <div className='row justify-content-center'>
                                        <div className='text-center rounded bg-payment p-1'>
                                            <div className='text-white text-center'>
                                                หมายเลขคำสั่งซื้อ {props.response.reference1}
                                            </div>
                                            <div className='qrimg'>
                                                <QRCode
                                                    className='border'
                                                    value={props.response.qrcode}
                                                    size={256}
                                                    eyeRadius={10}
                                                    logoImage={toAbsoluteUrl('/media/logos/new-logo.png')}
                                                    logoWidth={100}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='col-12'>
                                    <div className='row justify-content-center'>
                                        <div className='text-center'>
                                            <div className='qrimg'>
                                                <img
                                                    alt='error'
                                                    src={toAbsoluteUrl('/media/error/qr-error.png')}
                                                    className='logo-sticky max-h-250px'
                                                />
                                            </div>
                                            <h4 className='text-center text-color-main mb-0 mt-5'>
                                                คิวอาร์โค้ดไม่สมบูรณ์
                                            </h4>
                                        </div>
                                        <div className='col-12 mt-50 text-center'>
                                            <p className='text-center'>กรุณาติดต่อเจ้าหน้าที่</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {props.response.responsecode === 0 && (
                                <div className='row col-12 p-0 m-0'>
                                    <div className='col-12 mt-50 text-center'>
                                        <button
                                            type='button'
                                            className='btn btn-success font-weight-bold text-uppercase px-6 py-2'
                                            onClick={btnDownload}
                                        >
                                            บันทึกภาพ
                                        </button>
                                    </div>
                                    <div className='col-12 mt-50 text-center'>
                                        <p className='text-center'>
                                            กรุณาบันทึกภาพคิวอาร์โค้ดและทำการชำระเงินที่แอพ
                                            <span> ธนาคาร </span> ของท่าน
                                            <b className='text-danger'>ภายใน 1 วัน</b>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='col-12'>
                            <div className='row justify-content-center'>
                                <div className='text-center'>
                                    <div className='qrimg'>
                                        <img
                                            alt='result'
                                            src={toAbsoluteUrl('/media/misc/regiser-success.svg')}
                                            className='logo-sticky h-400px'
                                        />
                                    </div>
                                    <h4 className='text-center text-color-main mb-0 mt-5'>
                                        จองหุ้นเรียบร้อย
                                        {/* {props.response.responsecode === '102' ? (
                                            'ขั้นตอนการเพิ่มสิทธิ์ไม่สมบูรณ์ กรุณาติดต่อเจ้าหน้าที่'
                                        ) : (
                                            'คิวอาร์โค้ดไม่สมบูรณ์'
                                        )} */}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
