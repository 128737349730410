import React, { useState } from "react";

import { Modal } from "react-bootstrap";

import NumberFormat from "react-number-format";

import Countdown from "react-countdown";

import OtpInput from "react-otp-input";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import config from "../../environments/config";

export function WDConfirm(prop) {
    const [loading, setLoading] = useState(false);
    const [showModal, setShow] = useState(false);
    const [countdown, setCountdown] = useState(null);
    const [disabled, setDisabled] = useState(false);
    // const [otpcode, setOtpcode] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => {
        // getOTP();
        if (prop.expired === "") {
            getOTP();
        } else {
            if (Date.now() >= Date.parse(prop.expired)) {
                getOTP();
            } else {
                setShow(true);
            }
        }
        // setShow(true);
        // // setCountdown(Date.now() + 60000 * 15);
        // if (Date.now() >= countdown) {
        //     setCountdown(Date.now() + (60000 * 1) / 2);
        // }
    };

    const renewOTP = () => {
        getOTP();
    };

    const Completionist = () => {
        return (
            <div className="row justify-content-center col-12 p-0 m-0">
                <button className="btn btn-primary" onClick={renewOTP}>
                    ขอ OTP ใหม่
                </button>
            </div>
        );
    };

    // Renderer callback with condition
    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            // ("2020-01-02 11:00:00");
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className="row col-12 p-0 m-0 text-center">
                    <p className="col-12 m-0">กรุณาลองใหม่ในอีก</p>
                    <span className="col-12">
                        {(minutes < 10 ? "0" : "") + minutes}:
                        {(seconds < 10 ? "0" : "") + seconds}
                    </span>
                </div>
            );
        }
    };

    const getOTP = () => {
        setLoading(true);
        fetch(config.py_url + config.version + "/auth/otp-generate", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                // userid: "807",
                userid: prop.userid,
                // phonenumber: "0992178354",
                phonenumber: prop.phonenumber,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    prop.handleRefcode(result.refcode);
                    prop.handleExpired(result.timeout);
                    setCountdown(Date.parse(result.timeout));
                    // setCountdown(Date.now() + (60000 * 1) / 2);
                    setShow(true);
                    // setBalance(result.total);
                    //     setAccount(result.bank.account_name);
                    //     setBank(result.bank.bank_name);
                    //     setBankno(result.bank.account_no);
                } else if (result.responsecode === 400) {
                    Swal.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            '<div class="alert-text">ข้อมูลของคุณไม่สอดคร้องกับเลขหมายที่ต้องการถอนเงิน</div>',
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        // cancelButtonText: "ยกเลิก",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {});
                } else if (result.responsecode === 401) {
                    Swal.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            '<div class="alert-text">ไม่สามารถส่งรหัส OTP ได้เนื่องขากเลขหมายนี้ไม่ใช่เบอร์ Feels</div>',
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        // cancelButtonText: "ยกเลิก",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {});
                } else if (
                    result.responsecode === 402 ||
                    result.responsecode === 499
                ) {
                    Swal.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            '<div class="alert-text">เอ๊ะ!!! มีบางอย่างผิดพลาดกรุณาลองใหม่อีกครั้ง</div>',
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        // cancelButtonText: "ยกเลิก",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {});
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html:
                        '<div class="alert-text">เอ๊ะ!!! มีบางอย่างผิดพลาดกรุณาลองใหม่อีกครั้ง</div>',
                    icon: "warning",
                    // showCancelButton: true,
                    confirmButtonText: "รับทราบ",
                    // cancelButtonText: "ยกเลิก",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                }).then((result) => {});
            });
    };

    const verifyOTP = () => {
        setLoading(true);
        setDisabled(true);
        fetch(config.py_url + config.version + "/auth/otp-verify", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                // userid: "807",
                userid: prop.userid,
                // phonenumber: "0992178354",
                phonenumber: prop.phonenumber,
                otp: prop.otp,
                ref: prop.refcode,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    prop.nextStep();
                } else if (result.responsecode === 400) {
                    setDisabled(false);
                    Swal.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            '<div class="alert-text">รหัส OTP ไม่ถูกต้องกรุณาลองใหม่อีกครั้ง</div>',
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        // cancelButtonText: "ยกเลิก",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {});
                } else if (result.responsecode === 401) {
                    setDisabled(false);
                    Swal.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            '<div class="alert-text">รหัส OTP หมดอายุแล้วกรุณาลองใหม่อีกครั้ง</div>',
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        // cancelButtonText: "ยกเลิก",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {});
                } else if (result.responsecode === 499) {
                    setDisabled(false);
                    Swal.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html:
                            '<div class="alert-text">เอ๊ะ!!! มีบางอย่างผิดพลาดกรุณาลองใหม่อีกครั้ง</div>',
                        icon: "warning",
                        // showCancelButton: true,
                        confirmButtonText: "รับทราบ",
                        // cancelButtonText: "ยกเลิก",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                    }).then((result) => {});
                }
            })
            .catch((error) => {
                setLoading(false);
                setDisabled(false);
                Swal.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html:
                        '<div class="alert-text">เอ๊ะ!!! มีบางอย่างผิดพลาดกรุณาลองใหม่อีกครั้ง</div>',
                    icon: "warning",
                    // showCancelButton: true,
                    confirmButtonText: "รับทราบ",
                    // cancelButtonText: "ยกเลิก",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                }).then((result) => {});
            });
    };
    return (
        <>
            {loading && (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            <>
                <div className="flex-row-fluid col-12 p-0 m-0">
                    {/* <div className="card card-custom card-stretch">
                <div className="card-body"> */}
                    <div className="d-flex row align-items-center justify-content-center">
                        <h1 className="col-xl-12 text-center">
                            ตรวจสอบข้อมูลการถอนเงิน
                        </h1>
                        <div className="col-xl-12">
                            <div className="row m-0 p-3">
                                <div className="d-flex col-xl-12">
                                    <div className="p-2">ชื่อธนาคาร</div>
                                    <div className="ml-auto p-2">
                                        {prop.bank}
                                    </div>
                                </div>
                                <div className="d-flex col-xl-12">
                                    <div className="p-2">ชื่อบัญชี</div>
                                    <div className="ml-auto p-2">
                                        {prop.account}
                                    </div>
                                </div>
                                <div className="d-flex col-xl-12">
                                    <div className="p-2">เลขที่บัญชี</div>
                                    <div className="ml-auto p-2">
                                        {prop.bankno}
                                    </div>
                                </div>
                                <div className="d-flex col-xl-12">
                                    <div className="p-2">
                                        จำนวนเงินที่ต้องการถอน
                                    </div>
                                    <div className="ml-auto p-2">
                                        <NumberFormat
                                            value={parseFloat(prop.amount)}
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            // suffix={
                                            //     " บาท"
                                            // }
                                        />
                                    </div>
                                </div>
                                <div className="d-flex col-xl-12">
                                    <div className="p-2">ค่าธรรมเนียม</div>
                                    <div className="ml-auto p-2">
                                        <NumberFormat
                                            value={parseFloat(prop.fee)}
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            // suffix={
                                            //     " บาท"
                                            // }
                                        />
                                    </div>
                                </div>
                                <div className="d-flex col-xl-12">
                                    <div className="p-2">
                                        ภาษีหัก ณ ที่จ่าย 3 %
                                    </div>
                                    <div className="ml-auto p-2">
                                        <NumberFormat
                                            value={parseFloat(
                                                parseFloat(prop.amount) *
                                                    parseFloat(prop.tag)
                                            )}
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            // suffix={
                                            //     " บาท"
                                            // }
                                        />
                                    </div>
                                </div>
                                <div className="d-flex col-xl-12">
                                    <div className="p-2">
                                        จำนวนเงินที่ได้รับ
                                    </div>
                                    <div className="ml-auto p-2">
                                        <NumberFormat
                                            value={parseFloat(
                                                parseFloat(prop.amount) -
                                                    (prop.fee +
                                                        parseFloat(
                                                            prop.amount
                                                        ) *
                                                            parseFloat(
                                                                prop.tag
                                                            ))
                                            )}
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            // suffix={
                                            //     " บาท"
                                            // }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div>
            </div> */}
                </div>
                <div className="col-12 d-flex justify-content-between border-top mt-5 py-10">
                    <div className="mr-2">
                        {/* <button
                            type="button"
                            onClick={backStep}
                            className="btn btn-light-primary btn-cus-rounded font-weight-bold text-uppercase px-9 py-4"
                        >
                            ก่อนหน้า
                        </button> */}
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={handleShow}
                            className="btn btn-success btn-cus-rounded font-weight-bold text-uppercase px-9 py-4"
                        >
                            ยืนยัน
                        </button>
                    </div>
                </div>
                {/* // <Countdown date={Date.now() + 60000 * 15} renderer={renderer} /> */}
                <Modal
                    show={showModal}
                    onHide={handleClose}
                    size="lg"
                    centered
                    aria-labelledby="otp-modal"
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="otp-modal" className="col-11 p-0">
                            ยืนยันการทำรายการ
                        </Modal.Title>
                        <button
                            type="button"
                            className="close col-1 text-right pr-3"
                            onClick={handleClose}
                        >
                            <i className="ki ki-close"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12 text-center">
                                <h4>
                                    กรุณากรอกรหัส OTP
                                    ที่ส่งไปยังเบอร์ที่ต้องการถอนเงิน
                                </h4>
                                <h6>(Ref : {prop.refcode})</h6>
                            </div>
                            <div className="col-12 row justify-content-center m-0 p-0 py-15">
                                <OtpInput
                                    value={prop.otp}
                                    onChange={prop.handleOtp}
                                    numInputs={6}
                                    isInputNum={true}
                                    containerStyle="col-12 row justify-content-center form-group digit-group"
                                    // inputStyle="form-control form-control-lg"
                                />
                                {/* <p>กรุณาลองใหม่ในอีก</p> */}
                                <Countdown
                                    date={countdown}
                                    renderer={renderer}
                                />
                            </div>
                            <div className="d-flex col-12 justify-content-between border-top mt-5 pt-5">
                                <div className="mr-2" />
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-success btn-cus-rounded font-weight-bold text-uppercase px-9 py-4"
                                        onClick={verifyOTP}
                                        disabled={disabled}
                                    >
                                        ถัดไป
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        </>
    );
}
