import React from 'react';

import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import * as auth from '../../../../modules/Auth/_redux/authRedux';

import config from '../../../environments/config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

function ShopCartAccountLogin(props) {
    const register = () => {
        props.setLoading(true);
        fetch(config.punsook_shop_url + 'guest/user/register', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret
            },
            body: JSON.stringify({
                username: props.phonenumber,
                user_id: props.user.id
            })
        })
            .then((response) => response.json())
            .then((result) => {
                // props.setLoading(false);
                if (result.responsecode === 0) {
                    let edited = props.user;
                    edited['shop_account'] = props.phonenumber;
                    edited['shop_token'] = result.token;
                    props.newLogin(edited);
                    props.setPage('main');
                    props.getCarts();
                } else {
                    props.setLoading(false);
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถดำเนินการต่อได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                // console.log(result);
                // setAccounts(result);
            })
            .catch((error) => {
                props.setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถดำเนินการต่อได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };
    return (
        <div className='login-account h-500px bg-white p-5'>
            <div className='d-flex flex-column'>
                <div className='d-flex justify-content-start align-items-center p-5'>
                    <img
                        alt='logo'
                        className='max-h-75px my-auto'
                        src={toAbsoluteUrl('/media/logos/logo_sim_punsuk_final.png')}
                    />
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <div className='symbol symbol-60 symbol-light-white mr-0 mb-7'>
                    <div className='symbol-label'>
                        <i className='fas fa-sim-card fa-4x' />
                    </div>
                </div>
                <h6 className='font-weight-bold'>Punsook Shop ขอสิทธิ์การเข้าถึง:</h6>
                <p className='mb-15'>ข้อมูลส่วนบุคคลของคุณ</p>
                <button type='button' className='btn btn-primary btn-block' onClick={register}>
                    ดำเนินการต่อด้วยหมายเลข {props.phonenumber}
                </button>
                <button type='button' className='btn btn-secondary btn-block' onClick={() => props.setPage('switch')}>
                    ยกเลิก
                </button>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(ShopCartAccountLogin));
