import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Select, { components } from "react-select";

import NumberFormat from "react-number-format";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import DatePicker, { utils } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import "./THReport.scss";

import config from "../../environments/config";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { SubscriberList } from "../../../Setting";

const myCustomLocale = {
    // months list by order
    months: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
    ],

    // week days by order
    weekDays: [
        {
            name: "อาทิตย์", // used for accessibility
            short: "อา", // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: "จันทร์",
            short: "จ",
        },
        {
            name: "อังคาร",
            short: "อ",
        },
        {
            name: "พุธ",
            short: "พ",
        },
        {
            name: "พฤหัสบดี",
            short: "พฤ",
        },
        {
            name: "ศุกร์",
            short: "ศ",
        },
        {
            name: "เสาร์",
            short: "ส",
            isWeekend: true,
        },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },

    // texts in the date picker
    nextMonth: "Next Month",
    previousMonth: "Previous Month",
    openMonthSelector: "Open Month Selector",
    openYearSelector: "Open Year Selector",
    closeMonthSelector: "Close Month Selector",
    closeYearSelector: "Close Year Selector",
    defaultPlaceholder: "Select...",

    // for input range value
    from: "from",
    to: "to",

    // used for input value when multi dates are selected
    digitSeparator: ",",

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
};

const minimumDate = {
    year: 2020,
    month: 12,
    day: 31,
};

const controlStyles = {
    borderRadius: "0.85rem",
    padding: "5px",
    background: "#F3F6F9",
    color: "white",
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#F3F6F9",
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
    }),
    // singleValue: (provided, state) => {
    //     const opacity = state.isDisabled ? 0.5 : 1;
    //     const transition = "opacity 300ms";
    //     return { ...provided, opacity, transition };
    // },
};

export function THReport() {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState({
        from: null,
        to: null,
    });
    const [numberlist, setNumberList] = useState([]);
    const [balance, setBalance] = useState("0");
    const [periodbalance, setPeriodBalance] = useState("0");
    const [phonenumber, setPhonenumber] = useState("");
    const [requestdate, setRequestDate] = useState("");
    const [statementlist, setStatementList] = useState([]);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (SubscriberList.includes(user.main_position)) {
            history.push('/');
        } else {
            getNumberList(user.id);
        }
    }, []);

    const getNumberList = (memberid) => {
        fetch(config.py_url + config.version + "/profile/phonenumber_list", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                userid: memberid,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    let numbers = [];
                    // let numbers = [
                    //     {
                    //         value: "0976935555",
                    //         label: "0976935555",
                    //     },
                    // ];
                    let index = 0;
                    // let index = 1;
                    for (const prop in result.numbers) {
                        numbers[index] = {
                            value: result.numbers[prop]["phonenumber"],
                            label: result.numbers[prop]["phonenumber"],
                        };
                        index += 1;
                    }
                    setNumberList(numbers);
                    // console.log(numberlist);
                    // setNumberList
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to
                    ? date.from.year +
                      "/" +
                      ("0" + date.from.month).slice(-2) +
                      "/" +
                      ("0" + date.from.day).slice(-2) +
                      " - " +
                      date.to.year +
                      "/" +
                      ("0" + date.to.month).slice(-2) +
                      "/" +
                      ("0" + date.to.day).slice(-2)
                    : ""
            }
            className="form-control form-control-lg form-control-solid"
            type="text"
            id="dateperiod"
            name="dateperiod"
        />
    );

    const selectAccount = (item) => {
        if (item) {
            setPhonenumber(item);
        }
    };

    const search = () => {
        setLoading(true);
        if (phonenumber === "") {
            Swal.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกเบอร์ที่ต้องการดูประวัติธุรกรรม",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            setLoading(false);
            return;
        }
        let postparam = {};
        postparam = {
            userid: user.id,
            phonenumber: phonenumber.value,
        };
        let request = new Date();
        request.toLocaleString("th-TH", { timeZone: "Asia/Bangkok" });
        let requestdatestr =
            "ข้อมูล ณ วันที่ " +
            request.getDate() +
            " " +
            myCustomLocale.months[request.getMonth()] +
            " " +
            request.getFullYear() +
            " เวลา " +
            request.getHours() +
            ":" +
            request.getMinutes() +
            ":" +
            request.getSeconds() +
            " น.";
        setRequestDate(requestdatestr);
        getBalance(postparam);
    };

    const getBalance = (postparam) => {
        fetch(config.py_url + config.version + "/wallet/balance", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                // setLoading(false);
                // console.log(result);
                // getStatement();
                if (result.responsecode === 0) {
                    setBalance(result.total);
                    getStatement();
                    //     setStatementList(result.transactions);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getStatement = () => {
        // setLoading(true);
        // let request = new Date();
        // request.toLocaleString("th-TH", { timeZone: "Asia/Bangkok" });
        // console.log(request.getDate());
        // ข้อมูล ณ วันที่ {requestdate.date} เวลา
        //                                 13:54:45 น.
        // let requestdatestr =
        //     "ข้อมูล ณ วันที่ " +
        //     request.getDate() +
        //     " " +
        //     myCustomLocale.months[request.getMonth()] +
        //     " " +
        //     request.getFullYear() +
        //     " เวลา " +
        //     request.getHours() +
        //     ":" +
        //     request.getMinutes() +
        //     ":" +
        //     request.getSeconds() +
        //     " น.";
        // setRequestDate(requestdatestr);
        // if (phonenumber === "") {
        //     console.log("empty param");
        //     setLoading(false);
        //     return;
        // }
        // let postparam1 = {
        //     phonenumber: phonenumber.value,
        // }
        // fetch("http://192.168.159.44:5004/v1/wallet/balance", {
        //     method: "post",
        //     headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //         secret: "JHD769GasI8MgQE4s0C0kloRvIZ2gdPO",
        //     },
        //     body: JSON.stringify(postparam1),
        // })
        //     .then((response) => response.json())
        //     .then((result) => {
        //         setLoading(false);
        //         console.log(result);
        //         // if (result.responsecode === 0) {
        //         //     setStatementList(result.transactions);
        //         // }
        //     })
        //     .catch((error) => {
        //         setLoading(false);
        //     });
        let postparam = {
            phonenumber: phonenumber.value,
        };
        if (date.from !== null && date.to !== null) {
            postparam["start"] =
                date.from.year +
                "-" +
                ("0" + date.from.month).slice(-2) +
                "-" +
                ("0" + date.from.day).slice(-2);
            postparam["end"] =
                date.to.year +
                "-" +
                ("0" + date.to.month).slice(-2) +
                "-" +
                ("0" + date.to.day).slice(-2);
        }
        fetch(config.py_url + config.version + "/wallet/statement", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    setStatementList(result.transactions);
                    setPeriodBalance(
                        result.summary.deposit - result.summary.withdraw
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    return (
        <>
            {!loading ? (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-custom">
                            <div className="card-header bg-danger text-white">
                                <div className="card-title">
                                    <span className="card-icon">
                                        <i className="fa fa-search text-white" />
                                    </span>
                                    <h3 className="card-label text-white">
                                        ค้นหา
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group col-sm-6">
                                        <label htmlFor="name">บัญชี</label>
                                        <Select
                                            components={{
                                                Control: ControlComponent,
                                            }}
                                            noOptionsMessage={() =>
                                                "ไม่พบข้อมูล"
                                            }
                                            value={phonenumber}
                                            options={numberlist}
                                            placeholder="เลือก"
                                            id="account"
                                            onChange={selectAccount}
                                            // isClearable={true}
                                            menuPortalTarget={document.body}
                                            styles={customStyles}
                                        />
                                        {/* <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        onChange={this.handleChange}
                                                    // value={this.state.filter.name}
                                                    /> */}
                                    </div>
                                    {/* <div className="form-group col-sm-4">
                                        <label htmlFor="filterorderstatus">
                                            ประเภทธุรกรรม
                                        </label>
                                        <input
                                            className="form-control form-control-lg form-control-solid"
                                            type="text"
                                            id="name"
                                            name="name"
                                            defaultValue=""
                                        />
                                        <Select
                                                        // defaultValue={this.orderstatus[0]}
                                                        options={this.orderstatus}
                                                        placeholder="เลือก"
                                                        onChange={this.selectOrderStatus}
                                                        // value={this.state.filter.orderstatus}
                                                        isClearable={true}
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    />
                                    </div> */}
                                    <div className="form-group col-sm-6">
                                        <label htmlFor="filterorderdate">
                                            วันที่ทำธุรกรรม
                                        </label>
                                        {/* <input
                                    className="form-control form-control-lg form-control-solid"
                                    type="text"
                                    id="name"
                                    name="name"
                                    defaultValue=""
                                /> */}
                                        {/* <div className="col-12 p-0"> */}
                                        <DatePicker
                                            value={date}
                                            onChange={setDate}
                                            renderInput={renderCustomInput}
                                            minimumDate={minimumDate}
                                            maximumDate={utils().getToday()}
                                            locale={myCustomLocale}
                                            wrapperClassName="col-12 p-0"
                                            calendarClassName="responsive-calendar"
                                            calendarPopperPosition="bottom"
                                            shouldHighlightWeekends
                                        />
                                        <span className="form-text text-muted">
                                            หากไม่ได้เลือกจะดึงข้อมูลของวันนี้
                                        </span>
                                        {/* </div> */}
                                        {/* <DateRangePicker
                                    className="d-block"
                                    placeholder="เลือก"
                                    locale={{
                                        sunday: "อา",
                                        monday: "จ",
                                        tuesday: "อ",
                                        wednesday: "พ",
                                        thursday: "พฤ",
                                        friday: "ศ",
                                        saturday: "ส",
                                        ok: "ยืนยัน",
                                        today: "วันนี้",
                                        yesterday: "เมื่อวาน",
                                        last7Days: "7 วันที่แล้ว",
                                    }}
                                    showOneCalendar
                                    // disabledDate={afterToday}
                                    onChange={(item) => selectDate(item)}
                                /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button
                                    type="button"
                                    onClick={search}
                                    className="btn btn-sm btn-primary"
                                >
                                    <i className="far fa-dot-circle"></i> ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 result-body">
                        <div className="card card-custom">
                            <div className="card-header bg-danger text-white">
                                <div className="card-title">
                                    <span className="card-icon">
                                        <i className="flaticon2-file text-white" />
                                    </span>
                                    <h3 className="card-label text-white">
                                        รายงาน
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="ml-auto mb-3">
                                        {requestdate}
                                    </div>
                                    <div className="example example-basic col-12 p-0">
                                        <div
                                            className="example-preview"
                                            id="threport"
                                        >
                                            <div className="header row col-12 w-100 m-0 p-0">
                                                <div className="row col-12 p-0 m-0">
                                                    <h2 className="col text-left p-0">
                                                        ยอดคงเหลือ
                                                    </h2>
                                                    <p className="col text-right total-balance p-0">
                                                        <NumberFormat
                                                            value={parseFloat(
                                                                balance
                                                            )}
                                                            displayType={"text"}
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            suffix={" บาท"}
                                                        />
                                                    </p>
                                                </div>
                                                <div className="row col-12 p-0 m-0">
                                                    <p className="col text-left p-0">
                                                        ยอดคงเหลือตามช่วงที่ค้นหา
                                                    </p>
                                                    <p className="col text-right p-0">
                                                        <NumberFormat
                                                            value={parseFloat(
                                                                periodbalance
                                                            )}
                                                            displayType={"text"}
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                                true
                                                            }
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            suffix={" บาท"}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                            {statementlist.length <= 0 ? (
                                                <div className="balance-info-height">
                                                    <div className="row justify-content-center align-items-center h-100">
                                                        {/* <div>
                                                            <i className="fas fa-list-ul fa-2x"></i>
                                                        </div> */}
                                                        <h1>
                                                            ไม่พบรายการธุรกรรม
                                                        </h1>
                                                    </div>
                                                </div>
                                            ) : (
                                                <OverlayScrollbarsComponent className="balance-info-height">
                                                    <div className="timeline timeline-1">
                                                        <div className="timeline-sep bg-primary-opacity-20" />
                                                        {/* <div className="timeline-bar" /> */}
                                                        <div className="timeline-items">
                                                            {statementlist.map(
                                                                (
                                                                    object,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <div className="timeline-item">
                                                                            <div className="timeline-label">
                                                                                {
                                                                                    object.date
                                                                                }
                                                                            </div>
                                                                            <div className="timeline-badge">
                                                                                {object.type ===
                                                                                "withdraw" ? (
                                                                                    <i className="fas fa-minus-circle text-primary " />
                                                                                ) : (
                                                                                    <i className="fas fa-plus-circle text-success " />
                                                                                )}
                                                                            </div>
                                                                            <div className="timeline-content text-muted font-weight-normal">
                                                                                <div className="row col-12">
                                                                                    <div className="col-7 text-left p-0 my-auto">
                                                                                        {
                                                                                            object.detail
                                                                                        }
                                                                                    </div>
                                                                                    <div className="d-none d-md-block col-5 text-right p-0 my-auto">
                                                                                        <NumberFormat
                                                                                            value={parseFloat(
                                                                                                object.amount
                                                                                            )}
                                                                                            displayType={
                                                                                                "text"
                                                                                            }
                                                                                            decimalScale={
                                                                                                2
                                                                                            }
                                                                                            fixedDecimalScale={
                                                                                                true
                                                                                            }
                                                                                            thousandSeparator={
                                                                                                true
                                                                                            }
                                                                                            suffix={
                                                                                                " บาท"
                                                                                            }
                                                                                        />
                                                                                        {/* {object.amount +
                                                                                        " บาท"} */}
                                                                                    </div>
                                                                                    <div className="d-block d-md-none col-5 text-right p-0 my-auto">
                                                                                        <NumberFormat
                                                                                            value={parseFloat(
                                                                                                object.amount
                                                                                            )}
                                                                                            displayType={
                                                                                                "text"
                                                                                            }
                                                                                            decimalScale={
                                                                                                2
                                                                                            }
                                                                                            fixedDecimalScale={
                                                                                                true
                                                                                            }
                                                                                            thousandSeparator={
                                                                                                true
                                                                                            }
                                                                                            // suffix={
                                                                                            //     " บาท"
                                                                                            // }
                                                                                        />
                                                                                        {/* {object.amount +
                                                                                        " บาท"} */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        // <div
                                                                        //     className={`timeline-item ${
                                                                        //         object.type ===
                                                                        //         "withdraw"
                                                                        //             ? "timeline-item-right"
                                                                        //             : "timeline-item-left"
                                                                        //     }`}
                                                                        // >
                                                                        //     <div className="timeline-badge">
                                                                        //         <div
                                                                        //             className={`${
                                                                        //                 object.type ===
                                                                        //                 "withdraw"
                                                                        //                     ? "bg-danger"
                                                                        //                     : "bg-success"
                                                                        //             }`}
                                                                        //         />
                                                                        //     </div>
                                                                        //     <div className="timeline-label">
                                                                        //         <span className="text-primary font-weight-bold">
                                                                        //             {
                                                                        //                 object.date
                                                                        //             }
                                                                        //         </span>
                                                                        //     </div>
                                                                        //     <div className="timeline-content">
                                                                        //         <h3>
                                                                        //             {
                                                                        //                 object.detail
                                                                        //             }
                                                                        //         </h3>
                                                                        //     </div>
                                                                        // </div>
                                                                    );
                                                                }
                                                            )}
                                                            {/* <div className="timeline-item timeline-item-left">
                                                            <div className="timeline-badge">
                                                                <div className="bg-danger" />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <span className="text-primary font-weight-bold">
                                                                    11:35 AM
                                                                </span>
                                                            </div>
                                                            <div className="timeline-content">
                                                                Lorem ipsum
                                                                dolor sit
                                                                amit,consectetur
                                                                eiusmdd tempors
                                                                labore et
                                                                dolore. Lorem
                                                                ipsum dolor sit
                                                                amit.
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item timeline-item-right">
                                                            <div className="timeline-badge">
                                                                <div className="bg-success" />
                                                            </div>
                                                            <div className="timeline-label text-primary">
                                                                <span className="text-primary font-weight-bold">
                                                                    2:20 PM
                                                                </span>
                                                            </div>
                                                            <div className="timeline-content">
                                                                Lorem ipsum
                                                                dolor sit
                                                                amit,consectetur
                                                                eiusmdd tempors
                                                                labore et
                                                                dolore.
                                                            </div>
                                                        </div>
                                                        <div className="timeline-item timeline-item-left">
                                                            <div className="timeline-badge">
                                                                <div className="bg-danger" />
                                                            </div>
                                                            <div className="timeline-label">
                                                                <span className="text-primary font-weight-bold">
                                                                    11:35 AM
                                                                </span>
                                                            </div>
                                                            <div className="timeline-content">
                                                                Lorem ipsum
                                                                dolor sit
                                                                amit,consectetur
                                                                eiusmdd tempors
                                                                labore et
                                                                dolore. Lorem
                                                                ipsum dolor sit
                                                                amit,consectetur
                                                                eiusmdd tempors.
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </OverlayScrollbarsComponent>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="example example-basic col-12 p-0">
                                        <div
                                            className="example-preview"
                                            id="threport"
                                        >
                                            <div className="balance-sheet">
                                                <div className="header col-12 w-100 m-0">
                                                    <h2>Current Balance</h2>
                                                    <p className="total-balance">
                                                        €729.<sup>00</sup>
                                                    </p>
                                                </div>
                                                <OverlayScrollbarsComponent className="balance-info-height">
                                                    <div className="balance-movement col-12 w-100 m-0">
                                                        {statementlist.map(
                                                            (object, index) => {
                                                                console.log(
                                                                    index
                                                                );
                                                                console.log(
                                                                    object
                                                                );
                                                                return (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <div className="movement">
                                                                            <img src="https://svgshare.com/i/Kz0.svg" />
                                                                            <div className="movement-description">
                                                                                <h3>
                                                                                    {
                                                                                        object.detail
                                                                                    }
                                                                                </h3>
                                                                                <p>
                                                                                    Electronics
                                                                                    #32343424
                                                                                    -{" "}
                                                                                    <span>
                                                                                        {
                                                                                            object.date
                                                                                        }
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                            <p
                                                                                className={`amount ${
                                                                                    object.type ===
                                                                                    "withdraw"
                                                                                        ? "negative"
                                                                                        : "positive"
                                                                                }`}
                                                                            >
                                                                                €650.
                                                                                <sup>
                                                                                    00
                                                                                </sup>
                                                                                {object.amount +
                                                                                    " บาท"}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                        <div>
                                                            <div className="movement">
                                                                <img src="https://svgshare.com/i/Kz0.svg" />
                                                                <div className="movement-description">
                                                                    <h3>
                                                                        Apple
                                                                        iPhone
                                                                        X, 128Gb
                                                                    </h3>
                                                                    <p>
                                                                        Electronics
                                                                        #32343424
                                                                        -{" "}
                                                                        <span>
                                                                            12
                                                                            March
                                                                            2020
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <p className="amount negative">
                                                                    €650.
                                                                    <sup>
                                                                        00
                                                                    </sup>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="movement">
                                                                <img src="https://svgshare.com/i/L0A.svg" />
                                                                <div className="movement-description">
                                                                    <h3>
                                                                        Funds
                                                                        Added
                                                                    </h3>
                                                                    <p>
                                                                        Payment
                                                                        #5435 -{" "}
                                                                        <span>
                                                                            11
                                                                            March
                                                                            2020
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <p className="amount positive">
                                                                    €900.
                                                                    <sup>
                                                                        00
                                                                    </sup>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="movement">
                                                                <img src="https://svgshare.com/i/Kz0.svg" />
                                                                <div className="movement-description">
                                                                    <h3>
                                                                        Energy
                                                                        Bill
                                                                    </h3>
                                                                    <p>
                                                                        Expenses
                                                                        #745237423
                                                                        -{" "}
                                                                        <span>
                                                                            11
                                                                            March
                                                                            2020
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <p className="amount negative">
                                                                    €84.
                                                                    <sup>
                                                                        90
                                                                    </sup>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="movement">
                                                                <img src="https://svgshare.com/i/Kz0.svg" />
                                                                <div className="movement-description">
                                                                    <h3>
                                                                        The Eye
                                                                        Cinema
                                                                        Tickets
                                                                    </h3>
                                                                    <p>
                                                                        Entertainment
                                                                        #323345662
                                                                        -{" "}
                                                                        <span>
                                                                            10
                                                                            March
                                                                            2020
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <p className="amount negative">
                                                                    €40.
                                                                    <sup>
                                                                        00
                                                                    </sup>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="movement">
                                                                <img src="https://svgshare.com/i/Kz0.svg" />
                                                                <div className="movement-description">
                                                                    <h3>
                                                                        McBurger
                                                                        Restaurant
                                                                    </h3>
                                                                    <p>
                                                                        Food
                                                                        #210974
                                                                        -{" "}
                                                                        <span>
                                                                            10
                                                                            March
                                                                            2020
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <p className="amount negative">
                                                                    €10.
                                                                    <sup>
                                                                        50
                                                                    </sup>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="movement">
                                                                <img src="https://svgshare.com/i/Kz0.svg" />
                                                                <div className="movement-description">
                                                                    <h3>
                                                                        Gas
                                                                        Station
                                                                    </h3>
                                                                    <p>
                                                                        Expenses
                                                                        #3123950
                                                                        -{" "}
                                                                        <span>
                                                                            10
                                                                            March
                                                                            2020
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <p className="amount negative">
                                                                    €50.
                                                                    <sup>
                                                                        00
                                                                    </sup>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </OverlayScrollbarsComponent>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
