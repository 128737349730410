import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import address from '../environments/address';
import config from '../environments/config';
import { UCPackages } from './step/Packages';
import { UCConfirm } from './step/Confirm';
import { UCSummary } from './step/Summary';
import { SubscriberList, UpgradeList } from '../../Setting';

export function MainUpgradeContactPage() {
    const history = useHistory();
    const [ loading, setLoading ] = useState(true);
    const [ step, setStep ] = useState(1);
    const [ citizenid, setCitizenid ] = useState('');
    const [ packages, setPackages ] = useState({});
    // step 1
    const [ type, setType ] = useState('PT2');
    const [ price, setPrice ] = useState('');
    // step 2
    const [ phonenumber, setPhonenumber ] = useState('');
    // step 3
    const [ shippingtype, setShippingType ] = useState('ST1');
    const [ ogname, setOgName ] = useState('');
    const [ name, setName ] = useState('');
    const [ ogcontactnumber, setOgContactnumber ] = useState('');
    const [ contactnumber, setContactnumber ] = useState('');
    const [ oggeo, setOggeo ] = useState('');
    const [ oggeoid, setOggeoid ] = useState('');
    const [ geo, setGeo ] = useState('');
    const [ maingeo, setMainGeo ] = useState([]);
    const [ ogprovince, setOgprovince ] = useState('');
    const [ ogprovinceid, setOgprovinceid ] = useState('');
    const [ province, setProvince ] = useState('');
    const [ mainprovince, setMainProvince ] = useState([]);
    const [ ogdistrict, setOgdistrict ] = useState('');
    const [ ogdistrictid, setOgdistrictid ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ maindistrict, setMainDistrict ] = useState([]);
    const [ ogsubdistrict, setOgsubdistrict ] = useState('');
    const [ ogsubdistrictid, setOgsubdistrictid ] = useState('');
    const [ subdistrict, setSubDistrict ] = useState('');
    const [ mainsubdistrict, setMainSubDistrict ] = useState([]);
    const [ ogzipcode, setOgzipcode ] = useState('');
    const [ zipcode, setZipcode ] = useState('');
    const [ ogaddr, setOgaddr ] = useState('');
    const [ addr, setAddr ] = useState('');
    // step 4
    const [ paymenttype, setPaymentType ] = useState('2');
    const [ shipname, setShipname ] = useState('');
    const [ shipcontact, setShipcontact ] = useState('');
    const [ shipgeo, setShipgeo ] = useState('');
    const [ shipprovince, setShipprovince ] = useState('');
    const [ shipdistrict, setShipdistrict ] = useState('');
    const [ shipsubdistrict, setShipsubdistrict ] = useState('');
    const [ shipzipcode, setShipzipcode ] = useState('');
    const [ shipaddress, setShipaddress ] = useState('');
    const [ response, setResponse ] = useState({});

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        // if (SubscriberList.includes(user.main_position)) {
        //     history.push('/');
        // } else {
        setMainGeo(address.geo);
        getProfile(user.id);
        // }
    }, []);

    const getProfile = (memberid) => {
        fetch(config.py_url + config.version + '/profile/info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    // setPhonenumber(result.mainnumber.number);
                    setOgName(result.profile.name);
                    setOgContactnumber(result.profile.contact_mobile);
                    setShipname(result.profile.name);
                    setShipcontact(result.profile.contact_mobile);
                    for (const prop in address.geo) {
                        if (address.geo[prop]['value'] === result.profile.geo_id.toString()) {
                            setOggeo(address.geo[prop]['label']);
                            setOggeoid(result.profile.geo_id.toString());
                            setShipgeo(address.geo[prop]['label']);
                        }
                    }
                    for (const prop in address.province) {
                        if (address.province[prop]['value'] === result.profile.province_id.toString()) {
                            setOgprovince(address.province[prop]['label']);
                            setOgprovinceid(result.profile.province_id.toString());
                            setShipprovince(address.province[prop]['label']);
                        }
                    }
                    for (const prop in address.district) {
                        if (address.district[prop]['value'] === result.profile.amphur_id.toString()) {
                            setOgdistrict(address.district[prop]['label']);
                            setOgdistrictid(result.profile.amphur_id.toString());
                            setShipdistrict(address.district[prop]['label']);
                        }
                    }
                    for (const prop in address.subdistrict) {
                        if (address.subdistrict[prop]['value'] === result.profile.district_id.toString()) {
                            setOgsubdistrict(address.subdistrict[prop]['label']);
                            setOgsubdistrictid(result.profile.district_id.toString());
                            setShipsubdistrict(address.subdistrict[prop]['label']);
                        }
                    }
                    setOgzipcode(result.profile.zip);
                    setOgaddr(result.profile.address);
                    setCitizenid(result.profile.id_card);
                    setShipzipcode(result.profile.zip);
                    setShipaddress(result.profile.address);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const nextStep = () => {
        if (step === 2) {
            setLoading(true);
            let postparam = {
                number: phonenumber
            };
            postparam = {
                phonenumber: phonenumber
            };
            checkFeels(postparam);
        } else {
            setStep(step + 1);
        }
    };

    const checkFeels = (postparam) => {
        fetch(config.py_url + config.version + '/product/get-sim-profile', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    if (result.simprofile['sim state'] === 'ACTIVE') {
                        let postparam = {
                            userid: user.id,
                            phonenumber: phonenumber,
                            satype: 'A',
                            payment: paymenttype,
                            name_t: shipname,
                            zip: shipzipcode,
                            ship_name: shipname,
                            ship_mobile: shipcontact,
                            ship_address: shipaddress,
                            ship_zip: shipzipcode,
                            ship_geo_id: oggeoid,
                            ship_province_id: ogprovinceid,
                            ship_amphur_id: ogdistrictid,
                            ship_district_id: ogsubdistrictid,
                            order_items: [
                                {
                                    productcode: price,
                                    quantity: '1'
                                }
                            ]
                        };
                        createOrder(postparam);
                    } else {
                        setLoading(false);
                        Swal.fire({
                            title: 'ผิดพลาด!',
                            html:
                                '<div class="alert-text">สถานะซิมไม่พร้อมทำรายการ กรุณาเติมเงินเพื่อดำเนินการต่อ</div>',
                            icon: 'warning',
                            confirmButtonColor: '#ffa800',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        }).then((result) => {});
                    }
                } else {
                    setLoading(false);
                    Swal.fire({
                        title: 'ผิดพลาด!',
                        html: '<div class="alert-text">ไม่สามารถตรวจสอบสถานะซิมได้ กรุณาติดต่อเจ้าหน้าที่</div>',
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {});
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const createOrder = (postparam) => {
        fetch(config.py_url + config.version + '/order/create', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0 || result.responsecode === '102') {
                    setResponse(result);
                    setStep(step + 1);
                } else if (result.responsecode === '100') {
                    Swal.fire({
                        title: 'ผิดพลาด!',
                        html: '<div class="alert-text">ไม่สามารถสร้างรายการได้ชั่วคราว กรุณาลองใหม่อีกครั้ง</div>',
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {});
                } else if (result.responsecode === '101') {
                    Swal.fire({
                        html: '<div class="alert-text">ไม่สามารถสร้างรายการได้เนื่องจากยอดเงินไม่เพียงพอ</div>',
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {});
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const backStep = () => {
        setStep(step - 1);
    };

    const handleLoading = (loading) => {
        setLoading(loading);
    };

    const handleType = (type) => {
        setType(type);
    };

    const handlePrice = (price) => {
        setPrice(price);
    };

    const handlePayment = (payment) => {
        setPaymentType(payment);
    };

    const handlePackages = (pack) => {
        setPackages(pack);
    };

    return (
        <div>
            {!loading ? (
                <div className='card card-custom card-shadowless bg-white rounded'>
                    <div className='wizard wizard-1' id='kt_wizard'>
                        <div className='wizard-nav border-bottom'>
                            <div className='wizard-steps p-8 p-lg-10'>
                                <div
                                    className={`wizard-step d-md-flex ${step !== 1 && 'd-none'}`}
                                    data-wizard-type='step'
                                    data-wizard-state={`${step >= 1 ? 'current' : 'pending'}`}
                                >
                                    <div className='wizard-label'>
                                        <i className='wizard-icon flaticon-interface-7' />
                                        <h3 className='wizard-title'>เลือกแพ็กเกจดีลเลอร์</h3>
                                    </div>
                                    <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                        <SVG
                                            title='ขั้นตอนที่1'
                                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`wizard-step d-md-flex ${step !== 2 && 'd-none'}`}
                                    data-wizard-type='step'
                                    data-wizard-state={`${step >= 2 ? 'current' : 'pending'}`}
                                >
                                    <div className='wizard-label'>
                                        <i className='wizard-icon flaticon-questions-circular-button' />
                                        <h3 className='wizard-title'>ยืนยันการทำรายการ</h3>
                                    </div>
                                    <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                        <SVG
                                            title='ขั้นตอนที่2'
                                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`wizard-step d-md-flex ${step !== 3 && 'd-none'}`}
                                    data-wizard-type='step'
                                    data-wizard-state={`${step >= 3 ? 'current' : 'pending'}`}
                                >
                                    <div className='wizard-label'>
                                        <i className='wizard-icon flaticon-statistics' />
                                        <h3 className='wizard-title'>สรุปรายการ</h3>
                                    </div>
                                    <span className='svg-icon svg-icon-xl wizard-arrow last'>
                                        <SVG
                                            title='ขั้นตอนที่3'
                                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-body p-0'>
                        <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                            <div className={`col-xl-12 ${step === 2 || step === 3 ? 'col-xxl-12' : 'col-xxl-10'}`}>
                                <form
                                    className='form mt-0 mt-lg-10 fv-plugins-bootstrap fv-plugins-framework'
                                    id='kt_form'
                                >
                                    {step === 1 ? (
                                        <UCPackages
                                            userid={user.id}
                                            phonenumber={phonenumber}
                                            setPhonenumber={setPhonenumber}
                                            loading={loading}
                                            handleLoading={handleLoading}
                                            type={type}
                                            handleType={handleType}
                                            price={price}
                                            handlePrice={handlePrice}
                                            handlePackages={handlePackages}
                                        />
                                    ) : step === 2 ? (
                                        <UCConfirm
                                            backStep={backStep}
                                            nextStep={nextStep}
                                            type={type}
                                            price={price}
                                            phonenumber={phonenumber}
                                            shippingtype={shippingtype}
                                            shipname={shipname}
                                            shipcontact={shipcontact}
                                            shipgeo={shipgeo}
                                            shipprovince={shipprovince}
                                            shipdistrict={shipdistrict}
                                            shipsubdistrict={shipsubdistrict}
                                            shipzipcode={shipzipcode}
                                            shipaddress={shipaddress}
                                            paymenttype={paymenttype}
                                            handlePayment={handlePayment}
                                            packages={packages}
                                        />
                                    ) : step === 3 ? (
                                        <UCSummary paymenttype={paymenttype} response={response} />
                                    ) : (
                                        <div>test</div>
                                    )}
                                    <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                        <div className='mr-2'>
                                            {step > 1 &&
                                            step !== 3 && (
                                                <button
                                                    type='button'
                                                    onClick={backStep}
                                                    className='btn btn-light-primary btn-cus-rounded font-weight-bold text-uppercase px-9 py-4'
                                                >
                                                    ก่อนหน้า
                                                </button>
                                            )}
                                        </div>
                                        <div>
                                            {step <= 1 ? (
                                                <button
                                                    type='button'
                                                    onClick={nextStep}
                                                    className='btn btn-success btn-cus-rounded font-weight-bold text-uppercase px-9 py-4'
                                                    disabled={
                                                        step === 1 ? (
                                                            price === '' || phonenumber === ''
                                                        ) : (
                                                            step === 2 &&
                                                            phonenumber.length !== 10 &&
                                                            phonenumber.charAt(0) !== '0'
                                                        )
                                                    }
                                                >
                                                    ถัดไป
                                                </button>
                                            ) : (
                                                step === 3 && (
                                                    <button
                                                        type='button'
                                                        onClick={() => {
                                                            history.push('/dashboard');
                                                        }}
                                                        className='btn btn-success btn-cus-rounded font-weight-bold text-uppercase px-9 py-4'
                                                    >
                                                        หน้าหลัก
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
