import React from 'react';

import { Tab, Tabs, Nav } from "react-bootstrap";

import NumberFormat from "react-number-format";

import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const allowstatuslist = ["VALID", "ACTIVE", "INACTIVE1", "INACTIVE2"];
const expiredstatuslist = ["DEACTIVE", "DISCONNECT"];

const monthNamesThai = ["มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน",
"กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤษจิกายน","ธันวาคม"];

const dayNames = ["วันอาทิตย์ที่","วันจันทร์ที่","วันอังคารที่","วันพุทธที่","วันพฤหัสบดีที่","วันศุกร์ที่","วันเสาร์ที่"];

export function DealerDetail(prop) {
    const thaiDateFormat = (date) => {
        let d = new Date(date);
        return dayNames[d.getDay()] + "  " + d.getDate() + " " + monthNamesThai[d.getMonth()] + "  " + (d.getFullYear() + 543) + " เวลา " + date.split(' ')[1]
    }

    return (
        <>
            {allowstatuslist.includes(prop.data.statusdb) ? (
                <div className='row'>
                    <div className='col-12 col-md-6 mb-md-0 mb-3'>
                        <div className='row mb-3'>
                            <div className='col-12'>
                                <h6 className='d-block'>
                                    ชื่อผู้ใช้งาน : {prop.data.name}
                                </h6>
                                <h6 className='d-block'>
                                    สถานะหมายเลข : {prop.data.statustot}
                                </h6>
                                <h6 className='d-block'>
                                    ยอดเงินคงเหลือ :{' '}
                                    <NumberFormat
                                        value={parseFloat(
                                            prop.data.balance.split(',').join('')
                                        )}
                                        displayType={
                                            "text"
                                        }
                                        decimalScale={
                                            2
                                        }
                                        fixedDecimalScale={
                                            true
                                        }
                                        thousandSeparator={
                                            true
                                        }
                                        // suffix={
                                        //     " บาท"
                                        // }
                                    />
                                </h6>
                                <h6 className='d-block'>
                                    ยอดเงินโบนัส :{' '}
                                    <NumberFormat
                                        value={parseFloat(
                                            prop.data.bonus.split(',').join('')
                                        )}
                                        displayType={
                                            "text"
                                        }
                                        decimalScale={
                                            2
                                        }
                                        fixedDecimalScale={
                                            true
                                        }
                                        thousandSeparator={
                                            true
                                        }
                                        // suffix={
                                        //     " บาท"
                                        // }
                                    />
                                </h6>
                                <div className="col-12 mt-5">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="current">
                                        <div className="row">
                                            <div className="col-12 p-0">
                                                <Nav fill variant="tabs">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="current">
                                                            แพ็กเกจปัจจุบัน
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="lasted">
                                                            แพ็กเกจล่าสุด
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                            <div className="col-12 p-0">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="current" className="min-h-200px">
                                                        {prop.data.thispackage === "-" ? (
                                                            <div className="row justify-content-center align-items-center sim-stock-bg vh-100 max-h-200px m-0">
                                                                <h3 className='m-0'>ไม่พบแพ็กเกจปัจจุบัน</h3>
                                                            </div>
                                                        ) : (
                                                            // <OverlayScrollbarsComponent className='sim-stock-height'>
                                                                // <div className='pt-3 pb-3 pl-5 pr-5 col-12 col-md-12 sim-stock-bg min-h-200px'>
                                                                <div className='pt-3 pb-3 pl-5 pr-5 col-12 col-md-12 sim-stock-bg'>
                                                                    <div className="row p-5">
                                                                        <div className="col-12">
                                                                            <h6 className="d-block"> ชื่อแพ็กเกจ :{' '}
                                                                                <span>
                                                                                    {prop.data.thispackage.packagename === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.thispackage.packagename !== "-" ? (
                                                                                        prop.data.thispackage.packagename
                                                                                    ) : (
                                                                                        "ไม่มีชื่อแพ็กเกจ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> อายุแพ็กเกจ :{' '}
                                                                                <span>
                                                                                    {prop.data.thispackage.packagedate === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.thispackage.packagedate !== "-" ? (
                                                                                        prop.data.thispackage.packagedate + " วัน"
                                                                                    ) : (
                                                                                        "ไม่มีอายุแพ็กเกจ"
                                                                                    )}
                                                                                </span> 
                                                                            </h6>
                                                                            <h6 className="d-block"> ราคาแพ็กเกจ :{' '}
                                                                                <span>
                                                                                    {prop.data.thispackage.price === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.thispackage.price !== "-" ? (
                                                                                        <NumberFormat
                                                                                            value={parseFloat(
                                                                                                prop.data.thispackage.price.split(',').join('')
                                                                                            )}
                                                                                            displayType={
                                                                                                "text"
                                                                                            }
                                                                                            decimalScale={
                                                                                                2
                                                                                            }
                                                                                            fixedDecimalScale={
                                                                                                true
                                                                                            }
                                                                                            thousandSeparator={
                                                                                                true
                                                                                            }
                                                                                            suffix={
                                                                                                " บาท"
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        "ไม่มีราคาแพ็กเกจ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> อินเตอร์เน็ต :{' '}
                                                                                {prop.data.thispackage.pcsid !== "0" && prop.data.thispackage.pcsid !== null ? (
                                                                                    <span>
                                                                                        {prop.data.thispackage.datavolumn === null ? (
                                                                                            "ไม่มีข้อมูล"
                                                                                        ) : prop.data.thispackage.datavolumn === "Unlimited" ? (
                                                                                        "ใช้งานได้ไม่จำกัด"
                                                                                        ) : prop.data.thispackage.datavolumn}{' '}
                                                                                        {prop.data.thispackage.dataspeed === null ? (
                                                                                            "ไม่มีข้อมูล"
                                                                                        ) : prop.data.thispackage.dataspeed === "FullSpeed" ? (
                                                                                        "ความเร็วสูงสุด"
                                                                                        ) : prop.data.thispackage.dataspeed}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span>ไม่มีอินเตอร์เน็ต</span>
                                                                                )}
                                                                            </h6>
                                                                            <h6 className="d-block"> ลดความเร็ว :{' '}
                                                                                <span>
                                                                                    {prop.data.thispackage.dropspeed === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.thispackage.dropspeed !== "-" ? (
                                                                                        "ลดความเร็วเมื่อใช้งานครบจำนวน เหลือความเร็ว" + prop.data.thispackage.dropspeed
                                                                                    ) : (
                                                                                        "ไม่มีลดความเร็ว"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> ข้อความ :{' '}
                                                                                <span>
                                                                                    {prop.data.thispackage.sms === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.thispackage.sms !== "-" ? (
                                                                                        prop.data.thispackage.sms + " ข้อความ"
                                                                                    ) : (
                                                                                        "ไม่มีข้อความ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> โทร :{' '}
                                                                                <ul className="m-0">
                                                                                    <li> ในเครือข่าย :{' '}
                                                                                        <span>
                                                                                            {prop.data.thispackage.onnetvoice === null ? (
                                                                                                "ไม่มีข้อมูล"
                                                                                            ) : prop.data.thispackage.onnetvoice === "infinity" ? (
                                                                                                "โทรฟรีในเครือข่าย"
                                                                                            ) : prop.data.thispackage.onnetvoice === "-" || prop.data.thispackage.onnetvoice === undefined ? (
                                                                                                "ไม่มีโทรฟรีในเครือข่าย"
                                                                                            ) : (
                                                                                                prop.data.thispackage.onnetvoice + " นาที"
                                                                                            )}
                                                                                        </span>
                                                                                    </li>
                                                                                    <li> นอกเครือข่าย :{' '}
                                                                                        <span>
                                                                                            {prop.data.thispackage.offnetvoice === null ? (
                                                                                                "ไม่มีข้อมูล"
                                                                                            ) : prop.data.thispackage.offnetvoice === "infinity" ? (
                                                                                                "โทรฟรีนอกเครือข่าย"
                                                                                            ) : prop.data.thispackage.offnetvoice !== "-" || prop.data.thispackage.offnetvoice === undefined ? (
                                                                                                "ไม่มีโทรฟรีนอกเครือข่าย"
                                                                                            ) : (
                                                                                                prop.data.thispackage.offnetvoice + " นาที"
                                                                                            )}
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                            </h6>
                                                                            <h6 className="d-block"> ต่อแพ็กเกจอัตโนมัติ :{' '}
                                                                                <span>
                                                                                    {prop.data.thispackage.recurring === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.thispackage.recurring === "infinity" ? (
                                                                                        "ไม่จำกัดครั้ง"
                                                                                    ) : prop.data.thispackage.recurring !== "-" ? (
                                                                                        prop.data.thispackage.recurring.split(' ')[0] + " ครั้ง"
                                                                                    ) : (
                                                                                        "ไม่มีต่อแพ็กเกจอัตโนมัติ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> วันสมัครแพ็กเกจ : {' '}
                                                                                <span>
                                                                                    {prop.data.thispackage.startdate === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.thispackage.startdate !== "-" ? (
                                                                                        thaiDateFormat(prop.data.thispackage.startdate)
                                                                                    ) : (
                                                                                        "ไม่มีวันสมัครแพ็กเกจ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> วันหมดแพ็กเกจ : {' '}
                                                                                <span>
                                                                                    {prop.data.thispackage.enddate === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.thispackage.enddate !== "-" ? (
                                                                                        thaiDateFormat(prop.data.thispackage.enddate)
                                                                                    ) : (
                                                                                        "ไม่มีวันหมดแพ็กเกจ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            // </OverlayScrollbarsComponent>
                                                        )}
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="lasted" className="min-h-200px">
                                                        {prop.data.lastedpackage === "-" ? (
                                                            <div className="row justify-content-center align-items-center sim-stock-bg vh-100 max-h-200px m-0">
                                                                <h3 className='m-0'>ไม่พบแพ็กเกจล่าสุด</h3>
                                                            </div>
                                                        ) : (
                                                            // <OverlayScrollbarsComponent className='sim-stock-height'>
                                                                // <div className='pt-3 pb-3 pl-5 pr-5 col-12 col-md-12 sim-stock-bg min-h-200px'>
                                                                <div className='pt-3 pb-3 pl-5 pr-5 col-12 col-md-12 sim-stock-bg'>
                                                                    <div className="row p-5">
                                                                        <div className="col-12">
                                                                            <h6 className="d-block"> ชื่อแพ็กเกจ :{' '}
                                                                                <span>
                                                                                    {prop.data.lastedpackage.packagename === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.lastedpackage.packagename !== "-" ? (
                                                                                        prop.data.lastedpackage.packagename
                                                                                    ) : (
                                                                                        "ไม่มีชื่อแพ็กเกจ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> อายุแพ็กเกจ :{' '}
                                                                                <span>
                                                                                    {prop.data.lastedpackage.packagedate === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.lastedpackage.packagedate !== "-" ? (
                                                                                        prop.data.lastedpackage.packagedate + " วัน"
                                                                                    ) : (
                                                                                        "ไม่มีอายุแพ็กเกจ"
                                                                                    )}
                                                                                </span> 
                                                                            </h6>
                                                                            <h6 className="d-block"> ราคาแพ็กเกจ :{' '}
                                                                                <span>
                                                                                    {prop.data.lastedpackage.price === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.lastedpackage.price !== "-" ? (
                                                                                        <NumberFormat
                                                                                            value={parseFloat(
                                                                                                prop.data.lastedpackage.price.split(',').join('')
                                                                                            )}
                                                                                            displayType={
                                                                                                "text"
                                                                                            }
                                                                                            decimalScale={
                                                                                                2
                                                                                            }
                                                                                            fixedDecimalScale={
                                                                                                true
                                                                                            }
                                                                                            thousandSeparator={
                                                                                                true
                                                                                            }
                                                                                            suffix={
                                                                                                " บาท"
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        "ไม่มีราคาแพ็กเกจ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> อินเตอร์เน็ต :{' '}
                                                                                {prop.data.lastedpackage.pcsid !== "0" && prop.data.lastedpackage.pcsid !== null ? (
                                                                                    <span>
                                                                                        {prop.data.lastedpackage.datavolumn === null ? (
                                                                                            "ไม่มีข้อมูล"
                                                                                        ) : prop.data.lastedpackage.datavolumn === "Unlimited" ? (
                                                                                        "ใช้งานได้ไม่จำกัด"
                                                                                        ) : prop.data.lastedpackage.datavolumn}{' '}
                                                                                        {prop.data.lastedpackage.dataspeed === null ? (
                                                                                            "ไม่มีข้อมูล"
                                                                                        ) : prop.data.lastedpackage.dataspeed === "FullSpeed" ? (
                                                                                        "ความเร็วสูงสุด"
                                                                                        ) : prop.data.lastedpackage.dataspeed}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span>ไม่มีอินเตอร์เน็ต</span>
                                                                                )}
                                                                            </h6>
                                                                            <h6 className="d-block"> ลดความเร็ว :{' '}
                                                                                <span>
                                                                                    {prop.data.lastedpackage.dropspeed === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.lastedpackage.dropspeed !== "-" ? (
                                                                                        "ลดความเร็วเมื่อใช้งานครบจำนวน เหลือความเร็ว" + prop.data.lastedpackage.dropspeed
                                                                                    ) : (
                                                                                        "ไม่มีลดความเร็ว"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> ข้อความ :{' '}
                                                                                <span>
                                                                                    {prop.data.lastedpackage.sms === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.lastedpackage.sms !== "-" ? (
                                                                                        prop.data.lastedpackage.sms + " ข้อความ"
                                                                                    ) : (
                                                                                        "ไม่มีข้อความ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> โทร :{' '}
                                                                                <ul className="m-0">
                                                                                <li> ในเครือข่าย :{' '}
                                                                                        <span>
                                                                                            {prop.data.lastedpackage.onnetvoice === null ? (
                                                                                                "ไม่มีข้อมูล"
                                                                                            ) : prop.data.lastedpackage.onnetvoice === "infinity" ? (
                                                                                                "โทรฟรีในเครือข่าย"
                                                                                            ) : prop.data.lastedpackage.onnetvoice === "-" || prop.data.lastedpackage.onnetvoice === undefined ? (
                                                                                                "ไม่มีโทรฟรีในเครือข่าย"
                                                                                            ) : (
                                                                                                prop.data.lastedpackage.onnetvoice + " นาที"
                                                                                            )}
                                                                                        </span>
                                                                                    </li>
                                                                                    <li> นอกเครือข่าย :{' '}
                                                                                        <span>
                                                                                            {prop.data.lastedpackage.offnetvoice === null ? (
                                                                                                "ไม่มีข้อมูล"
                                                                                            ) : prop.data.lastedpackage.offnetvoice === "infinity" ? (
                                                                                                "โทรฟรีนอกเครือข่าย"
                                                                                            ) : prop.data.lastedpackage.offnetvoice !== "-" || prop.data.lastedpackage.offnetvoice === undefined ? (
                                                                                                "ไม่มีโทรฟรีนอกเครือข่าย"
                                                                                            ) : (
                                                                                                prop.data.lastedpackage.offnetvoice + " นาที"
                                                                                            )}
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                            </h6>
                                                                            <h6 className="d-block"> ต่อแพ็กเกจอัตโนมัติ :{' '}
                                                                                <span>
                                                                                    {prop.data.lastedpackage.recurring === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.lastedpackage.recurring === "infinity" ? (
                                                                                        "ไม่จำกัดครั้ง"
                                                                                    ) : prop.data.lastedpackage.recurring !== "-" ? (
                                                                                        prop.data.lastedpackage.recurring.split(' ')[0] + " ครั้ง"
                                                                                    ) : (
                                                                                        "ไม่มีต่อแพ็กเกจอัตโนมัติ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> วันสมัครแพ็กเกจ : {' '}
                                                                                <span>
                                                                                    {prop.data.lastedpackage.startdate === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.lastedpackage.startdate !== "-" ? (
                                                                                        thaiDateFormat(prop.data.lastedpackage.startdate)
                                                                                    ) : (
                                                                                        "ไม่มีวันสมัครแพ็กเกจ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                            <h6 className="d-block"> วันหมดแพ็กเกจ : {' '}
                                                                                <span>
                                                                                    {prop.data.lastedpackage.enddate === null ? (
                                                                                        "ไม่มีข้อมูล"
                                                                                    ) : prop.data.lastedpackage.enddate !== "-" ? (
                                                                                        thaiDateFormat(prop.data.lastedpackage.enddate)
                                                                                    ) : (
                                                                                        "ไม่มีวันหมดแพ็กเกจ"
                                                                                    )}
                                                                                </span>
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            // </OverlayScrollbarsComponent>
                                                        )}
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </div>
                                        </div>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <h6 className='d-block'>โปรไฟล์บนระบบ : </h6>
                        {/* <OverlayScrollbarsComponent className='sim-stock-height'> */}
                            {/* <div className='p-5 col-12 col-md-12 sim-stock-bg min-h-200px'> */}
                            <div className='p-5 col-12 col-md-12 sim-stock-bg'>
                                {prop.data.bundles.length === 0 ? (
                                    <div className="row justify-content-center align-items-center">
                                        <h3 className='m-0'>ไม่พบโปรไฟล์บนระบบ</h3>
                                    </div>
                                ) : (
                                    <div className="row">
                                        {prop.data.bundles.map((item, index) => {
                                            return (
                                                <div className="col-12" key={index}>
                                                    <div className={`card card-stretch gutter-b ${index !== prop.data.bundles.length - 1 ? "card-custom" : "m-0"}`}>
                                                        <div className="card-body p-0 position-relative">
                                                            <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                                                                <span className="symbol symbol-50 symbol-light-info mr-2">
                                                                    <span className={`symbol-label ${item.buckets === "PCRF" || item.buckets[0].buckettype === "DATA" ? (
                                                                            "bg-cus-teal"
                                                                        ) : item.buckets[0].buckettype === "CREDIT" ? (
                                                                            "bg-cus-orange"
                                                                        ) : item.buckets[0].buckettype === "VOICE" ? (
                                                                            "bg-cus-red"
                                                                        ) : item.buckets[0].buckettype === "SMS" ? (
                                                                            "bg-cus-indigo"
                                                                        ) : ""}`}>
                                                                        <i className={`fas ${item.buckets === "PCRF" || item.buckets[0].buckettype === "DATA" ? (
                                                                            "fa-signal"
                                                                        ) : item.buckets[0].buckettype === "CREDIT" ? (
                                                                            "fa-coins"
                                                                        ) : item.buckets[0].buckettype === "VOICE" ? (
                                                                            "fa-phone-alt"
                                                                        ) : item.buckets[0].buckettype === "SMS" ? (
                                                                            "fa-sms"
                                                                        ) : ""} text-white icon-2x`}></i>
                                                                    </span>
                                                                </span>
                                                                <div className="d-flex flex-column text-right">
                                                                    <span className="text-dark-75 font-weight-bolder font-size-h3">
                                                                        ประเภทโปรไฟล์ในระบบ : {' '}
                                                                        {item.buckets === "PCRF" || item.buckets[0].buckettype === "DATA" ? (
                                                                            "อินเตอร์เน็ต"
                                                                        ) : item.buckets[0].buckettype === "CREDIT" ? (
                                                                            "โบนัส"
                                                                        ) : item.buckets[0].buckettype === "VOICE" ? (
                                                                            "โทร"
                                                                        ) : item.buckets[0].buckettype === "SMS" ? (
                                                                            "ข้อความ"
                                                                        ) : ""}
                                                                    </span>
                                                                    <span className="text-muted font-weight-bold mt-2">
                                                                        วันเริ่มต้นโปรไฟล์ในระบบ : {item.startdate}
                                                                    </span>
                                                                    <span className="text-muted font-weight-bold mt-2">
                                                                        วันสิ้นสุดโปรไฟล์ในระบบ : {item.enddate}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className={`card mt-3 ${index === prop.data.bundles.lenght ? "mb-3" : ""}`}>
                                                        <div className="card-body p-3 clearfix">
                                                            <i className={`fas ${item.buckets === "PCRF" || item.buckets[0].buckettype === "DATA" ? (
                                                                "fa-signal bg-cus-teal"
                                                            ) : item.buckets[0].buckettype === "CREDIT" ? (
                                                                "fa-coins bg-cus-orange"
                                                            ) : item.buckets[0].buckettype === "VOICE" ? (
                                                                "fa-phone-alt bg-cus-red"
                                                            ) : item.buckets[0].buckettype === "SMS" ? (
                                                                "fa-sms bg-cus-indigo"
                                                            ) : ""} text-white p-4 icon-2x mr-3 float-left my-auto`}></i>
                                                            <div className="row">
                                                                <div className="col-12 col-md-5 my-auto">
                                                                    <h6 class="d-block">
                                                                        Bundle Name : {item.bundlename}
                                                                    </h6>
                                                                    <h6 className="d-block">
                                                                        ประเภทโปรไฟล์ในระบบ :
                                                                        <span>
                                                                            {item.buckets === "PCRF" || item.buckets[0].buckettype === "DATA" ? (
                                                                                "อินเตอร์เน็ต"
                                                                            ) : item.buckets[0].buckettype === "CREDIT" ? (
                                                                                "โบนัส"
                                                                            ) : item.buckets[0].buckettype === "VOICE" ? (
                                                                                "โทร"
                                                                            ) : item.buckets[0].buckettype === "SMS" ? (
                                                                                "ข้อความ"
                                                                            ) : ""}
                                                                        </span>
                                                                    </h6>
                                                                </div>
                                                                <div className="col-12 col-md-7">
                                                                    <h6 className="d-block">
                                                                        วันเริ่มต้นโปรไฟล์ในระบบ : {item.startdate}
                                                                    </h6>
                                                                    <h6 className="d-block">
                                                                        วันสิ้นสุดโปรไฟล์ในระบบ : {item.enddate}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                )}
                            </div>
                        {/* </OverlayScrollbarsComponent> */}
                    </div>
                </div>
            ) : prop.data.statusdb === null ? (
                <div className="col-xl-12 text-center m-0 my-25 p-0">
                    <i className="fas fa-exclamation-circle fa-5x mb-2 text-dark-70"></i>
                    <h4 className="mt-3">
                        ไม่สามารถดึงข้อมูลได้ เนื่องจากเบอร์ยังไม่ได้ลงทะเบียน
                    </h4>
                </div>
            ) : prop.data.statusdb === "PENDING" ? (
                <div className="col-xl-12 text-center m-0 my-25 p-0">
                    <i className="fas fa-exclamation-circle fa-5x mb-2 text-dark-70"></i>
                    <h4 className="mt-3">
                        ไม่สามารถดึงข้อมูลได้ เนื่องจากเบอร์อยู่ระหว่างตรวจสอบตัวตน
                    </h4>
                </div>
            ) : prop.data.statusdb === "EXPIRED" ? (
                <div className="col-xl-12 text-center m-0 my-25 p-0">
                    <i className="fas fa-exclamation-circle fa-5x mb-2 text-dark-70"></i>
                    <h4 className="mt-3">
                        ไม่สามารถดึงข้อมูลได้ เนื่องจากเบอร์ยังไม่ได้ลงทะเบียนภายในกำหนดที่ให้เปิดใช้งาน
                    </h4>
                </div>
            ) : expiredstatuslist.includes(prop.data.statusdb) ? (
                <div className="col-xl-12 text-center m-0 my-25 p-0">
                    <i className="fas fa-exclamation-circle fa-5x mb-2 text-dark-70"></i>
                    <h4 className="mt-3">
                        ไม่สามารถดึงข้อมูลได้ เนื่องจากเบอร์ไม่มีการใช้งานนานเกินกำหนด
                    </h4>
                </div>
            ) : (
                <div className="col-xl-12 text-center m-0 my-25 p-0">
                    <i className="fas fa-exclamation-circle fa-5x mb-2 text-dark-70"></i>
                    <h4 className="mt-3">
                        ไม่สามารถดึงข้อมูลได้ เนื่องจากเบอร์ไม่ปกติกรุณาติดต่อเจ้าหน้าที่เพื่อตรวจสอบ
                    </h4>
                </div>
            )}
        </>
    );
}
