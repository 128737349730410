import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router';

import { useSelector } from 'react-redux';

import config from './environments/config';

const reg_url = 'https://reg.simk4.com/';
const training_url = 'https://training.simk4.com/vsp?id=';

export function MobileRegisterMenu() {
    const location = useLocation();
    const { user } = useSelector((state) => state.auth);
    const [ numberlist, setNumberList ] = useState([]);
    const [ dealer, setDealer ] = useState(false);
    const [ subdealer, setSubDealer ] = useState(false);
    const [ scriber, setScriber ] = useState(false);
    const [ subscriber, setSubscriber ] = useState(false);

    useEffect(
        () => {
            getNumberList(user.id);
        },
        [ location ]
    );

    const handleClick = (menu) => {
        if (menu === 'dealer') {
            setDealer(!dealer);
            setScriber(false);
            setSubscriber(false);
        } else {
            setScriber(!scriber);
            setDealer(false);
            setSubDealer(false);
        }
    };

    const getNumberList = (memberid) => {
        fetch(config.py_url + config.version + '/profile/phonenumber_list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    setNumberList(result.numbers);
                }
            })
            .catch((error) => {});
    };

    return (
        <div className='row p-0 m-0 mb-15'>
            <div className='col-12 p-0 cursor-pointer' onClick={() => handleClick('dealer')}>
                <div className={`card${dealer ? ' bg-primary' : ''}`}>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <i
                                    className={`fas fa-chevron-${dealer ? 'up text-white' : 'down text-primary'} fa-2x`}
                                />
                            </span>
                            <h5 className={`my-auto font-weight-bolder ${dealer ? 'text-white' : 'text-dark'}`}>
                                สมัครดีลเลอร์ใหม่
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            {dealer && (
                <div className='col-12 row p-0 m-0'>
                    <a
                        className='col-12 p-0'
                        href={`${reg_url}agreement?mobile=${user.main_phonenumber}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <div className='card'>
                            <div className='card-body'>
                                <div className='d-flex'>
                                    <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                        <i className='fas fa-dot-circle text-primary' />
                                    </span>
                                    <h5 className='my-auto font-weight-bolder text-dark'>สมัครต่อเบอร์หลัก</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                    {numberlist.length > 1 && (
                        <div className='col-12 p-0 cursor-pointer' onClick={() => setSubDealer(!subdealer)}>
                            <div className={`card${subdealer ? ' bg-primary' : ''}`}>
                                <div className='card-body'>
                                    <div className='d-flex'>
                                        <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                            <i
                                                className={`fas fa-chevron-${subdealer
                                                    ? 'up text-white'
                                                    : 'down text-primary'} fa-2x`}
                                            />
                                        </span>
                                        <h5
                                            className={`my-auto font-weight-bolder ${subdealer
                                                ? 'text-white'
                                                : 'text-dark'}`}
                                        >
                                            สมัครต่อเบอร์อื่นๆ
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {subdealer && (
                        <div className='col-12 row p-0 m-0'>
                            {numberlist.map((object, index) => {
                                if (object.phonenumber !== user.main_phonenumber) {
                                    if (object.position !== '') {
                                        return (
                                            <a
                                                className='col-12 p-0'
                                                href={`${reg_url}agreement?mobile=${object.phonenumber}`}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                key={index}
                                            >
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className='d-flex'>
                                                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                                                <i className='fas fa-dot-circle text-primary' />
                                                            </span>
                                                            <h5 className='my-auto font-weight-bolder text-dark'>
                                                                {object.phonenumber}
                                                            </h5>
                                                            {object.feels_kyc === 0 && (
                                                                <span className='ml-auto label label-warning label-inline'>
                                                                    ยังไม่ลงทะเบียนซิม
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        );
                                    } else {
                                        return (
                                            <div className='col-12 p-0'>
                                                <div className='card bg-secondary'>
                                                    <div className='card-body'>
                                                        <div className='d-flex'>
                                                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                                                <i className='fas fa-dot-circle text-primary' />
                                                            </span>
                                                            <h5 className='my-auto font-weight-bolder text-dark'>
                                                                {object.phonenumber}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            })}
                        </div>
                    )}
                </div>
            )}
            <div className='col-12 p-0 cursor-pointer' onClick={() => handleClick('scriber')}>
                <div className={`card${scriber ? ' bg-primary' : ''}`}>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <i
                                    className={`fas fa-chevron-${scriber
                                        ? 'up text-white'
                                        : 'down text-primary'} fa-2x`}
                                />
                            </span>
                            <h5 className={`my-auto font-weight-bolder ${scriber ? 'text-white' : 'text-dark'}`}>
                                สมัครผู้ใช้งานใหม่
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            {scriber && (
                <div className='col-12 row p-0 m-0'>
                    <a
                        className='col-12 p-0'
                        href={`${reg_url}subscriber/register?mobile=${user.main_phonenumber}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <div className='card'>
                            <div className='card-body'>
                                <div className='d-flex'>
                                    <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                        <i className='fas fa-dot-circle text-primary' />
                                    </span>
                                    <h5 className='my-auto font-weight-bolder text-dark'>สมัครต่อเบอร์หลัก</h5>
                                </div>
                            </div>
                        </div>
                    </a>
                    {numberlist.length > 1 && (
                        <div className='col-12 p-0 cursor-pointer' onClick={() => setSubscriber(!subscriber)}>
                            <div className={`card${subscriber ? ' bg-primary' : ''}`}>
                                <div className='card-body'>
                                    <div className='d-flex'>
                                        <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                            <i
                                                className={`fas fa-chevron-${subscriber
                                                    ? 'up text-white'
                                                    : 'down text-primary'} fa-2x`}
                                            />
                                        </span>
                                        <h5
                                            className={`my-auto font-weight-bolder ${subscriber
                                                ? 'text-white'
                                                : 'text-dark'}`}
                                        >
                                            สมัครต่อเบอร์อื่นๆ
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {subscriber && (
                        <div className='col-12 row p-0 m-0'>
                            {numberlist.map((object, index) => {
                                if (object.phonenumber !== user.main_phonenumber) {
                                    if (object.position !== '') {
                                        return (
                                            <a
                                                className='col-12 p-0'
                                                href={`${reg_url}subscriber/register?mobile=${object.phonenumber}`}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                key={index}
                                            >
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className='d-flex'>
                                                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                                                <i className='fas fa-dot-circle text-primary' />
                                                            </span>
                                                            <h5 className='my-auto font-weight-bolder text-dark'>
                                                                {object.phonenumber}
                                                            </h5>
                                                            {object.feels_kyc === 0 && (
                                                                <span className='ml-auto label label-warning label-inline'>
                                                                    ยังไม่ลงทะเบียนซิม
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        );
                                    } else {
                                        return (
                                            <div className='col-12 p-0'>
                                                <div className='card bg-secondary'>
                                                    <div className='card-body'>
                                                        <div className='d-flex'>
                                                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                                                <i className='fas fa-dot-circle text-primary' />
                                                            </span>
                                                            <h5 className='my-auto font-weight-bolder text-dark'>
                                                                {object.phonenumber}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
