import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import { useSelector } from 'react-redux';
import initaddress from '../environments/address';
import config from '../environments/config';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { SubscriberList } from '../../Setting';

const controlStyles = {
    borderRadius: '0.85rem',
    padding: '5px',
    background: '#F3F6F9',
    color: 'white'
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    })
};

export function EditProfile() {
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ name, setName ] = useState('');
    const [ personaltype, setPersonaltype ] = useState('หมายเลขบัตรประชาชน/พาสปอร์ต');
    const [ personalid, setPersonalid ] = useState('');
    const [ bank, setBank ] = useState('');
    const [ banknumber, setBankNumber ] = useState('');
    const [ contactnumber, setContactNumber ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ geo, setGeo ] = useState('');
    const [ province, setProvince ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ subdistrict, setSubDistrict ] = useState('');
    const [ zipcode, setZipcode ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ profileimg, setProfileimg ] = useState('');
    // init value
    const [ maingeo, setMainGeo ] = useState([]);
    const [ mainprovince, setMainProvince ] = useState([]);
    const [ maindistrict, setMainDistrict ] = useState([]);
    const [ mainsubdistrict, setMainSubDistrict ] = useState([]);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        setMainGeo(initaddress.geo);
        getProfile(user.id);
    }, []);

    const getProfile = (memberid) => {
        fetch(config.py_url + config.version + '/profile/info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    if (result.profile.national === 'ไทย') {
                        setPersonaltype('หมายเลขบัตรประชาชน');
                    } else {
                        setPersonaltype('หมายเลขพาสปอร์ต');
                    }
                    for (const prop in initaddress.geo) {
                        if (initaddress.geo[prop]['value'] === result.profile.geo_id.toString()) {
                            setGeo(initaddress.geo[prop]);
                            let thisProvince = [];
                            let geoindex = 0;
                            for (const prop in initaddress.province) {
                                if (initaddress.province[prop]['geoid'] === result.profile.geo_id.toString()) {
                                    thisProvince[geoindex] = initaddress.province[prop];
                                    geoindex += 1;
                                }
                            }
                            setMainProvince(thisProvince);
                            break;
                        }
                    }
                    for (const prop in initaddress.province) {
                        if (initaddress.province[prop]['value'] === result.profile.province_id.toString()) {
                            setProvince(initaddress.province[prop]);
                            let thisDistrict = [];
                            let provinceindex = 0;
                            for (const prop in initaddress.district) {
                                if (
                                    initaddress.district[prop]['provinceid'] === result.profile.province_id.toString()
                                ) {
                                    thisDistrict[provinceindex] = initaddress.district[prop];
                                    provinceindex += 1;
                                }
                            }
                            setMainDistrict(thisDistrict);
                            break;
                        }
                    }
                    for (const prop in initaddress.district) {
                        if (initaddress.district[prop]['value'] === result.profile.amphur_id.toString()) {
                            setDistrict(initaddress.district[prop]);
                            let thisSubDistrict = [];
                            let districtindex = 0;
                            for (const prop in initaddress.subdistrict) {
                                if (
                                    initaddress.subdistrict[prop]['districtid'] === result.profile.amphur_id.toString()
                                ) {
                                    thisSubDistrict[districtindex] = initaddress.subdistrict[prop];
                                    districtindex += 1;
                                }
                            }
                            setMainSubDistrict(thisSubDistrict);
                            break;
                        }
                    }
                    for (const prop in initaddress.subdistrict) {
                        if (initaddress.subdistrict[prop]['value'] === result.profile.district_id.toString()) {
                            setSubDistrict(initaddress.subdistrict[prop]);
                            break;
                        }
                    }
                    setName(result.profile.name);
                    setPersonalid(result.profile.id_card);
                    setBank(result.bank.bank_name);
                    setBankNumber(result.bank.account_no);
                    setContactNumber(result.profile.contact_mobile);
                    setEmail(result.profile.email);
                    setZipcode(result.profile.zip);
                    setAddress(result.profile.address);
                    setProfileimg(result.profile.image !== '' ? result.profile.image : '');
                } else {
                    setError(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
            });
    };

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    const selectGeo = (item) => {
        setGeo(item);
        setProvince('');
        setDistrict('');
        setMainDistrict([]);
        setSubDistrict('');
        setMainSubDistrict([]);
        setZipcode('');
        let thisProvince = [];
        let thisindex = 0;
        if (item) {
            for (const prop in initaddress.province) {
                if (initaddress.province[prop]['geoid'] === item['value']) {
                    thisProvince[thisindex] = initaddress.province[prop];
                    thisindex += 1;
                }
            }
        }
        setMainProvince(thisProvince);
    };

    const selectProvince = (item) => {
        setProvince(item);
        setDistrict('');
        setSubDistrict('');
        setMainSubDistrict([]);
        setZipcode('');
        let thisDistrict = [];
        let thisindex = 0;
        if (item) {
            for (const prop in initaddress.district) {
                if (initaddress.district[prop]['provinceid'] === item['value']) {
                    thisDistrict[thisindex] = initaddress.district[prop];
                    thisindex += 1;
                }
            }
        }
        setMainDistrict(thisDistrict);
    };

    const selectDistrict = (item) => {
        setDistrict(item);
        setSubDistrict('');
        setZipcode('');
        let thisSubDistrict = [];
        let thisindex = 0;
        if (item) {
            for (const prop in initaddress.subdistrict) {
                if (initaddress.subdistrict[prop]['districtid'] === item['value']) {
                    thisSubDistrict[thisindex] = initaddress.subdistrict[prop];
                    thisindex += 1;
                }
            }
        }
        setMainSubDistrict(thisSubDistrict);
    };

    const selectSubDistrict = (item) => {
        setSubDistrict(item);
        let thisZipcode = '';
        if (item) {
            thisZipcode = item['zipcode'];
        }
        setZipcode(thisZipcode);
    };

    const updateProfile = (params) => {
        fetch(config.py_url + config.version + '/profile/update_profile', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(params)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    Swal.fire({
                        html: 'แก้ไขข้อมูลส่วนตัวเรียบร้อยแล้ว',
                        icon: 'success',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                        if (result) {
                            window.location.reload();
                        }
                    });
                } else {
                    Swal.fire({
                        html: 'ไม่สามารถแก้ไขข้อมูลส่วนตัว กรุณาลองใหม่อีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const submitInfo = (e) => {
        e.preventDefault();
        setLoading(true);
        if (name === undefined || name === '') {
            Swal.fire({
                html: 'กรุณากรอกชื่อ - นามสกุลให้เรียบร้อบ',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            setLoading(false);
            return;
        }
        if (contactnumber === undefined || contactnumber === '') {
            Swal.fire({
                html: 'กรุณากรอกเบอร์โทรศัพท์ติดต่อให้เรียบร้อบ',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            setLoading(false);
            return;
        }
        if (geo === undefined || geo === null || geo === '') {
            Swal.fire({
                html: 'กรุณาเลือกภาคให้เรียบร้อบ',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            setLoading(false);
            return;
        }
        if (province === undefined || province === null || province === '') {
            Swal.fire({
                html: 'กรุณาเลือกจังหวัดให้เรียบร้อบ',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            setLoading(false);
            return;
        }
        if (district === undefined || district === null || district === '') {
            Swal.fire({
                html: 'กรุณาเลือกอำเภอให้เรียบร้อบ',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            setLoading(false);
            return;
        }
        if (subdistrict === undefined || subdistrict === null || subdistrict === '') {
            Swal.fire({
                html: 'กรุณาเลือกตำบลให้เรียบร้อบ',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            setLoading(false);
            return;
        }
        if (zipcode === undefined || zipcode === '') {
            Swal.fire({
                html: 'กรุณากรอกรหัสไปรษณีย์ให้เรียบร้อบ',
                icon: 'warning',
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            setLoading(false);
            return;
        }
        let postparams = {
            userid: user.id,
            profile: {
                name: name,
                contact_mobile: contactnumber,
                avatar: profileimg
            },
            address: {
                geo_id: geo.value,
                province_id: province.value,
                amphur_id: district.value,
                district_id: subdistrict.value,
                address: address,
                zip: zipcode
            }
        };
        if (email !== '') {
            postparams['profile']['email'] = email;
        }
        updateProfile(postparams);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleChangeImage = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        setProfileimg(base64.split(',')[1]);
    };

    return (
        <div>
            {!loading ? (
                <div className='d-flex flex-row'>
                    <div className='flex-row-fluid ml-lg-8'>
                        <form className='form' onSubmit={submitInfo}>
                            <div className='card card-custom card-stretch'>
                                <div className='card-header py-3'>
                                    <div className='card-title align-items-start flex-column'>
                                        <h3 className='card-label font-weight-bolder text-dark'>ข้อมูลส่วนตัว</h3>
                                        <span className='text-muted font-weight-bold font-size-sm mt-1'>
                                            แก้ไขข้อมูลส่วนตัวให้เรียบร้อยแล้วกด "ยืนยัน"
                                        </span>
                                    </div>
                                    <div className='card-toolbar'>
                                        <button type='submit' className='btn btn-success mr-2' disabled={error}>
                                            ยืนยัน
                                        </button>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>รูปภาพประจำตัว</label>
                                        <div className='col-lg-9 col-xl-6'>
                                            <div
                                                className='image-input image-input-outline'
                                                id='kt_profile_avatar'
                                                style={{
                                                    backgroundImage: `url(${profileimg !== ''
                                                        ? `data:image/png;base64,${profileimg}`
                                                        : toAbsoluteUrl('/media/users/blank.png')})`
                                                }}
                                            >
                                                <div className='image-input-wrapper' />
                                                <label
                                                    className={`btn btn-xs btn-icon btn-circle btn-white ${!error
                                                        ? 'btn-hover-success'
                                                        : ''} btn-shadow`}
                                                    data-action='change'
                                                    data-toggle='tooltip'
                                                    data-original-title='เปลี่ยนรูป'
                                                >
                                                    <i className='fa fa-pen icon-sm text-muted' />
                                                    <input
                                                        type='file'
                                                        name='profile_avatar'
                                                        accept='.png, .jpg, .jpeg'
                                                        onChange={(e) => {
                                                            if (!error) {
                                                                handleChangeImage(e);
                                                            }
                                                        }}
                                                        disabled={error}
                                                    />
                                                </label>
                                                <span
                                                    className={`btn btn-xs btn-icon btn-circle btn-white ${!error
                                                        ? 'btn-hover-primary'
                                                        : ''} btn-shadow`}
                                                    data-action='remove'
                                                    data-toggle='tooltip'
                                                    title='ยกเลิก'
                                                    onClick={() => {
                                                        if (!error) {
                                                            setProfileimg('');
                                                        }
                                                    }}
                                                >
                                                    <i className='ki ki-bold-close icon-xs text-muted' />
                                                </span>
                                            </div>
                                            <span className='form-text text-muted'>
                                                อนุญาติเฉพาะไฟล์ประเภท: png, jpg, jpeg เท่านั้น
                                            </span>
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>
                                            ชื่อ - นามสกุล
                                            <span className='required-text'>*</span>
                                        </label>
                                        <div className='col-lg-9 col-xl-6'>
                                            <input
                                                className='form-control form-control-lg form-control-solid'
                                                type='text'
                                                id='name'
                                                name='name'
                                                defaultValue={name}
                                                onBlur={(e) => setName(e.target.value)}
                                                disabled={error}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>{personaltype}</label>
                                        <div className='col-lg-9 col-xl-6 row m-0'>
                                            <h5 className='my-auto'>{personalid}</h5>
                                        </div>
                                    </div>
                                    {!SubscriberList.includes(user.main_position) && (
                                        <div>
                                            <div className='form-group row'>
                                                <label className='col-xl-3 col-lg-3 col-form-label'>ธนาคาร</label>
                                                <div className='col-lg-9 col-xl-6 row m-0'>
                                                    <h5 className='my-auto'>{bank}</h5>
                                                </div>
                                            </div>
                                            <div className='form-group row'>
                                                <label className='col-xl-3 col-lg-3 col-form-label'>เลขที่บัญชี</label>
                                                <div className='col-lg-9 col-xl-6 row m-0'>
                                                    <h5 className='my-auto'>{banknumber}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>
                                            เบอร์โทรศัพท์ติดต่อ
                                            <span className='required-text'>*</span>
                                        </label>
                                        <div className='col-lg-9 col-xl-6'>
                                            <input
                                                type='tel'
                                                name='mobile'
                                                className='form-control form-control-lg form-control-solid'
                                                defaultValue={contactnumber}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                maxLength='10'
                                                onInput={maxLengthCheck}
                                                onBlur={(e) => setContactNumber(e.target.value)}
                                                disabled={error}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>อีเมล</label>
                                        <div className='col-lg-9 col-xl-6'>
                                            <input
                                                name='email'
                                                className='form-control form-control-lg form-control-solid'
                                                defaultValue={email}
                                                disabled={error}
                                                onBlur={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>
                                            ภาค
                                            <span className='required-text'>*</span>
                                        </label>
                                        <div className='col-lg-9 col-xl-6'>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={geo}
                                                options={maingeo}
                                                placeholder='เลือก'
                                                id='geo'
                                                onChange={selectGeo}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                                isDisabled={error}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>
                                            จังหวัด
                                            <span className='required-text'>*</span>
                                        </label>
                                        <div className='col-lg-9 col-xl-6'>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={province}
                                                options={mainprovince}
                                                placeholder='เลือก'
                                                id='province'
                                                onChange={selectProvince}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                                isDisabled={
                                                    mainprovince === undefined || mainprovince.length === 0 || error
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>
                                            {province === '' ? (
                                                'เขต/อำเภอ'
                                            ) : province === 'กรุงเทพมหานคร' ? (
                                                'เขต'
                                            ) : (
                                                'อำเภอ'
                                            )}
                                            <span className='required-text'>*</span>
                                        </label>
                                        <div className='col-lg-9 col-xl-6'>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={district}
                                                options={maindistrict}
                                                placeholder='เลือก'
                                                id='district'
                                                onChange={selectDistrict}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                                isDisabled={
                                                    maindistrict === undefined || maindistrict.length === 0 || error
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>
                                            ตำบล
                                            {province === '' ? (
                                                'แขวง/ตำบล'
                                            ) : province === 'กรุงเทพมหานคร' ? (
                                                'แขวง'
                                            ) : (
                                                'ตำบล'
                                            )}
                                            <span className='required-text'>*</span>
                                        </label>
                                        <div className='col-lg-9 col-xl-6'>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={subdistrict}
                                                options={mainsubdistrict}
                                                placeholder='เลือก'
                                                id='subdistrict'
                                                onChange={selectSubDistrict}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                                isDisabled={
                                                    mainsubdistrict === undefined ||
                                                    mainsubdistrict.length === 0 ||
                                                    error
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>
                                            รหัสไปรษณีย์
                                            <span className='required-text'>*</span>
                                        </label>
                                        <div className='col-lg-9 col-xl-6'>
                                            <div className='input-group input-group-lg input-group-solid'>
                                                <input
                                                    type='tel'
                                                    id='zip'
                                                    name='zip'
                                                    className='form-control col-md-12 col-sm-12 col-xs-12'
                                                    defaultValue={zipcode}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxLength='5'
                                                    onInput={maxLengthCheck}
                                                    disabled={error}
                                                    onBlur={(e) => setZipcode(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <label className='col-xl-3 col-lg-3 col-form-label'>บ้านเลขที่ ถนน ซอย</label>
                                        <div className='col-lg-9 col-xl-6'>
                                            <div className='input-group input-group-lg input-group-solid'>
                                                <TextareaAutosize
                                                    id='address'
                                                    name='address'
                                                    className='form-control'
                                                    maxLength='255'
                                                    defaultValue={address}
                                                    disabled={error}
                                                    onBlur={(e) => setAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            ) : (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
