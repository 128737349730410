import React, { useEffect, useState } from 'react';
import config from '../../../environments/config';

export function NCPhonenumber(props) {
    const [ numberlist, setNumberlist ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ start, setStart ] = useState(0);
    const [ end, setEnd ] = useState(11);
    const [ filter, setFilter ] = useState('');

    useEffect(() => {
        if (props.type === 'PT2') {
            let postparam = {};
            if (props.selectpackages.sim_table === 'sim') {
                postparam = {
                    type: 'sim',
                    qty: '48',
                    package: props.selectpackages.sim_pack_id
                };
                getPhonenumber(postparam);
            } else if (props.selectpackages.sim_table === 'exsim') {
                postparam = {
                    type: 'sim',
                    package: 'P1',
                    qty: '48'
                };
                getExPhonenumber(postparam);
            }
        }
    }, []);

    const getPhonenumber = (postparam) => {
        props.handlePhonenumber('');
        setLoading(true);
        setNumberlist({});
        setStart(0);
        setEnd(11);
        fetch(config.laravel_url + '/api/register/getsim', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setNumberlist(Object.values(result.info));
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getExPhonenumber = (postparam) => {
        props.handlePhonenumber('');
        setLoading(true);
        setNumberlist({});
        setStart(0);
        setEnd(11);
        fetch(config.py_url + config.version + '/register/get-sim', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    let listNumber = [];
                    for (const index in result.info) {
                        let object = result.info[index];
                        object['phonenumber'] = result.info[index]['mobile_number'];
                        listNumber.push(object);
                    }
                    setNumberlist(listNumber);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const onPrevious = () => {
        setStart(start - 12);
        setEnd(end - 12);
    };

    const onNext = () => {
        setStart(start + 12);
        setEnd(end + 12);
    };

    const fecthNew = () => {
        let postparam = {};
        if (props.selectpackages.sim_table === 'sim') {
            postparam = {
                type: 'sim',
                qty: '48',
                package: props.selectpackages.sim_pack_id
            };
            if (filter !== '') {
                postparam['filter'] = {
                    number: filter
                };
            }
            getPhonenumber(postparam);
        } else if (props.selectpackages.sim_table === 'exsim') {
            postparam = {
                type: 'sim',
                package: 'P1',
                qty: '48'
            };
            if (filter !== '') {
                postparam['number'] = filter;
            }
            getExPhonenumber(postparam);
        }
    };

    return (
        <div>
            {props.type === 'PT1' ? (
                <div className='row col-xl-12 col-sm-12 justify-content-center'>
                    <div className='col-xl-12'>
                        <div className='form-group fv-plugins-icon-container'>
                            <label>เบอร์ Feels ที่ใช้งานอยู่</label>
                            <input
                                type='text'
                                inputMode='tel'
                                className='form-control form-control-solid form-control-lg'
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={props.phonenumber}
                                maxLength='10'
                                onInput={props.maxLengthCheck}
                                onChange={(e) => props.handlePhonenumber(e.target.value)}
                            />
                            {props.phonenumber === '' ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>กรุณากรอกเบอร์ Feels ที่ใช้งานอยู่</div>
                                </div>
                            ) : (
                                (props.phonenumber.charAt(0) !== '0' || props.phonenumber.length < 9) && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            กรุณากรอกเบอร์ Feels ที่ใช้งานอยู่ให้ถูกต้อง
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='row justify-content-center'>
                        <div className='row col-9 justify-content-center'>
                            <div className='col-xl-12'>
                                <div className='form-group fv-plugins-icon-container'>
                                    <label>ค้นหาเลขที่สนใจ</label>
                                    <input
                                        type='text'
                                        inputMode='tel'
                                        className='form-control form-control-solid form-control-lg'
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        maxLength='10'
                                        onInput={props.maxLengthCheck}
                                        onChange={(e) => setFilter(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-3 d-flex justify-content-center align-items-center'>
                            <button
                                type='button'
                                className='btn btn-secondary font-weight-bold text-uppercase px-9 py-4'
                                onClick={(e) => fecthNew(e)}
                            >
                                ค้นหา
                            </button>
                        </div>
                    </div>
                    <div className='row col-xl-12 m-0 justify-content-center'>
                        <div className='number-box w-100'>
                            <div>
                                <div className='col-xl-12 row form-group m-0 mt-5 mb-5 p-0'>
                                    {!loading ? Object.keys(numberlist).length > 0 ? (
                                        Object.keys(numberlist).slice(start, end + 1).map((object, index) => {
                                            return (
                                                <div className='col-md-3 col-sm-4 col-6 pr-2 pl-2 mt-3' key={index}>
                                                    <button
                                                        type='button'
                                                        className={`btn pt-2 pb-2 pl-0 pr-0 fs-20 font-weight-normal w-100 ${props.phonenumber ===
                                                        numberlist[object]['phonenumber']
                                                            ? 'btn-success'
                                                            : 'btn-outline-success'}`}
                                                        onClick={() =>
                                                            props.handlePhonenumber(numberlist[object]['phonenumber'])}
                                                    >
                                                        {numberlist[object]['phonenumber'].slice(0, 3) +
                                                            '-' +
                                                            numberlist[object]['phonenumber'].slice(3, 6) +
                                                            '-' +
                                                            numberlist[object]['phonenumber'].slice(6)}
                                                    </button>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className='col-xl-12 text-center m-0 my-25 p-0'>
                                            <i className='fas fa-box-open fa-5x mb-2 text-success' />
                                            <h4 className='mt-3'>ไม่พบเลขนี้ในระบบ</h4>
                                        </div>
                                    ) : (
                                        <div className='col-12 row p-0 m-0'>
                                            <div
                                                className='spinner-border mx-auto text-success mt-25 mb-10 p-5'
                                                role='status'
                                            >
                                                <span className='sr-only'>Loading...</span>
                                            </div>
                                            <h1 className='d-block col-12 text-center mb-15'>กรุณารอสักครู่</h1>
                                        </div>
                                    )}
                                </div>
                                <div className='col-xl-12 text-center d-flex justify-content-center mt-3'>
                                    <button
                                        type='button'
                                        className={`btn btn-success btn-sm font-weight-bold text-uppercase mr-3 ${Object.keys(
                                            numberlist
                                        ).length > 12
                                            ? ''
                                            : 'd-none'}`}
                                        disabled={start <= 0}
                                        onClick={onPrevious}
                                    >
                                        ก่อนหน้า
                                    </button>
                                    <button
                                        type='button'
                                        className={`btn btn-success btn-sm font-weight-bold text-uppercase ${end >=
                                        Object.keys(numberlist).length - 1
                                            ? 'd-none'
                                            : ''}`}
                                        onClick={onNext}
                                    >
                                        ถัดไป
                                    </button>
                                    <button
                                        type='button'
                                        className={`btn btn-success btn-sm font-weight-bold text-uppercase ${end >=
                                            Object.keys(numberlist).length - 1 && Object.keys(numberlist).length > 12
                                            ? ''
                                            : 'd-none'}`}
                                        onClick={fecthNew}
                                    >
                                        สุ่มใหม่
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
