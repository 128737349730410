import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import NumberFormat from 'react-number-format';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export function NCSummary(props) {
    return (
        <div>
            <div className='row d-flex justify-content-center pb-5'>
                <div className='col-sm-5 col-md-6 mb-5 mb-md-0'>
                    <div className='py-4 d-flex flex-row'>
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/logos/new-logo.png')}
                            className='logo-sticky max-h-35px'
                        />
                        <h5 className='my-auto'>
                            <b>ปันสุข</b> |{' '}
                        </h5>
                        <span className='pl-2 my-auto'>ซิม</span>
                    </div>
                    <OverlayScrollbarsComponent className='summary-body bg-light'>
                        <div className='p-5'>
                            <div className='d-flex row m-0 p-2'>
                                <div className='col-xl-12 d-flex'>
                                    <div>
                                        <p>
                                            <i className='fas fa-truck mr-3' />
                                            <b>ข้อมูลที่อยู่จัดส่ง</b>
                                        </p>
                                    </div>
                                    <div className='ml-auto'>
                                        <p className='text-primary cursor-pointer' onClick={props.backStep}>
                                            <i className='fas fa-pencil-alt text-primary' />
                                            แก้ไข
                                        </p>
                                    </div>
                                </div>
                                <div className='col-xl-12'>
                                    <div className='rounded bg-white row m-0 p-3'>
                                        <div className='d-flex col-xl-12'>
                                            <div className='p-2'>ชื่อผู้รับสินค้า</div>
                                            <div className='ml-auto p-2'>{props.shipname}</div>
                                        </div>
                                        <div className='d-flex col-xl-12'>
                                            <div className='p-2'>เบอร์โทรศัพท์ติดต่อ</div>
                                            <div className='ml-auto p-2'>{props.shipcontact}</div>
                                        </div>
                                        <div className='d-flex col-xl-12'>
                                            <div className='p-2'>ภูมิภาค</div>
                                            <div className='ml-auto p-2'>
                                                {props.shippingtype === 'ST1' ? props.shipgeo : props.shipgeo.label}
                                            </div>
                                        </div>
                                        <div className='d-flex col-xl-12'>
                                            <div className='p-2'>จังหวัด</div>
                                            <div className='ml-auto p-2'>
                                                {props.shippingtype === 'ST1' ? (
                                                    props.shipprovince
                                                ) : (
                                                    props.shipprovince.label
                                                )}
                                            </div>
                                        </div>
                                        <div className='d-flex col-xl-12'>
                                            <div className='p-2'>
                                                {(props.shippingtype === 'ST1'
                                                    ? props.shipprovince
                                                    : props.shipprovince.label) === 'กรุงเทพมหานคร' ? (
                                                    'เขต'
                                                ) : (
                                                    'อำเภอ'
                                                )}
                                            </div>
                                            <div className='ml-auto p-2'>
                                                {props.shippingtype === 'ST1' ? (
                                                    props.shipdistrict
                                                ) : (
                                                    props.shipdistrict.label
                                                )}
                                            </div>
                                        </div>
                                        <div className='d-flex col-xl-12'>
                                            <div className='p-2'>
                                                {(props.shippingtype === 'ST1'
                                                    ? props.shipprovince
                                                    : props.shipprovince.label) === 'กรุงเทพมหานคร' ? (
                                                    'แขวง'
                                                ) : (
                                                    'ตำบล'
                                                )}
                                            </div>
                                            <div className='ml-auto p-2'>
                                                {props.shippingtype === 'ST1' ? (
                                                    props.shipsubdistrict
                                                ) : (
                                                    props.shipsubdistrict.label
                                                )}
                                            </div>
                                        </div>
                                        <div className='d-flex col-xl-12'>
                                            <div className='p-2'>รหัสไปรษณีย์</div>
                                            <div className='ml-auto p-2'>{props.shipzipcode}</div>
                                        </div>
                                        <div className='d-flex col-xl-12'>
                                            <div className='p-2'>บ้านเลขที่ ถนน ซอย</div>
                                            <div className='ml-auto p-2 text-right'>{props.shipaddress}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
                <div className='col-sm-3 col-md-6 mobile'>
                    <div className='bg-light rounded d-flex flex-column p-3'>
                        <div className='p-2 ml-3'>
                            <h4>รายละเอียดการสมัครผู้ใช้งานใหม่</h4>
                        </div>
                        <div className='p-2 d-flex'>
                            <div className='col-7'>ประเภทเบอร์ใช้งาน</div>
                            <div className='ml-auto text-right'>
                                {props.type === 'PT2' ? (
                                    'เข้าร่วมด้วยเบอร์ใหม่'
                                ) : (
                                    'เข้าร่วมด้วยเบอร์ที่ท่านมีอยู่'
                                )}
                            </div>
                        </div>
                        <div className='p-2 d-flex'>
                            <div className='col-8'>ขนาดแพ็กเกจของผู้ใช้งานใหม่</div>
                            <div className='ml-auto'>
                                {props.selectpackages.title}
                                {/* <NumberFormat
                                    value={parseFloat(props.selectpackages.price)}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    suffix={' บาท'}
                                /> */}
                            </div>
                        </div>
                        <div className='p-2 d-flex'>
                            <div className='col-8'>เบอร์โทรศัพท์ที่ต้องการใช้งานใหม่</div>
                            <div className='ml-auto'>{props.phonenumber}</div>
                        </div>
                        <div className='border-top px-4 mx-3' />
                        <div className='d-flex flex-row align-content-center p-4'>
                            <div className='pt-2 pr-2 col-xl-12'>
                                <div className='radio-inline'>
                                    <label className='radio radio-primary col-xl-12'>
                                        <input
                                            type='radio'
                                            name='radio'
                                            value='2'
                                            checked={props.paymenttype === '2'}
                                            onChange={() => props.handlePayment('2')}
                                        />
                                        <span />
                                        <div className='rounded border d-flex w-100 px-2 bg-white'>
                                            <div className='row p-0 m-0'>
                                                <p className='my-auto'>
                                                    <i className='fas fa-qrcode text-primary pr-2' />
                                                    ชำระเงินผ่านคิวอาร์โค้ด
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row align-content-center p-4'>
                            <div className='pt-2 pr-2 col-xl-12'>
                                <div className='radio-inline'>
                                    <label className='radio radio-primary col-xl-12'>
                                        <input
                                            type='radio'
                                            name='radio'
                                            value='3'
                                            checked={props.paymenttype === '3'}
                                            onChange={() => props.handlePayment('3')}
                                        />
                                        <span />
                                        <div className='rounded border d-flex w-100 px-2 bg-white'>
                                            <div className='row p-0 m-0'>
                                                <p className='my-auto'>
                                                    <i className='fas fa-wallet text-primary pr-2' />
                                                    กระเป๋าซิมปันสุข
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='border-top px-4 mx-3' />
                        <div className='p-2 d-flex pt-3'>
                            <div className='col-8'>
                                <b>ยอดรวม</b>
                            </div>
                            <div className='ml-auto'>
                                <b className='green'>
                                    <NumberFormat
                                        value={parseFloat(props.selectpackages.price)}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        suffix={' บาท'}
                                    />
                                </b>
                            </div>
                        </div>
                        <div className='m-3'>
                            <button type='button' className='btn btn-primary btn-block' onClick={props.nextStep}>
                                ชำระเงิน
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
