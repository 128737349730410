import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { ShareOrderOperationForm, ShareOrderOperationResult } from './components';
import config from '../../../environments/config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function ShareOrderOperationMain() {
    const { user } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('main');
    const [ share, setShare ] = useState({});
    const [ amount, setAmount ] = useState(0);
    const [ payment, setPayment ] = useState('bank');
    const [ result, setResult ] = useState({});

    const btnNext = () => {
        if (page === 'main') {
            setPage('form');
        } else if (page === 'form') {
            if (amount === 0) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `กรุณากรอกจำนวนหุ้นที่ต้องการ`,
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            orderShare();
        }
    };

    const getShare = () => {
        setLoading(true);
        fetch(config.py_url + 'v1/share/get-detail', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                id: '1'
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setShare(result);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const orderShare = () => {
        setLoading(true);
        fetch(config.py_url + 'v1/share/order', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: user.id,
                shareid: '1',
                amount: amount.toString(),
                payment: payment
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setResult(result);
                    setPage('result');
                } else {
                    let message = '';
                    if (result.responsecode === 100) {
                        message = 'มีบางอย่างผิดพลาด กรุณาติดต่อเจ้าหน้าที่';
                    } else if (result.responsecode === 101) {
                        message = 'ไม่พบบัญชีของคุณ กรุณาติดต่อเจ้าหน้าที่';
                    } else if (result.responsecode === 102) {
                        message = 'ไม่พบหุ้นที่ต้องการจอง กรุณาติดต่อเจ้าหน้าที่';
                    } else if (result.responsecode === 103 || result.responsecode === 104) {
                        message = 'หุ้นที่ต้องการจองไม่พร้อมใช้งาน กรุณาติดต่อเจ้าหน้าที่';
                    } else if (result.responsecode === 105) {
                        message = 'จำนวนหุ้นที่ต้องการจองในระบบถึงยอดสูงสุดแล้ว';
                    } else if (result.responsecode === 106) {
                        message = 'จำนวนหุ้นที่ต้องการจองมีรูปแบบไม่ถูกต้อง';
                    } else if (result.responsecode === 107) {
                        message = 'จำนวนหุ้นที่ต้องการจองเกินกว่ายอดจำนวนหุ้นที่สามารถจองได้สูงสุด';
                    } else if (result.responsecode === 108) {
                        message = 'ไม่พบช่องทางที่ต้องการชำระเงิน';
                    } else if (result.responsecode === 109) {
                        message = 'ไม่สามารถสร้างคิวอาร์โค้ดได้ กรุณาลองใหม่อีกครั้ง';
                    } else if (result.responsecode === 110) {
                        message = 'ยอดเงินในกระเป๋าไม่เพียงพอ กรุณาเปลี่ยนช่องทางชำระเงิน';
                    } else if (result.responsecode === 111) {
                        message = 'คุณไม่มีกระเป๋าประเภทนี้ กรุณาเปลี่ยนช่องทางชำระเงิน';
                    } else if (result.responsecode === 112) {
                        message = 'กระเป๋าของคุณถูกปิดใช้งานอยู่ กรุณาเปลี่ยนช่องทางชำระเงิน';
                    }
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${message}`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถดำเนินการต่อได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    useEffect(() => {
        getShare();
    }, []);

    return (
        <div>
            {loading && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            {page === 'main' ? (
                <div className='card card-custom gutter-b'>
                    <div className='card-body d-flex rounded bg-danger p-md-12 p-0 justify-content-center'>
                        <div className='row m-0'>
                            <div className='col-lg-7 text-center my-7 my-md-auto'>
                                <img
                                    className='max-h-md-450px max-h-250px'
                                    src={toAbsoluteUrl('/media/misc/promotion-220916-update-2.jpg')}
                                />
                            </div>
                            <div className='col-lg-5'>
                                <div className='card card-custom ml-auto'>
                                    <div className='card-body px-12 py-10'>
                                        <div className='font-size-sm mb-8'>
                                            <p className='font-weight-bolder font-size-h5 mb-5'>
                                                เปิดรับแล้ว หุ้นส่วนรายใหม่ที่พร้อมจะเป็นผู้ถือหุ้นบุริมสิทธิของบริษัท
                                                ที่เป็นผู้ประกอบการในธุรกิจหลากหลายด้านโดยเฉพาะ Digital Technology
                                            </p>
                                            <p className='mb-0'>📍เรามีระบบจัดการภาษีอย่างถูกต้องให้แก่หุ้นส่วน</p>
                                            <p className='mb-0'>📍เรามีใบสัญญาซื้อขายหรือจองหุ้น</p>
                                            <p className='mb-0'>
                                                📍เราดำเนินธุรกิจต่อเนื่องด้วยประสบการณ์มามากกว่า 5 ปี
                                            </p>
                                            <p>📍ถูกต้องตามกฎหมาย 100%</p>
                                            <p>สิทธิของผู้ถือหุ้นบุริมสิทธิ</p>
                                            <p>
                                                1. ผู้ลงทุน จะได้รับสัญญา ภายใน 1-3 วัน หลังจากการชำระทุนเสร็จสมบูรณ์
                                                และจะได้รับใบหุ้นสำหรับผู้ลงทุน 100,000 บาทขึ้นไปภายใน 30 วัน
                                                เมื่อบริษัทได้จดทะเบียนเพิ่มทุนเสร็จสมบูรณ์
                                            </p>
                                            <p>
                                                2. ผู้ลงทุนสามารถ เช็ครายชื่อ ผู้ถือหุ้นที่ DBD ภายใน 60 วัน และไม่เกิน
                                                150 วันตามกฎหมาย เมื่อบริษัทได้จดทะเบียนเพิ่มทุนเสร็จสมบูรณ์
                                            </p>
                                            <p>
                                                3. ผู้ลงทุน มีสิทธิใช้ห้องประชุมของบริษัทและสามารถเยี่ยมชมกิจการ
                                                เบื้องหลังการทำงานของบริษัทในเครือที่ร่วมลงทุน และร่วมงานกิจกรรมต่างๆ
                                                ของบริษัทได้ตลอดอายุสัญญาการร่วมทุน ทั้งนี้
                                                การใช้ห้องประชุมให้เป็นไปตามระเบียบการจองและใช้ห้องประชุมของบริษัท
                                            </p>
                                            <p className='mb-0'>
                                                ** การลงทุนมีความเสี่ยง ผู้ลงทุนควรศึกษาและทำความเข้าใจลักษณะสินค้า
                                                เงื่อนไขผลตอบแทน และความเสี่ยงก่อนตัดสินใจลงทุน การลงทุนนั้นมีความเสี่ยง
                                                อาจไม่เหมาะสำหรับทุกท่านและอาจส่งผลให้สูญเสียเงินทุนทั้งหมด
                                            </p>
                                            <p className='mb-0'>
                                                ** หุ้นบุริมสิทธิ มีส่วนร่วมเป็นเจ้าของกิจการเสมือนหุ้นสามัญ ทั้งนี้
                                                ผู้ลงทุนซื้อหุ้นจะต้องถือครองหุ้นต่อเนื่อง 2 ปี
                                            </p>
                                            <p>
                                                ** ผู้ถือหุ้นชนิดนี้จะจำหน่ายจ่ายโอน
                                                หรือขายหุ้นของตนให้แก่ผู้ใดก็ได้เมื่อพ้นกำหนดระยะเวลา
                                            </p>
                                        </div>
                                        {!loading ? Object.keys(share).length > 0 ? share.responsecode ===
                                        0 ? share.totalAmount < share.maxAmount ? (
                                            <button
                                                type='button'
                                                className='btn btn-block btn-primary'
                                                onClick={btnNext}
                                            >
                                                จองทันที
                                            </button>
                                        ) : (
                                            <div className='form-group d-flex flex-wrap flex-center mt-10'>
                                                ไม่สามารถจองหุ้นได้ เนื่องจากจำนวนสูงสุดแล้ว
                                            </div>
                                        ) : (
                                            <div className='form-group d-flex flex-wrap flex-center mt-10'>
                                                ไม่ดึงสามารถจำนวนหุ้นได้ กรุณาลองใหม่อีกครั้ง
                                            </div>
                                        ) : (
                                            <div className='form-group d-flex flex-wrap flex-center mt-10'>
                                                ไม่ดึงสามารถจำนวนหุ้นได้ กรุณาลองใหม่อีกครั้ง
                                            </div>
                                        ) : (
                                            <div className='form-group d-flex flex-wrap flex-center mt-10'>
                                                <i className='fas fa-circle-notch fa-spin text-dark mr-3' />{' '}
                                                กรุณารอสักครู่
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : page === 'form' ? (
                <ShareOrderOperationForm
                    maxAmount={share.maxAmount}
                    totalAmount={share.totalAmount}
                    price={share.price}
                    amount={amount}
                    setAmount={setAmount}
                    payment={payment}
                    setPayment={setPayment}
                    btnNext={btnNext}
                />
            ) : (
                <ShareOrderOperationResult payment={payment} response={result} />
            )}
        </div>
    );
}
