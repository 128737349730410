import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { Pdfcontent } from "./content";

import config from "../environments/config";
import { SubscriberList } from "../../Setting";
// import { toAbsoluteUrl } from "../../../_metronic/_helpers";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf",
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf",
    },
};

export function AgreementPage() {
    const history = useHistory();
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    // const [phonenumber, setPhonenumber] = useState("");
    const [rawdoc, setRawDoc] = useState({});
    const [contactdate, setContactdate] = useState("");
    const [name, setName] = useState("");
    const [citizenid, setCitizenid] = useState("");
    const [address, setAddress] = useState("");

    useEffect(() => {
        if (SubscriberList.includes(user.main_position)) {
            history.push('/');
        } else {
            getInfo();
        }
    }, []);

    const toThaiDateString = (date) => {
        let monthNames = [
            "มกราคม",
            "กุมภาพันธ์",
            "มีนาคม",
            "เมษายน",
            "พฤษภาคม",
            "มิถุนายน",
            "กรกฎาคม",
            "สิงหาคม.",
            "กันยายน",
            "ตุลาคม",
            "พฤศจิกายน",
            "ธันวาคม",
        ];

        let year = date.getFullYear() + 543;
        let month = monthNames[date.getMonth()];
        let numOfDay = date.getDate().toString().padStart(2, "0");

        return `${numOfDay} ${month} ${year}`;
    };

    const getInfo = () => {
        // console.log("begin getInfo");
        fetch(config.py_url + config.version + "/profile/dealer-info", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                userid: user.id,
                phonenumber: user.main_phonenumber,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);
                if (result.responsecode === 0) {
                    // console.log(result);
                    setName(result.profile.name);
                    setCitizenid(result.profile.id_card);
                    let infoaddress = [
                        result.profile.address,
                        result.profile.amphur.split(" ").join(""),
                        result.profile.district.split(" ").join(""),
                        result.profile.province.split(" ").join(""),
                        result.profile.zip,
                    ];
                    // console.log(infoaddress.join(" "));
                    // console.log(
                    //     toThaiDateString(new Date(result.dealer.register_date))
                    // );
                    setAddress(infoaddress.join(" "));
                    setContactdate(
                        toThaiDateString(new Date(result.dealer.register_date))
                    );
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const downloadPdf = () => {
        pdfMake
            .createPdf(rawdoc)
            .download("dealer-mou-" + user.main_phonenumber);
    };

    const printPdf = () => {
        pdfMake.createPdf(rawdoc).print();
    };

    const handleSelect = (e) => {
        // console.log(typeof rawdoc);
        // console.log(rawdoc);
        if (e === "print") {
            printPdf();
        } else {
            downloadPdf();
        }
    };

    return (
        <>
            {!loading ? (
                <Pdfcontent
                    setRawDoc={setRawDoc}
                    handleSelect={handleSelect}
                    downloadPdf={downloadPdf}
                    contactdate={contactdate}
                    name={name}
                    citizenid={citizenid}
                    address={address}
                />
            ) : (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
