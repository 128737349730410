import React, { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import config from '../../../environments/config';

export function ShopCartSwitchAccount(props) {
    const { user } = useSelector((state) => state.auth);
    const ref = useRef(null);

    const [ loading, setLoading ] = useState(false);
    const [ disable, setDisable ] = useState(true);
    const [ accounts, setAccounts ] = useState({});

    const [ maxwidth, setMaxwidth ] = useState(0);
    const [ currentwidth, setCurrentwidth ] = useState(0);

    let count = 0;

    const btnSelect = (phonenumber) => {
        if (disable !== true) {
            props.memberLogin(phonenumber);
            // props.setPhonenumber(phonenumber);
            // props.setPage('login');
        }
    };

    const getAccounts = (memberid) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/profile/phonenumber_list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setAccounts(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const scroll = (size, option) => {
        const osInstance = ref.current.osInstance();
        if (osInstance) {
            osInstance.scrollStop().scroll(
                {
                    x: `${option}= ${size}px`
                },
                1000
                // 'easeOutCubic'
            );
            setTimeout(() => {
                setCurrentwidth(Math.ceil(osInstance.scroll().position.x));
            }, 1000);
        }
    };

    useEffect(() => {
        getAccounts(user.id);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            // const osInstance = ref.current.osInstance();
            // setMaxwidth(osInstance.scrollStop().scroll().max.x);
            setDisable(false);
        }, 1000);
    }, []);

    return (
        <div className='switch-account h-600px'>
            <div className='d-flex flex-column'>
                <div className='d-flex justify-content-center align-items-center p-5'>
                    <img
                        alt='logo'
                        className='max-h-175px my-auto'
                        src={toAbsoluteUrl('/media/logos/logo_sim_punsuk_final.png')}
                    />
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <h1 className='text-white display-3 font-weight-bold'>บัญชีที่คุณมี</h1>
                {loading ? (
                    <div className='row align-items-center col-12 p-0 m-0 min-h-200px'>
                        <div className='text-center col-12 p-0'>
                            <i className='text-dark-75 fas fa-spinner fa-pulse fa-5x' />
                            <h1 className='col mt-5'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                ) : (
                    <div>
                        {Object.keys(accounts).length <= 0 ? (
                            <div className='row align-items-center col-12 p-0 m-0 min-h-200px'>
                                <div className='text-center col-12 p-0'>
                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                    <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {accounts.responsecode !== 0 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-200px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='group-list w-md-500px w-300px'>
                                        <div className='slider inline-scroll'>
                                            <div
                                                className={`slider__arrow-left d-none ${currentwidth === 0
                                                    ? 'd-md-none'
                                                    : 'd-md-flex'}`}
                                            >
                                                <span className='arrow' onClick={() => scroll('200', '-')}>
                                                    <i className='fa fa-angle-left fa-3x' />
                                                </span>
                                            </div>
                                            <OverlayScrollbarsComponent className='os-theme-light wv-100' ref={ref}>
                                                <ul
                                                    className={`d-flex flex-row p-0${accounts.numbers.length < 3
                                                        ? ' row justify-content-center'
                                                        : ''}`}
                                                >
                                                    {accounts.numbers.map((object, index, arr) => {
                                                        if (object.position !== '' && object.feels_kyc !== 0) {
                                                            count += 1;
                                                            return (
                                                                <li
                                                                    className='group-item'
                                                                    key={index}
                                                                    onClick={() => btnSelect(object.phonenumber)}
                                                                >
                                                                    <div className='symbol symbol-40 symbol-light-white mr-0 group-image'>
                                                                        <div className='symbol-label w-100 h-100'>
                                                                            <i className='fas fa-sim-card fa-5x' />
                                                                        </div>
                                                                    </div>
                                                                    {/* <img
                                                                        alt='logo'
                                                                        className='group-image'
                                                                        // src={
                                                                        //     object.group_img === '' ? (
                                                                        //         toAbsoluteUrl('/media/users/blank.png')
                                                                        //     ) : (
                                                                        //         'data:image/png;base64,' + object.group_img
                                                                        //     )
                                                                        // }
                                                                        // onClick={() => goHome(object)}
                                                                    /> */}
                                                                    <p className='group-name cursor-pointer'>
                                                                        {object.phonenumber}
                                                                    </p>
                                                                </li>
                                                            );
                                                        }
                                                        if (arr.length - 1 === index) {
                                                            if (count === 0) {
                                                                return (
                                                                    <div className='row align-items-center col-12 p-0 m-0 min-h-200px'>
                                                                        <div className='text-center col-12 p-0'>
                                                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                                            <h1 className='col mt-5'>
                                                                                ไม่มีหมายเลขที่พร้อมใช้งาน
                                                                            </h1>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        }
                                                    })}
                                                </ul>
                                            </OverlayScrollbarsComponent>
                                            <div
                                                className={`slider__arrow-right d-none ${currentwidth >= maxwidth
                                                    ? 'd-md-none'
                                                    : 'd-md-flex'}`}
                                            >
                                                <span className='arrow' onClick={() => scroll('200', '+')}>
                                                    <i className='fa fa-angle-right fa-3x' />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
