import React, { useEffect } from 'react';

import $ from 'jquery';

export function OrderTable(prop) {
    // console.log(prop);
    useEffect(() => {
        $(document).ready(function() {
            if (prop.ordertype === 'Q') {
                $('#kt_datatable').DataTable({
                    data: prop.data,
                    lengthChange: false,
                    pageLength: -1,
                    responsive: true,
                    dom: 'Bfrtip',
                    buttons: [ 'excel' ],
                    order: [ [ 2, 'asc' ] ],
                    language: {
                        paginate: {
                            previous: 'ก่อนหน้า',
                            next: 'ถัดไป'
                        },
                        info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                        infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                        emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                        infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                        zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                    },
                    oLanguage: {
                        sSearch: 'คำสำคัญ'
                    },
                    columns: [
                        {
                            title: 'ดูรายละเอียด',
                            className: 'dt-body-center',
                            data: null,
                            render: function(data, type, row) {
                                if (data.status === 'pending' && data.payment === 'QR-Code' && data.qrcode !== '') {
                                    return `<button
                                                    type="button"
                                                    class="btn btn-icon btn-primary view-order-info"
                                                    data-qrcode=${data.qrcode}
                                                    data-order=${data.order_number}
                                                    data-price=${data.total}
                                                    data-payment=${data.payment}
                                                    data-status=${data.status}
                                                    >
                                                        <i class="fa fa-search"></i>
                                                </button>`;
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            title: 'หมายเลขคำสั่งซื้อ',
                            data: 'order_number'
                        },
                        {
                            title: 'วันที่สั่งซื้อ',
                            data: 'order_date'
                        },
                        {
                            title: 'ชื่อสมาชิก',
                            data: function(value) {
                                if (value.name !== null && value.name !== '') {
                                    return value.name;
                                } else {
                                    return '-';
                                }
                            }
                        },
                        {
                            title: 'ช่องทางชำระเงิน',
                            data: function(value) {
                                if (value.payment === 'QR-Code') {
                                    return 'คิวอาร์โค้ด';
                                } else {
                                    return 'กระเป๋าซิมปันสุข';
                                }
                            }
                        },
                        {
                            title: 'สถานะคำสั่งซื้อ',
                            data: function(value) {
                                if (value.status === 'cancel') {
                                    return '<span class="badge badge-danger mx-auto my-auto">หมดอายุ</span>';
                                } else if (value.status === 'paid') {
                                    return '<span class="badge badge-success mx-auto my-auto">ชำระเรียบร้อยแล้ว</span>';
                                } else if (value.status === 'pending') {
                                    if (value.payment === 'QR-Code' && value.qrcode === '') {
                                        return '<span class="badge badge-warning mx-auto my-auto">มีปัญหา</span>';
                                    } else {
                                        return '<span class="badge badge-secondary mx-auto my-auto">รอการชำระเงิน</span>';
                                    }
                                }
                                // if (value.invoice_number !== 0) {
                                //     return '<span class="badge badge-success mx-auto my-auto">ชำระเรียบร้อยแล้ว</span>';
                                // } else if (value.invoice_number === 0) {
                                //     if (value.status === "cancel") {
                                //         return '<span class="badge badge-danger mx-auto my-auto">หมดอายุ</span>';
                                //     } else {
                                //         if (
                                //             value.payment === "QR-Code" &&
                                //             value.qrcode === ""
                                //         ) {
                                //             return '<span class="badge badge-warning mx-auto my-auto">มีปัญหา</span>';
                                //         } else {
                                //             return '<span class="badge badge-secondary mx-auto my-auto">รอการชำระเงิน</span>';
                                //         }
                                //     }
                                // }
                            }
                        },
                        {
                            title: 'หมายเลข',
                            data: function(value) {
                                if (value.phonenumber !== null && value.phonenumber !== '') {
                                    return (
                                        value.phonenumber.slice(0, 3) +
                                        '-' +
                                        value.phonenumber.slice(3, 6) +
                                        '-' +
                                        value.phonenumber.slice(6)
                                    );
                                } else {
                                    return '-';
                                }
                            }
                        },
                        {
                            title: 'ราคา',
                            data: 'total'
                            // data: null,
                            // render: function (data, type, row) {
                            //     return (
                            //         <NumberFormat
                            //             value={parseFloat(data.total)}
                            //             displayType={"text"}
                            //             decimalScale={2}
                            //             fixedDecimalScale={true}
                            //             thousandSeparator={true}
                            //             suffix={" บาท"}
                            //         />
                            //     );
                            // },
                        }

                        // {
                        //     title: "เปอร์เซ็นต์",
                        //     data: "percent",
                        // },
                        // {
                        //     title: "คอมมิชชั่น",
                        //     data: "comm",
                        // },
                    ]
                });
            } else {
                $('#kt_datatable').DataTable({
                    data: prop.data,
                    lengthChange: false,
                    pageLength: -1,
                    responsive: true,
                    dom: 'Bfrtip',
                    buttons: [ 'excel' ],
                    order: [ [ 3, 'asc' ] ],
                    language: {
                        paginate: {
                            previous: 'ก่อนหน้า',
                            next: 'ถัดไป'
                        },
                        info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                        infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                        emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                        infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                        zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                    },
                    oLanguage: {
                        sSearch: 'คำสำคัญ'
                    },
                    columns: [
                        {
                            title: 'ดูรายละเอียด',
                            className: 'dt-body-center',
                            data: null,
                            render: function(data, type, row) {
                                if (data.status === 'pending' && data.payment === 'QR-Code' && data.qrcode !== '') {
                                    return `<button
                                                    type="button"
                                                    class="btn btn-icon btn-primary view-order-info"
                                                    data-qrcode=${data.qrcode}
                                                    data-order=${data.order_number}
                                                    data-price=${data.total}
                                                    data-payment=${data.payment}
                                                    data-status=${data.status}
                                                    >
                                                        <i class="fa fa-search"></i>
                                                </button>`;
                                } else {
                                    return '';
                                }
                            }
                        },
                        {
                            title: 'หมายเลขพัสดุ',
                            data: null,
                            render: function(data, type, row) {
                                if (data.tracking !== '') {
                                    return `<div
                                            class='tracking-number text-dark cursor-pointer'
                                            data-number='${data.tracking}'
                                        >
                                            <u class='text-horver-danger'>${data.tracking}</u>
                                        </div>`;
                                } else {
                                    return '-';
                                }
                            }
                        },
                        {
                            title: 'หมายเลขคำสั่งซื้อ',
                            data: 'order_number'
                        },
                        {
                            title: 'วันที่สั่งซื้อ',
                            data: 'order_date'
                        },
                        {
                            title: 'ชื่อสมาชิก',
                            data: function(value) {
                                if (value.name !== null && value.name !== '') {
                                    return value.name;
                                } else {
                                    return '-';
                                }
                            }
                        },
                        {
                            title: 'ช่องทางชำระเงิน',
                            data: function(value) {
                                if (value.payment === 'QR-Code') {
                                    return 'คิวอาร์โค้ด';
                                } else {
                                    return 'กระเป๋าซิมปันสุข';
                                }
                            }
                        },
                        {
                            title: 'สถานะคำสั่งซื้อ',
                            data: function(value) {
                                if (value.status === 'cancel') {
                                    return '<span class="badge badge-danger mx-auto my-auto">หมดอายุ</span>';
                                } else if (value.status === 'paid') {
                                    return '<span class="badge badge-success mx-auto my-auto">ชำระเรียบร้อยแล้ว</span>';
                                } else if (value.status === 'pending') {
                                    if (value.payment === 'QR-Code' && value.qrcode === '') {
                                        return '<span class="badge badge-warning mx-auto my-auto">มีปัญหา</span>';
                                    } else {
                                        return '<span class="badge badge-secondary mx-auto my-auto">รอการชำระเงิน</span>';
                                    }
                                }
                                // if (value.invoice_number !== 0) {
                                //     return '<span class="badge badge-success mx-auto my-auto">ชำระเรียบร้อยแล้ว</span>';
                                // } else if (value.invoice_number === 0) {
                                //     if (value.status === "cancel") {
                                //         return '<span class="badge badge-danger mx-auto my-auto">หมดอายุ</span>';
                                //     } else {
                                //         if (
                                //             value.payment === "QR-Code" &&
                                //             value.qrcode === ""
                                //         ) {
                                //             return '<span class="badge badge-warning mx-auto my-auto">มีปัญหา</span>';
                                //         } else {
                                //             return '<span class="badge badge-secondary mx-auto my-auto">รอการชำระเงิน</span>';
                                //         }
                                //     }
                                // }
                            }
                        },
                        {
                            title: 'หมายเลข',
                            data: function(value) {
                                if (value.phonenumber !== null && value.phonenumber !== '') {
                                    return (
                                        value.phonenumber.slice(0, 3) +
                                        '-' +
                                        value.phonenumber.slice(3, 6) +
                                        '-' +
                                        value.phonenumber.slice(6)
                                    );
                                } else {
                                    return '-';
                                }
                            }
                        },
                        {
                            title: 'ราคา',
                            data: 'total'
                            // data: null,
                            // render: function (data, type, row) {
                            //     return (
                            //         <NumberFormat
                            //             value={parseFloat(data.total)}
                            //             displayType={"text"}
                            //             decimalScale={2}
                            //             fixedDecimalScale={true}
                            //             thousandSeparator={true}
                            //             suffix={" บาท"}
                            //         />
                            //     );
                            // },
                        }

                        // {
                        //     title: "เปอร์เซ็นต์",
                        //     data: "percent",
                        // },
                        // {
                        //     title: "คอมมิชชั่น",
                        //     data: "comm",
                        // },
                    ]
                });
            }
        });
    }, []);

    useEffect(() => {
        $(document).ready(function() {
            $('.view-order-info').click(function() {
                if ($(this).attr('data-qrcode').includes('base64,')) {
                    prop.setQrcodeType('base64');
                }
                prop.setOrdernumber($(this).attr('data-order'));
                prop.setQrcode($(this).attr('data-qrcode'));
                prop.setPrice($(this).attr('data-price'));
                prop.setPayment($(this).attr('data-payment'));
                prop.setOrderstatus($(this).attr('data-status'));
                prop.handleShow();
            });

            $('.tracking-number').click(function() {
                var $temp = $('<input>');
                $('body').append($temp);
                $temp.val($(this).attr('data-number')).select();
                document.execCommand('copy');
                $temp.remove();
                window.open('https://th.kerryexpress.com/th/track/', '_blank');
            });
        });
    });

    return <table id='kt_datatable' className='table table-striped table-bordered' />;
}
