import React, { useEffect } from 'react';

import $ from 'jquery';

export function SubDealerTable(prop) {
    // console.log(prop);
    useEffect(() => {
        $(document).ready(function() {
            $('#kt_sub_datatable').DataTable({
                data: prop.data,
                lengthChange: false,
                pageLength: 10,
                responsive: true,
                dom: 'Bfrtip',
                buttons: [ 'excel' ],
                order: [ [ 2, 'desc' ] ],
                // paging: false,
                language: {
                    paginate: {
                        previous: 'ก่อนหน้า',
                        next: 'ถัดไป'
                    },
                    info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                    infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                    emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                    infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                    zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                },
                oLanguage: {
                    sSearch: 'คำสำคัญ'
                },
                columns: [
                    // {
                    //     title: "ดูรายละเอียด",
                    //     className: "dt-body-center",
                    //     data: null,
                    //     render: function (data, type, row) {
                    //         if (data.position === "") {
                    //             return `<button
                    //                         type="button"
                    //                         class="btn btn-icon btn-primary view-info"
                    //                         data-phonenumber=${data.phonenumber}
                    //                         >
                    //                             <i class="fa fa-search"></i>
                    //                     </button>`;
                    //         } else {
                    //             return `<button
                    //                         type="button"
                    //                         class="btn btn-icon btn-success view-dealer-list"
                    //                         data-userid=${data.id}
                    //                         data-phonenumber=${data.phonenumber}
                    //                         >
                    //                             <span class="svg-icon">
                    //                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    //                                     <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    //                                         <polygon points="0 0 24 0 24 24 0 24"/>
                    //                                         <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                    //                                         <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
                    //                                     </g>
                    //                                 </svg>
                    //                             </span>
                    //                     </button>`;
                    //         }
                    //     },
                    // },
                    {
                        title: 'ชื่อสมาชิก',
                        // data: "name",
                        data: function(value) {
                            if (value.name !== null && value.name !== '') {
                                return value.name;
                            } else {
                                return '-';
                            }
                        }
                    },
                    {
                        title: 'หมายเลข',
                        className: 'dt-body-center',
                        data: function(value) {
                            let pending = [ 'PENDING', 'VALID' ];
                            let inactive = [ 'INACTIVE1', 'INACTIVE2' ];
                            let expired = [ 'DEACTIVE', 'DISCONNECT' ];
                            if (value.sim_state === undefined) {
                                return value.phonenumber;
                            } else {
                                if (value.sim_state === 'NEW') {
                                    return `<span class="label label-new-status label-pill label-inline mx-auto my-auto">
                                            <span class="label label-dot label-success mr-3"></span>${value.phonenumber}
                                        </span>`;
                                } else if (pending.includes(value.sim_state)) {
                                    return `<span class="label label-active-status label-pill label-inline mx-auto my-auto">
                                            <span class="label label-dot label-warning mr-3"></span>${value.phonenumber}
                                        </span>`;
                                } else if (value.sim_state === 'ACTIVE') {
                                    return `<span class="label label-active-status label-pill label-inline mx-auto my-auto">${value.phonenumber}</span>`;
                                } else if (inactive.includes(value.sim_state)) {
                                    return `<span class="label label-inactive-status label-pill label-inline mx-auto my-auto">${value.phonenumber}</span>`;
                                } else if (expired.includes(value.sim_state)) {
                                    return `<span class="label label-expired-status label-pill label-inline mx-auto my-auto">${value.phonenumber}</span>`;
                                } else if (value.sim_state === 'EXPIRED') {
                                    return `<span class="label label-new-status label-pill label-inline mx-auto my-auto">
                                            <span class="label label-dot label-danger mr-3"></span>${value.phonenumber}
                                        </span>`;
                                } else {
                                    return '-';
                                }
                            }
                        }
                    },
                    {
                        title: 'ระดับผลงานปัจจุบัน',
                        className: 'dt-body-center',
                        data: function(value) {
                            if (value.position === 'E') {
                                return '<span class="label label-info label-pill label-inline mx-auto my-auto">Elite Dealer</span>';
                            } else if (value.position === 'SS') {
                                return '<span class="label label-warning label-pill label-inline mx-auto my-auto">Super Star Dealer</span>';
                            } else if (value.position === 'S') {
                                return '<span class="label label-success label-pill label-inline mx-auto my-auto">Star Dealer</span>';
                            } else if (value.position === 'B') {
                                return '<span class="label label-dark label-pill label-inline mx-auto my-auto">Basic Dealer</span>';
                            } else if (value.position === 'T') {
                                return '<span class="label label-cus-trainee label-pill label-inline mx-auto my-auto">Trainee Dealer</span>';
                            } else if (value.position === 'F') {
                                return '<span class="label label-cus-freedom label-pill label-inline mx-auto my-auto">Freedom</span>';
                            } else if (value.position === 'BC') {
                                return '<span class="label label-cus-blackcard label-pill label-inline mx-auto my-auto">Black Card</span>';
                            } else if (value.position === 'RC') {
                                return '<span class="label label-cus-redcard label-pill label-inline mx-auto my-auto">Red Card</span>';
                            } else if (value.position === 'GC') {
                                return '<span class="label label-cus-greencard label-pill label-inline mx-auto my-auto">Green Card</span>';
                            } else {
                                return '<span class="label label-primary label-pill label-inline mx-auto my-auto">รอการชำระเงิน</span>';
                            }
                        }
                    },
                    {
                        title: 'รอการชำระเงิน',
                        // data: "phonenumber",
                        data: function(value) {
                            return value.team.team[''];
                        }
                    },
                    {
                        title: 'GC',
                        // data: "phonenumber",
                        data: function(value) {
                            return value.team.team['GC'];
                        }
                    },
                    {
                        title: 'RC',
                        // data: "phonenumber",
                        data: function(value) {
                            return value.team.team['RC'];
                        }
                    },
                    {
                        title: 'BC',
                        // data: "phonenumber",
                        data: function(value) {
                            return value.team.team['BC'];
                        }
                    },
                    {
                        title: 'F',
                        // data: "phonenumber",
                        data: function(value) {
                            return value.team.team['F'];
                        }
                    },
                    {
                        title: 'T',
                        // data: "phonenumber",
                        data: function(value) {
                            return value.team.team['T'];
                        }
                    },
                    {
                        title: 'B',
                        // data: "phonenumber",
                        data: function(value) {
                            return value.team.team['B'];
                        }
                    },
                    {
                        title: 'S',
                        // data: "phonenumber",
                        data: function(value) {
                            return value.team.team['S'];
                        }
                    },
                    {
                        title: 'SS',
                        // data: "phonenumber",
                        data: function(value) {
                            return value.team.team['SS'];
                        }
                    },
                    {
                        title: 'E',
                        // data: "phonenumber",
                        data: function(value) {
                            return value.team.team['E'];
                        }
                    },
                    {
                        title: 'ยอดรวม',
                        data: function(value) {
                            let total = 0;
                            for (const thisprop in value.team.team) {
                                if (thisprop !== '' && thisprop !== 'SS_LINE') {
                                    total += value.team.team[thisprop];
                                }
                            }
                            return total;
                        }
                    }
                ]
            });
        });
    }, []);

    useEffect(() => {
        $(document).ready(function(e) {
            $('.view-info').click(function() {
                prop.getRegisDetail($(this).attr('data-phonenumber'));
                e.preventDefault();
            });

            // $(".view-dealer-list").click(function (e) {
            //     console.log("SubTable view-dealer-list["+$(this).attr("data-userid")+"]"+Date.now())
            //     let postparam = {
            //         userid: $(this).attr("data-userid"),
            //         phonenumber: $(this).attr("data-phonenumber"),
            //         max_level: "5",
            //     }
            //     prop.getDealerList(postparam);
            //     e.preventDefault();
            // });
        });
    }, []);

    return <table id='kt_sub_datatable' className='table table-striped table-bordered' />;
}
