import React, { useMemo, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import objectPath from 'object-path';
// import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from '../../../_helpers';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { SubscriberList } from '../../../../app/Setting';

import SVG from 'react-inlinesvg';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { UserProfileDropdown } from "../extras/dropdowns/UserProfileDropdown";
// import { QuickUserToggler } from "../extras/QuickUserToggler";
import config from '../../../../app/pages/environments/config';

export function HeaderMobile() {
    const { user } = useSelector((state) => state.auth);
    const uiService = useHtmlClassService();
    const location = useLocation();
    const [ name, setName ] = useState('');

    const layoutProps = useMemo(
        () => {
            return {
                asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
                headerMobileCssClasses: uiService.getClasses('header_mobile', true),
                headerMobileAttributes: uiService.getAttributes('header_mobile')
                // offcanvas:
                //   objectPath.get(uiService.config, "extras.usermobile.layout") === "offcanvas",
                // extrasUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
                // extrasUserDisplay: objectPath.get(
                //   uiService.config,
                //   "extras.user.display"
                // ),
            };
        },
        [ uiService ]
    );

    useEffect(() => {
        getInfo();
    }, []);

    const getInfo = () => {
        fetch(config.py_url + config.version + '/profile/info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: user.id
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    setName(result.profile.name);
                }
            })
            .catch((error) => {
                setName(user.fullname + ' ' + user.lastname);
            });
    };

    return (
        <div
            id='kt_header_mobile'
            className={`header-mobile row h-auto ${layoutProps.headerMobileCssClasses}`}
            {...layoutProps.headerMobileAttributes}
        >
            {/* {location.pathname !== "/menu" ? (
                    <> */}
            <div className='row mx-auto d-lg-none col-12'>
                <div className='mr-auto d-flex'>
                    <div className='c-avatar my-auto mr-3'>
                        <div className='symbol symbol-20 symbol-circle symbol-success overflow-hidden'>
                            <span className='symbol-label'>
                                <img src={user.pic} className='h-100 align-self-end' alt='user icon' />
                            </span>
                        </div>
                        {/* <img
                                alt="avatar"
                                className="logo-default max-h-40px"
                                src={user.pic}
                            /> */}
                    </div>
                    <div className='my-auto text-dark'>{name}</div>
                </div>
                <div className='ml-auto d-flex'>
                    <div className='c-avatar mr-3'>
                        <b className='my-auto font-weight-bolder font-size-lg'>
                            เบอร์ประจำตัว{!SubscriberList.includes(user.main_position) ? 'ดีลเลอร์' : 'ผู้ใชช้งาน'}
                        </b>
                        {/* <img
                                alt="wallet"
                                className="logo-default max-h-40px"
                                src={toAbsoluteUrl(
                                    "/media/logos/logo_sim_punsuk_final.png"
                                )}
                            /> */}
                    </div>
                    <div className='my-auto text-dark'>
                        <span>{user.main_phonenumber}</span>
                    </div>
                </div>
            </div>
            <div className='testimonial-group vw-100'>
                <div className='overflow-auto'>
                    <ul className='mx-auto d-lg-none c-header-nav flex-nowrap'>
                        <li className='px-10 c-header-nav-item'>
                            <NavLink className='text-dark text-center row c-header-nav-link w-90px' to='/sharemenu'>
                                <div className='col-12'>
                                    <span
                                        className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                            location,
                                            '/sharemenu',
                                            true
                                        )
                                            ? 'svg-icon-danger'
                                            : ''}`}
                                    >
                                        <SVG
                                            title='หุ้น'
                                            src={toAbsoluteUrl('/media/svg/icons/Shopping/Chart-line1.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`col-12 font-weight-bolder p-0 ${checkIsActive(
                                        location,
                                        '/sharemenu',
                                        true
                                    )
                                        ? 'text-danger'
                                        : ''}`}
                                >
                                    หุ้น
                                </div>
                            </NavLink>
                        </li>
                        <li className='px-10 c-header-nav-item'>
                            <NavLink className='text-dark text-center row c-header-nav-link w-90px' to='/shopmenu'>
                                <div className='col-12'>
                                    <span
                                        className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                            location,
                                            '/shopmenu'
                                        )
                                            ? 'svg-icon-danger'
                                            : ''}`}
                                    >
                                        <SVG
                                            title='ร้านค้า'
                                            src={toAbsoluteUrl('/media/svg/icons/Shopping/Cart1.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`col-12 font-weight-bolder p-0 ${checkIsActive(location, '/shopmenu')
                                        ? 'text-danger'
                                        : ''}`}
                                >
                                    ร้านค้า
                                </div>
                            </NavLink>
                        </li>
                        <li className='px-10 c-header-nav-item'>
                            <NavLink className='text-dark text-center row c-header-nav-link w-100px' to='/self/refill'>
                                <div className='col-12'>
                                    <span
                                        className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                            location,
                                            '/self/refill'
                                        )
                                            ? 'svg-icon-danger'
                                            : ''}`}
                                    >
                                        <SVG
                                            title='เติมเงิน/แพ็กเกจ'
                                            src={toAbsoluteUrl('/media/svg/icons/Shopping/Box2.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`col-12 font-weight-bolder p-0 ${checkIsActive(location, '/self/refill')
                                        ? 'text-danger'
                                        : ''}`}
                                >
                                    เติมเงิน/แพ็กเกจ
                                </div>
                            </NavLink>
                        </li>
                        {!SubscriberList.includes(user.main_position) && (
                            <li className='px-10 c-header-nav-item'>
                                <NavLink
                                    className='text-dark text-center row c-header-nav-link w-140px'
                                    to='/another/refill'
                                >
                                    <div className='col-12'>
                                        <span
                                            className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                                location,
                                                '/another/refill'
                                            )
                                                ? 'svg-icon-danger'
                                                : ''}`}
                                        >
                                            <SVG
                                                title='เติมเงิน/แพ็กเกจให้คนอื่น'
                                                src={toAbsoluteUrl('/media/svg/icons/Communication/Sending.svg')}
                                            />
                                        </span>
                                    </div>
                                    <div
                                        className={`col-12 font-weight-bolder p-0 ${checkIsActive(
                                            location,
                                            '/another/refill'
                                        )
                                            ? 'text-danger'
                                            : ''}`}
                                    >
                                        เติมเงิน/แพ็กเกจให้คนอื่น
                                    </div>
                                </NavLink>
                            </li>
                        )}
                        <li className='px-10 c-header-nav-item'>
                            <NavLink className='text-dark text-center row c-header-nav-link' to='/withdrawmenu'>
                                <div className='col-12'>
                                    <span
                                        className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                            location,
                                            '/withdrawmenu'
                                        )
                                            ? 'svg-icon-danger'
                                            : ''}`}
                                    >
                                        <SVG
                                            title='ถอนเงิน'
                                            src={toAbsoluteUrl('/media/svg/icons/Communication/Outgoing-box.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`col-12 font-weight-bolder p-0 ${checkIsActive(location, '/withdrawmenu')
                                        ? 'text-danger'
                                        : ''}`}
                                >
                                    ถอนเงิน
                                </div>
                            </NavLink>
                        </li>
                        {!SubscriberList.includes(user.main_position) && (
                            <li className='px-10 c-header-nav-item'>
                                <NavLink
                                    className='text-dark text-center row c-header-nav-link w-90px'
                                    to='/dealermap/setmarker'
                                >
                                    <div className='col-12'>
                                        <span
                                            className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                                location,
                                                '/dealermap/setmarker'
                                            )
                                                ? 'svg-icon-danger'
                                                : ''}`}
                                        >
                                            <SVG
                                                title='ปักหมุดดีลเลอร์'
                                                src={toAbsoluteUrl('/media/svg/icons/Map/Marker1.svg')}
                                            />
                                        </span>
                                    </div>
                                    <div
                                        className={`col-12 font-weight-bolder p-0 ${checkIsActive(
                                            location,
                                            '/dealermap/setmarker'
                                        )
                                            ? 'text-danger'
                                            : ''}`}
                                    >
                                        ปักหมุดดีลเลอร์
                                    </div>
                                </NavLink>
                            </li>
                        )}
                        {!SubscriberList.includes(user.main_position) && (
                            <li className='px-10 c-header-nav-item'>
                                <NavLink
                                    className='text-dark text-center row c-header-nav-link'
                                    to='/commission/report'
                                >
                                    <div className='col-12'>
                                        <span
                                            className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                                location,
                                                '/commission/report'
                                            )
                                                ? 'svg-icon-danger'
                                                : ''}`}
                                        >
                                            <SVG
                                                title='คอมมิชชั่น'
                                                src={toAbsoluteUrl('/media/svg/icons/Shopping/Wallet.svg')}
                                            />
                                        </span>
                                    </div>
                                    <div
                                        className={`col-12 font-weight-bolder p-0 ${checkIsActive(
                                            location,
                                            '/commission/report'
                                        )
                                            ? 'text-danger'
                                            : ''}`}
                                    >
                                        คอมมิชชั่น
                                    </div>
                                </NavLink>
                            </li>
                        )}
                        <li className='px-10 c-header-nav-item'>
                            <NavLink className='text-dark text-center row c-header-nav-link w-90px' to='/dealer/report'>
                                <div className='col-12'>
                                    <span
                                        className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                            location,
                                            '/dealer/report'
                                        )
                                            ? 'svg-icon-danger'
                                            : ''}`}
                                    >
                                        <SVG
                                            title={
                                                !SubscriberList.includes(user.main_position) ? (
                                                    'ผังดีลเลอร์'
                                                ) : (
                                                    'ผังผู้ใช้งาน'
                                                )
                                            }
                                            src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`col-12 font-weight-bolder p-0 ${checkIsActive(
                                        location,
                                        '/dealer/report'
                                    )
                                        ? 'text-danger'
                                        : ''}`}
                                >
                                    {!SubscriberList.includes(user.main_position) ? 'ผังดีลเลอร์' : 'ผังผู้ใช้งาน'}
                                </div>
                            </NavLink>
                        </li>
                        {user.role !== undefined && user.role.length > 0 && user.role.includes('dealer-service') ? (
                            <li className='px-5 c-header-nav-item'>
                                <NavLink
                                    className='text-dark text-center row c-header-nav-link w-120px'
                                    to='/dealer/support'
                                >
                                    <div className='col-12'>
                                        <span
                                            className={`svg-icon svg-icon-2x menu-icon ${checkIsActive(
                                                location,
                                                '/dealer/support'
                                            )
                                                ? 'svg-icon-danger'
                                                : ''}`}
                                        >
                                            <SVG
                                                title='ข้อมูลการใช้งาน'
                                                src={toAbsoluteUrl('/media/svg/icons/Devices/iPhone-X.svg')}
                                            />
                                        </span>
                                    </div>
                                    <div
                                        className={`col-12 font-weight-bolder p-0 ${checkIsActive(
                                            location,
                                            '/dealer/support'
                                        )
                                            ? 'text-danger'
                                            : ''}`}
                                    >
                                        ข้อมูลการใช้งาน
                                    </div>
                                </NavLink>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>
        </div>
    );
}
