import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import NumberFormat from 'react-number-format';

import Carousel from 'react-multi-carousel';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import config from '../../../environments/config';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 4
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

export function ShopProductHome(props) {
    const [ catloading, setCatLoading ] = useState(false);
    const [ regloading, setRegLoading ] = useState(false);
    const [ ranloading, setRanLoading ] = useState(false);
    const [ keyword, setKeyword ] = useState('');
    const [ catalogs, setCatalogs ] = useState({});
    const [ regproducts, setRegProducts ] = useState({});
    const [ ranproducts, setRanProducts ] = useState({});

    useEffect(() => {
        getCatalogs();
        getRecommend();
        getRandom();
    }, []);

    const btnSelectCatalog = (catid) => {
        props.setCatid(catid);
        props.setPage('main');
    };

    const btnSearch = () => {
        if (keyword !== '') {
            props.setKeyword(keyword);
            props.setPage('main');
        }
    };

    const getCatalogs = () => {
        setCatLoading(true);
        fetch(config.punsook_shop_url + 'guest/category/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                filters: {
                    parent_id: '0'
                }
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setCatLoading(false);
                // console.log(result);
                setCatalogs(result);
                // if (result.responsecode === 0) {
                //     let json = checkboxs;
                //     for (const index in result.catalogs) {
                //         json[index] = false;
                //     }
                //     setCheckboxs(json);
                // }
            })
            .catch((error) => {
                setCatLoading(false);
                // console.log(error);
            });
    };

    const getRecommend = () => {
        setRegLoading(true);
        fetch(config.punsook_shop_url + 'guest/product/recommend', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                limit: '4'
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setRegLoading(false);
                // console.log(result);
                setRegProducts(result);
                // if (result.responsecode === 0) {
                //     let json = checkboxs;
                //     for (const index in result.catalogs) {
                //         json[index] = false;
                //     }
                //     setCheckboxs(json);
                // }
            })
            .catch((error) => {
                setRegLoading(false);
                // console.log(error);
            });
    };

    const getRandom = () => {
        setRanLoading(true);
        fetch(config.punsook_shop_url + 'guest/product/random', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                limit: '8'
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setRanLoading(false);
                // console.log(result);
                setRanProducts(result);
                // if (result.responsecode === 0) {
                //     let json = checkboxs;
                //     for (const index in result.catalogs) {
                //         json[index] = false;
                //     }
                //     setCheckboxs(json);
                // }
            })
            .catch((error) => {
                setRanLoading(false);
                // console.log(error);
            });
    };

    const addCart = (pid, amount) => {
        props.setMainLoading(true);
        fetch(config.punsook_shop_url + 'member/cart/add', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: props.user.shop_token,
                product_id: pid.toString(),
                quantity: amount
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setMainLoading(false);
                props.setShowToast(true);
                // console.log(result);
                if (result.responsecode === 0) {
                    props.toast.success('เพิ่มสินค้าลงตะกร้าเรียบร้อยแล้ว', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                } else {
                    props.toast.warn(`${result.responsecode}! ไม่สามารถเพิ่มสินค้าลงตะกร้าได้ กรุณาลองใหม่อีกครั้ง`, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
                // setProducts(result);
            })
            .catch((error) => {
                props.setMainLoading(false);
                props.setShowToast(true);
                props.toast.error('ไม่สามารถเพิ่มสินค้าลงตะกร้าได้ กรุณาลองใหม่อีกครั้ง', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            });
    };
    return (
        <div className='row'>
            <div className='col-12 row m-0 justify-content-between'>
                <label className='font-size-h3 font-weight-bolder text-dark my-auto d-none d-md-block'>หมวดหมู่</label>
                <div className='input-group col-12 col-md-7 p-0 my-auto'>
                    <input
                        type='text'
                        name='keyword'
                        className='form-control'
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                btnSearch();
                            }
                        }}
                    />
                    <div className='input-group-append'>
                        <button type='button' className='btn btn-primary' onClick={btnSearch}>
                            <i className='fa fa-search' />
                        </button>
                    </div>
                </div>
            </div>
            {catloading ? (
                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                    <div className='text-center col-12 p-0'>
                        <i className='text-dark-75 fas fa-spinner fa-pulse fa-5x' />
                        <h1 className='col mt-5'>กรุณารอสักครู่</h1>
                    </div>
                </div>
            ) : (
                <div className='col-12'>
                    {Object.keys(catalogs).length <= 0 ? (
                        <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                            <div className='text-center col-12 p-0'>
                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {catalogs.responsecode === 101 ? (
                                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h1 className='col mt-5'>ไม่มีหมวดหมู่ในตอนนี้ กรุณาลองใหม่อีกครั้งภายหลัง</h1>
                                    </div>
                                </div>
                            ) : catalogs.responsecode !== 0 ? (
                                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                    </div>
                                </div>
                            ) : (
                                <div className='product-detail'>
                                    <label className='font-size-h3 font-weight-bolder text-dark mt-7 d-block d-md-none'>
                                        หมวดหมู่
                                    </label>
                                    <div className='thumbnail_images slider'>
                                        <Carousel
                                            responsive={responsive}
                                            infinite={true}
                                            containerClass={`mt-5`}
                                            // containerClass={`${Object.keys(details.product.gallery)
                                            //     .length > 0 &&
                                            // details.product.gallery.images.length + 1 >= 4
                                            //     ? 'justify-content-start'
                                            //     : 'justify-content-center'}`}
                                        >
                                            {catalogs.catalogs.map((object, index) => {
                                                return (
                                                    <div
                                                        className='row m-0 cursor-pointer'
                                                        onClick={() => btnSelectCatalog(object.id)}
                                                        key={index}
                                                    >
                                                        <img
                                                            // onClick={() => setMainimage(object)}
                                                            src={`data:image/png;base64,${object.gallery.cover_image}`}
                                                            className='w-100'
                                                        />
                                                        <p className='col-12 font-size-h3 font-weight-bolder text-dark text-center mb-0 mt-3'>
                                                            {object.title}
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </Carousel>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            <div className='col-12 row m-0 mt-7 justify-content-between'>
                {/* <label className='font-size-h3 font-weight-bolder text-dark my-auto'>สินค้าแนะนำ</label> */}
            </div>
            {regloading ? (
                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                    <div className='text-center col-12 p-0'>
                        <i className='text-dark-75 fas fa-spinner fa-pulse fa-5x' />
                        <h1 className='col mt-5'>กรุณารอสักครู่</h1>
                    </div>
                </div>
            ) : (
                <div className='col-12'>
                    {Object.keys(regproducts).length <= 0 ? (
                        <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                            <div className='text-center col-12 p-0'>
                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {regproducts.responsecode === 102 ? (
                                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h1 className='col mt-5'>ไม่พบสินค้า กรุณาลองใหม่อีกครั้งภายหลัง</h1>
                                    </div>
                                </div>
                            ) : regproducts.responsecode !== 0 ? (
                                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                    </div>
                                </div>
                            ) : (
                                <div className='row'>
                                    {regproducts.products.map((object, index) => {
                                        return (
                                            <div className='col-md-3 col-xxl-3 col-lg-3 col-6' key={index}>
                                                <div className='card card-custom card-shadowless bg-light-danger'>
                                                    <div className='card-body p-0'>
                                                        <div className='overlay'>
                                                            <div className='overlay-wrapper rounded bg-light text-center'>
                                                                <img
                                                                    src={
                                                                        Object.keys(object.gallery).length > 0 ? object
                                                                            .gallery.cover_image !== '' ? (
                                                                            `data:image/png;base64,${object.gallery
                                                                                .cover_image}`
                                                                        ) : (
                                                                            toAbsoluteUrl(
                                                                                `/media/products/${index + 1}.png`
                                                                            )
                                                                        ) : (
                                                                            toAbsoluteUrl(
                                                                                `/media/products/${index + 1}.png`
                                                                            )
                                                                        )
                                                                    }
                                                                    alt={`product-${object.code}`}
                                                                    className='mw-100 w-100'
                                                                />
                                                            </div>
                                                            <div className='overlay-layer'>
                                                                <NavLink
                                                                    to={`/shop/productdetail/${object.code}`}
                                                                    target={'_blank'}
                                                                    className='btn font-weight-bolder btn-sm btn-primary mr-2'
                                                                >
                                                                    รายละเอียดสินค้า
                                                                </NavLink>
                                                                {props.user.shop_account !== undefined &&
                                                                props.user.shop_account !== null &&
                                                                props.user.shop_account !== '' && (
                                                                    <button
                                                                        type='button'
                                                                        className='btn font-weight-bolder btn-sm btn-light-primary'
                                                                        onClick={() => addCart(object.id, '1')}
                                                                    >
                                                                        เพิ่มไปยังตะกร้า
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column'>
                                                            <OverlayTrigger
                                                                placement={'bottom'}
                                                                overlay={<Tooltip>{object.name}</Tooltip>}
                                                            >
                                                                <div className='font-size-h5 font-weight-bolder overflow-hidden text-nowrap text-orange mb-1 px-2'>
                                                                    {object.name}
                                                                </div>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement={'bottom'}
                                                                overlay={<Tooltip>{object.description}</Tooltip>}
                                                            >
                                                                <span className='font-size-lg overflow-hidden text-nowrap px-4'>
                                                                    {object.description}
                                                                </span>
                                                            </OverlayTrigger>
                                                            <div className='font-size-h6 text-muted font-weight-bolder'>
                                                                <NumberFormat
                                                                    value={parseFloat(object.price)}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={true}
                                                                    suffix={' บาท'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            {ranloading ? (
                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                    <div className='text-center col-12 p-0'>
                        <i className='text-dark-75 fas fa-spinner fa-pulse fa-5x' />
                        <h1 className='col mt-5'>กรุณารอสักครู่</h1>
                    </div>
                </div>
            ) : (
                <div className='col-12'>
                    {Object.keys(ranproducts).length <= 0 ? (
                        <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                            <div className='text-center col-12 p-0'>
                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {ranproducts.responsecode === 102 ? (
                                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h1 className='col mt-5'>ไม่พบสินค้า กรุณาลองใหม่อีกครั้งภายหลัง</h1>
                                    </div>
                                </div>
                            ) : ranproducts.responsecode !== 0 ? (
                                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                    </div>
                                </div>
                            ) : (
                                <div className='row'>
                                    {ranproducts.products.map((object, index) => {
                                        return (
                                            <div className='col-md-3 col-xxl-3 col-lg-3 col-6' key={index}>
                                                <div className='card card-custom card-shadowless'>
                                                    <div className='card-body p-0'>
                                                        <div className='overlay'>
                                                            <div className='overlay-wrapper rounded bg-light text-center'>
                                                                <img
                                                                    src={
                                                                        Object.keys(object.gallery).length > 0 ? object
                                                                            .gallery.cover_image !== '' ? (
                                                                            `data:image/png;base64,${object.gallery
                                                                                .cover_image}`
                                                                        ) : (
                                                                            toAbsoluteUrl(
                                                                                `/media/products/${index + 1}.png`
                                                                            )
                                                                        ) : (
                                                                            toAbsoluteUrl(
                                                                                `/media/products/${index + 1}.png`
                                                                            )
                                                                        )
                                                                    }
                                                                    alt={`product-${object.code}`}
                                                                    className='mw-100 w-100'
                                                                />
                                                            </div>
                                                            <div className='overlay-layer'>
                                                                <NavLink
                                                                    to={`/shop/productdetail/${object.code}`}
                                                                    target={'_blank'}
                                                                    className='btn font-weight-bolder btn-sm btn-primary mr-2'
                                                                >
                                                                    รายละเอียดสินค้า
                                                                </NavLink>
                                                                {props.user.shop_account !== undefined &&
                                                                props.user.shop_account !== null &&
                                                                props.user.shop_account !== '' && (
                                                                    <button
                                                                        type='button'
                                                                        className='btn font-weight-bolder btn-sm btn-light-primary'
                                                                        onClick={() => addCart(object.id, '1')}
                                                                    >
                                                                        เพิ่มไปยังตะกร้า
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column'>
                                                            <OverlayTrigger
                                                                placement={'bottom'}
                                                                overlay={<Tooltip>{object.name}</Tooltip>}
                                                            >
                                                                <div className='font-size-h5 font-weight-bolder overflow-hidden text-nowrap text-dark-75 mb-1 px-2'>
                                                                    {object.name}
                                                                </div>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement={'bottom'}
                                                                overlay={<Tooltip>{object.description}</Tooltip>}
                                                            >
                                                                <span className='font-size-lg overflow-hidden text-nowrap px-4'>
                                                                    {object.description}
                                                                </span>
                                                            </OverlayTrigger>
                                                            <div className='font-size-h6 text-muted font-weight-bolder'>
                                                                <NumberFormat
                                                                    value={parseFloat(object.price)}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={true}
                                                                    suffix={' บาท'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
