import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { NavLink, useHistory } from 'react-router-dom';

import NumberFormat from 'react-number-format';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { ShopCouponOtpcode, ShopCouponPhonenumber } from './components';

import config from '../../environments/config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function ShopCouponMain() {
    const history = useHistory();

    const { user } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('main');
    const [ actionpanel, setActionpanel ] = useState(false);
    const [ coupons, setCoupons ] = useState({});
    const [ maincoupons, setMainCoupons ] = useState({});
    const [ couponcode, setCouponcode ] = useState('');
    const [ walletnumber, setWalletnumber ] = useState('');
    const [ otpcode, setOtpcode ] = useState('');
    const [ refcode, setRefcode ] = useState('');
    const [ expiretime, setExpiretime ] = useState('');
    const [ disablebtn, setDisableBtn ] = useState(false);
    const [ disableotp, setDisableOtp ] = useState(false);

    const Completionist = () => {
        return (
            <div className='row justify-content-center col-12 p-0 m-0'>
                <button className='btn btn-primary rounded-lg' onClick={(e) => getOtpcode(true)} disabled={disableotp}>
                    ขอ OTP ใหม่
                </button>
            </div>
        );
    };

    const renderer = ({ minutes, seconds, completed }) => {
        // console.log(minutes);
        // console.log(seconds);
        // console.log(completed);
        if (completed) {
            // Render a complete state
            // ("2020-01-02 11:00:00");
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className='row col-12 p-0 m-0 text-center'>
                    <p className='col-12 m-0'>กรุณาลองใหม่ในอีก</p>
                    <span className='col-12'>
                        {(minutes < 10 ? '0' : '') + minutes}:
                        {(seconds < 10 ? '0' : '') + seconds}
                    </span>
                </div>
            );
        }
    };

    const handleOtp = (code) => {
        console.log(code);
        setOtpcode(code);
        if (code.length >= 6) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    };

    useEffect(() => {
        if (user.shop_account === undefined || user.shop_account === null || user.shop_account === '') {
            history.push('/shop/product?page=login');
        }
        getCoupons();
    }, []);

    const btnCreate = () => {
        getMainCoupons();
    };

    const btnBack = () => {
        setPage('main');
    };

    const getCoupons = (setpage = '') => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/coupon/find/by-user', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user['shop_token']
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result)
                setCoupons(result);
                if (setpage !== '') {
                    setPage(setpage);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getMainCoupons = () => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/coupon/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user['shop_token']
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result)
                setMainCoupons(result);
                setPage('create');
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getOtpcode = (retry = false) => {
        setOtpcode('');
        setLoading(true);
        setDisableOtp(true);
        fetch(config.punsook_shop_url + 'punsook/otp-generate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: walletnumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setDisableOtp(false);
                if (result.responsecode === 0) {
                    setRefcode(result.refcode);
                    // let d = new Date();
                    // // d.setSeconds(d.getSeconds() + 10);
                    // d.setSeconds(d.getSeconds() + 5);
                    // setExpiretime(d);
                    setExpiretime(result.timeout);
                    setDisableBtn(true);
                    if (!retry) {
                        setPage('otpcode');
                    }
                } else if (result.responsecode === 100) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `หมายเลข ${walletnumber} ไม่ใช่เบอร์ในโครงการซิมปันสุข ไม่สามารถเข้าร่วมกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขได้`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                setDisableOtp(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const btnCreateCoupon = (couponcode) => {
        setCouponcode(couponcode);
        setPage('phonenumber');
    };

    const btnNext = () => {
        if (page === 'phonenumber') {
            getOtpcode();
        } else if (page === 'otpcode') {
            createCoupon();
        }
    };

    const createCoupon = () => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/coupon/exchange', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user['shop_token'],
                payment_channel: 'punsook-wallet',
                coupon_code: couponcode,
                phonenumber: walletnumber,
                otp: otpcode,
                ref: refcode
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `รับคูปองเรียบร้อยแล้ว`,
                        icon: 'success',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            getCoupons('main');
                        }
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };
    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='coupon-page row'>
                    {(page === 'main' || page === 'create') && (
                        <div className='col-md-4 custom-aside'>
                            <div className={`app-action-panel${actionpanel ? ' open' : ''}`} id='contacts-action-panel'>
                                <div className='action-panel-toggle' onClick={() => setActionpanel(!actionpanel)}>
                                    <i className='fa fa-chevron-right' />
                                    <i className='fa fa-chevron-left' />
                                </div>
                                <div
                                    id='categories-list'
                                    className='app-actions-list scrollable-container ps-container ps-theme-default p-5 bg-white'
                                >
                                    {page === 'main' ? (
                                        <button type='button' className='btn btn-primary btn-block' onClick={btnCreate}>
                                            รับสิทธิพิเศษ
                                        </button>
                                    ) : (
                                        <button type='button' className='btn btn-secondary btn-block' onClick={btnBack}>
                                            ย้อนกลับ
                                        </button>
                                    )}
                                </div>
                                <div className='m-h-md' />
                            </div>
                        </div>
                    )}
                    {page === 'main' ? (
                        <div className='col-md-8'>
                            {Object.keys(coupons).length <= 0 ? (
                                <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                    <div className='col-12 text-center'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {coupons.responsecode !== 0 ? (
                                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                            <div className='col-12 text-center'>
                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                <h3 className='col mt-5'>ไม่พบคูปองที่คุณมี</h3>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {coupons.info.map((object, index) => {
                                                return (
                                                    <div className='card card-custom gutter-b' key={index}>
                                                        <div className='card-body'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='promoblock--amountoff'>
                                                                        ส่วนลด{' '}
                                                                        <NumberFormat
                                                                            value={parseFloat(object.discount)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={
                                                                                object.discount_type === 'value' ? (
                                                                                    true
                                                                                ) : (
                                                                                    false
                                                                                )
                                                                            }
                                                                            thousandSeparator={true}
                                                                            suffix={
                                                                                object.discount_type === 'value' ? (
                                                                                    ' บาท'
                                                                                ) : (
                                                                                    '%'
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 row m-0'>
                                                                    <div className='ml-auto'>
                                                                        <NavLink
                                                                            to='/shop/cart'
                                                                            className='btn-cus btn--code corner--animate'
                                                                        >
                                                                            ใช้งานส่วนลด
                                                                            <div className='btn--code--corner' />
                                                                        </NavLink>
                                                                    </div>
                                                                </div>
                                                                <div className='fs12 gr5'>
                                                                    <span className='vam'>
                                                                        ออกเมื่อวันที่ {object.user_coupon_create_date}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : page === 'create' ? (
                        <div className='col-md-8'>
                            {Object.keys(maincoupons).length <= 0 ? (
                                <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                    <div className='col-12 text-center'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {maincoupons.responsecode !== 0 ? (
                                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                            <div className='col-12 text-center'>
                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                <h3 className='col mt-5'>ไม่พบคูปองที่มีในระบบ</h3>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {maincoupons.info.map((object, index) => {
                                                return (
                                                    <div className='card card-custom gutter-b' key={index}>
                                                        <div className='card-body'>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='promoblock--amountoff'>
                                                                        ส่วนลด{' '}
                                                                        <NumberFormat
                                                                            value={parseFloat(object.discount)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={
                                                                                object.discount_type === 'value' ? (
                                                                                    true
                                                                                ) : (
                                                                                    false
                                                                                )
                                                                            }
                                                                            thousandSeparator={true}
                                                                            suffix={
                                                                                object.discount_type === 'value' ? (
                                                                                    ' บาท'
                                                                                ) : (
                                                                                    '%'
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 row m-0'>
                                                                    <p className='font-size-h6'>
                                                                        {object.coupon_description}
                                                                    </p>
                                                                    <div className='ml-auto'>
                                                                        <button
                                                                            type='button'
                                                                            className='btn-cus btn--code corner--animate'
                                                                            onClick={() =>
                                                                                btnCreateCoupon(object.coupon_code)}
                                                                        >
                                                                            รับส่วนลด
                                                                            <div className='btn--code--corner' />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 fs12 gr5'>
                                                                    <span className='vam'>
                                                                        <i>ออกเมื่อวันที่ {object.create_date}</i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : page === 'phonenumber' ? (
                        <ShopCouponPhonenumber
                            walletnumber={walletnumber}
                            setWalletnumber={setWalletnumber}
                            btnNext={btnNext}
                        />
                    ) : page === 'otpcode' ? (
                        <ShopCouponOtpcode
                            Completionist={Completionist}
                            renderer={renderer}
                            otpcode={otpcode}
                            refcode={refcode}
                            expiretime={expiretime}
                            handleOtp={handleOtp}
                            disablebtn={disablebtn}
                            btnNext={btnNext}
                        />
                    ) : null}
                </div>
            )}
        </div>
    );
}
