import React, { useEffect } from 'react';

import { QRCode } from 'react-qrcode-logo';

import Moment from 'moment';

import html2canvas from 'html2canvas';

import $ from 'jquery';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export function ShopInsuranceResult(props) {
    useEffect(() => {
        if (props.paymentchannal === 'qrcode') {
            $(document).ready(function() {
                $('#react-qrcode-logo').css('border', '2px solid');
            });
        }
    }, []);
    const handleDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    return (
        <div className='row justify-content-center align-items-center m-0 h-500px bg-white col-12 p-0 rounded-lg mb-10 mb-md-0'>
            {props.paymentchannal === '1' ? (
                <div className='col-lg-10 col-12 p-0'>
                    {props.response.responsecode === 0 ? (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <QRCode
                                    className='border'
                                    value={props.response.qr}
                                    size={256}
                                    eyeRadius={10}
                                    logoImage={toAbsoluteUrl('/media/logos/new-logo.png')}
                                    logoWidth={100}
                                />
                                <div className='mt-7'>
                                    <button
                                        type='button'
                                        className='btn btn-primary rounded-lg'
                                        onClick={handleDownload}
                                    >
                                        บันทึกคิวอาร์โค้ด
                                    </button>
                                </div>
                                <h2 className='mt-7'>รอการชำระเงิน</h2>
                                <p className='m-0'>
                                    กรุณานำคิวอาร์โค้ดนี้ไปชำระเงินให้เรียบร้อยเพื่อดำเนินการยืนยันในการสั่งซื้อกรมธรรม์
                                </p>
                                <p className='m-0'>
                                    คิวอาร์โค้ดนี้มีอายุ 1 วัน หากเกิน 1
                                    วันกรุณาติดต่อเจ้าหน้าที่เพื่อดำเนินการให้เรียบร้อย
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <img
                                    className='max-h-200px'
                                    alt='qr-failed'
                                    src={toAbsoluteUrl('/media/error/qr-error.png')}
                                />
                                <h2 className='mt-7'>ไม่สามารถสร้างคิวอาร์โค้ดได้</h2>
                                <p className='m-0'>กรุณาลองใหม่อีกครั้ง</p>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className='col-lg-8 col-12 p-0'>
                    {props.response.responsecode === 0 ? (
                        <div className='col-12 p-0 appstore'>
                            <div className='text-center p-10'>
                                <img
                                    className='max-h-200px'
                                    alt='success-icon'
                                    src={toAbsoluteUrl('/media/icons/paid.png')}
                                />
                                <h2 className='mt-7'>สั่งซื้อกรมธรรม์เรียบร้อย</h2>
                                <p className='m-0'>
                                    ดำเนินการสั่งซื้อกรมธรรม์เรียบร้อยแล้ว ท่านสามารถตรวจสอบข้อมูลเพิ่มเติมได้ที่
                                </p>
                            </div>
                            <div id='newsspec-19854-app' className='news-app-promo'>
                                {/* <div className='news-app-promo-text'>
                                    <div className='news-app-promo-text__tagline'>
                                        The best way to get news on the go
                                    </div>
                                    <br />
                                    <div className='news-app-promo-text__download'>Download the NAYN.CO App.</div>
                                </div> */}
                                <div className='row'>
                                    <div className='col-lg-6 col-12 text-md-right text-center my-auto'>
                                        <img
                                            className='m-0'
                                            src={toAbsoluteUrl('/media/logos/tpa-app-logo.png')}
                                            width={106}
                                            height={106}
                                        />
                                    </div>
                                    <div className='col-lg-6 col-12 text-md-left text-center my-auto'>
                                        <a
                                            className='news-app-promo-subsection--link news-app-promo-subsection--playstore'
                                            href='https://play.google.com/store/apps/details?id=th.co.digix.tpacare'
                                            target='_blank'
                                        >
                                            <img
                                                className='news-app-promo__play-store mx-md-0 mx-auto mt-5 mt-md-0'
                                                src='//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/google_play_store.svg'
                                                width={161}
                                                height='auto'
                                                border={0}
                                            />
                                        </a>
                                        <a
                                            className='news-app-promo-subsection--link news-app-promo-subsection--appstore'
                                            href='https://apps.apple.com/th/app/tpa-care/id1456356305?l=th'
                                            target='_blank'
                                        >
                                            <img
                                                className='news-app-promo__app-store mx-md-0 mx-auto'
                                                src='//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/ios_app_store.svg'
                                                width={161}
                                                height='auto'
                                                border={0}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <img
                                    className='max-h-200px'
                                    alt='failed-icon'
                                    src={toAbsoluteUrl('/media/icons/warning.png')}
                                />
                                <h2 className='mt-7'>สั่งซื้อกรมธรรม์ไม่สำเร็จ</h2>
                                <p className='m-0'>ไม่สามารถสั่งซื้อกรมธรรม์ได้</p>
                                <p>
                                    กรุณาติดต่อเจ้าหน้าที่ ที่<a
                                        className='btn btn-link'
                                        href={`https://line.me/ti/p/~@dealersim`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        เจ้าหน้าที่
                                    </a>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {/* <div className='col-12 text-center p-3'>
                <button type='button' className='btn btn-primary text-white rounded-lg'>
                    กลับไปหน้าแรก
                </button>
            </div> */}
        </div>
    );
}
