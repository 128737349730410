import React, { useEffect } from 'react';

import { QRCode } from 'react-qrcode-logo';

import Moment from 'moment';

import html2canvas from 'html2canvas';

import $ from 'jquery';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export function ShopCartResult(props) {
    useEffect(() => {
        if (props.payment === 'qrcode') {
            $(document).ready(function() {
                $('#react-qrcode-logo').css('border', '2px solid');
            });
        }
    }, []);
    const handleDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    return (
        <div className='row justify-content-center align-items-center m-0 h-500px bg-white'>
            {props.payment === 'qrcode' ? (
                <div className='col-lg-10 col-12 p-0'>
                    {props.response.responsecode === 0 ? (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <QRCode
                                    className='border'
                                    value={props.response.qr}
                                    size={256}
                                    eyeRadius={10}
                                    logoImage={toAbsoluteUrl('/media/logos/new-logo.png')}
                                    logoWidth={100}
                                />
                                <div className='mt-7'>
                                    <button
                                        type='button'
                                        className='btn btn-primary rounded-lg'
                                        onClick={handleDownload}
                                    >
                                        บันทึกคิวอาร์โค้ด
                                    </button>
                                </div>
                                <h2 className='mt-7'>รอการชำระเงิน</h2>
                                <p className='m-0'>
                                    กรุณานำคิวอาร์โค้ดนี้ไปชำระเงินให้เรียบร้อยเพื่อดำเนินการยืนยันในการสั่งซื้อสินค้า
                                </p>
                                <p className='m-0'>
                                    คิวอาร์โค้ดนี้มีอายุ 1 วัน หากเกิน 1
                                    วันกรุณาติดต่อเจ้าหน้าที่เพื่อดำเนินการให้เรียบร้อย
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <img
                                    className='max-h-200px'
                                    alt='qr-failed'
                                    src={toAbsoluteUrl('/media/error/qr-error.png')}
                                />
                                <h2 className='mt-7'>ไม่สามารถสร้างคิวอาร์โค้ดได้</h2>
                                <p className='m-0'>กรุณาลองใหม่อีกครั้ง</p>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className='col-lg-8 col-12 p-0'>
                    {props.response.responsecode === 0 ? (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <img
                                    className='max-h-200px'
                                    alt='success-icon'
                                    src={toAbsoluteUrl('/media/icons/paid.png')}
                                />
                                <h2 className='mt-7'>สั่งซื้อสินค้าเรียบร้อย</h2>
                                <p className='m-0'>
                                    ดำเนินการสั่งซื้อเรียบร้อยแล้ว ท่านจะได้รับสินค้าภายใน 2 - 3 วันทำการ
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className='col-12 p-0'>
                            <div className='text-center'>
                                <img
                                    className='max-h-200px'
                                    alt='failed-icon'
                                    src={toAbsoluteUrl('/media/icons/warning.png')}
                                />
                                <h2 className='mt-7'>สั่งซื้อสินค้าไม่สำเร็จ</h2>
                                <p className='m-0'>ไม่สามารถสั่งซื้อสินค้าได้</p>
                                <p>
                                    กรุณาติดต่อเจ้าหน้าที่ ที่<a
                                        className='btn btn-link'
                                        href={`https://line.me/ti/p/~@dealersim`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        เจ้าหน้าที่
                                    </a>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {/* <div className='col-12 text-center p-3'>
                <button type='button' className='btn btn-primary text-white rounded-lg'>
                    กลับไปหน้าแรก
                </button>
            </div> */}
        </div>
    );
}
