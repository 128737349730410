import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import { useSelector } from 'react-redux';
import config from '../../../../../app/pages/environments/config';
import { SubscriberList, UpgradeList } from '../../../../../app/Setting';

const reg_url = 'https://reg.simk4.com/';
const training_url = 'https://training.simk4.com/vsp?id=';

export function AsideMenuList({ layoutProps }) {
    const history = useHistory();
    const location = useLocation();
    const [ numberlist, setNumberList ] = useState([]);
    const [ main, setMain ] = useState('');
    const { user } = useSelector((state) => state.auth);

    useEffect(
        () => {
            getNumberList(user.id);
        },
        [ location ]
    );

    const getMenuItemActive = (url, hasSubmenu = false, fix = false) => {
        return checkIsActive(location, url, fix) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : '';
    };

    const getNumberList = (memberid) => {
        fetch(config.py_url + config.version + '/profile/phonenumber_list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    setMain(result.main_number);
                    setNumberList(result.numbers);
                }
            })
            .catch((error) => {});
    };

    const logoutClick = () => {
        history.push('/logout');
    };

    return (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/dashboard'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ภาพรวม' src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                    </span>
                    <span className='menu-text'>ภาพรวม</span>
                </NavLink>
            </li>
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/shop', true)}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
            >
                <NavLink className='menu-link menu-toggle' to='/shop'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ร้านค้า' src={toAbsoluteUrl('/media/svg/icons/Shopping/Cart1.svg')} />
                    </span>
                    <span className='menu-text'>ร้านค้า</span>
                    <i className='menu-arrow' />
                </NavLink>
                <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                        <li className='menu-item menu-item-parent' aria-haspopup='true'>
                            <span className='menu-link'>
                                <span className='menu-text'>ร้านค้า</span>
                            </span>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/shop/product')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/shop/product'>
                                <span className='svg-icon menu-icon'>
                                    <SVG title='สินค้า' src={toAbsoluteUrl('/media/svg/icons/Shopping/Box3.svg')} />
                                </span>
                                <span className='menu-text'>สินค้า</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/shop/cart')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/shop/cart'>
                                <span className='svg-icon menu-icon'>
                                    <SVG title='ตะกร้า' src={toAbsoluteUrl('/media/svg/icons/Shopping/Cart2.svg')} />
                                </span>
                                <span className='menu-text'>ตะกร้า</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/shop/history')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/shop/history'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='ประวัติการซื้อ'
                                        src={toAbsoluteUrl('/media/svg/icons/Code/Time-schedule.svg')}
                                    />
                                </span>
                                <span className='menu-text'>ประวัติการซื้อ</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/shop/coupon')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/shop/coupon'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='คูปองส่วนลด'
                                        src={toAbsoluteUrl('/media/svg/icons/Shopping/Ticket.svg')}
                                    />
                                </span>
                                <span className='menu-text'>คูปองส่วนลด</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/shop/numberbox')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/shop/numberbox'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='รายการร้านค้าเสมือน'
                                        src={toAbsoluteUrl('/media/svg/icons/Home/Building.svg')}
                                    />
                                </span>
                                <span className='menu-text'>รายการร้านค้าเสมือน</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/shop/insurance')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/shop/insurance'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='ประกันภัย'
                                        src={toAbsoluteUrl('/media/svg/icons/Communication/Shield-user.svg')}
                                    />
                                </span>
                                <span className='menu-text'>ประกันภัย</span>
                            </NavLink>
                        </li>
                        <li
                            className={`menu-item ${getMenuItemActive('/shop/insurancehistory', false, true)}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/shop/insurancehistory'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='ประวัติการสั่งซื้อกรมธรรม์'
                                        src={toAbsoluteUrl('/media/svg/icons/Code/Time-schedule.svg')}
                                    />
                                </span>
                                <span className='menu-text'>ประวัติการสั่งซื้อกรมธรรม์</span>
                            </NavLink>
                        </li>
                        {/* <li
                            className={`menu-item ${getMenuItemActive('/shop/reservation', false, true)}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/shop/reservation'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='จองแท็บเล็ต'
                                        src={toAbsoluteUrl('/media/svg/icons/Devices/Tablet.svg')}
                                    />
                                </span>
                                <span className='menu-text'>จองแท็บเล็ต</span>
                            </NavLink>
                        </li> */}
                        <li className={`menu-item ${getMenuItemActive('/shop/simkit')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/shop/simkit'>
                                <span className='svg-icon menu-icon'>
                                    <SVG title='sim kit' src={toAbsoluteUrl('/media/svg/icons/Home/Box.svg')} />
                                </span>
                                <span className='menu-text'>Sim Kit</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li>
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/share', true)}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
            >
                <NavLink className='menu-link menu-toggle' to='/share'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='หุ้น' src={toAbsoluteUrl('/media/svg/icons/Shopping/Chart-line1.svg')} />
                    </span>
                    <span className='menu-text'>หุ้น</span>
                    <i className='menu-arrow' />
                </NavLink>
                <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                        <li className='menu-item menu-item-parent' aria-haspopup='true'>
                            <span className='menu-link'>
                                <span className='menu-text'>หุ้น</span>
                            </span>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/share/operation')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/share/operation'>
                                <span className='svg-icon menu-icon'>
                                    <SVG title='จองหุ้น' src={toAbsoluteUrl('/media/svg/icons/Shopping/Bitcoin.svg')} />
                                </span>
                                <span className='menu-text'>จองหุ้น</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/share/history')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/share/history'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='ประวัติการซื้อหุ้น'
                                        src={toAbsoluteUrl('/media/svg/icons/Code/Time-schedule.svg')}
                                    />
                                </span>
                                <span className='menu-text'>ประวัติการซื้อหุ้น</span>
                            </NavLink>
                        </li>
                        {/* <li className={`menu-item ${getMenuItemActive('/share/detail')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/share/detail'>
                                <span className='svg-icon menu-icon'>
                                    <SVG title='ซื้อหุ้น' src={toAbsoluteUrl('/media/svg/icons/Files/Compiled-file.svg')} />
                                </span>
                                <span className='menu-text'>รายละเอียดหุ้นของคุณ</span>
                            </NavLink>
                        </li> */}
                    </ul>
                </div>
            </li>
            <li className='menu-section '>
                <h4 className='menu-text'>
                    {SubscriberList.includes(user.main_position) ? 'ผู้ใช้งานทั่วไป' : 'ดีลเลอร์'}
                </h4>
                <i className='menu-icon flaticon-more-v2' />
            </li>
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/register', true)}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
            >
                <NavLink className='menu-link menu-toggle' to='/register'>
                    <span className='svg-icon menu-icon'>
                        <SVG
                            title='สมัครสมาชิกใหม่'
                            src={toAbsoluteUrl('/media/svg/icons/Communication/Add-user.svg')}
                        />
                    </span>
                    <span className='menu-text'>สมัครสมาชิกใหม่</span>
                    <i className='menu-arrow' />
                </NavLink>
                <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/register/dealer', true)}`}
                            aria-haspopup='true'
                            data-menu-toggle='hover'
                        >
                            <a className='menu-link menu-toggle'>
                                <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                </i>
                                <span className='menu-text'>สมัครดีลเลอร์ใหม่</span>
                                <i className='menu-arrow' />
                            </a>
                            <div className='menu-submenu '>
                                <i className='menu-arrow' />
                                <ul className='menu-subnav'>
                                    <li
                                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                                            '/register/dealer',
                                            true
                                        )}`}
                                        aria-haspopup='true'
                                        data-menu-toggle='hover'
                                    >
                                        <a
                                            className='menu-link menu-toggle'
                                            href={`${reg_url}agreement?mobile=${main}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <i className='menu-bullet menu-bullet-dot'>
                                                <span />
                                            </i>
                                            <span className='menu-text'>สมัครต่อเบอร์หลัก</span>
                                        </a>
                                    </li>
                                    {numberlist.length > 1 && (
                                        <li
                                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                '/register/dealer',
                                                true
                                            )}`}
                                            aria-haspopup='true'
                                            data-menu-toggle='hover'
                                        >
                                            <a className='menu-link menu-toggle'>
                                                <i className='menu-bullet menu-bullet-dot'>
                                                    <span />
                                                </i>
                                                <span className='menu-text'>สมัครต่อเบอร์อื่นๆ</span>
                                                <i className='menu-arrow' />
                                            </a>
                                            <div className='menu-submenu '>
                                                <i className='menu-arrow' />
                                                <ul className='menu-subnav'>
                                                    {numberlist.map((object, index) => {
                                                        if (object.phonenumber !== main) {
                                                            return (
                                                                <li
                                                                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                                        '/register/dealer',
                                                                        true
                                                                    )} ${object.position === ''
                                                                        ? 'disabled-link cursor-disable'
                                                                        : ''}`}
                                                                    aria-haspopup='true'
                                                                    data-menu-toggle='hover'
                                                                    key={index}
                                                                >
                                                                    {object.position !== '' ? (
                                                                        <a
                                                                            className='menu-link menu-toggle'
                                                                            href={`${reg_url}agreement?mobile=${object.phonenumber}`}
                                                                            target='_blank'
                                                                            rel='noopener noreferrer'
                                                                        >
                                                                            <i className='menu-bullet menu-bullet-dot'>
                                                                                <span />
                                                                            </i>
                                                                            <span className='menu-text'>
                                                                                {object.phonenumber}
                                                                            </span>
                                                                            {object.feels_kyc === 0 && (
                                                                                <span className='menu-label'>
                                                                                    <span className='label label-warning label-inline'>
                                                                                        ยังไม่ลงทะเบียนซิม
                                                                                    </span>
                                                                                </span>
                                                                            )}
                                                                        </a>
                                                                    ) : (
                                                                        <div className='menu-link menu-toggle disable-target cursor-disable'>
                                                                            <i className='menu-bullet menu-bullet-dot'>
                                                                                <span />
                                                                            </i>
                                                                            <span className='menu-text'>
                                                                                {object.phonenumber}
                                                                            </span>
                                                                            {object.position === '' && (
                                                                                <span className='menu-label'>
                                                                                    <span className='label label-danger label-inline'>
                                                                                        ไม่พร้อมใช้งาน
                                                                                    </span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </li>
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/register/subscriber', true)}`}
                            aria-haspopup='true'
                            data-menu-toggle='hover'
                        >
                            <a className='menu-link menu-toggle'>
                                <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                </i>
                                <span className='menu-text'>สมัครผู้ใช้งานใหม่</span>
                                <i className='menu-arrow' />
                            </a>
                            <div className='menu-submenu '>
                                <i className='menu-arrow' />
                                <ul className='menu-subnav'>
                                    <li
                                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                                            '/register/subscriber',
                                            true
                                        )}`}
                                        aria-haspopup='true'
                                        data-menu-toggle='hover'
                                    >
                                        <a
                                            className='menu-link menu-toggle'
                                            href={`${reg_url}subscriber/register?mobile=${main}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <i className='menu-bullet menu-bullet-dot'>
                                                <span />
                                            </i>
                                            <span className='menu-text'>สมัครต่อเบอร์หลัก</span>
                                        </a>
                                    </li>
                                    {numberlist.length > 1 && (
                                        <li
                                            className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                '/register/subscriber',
                                                true
                                            )}`}
                                            aria-haspopup='true'
                                            data-menu-toggle='hover'
                                        >
                                            <a className='menu-link menu-toggle'>
                                                <i className='menu-bullet menu-bullet-dot'>
                                                    <span />
                                                </i>
                                                <span className='menu-text'>สมัครต่อเบอร์อื่นๆ</span>
                                                <i className='menu-arrow' />
                                            </a>
                                            <div className='menu-submenu '>
                                                <i className='menu-arrow' />
                                                <ul className='menu-subnav'>
                                                    {numberlist.map((object, index) => {
                                                        if (object.phonenumber !== main) {
                                                            return (
                                                                <li
                                                                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                                        '/register/subscriber',
                                                                        true
                                                                    )} ${object.position === ''
                                                                        ? 'disabled-link cursor-disable'
                                                                        : ''}`}
                                                                    aria-haspopup='true'
                                                                    data-menu-toggle='hover'
                                                                    key={index}
                                                                >
                                                                    {object.position !== '' ? (
                                                                        <a
                                                                            className='menu-link menu-toggle'
                                                                            href={`${reg_url}subscriber/register?mobile=${object.phonenumber}`}
                                                                            target='_blank'
                                                                            rel='noopener noreferrer'
                                                                        >
                                                                            <i className='menu-bullet menu-bullet-dot'>
                                                                                <span />
                                                                            </i>
                                                                            <span className='menu-text'>
                                                                                {object.phonenumber}
                                                                            </span>
                                                                            {object.feels_kyc === 0 && (
                                                                                <span className='menu-label'>
                                                                                    <span className='label label-warning label-inline'>
                                                                                        ยังไม่ลงทะเบียนซิม
                                                                                    </span>
                                                                                </span>
                                                                            )}
                                                                        </a>
                                                                    ) : (
                                                                        <div className='menu-link menu-toggle disable-target cursor-disable'>
                                                                            <i className='menu-bullet menu-bullet-dot'>
                                                                                <span />
                                                                            </i>
                                                                            <span className='menu-text'>
                                                                                {object.phonenumber}
                                                                            </span>
                                                                            {object.position === '' && (
                                                                                <span className='menu-label'>
                                                                                    <span className='label label-danger label-inline'>
                                                                                        ไม่พร้อมใช้งาน
                                                                                    </span>
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </li>
                                                            );
                                                        }
                                                    })}
                                                </ul>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
            {/* {SubscriberList.includes(user.main_position) || UpgradeList.includes(user.main_position) ? ( */}
            <li className={`menu-item ${getMenuItemActive('/dealer/upgrade-contact', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/dealer/upgrade-contact'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='อัพเกรดดีลเลอร์' src={toAbsoluteUrl('/media/svg/icons/Files/Uploaded-file.svg')} />
                    </span>
                    <span className='menu-text'>อัพเกรดเป็นดีลเลอร์</span>
                </NavLink>
            </li>
            {/* ) : ( */}
            {!SubscriberList.includes(user.main_position) && (
                <li className={`menu-item ${getMenuItemActive('/dealer/newcontact', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/dealer/newcontact'>
                        <span className='svg-icon menu-icon'>
                            <SVG
                                title='เปิดสิทธิดีลเลอร์ใหม่'
                                src={toAbsoluteUrl('/media/svg/icons/Files/File-plus.svg')}
                            />
                        </span>
                        <span className='menu-text'>เปิดสิทธิดีลเลอร์ใหม่</span>
                    </NavLink>
                </li>
            )}
            {!SubscriberList.includes(user.main_position) && (
                <li
                    className={`menu-item ${getMenuItemActive('/dealer/subscriber/newcontact', false)}`}
                    aria-haspopup='true'
                >
                    <NavLink className='menu-link' to='/dealer/subscriber/newcontact'>
                        <span className='svg-icon menu-icon'>
                            <SVG title='ปักหมุดดีลเลอร์' src={toAbsoluteUrl('/media/svg/icons/Files/File-plus.svg')} />
                        </span>
                        <span className='menu-text'>เปิดสิทธิผู้ใช้งานใหม่</span>
                    </NavLink>
                </li>
            )}
            {!SubscriberList.includes(user.main_position) && (
                <li className={`menu-item ${getMenuItemActive('/dealermap/setmarker', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/dealermap/setmarker'>
                        <span className='svg-icon menu-icon'>
                            <SVG title='ปักหมุดดีลเลอร์' src={toAbsoluteUrl('/media/svg/icons/Map/Marker1.svg')} />
                        </span>
                        <span className='menu-text'>ปักหมุดดีลเลอร์</span>
                    </NavLink>
                </li>
            )}
            <li className={`menu-item ${getMenuItemActive('/profile/edit', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/profile/edit'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ข้อมูลส่วนตัว' src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
                    </span>
                    <span className='menu-text'>ข้อมูลส่วนตัว</span>
                </NavLink>
            </li>
            {user.role !== undefined &&
            user.role.length > 0 &&
            user.role.includes('dealer-service') && (
                <li className={`menu-item ${getMenuItemActive('/dealer/support', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/dealer/support'>
                        <span className='svg-icon menu-icon'>
                            <SVG title='ข้อมูลการใช้งาน' src={toAbsoluteUrl('/media/svg/icons/Devices/iPhone-X.svg')} />
                        </span>
                        <span className='menu-text'>ข้อมูลการใช้งาน</span>
                    </NavLink>
                </li>
            )}
            <li className='menu-section '>
                <h4 className='menu-text'>ธุรกรรม</h4>
                <i className='menu-icon flaticon-more-v2' />
            </li>
            {/* {!SubscriberList.includes(user.main_position) && (
                <li className={`menu-item ${getMenuItemActive('/wallet/withdraw', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/wallet/withdraw'>
                        <span className='svg-icon menu-icon'>
                            <SVG
                                title='ถอนเงิน'
                                src={toAbsoluteUrl('/media/svg/icons/Communication/Outgoing-box.svg')}
                            />
                        </span>
                        <span className='menu-text'>ถอนเงิน</span>
                    </NavLink>
                </li>
            )} */}
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/withdraw', true)}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
            >
                <NavLink className='menu-link menu-toggle' to='/withdraw'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ถอนเงิน' src={toAbsoluteUrl('/media/svg/icons/Communication/Outgoing-box.svg')} />
                    </span>
                    <span className='menu-text'>ถอนเงิน</span>
                    <i className='menu-arrow' />
                </NavLink>
                <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                        <li className='menu-item menu-item-parent' aria-haspopup='true'>
                            <span className='menu-link'>
                                <span className='menu-text'>ถอนเงิน</span>
                            </span>
                        </li>
                        {!SubscriberList.includes(user.main_position) && (
                            <li className={`menu-item ${getMenuItemActive('/withdraw/mainwallet')}`} aria-haspopup='true'>
                                <NavLink className='menu-link' to='/withdraw/mainwallet'>
                                    <span className='svg-icon menu-icon'>
                                        <SVG
                                            title='ถอนเงินคอมมิชชั่น'
                                            src={toAbsoluteUrl('/media/svg/icons/Shopping/Bitcoin.svg')}
                                        />
                                    </span>
                                    <span className='menu-text'>ถอนเงินคอมมิชชั่น</span>
                                </NavLink>
                            </li>
                        )}
                        <li className={`menu-item ${getMenuItemActive('/withdraw/subwallet')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/withdraw/subwallet'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='ถอนเงินอื่นๆ'
                                        src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')}
                                    />
                                </span>
                                <span className='menu-text'>ถอนเงินอื่นๆ</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li>
            <li className={`menu-item ${getMenuItemActive('/self/refill', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/self/refill'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='เติมเงิน/แพ็กเกจ' src={toAbsoluteUrl('/media/svg/icons/Shopping/Box2.svg')} />
                    </span>
                    <span className='menu-text'>เติมเงิน/แพ็กเกจ</span>
                </NavLink>
            </li>
            {!SubscriberList.includes(user.main_position) && (
                <li className={`menu-item ${getMenuItemActive('/another/refill', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/another/refill'>
                        <span className='svg-icon menu-icon'>
                            <SVG
                                title='เติมเงิน/แพ็กเกจให้คนอื่น'
                                src={toAbsoluteUrl('/media/svg/icons/Communication/Sending.svg')}
                            />
                        </span>
                        <span className='menu-text'>เติมเงิน/แพ็กเกจให้คนอื่น</span>
                    </NavLink>
                </li>
            )}
            <li className='menu-section '>
                <h4 className='menu-text'>รายงาน</h4>
                <i className='menu-icon flaticon-more-v2' />
            </li>
            {!SubscriberList.includes(user.main_position) && (
                <li className={`menu-item ${getMenuItemActive('/commission/report', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/commission/report'>
                        <span className='svg-icon menu-icon'>
                            <SVG title='คอมมิชชั่น' src={toAbsoluteUrl('/media/svg/icons/Shopping/Wallet.svg')} />
                        </span>
                        <span className='menu-text'>คอมมิชชั่น</span>
                    </NavLink>
                </li>
            )}
            {!SubscriberList.includes(user.main_position) && (
                <li className={`menu-item ${getMenuItemActive('/payment/report', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/payment/report'>
                        <span className='svg-icon menu-icon'>
                            <SVG
                                title='ประวัติธุรกรรม'
                                src={toAbsoluteUrl('/media/svg/icons/Navigation/Exchange.svg')}
                            />
                        </span>
                        <span className='menu-text'>ประวัติธุรกรรม</span>
                    </NavLink>
                </li>
            )}
            <li className={`menu-item ${getMenuItemActive('/order/report', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/order/report'>
                    <span className='svg-icon menu-icon'>
                        <SVG
                            title='ประวัติรายการสั่งซื้อ'
                            src={toAbsoluteUrl('/media/svg/icons/General/Clipboard.svg')}
                        />
                    </span>
                    <span className='menu-text'>ประวัติรายการสั่งซื้อ</span>
                </NavLink>
            </li>
            <li className={`menu-item ${getMenuItemActive('/dealer/report', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/dealer/report'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ผังดีลเลอร์' src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')} />
                    </span>
                    <span className='menu-text'>
                        {!SubscriberList.includes(user.main_position) ? 'ผังดีลเลอร์' : 'ผังผู้ใช้งาน'}
                    </span>
                </NavLink>
            </li>
            <li className='menu-section '>
                <h4 className='menu-text'>ช่วยเหลือ</h4>
                <i className='menu-icon flaticon-more-v2' />
            </li>
            {!SubscriberList.includes(user.main_position) && (
                <li className={`menu-item ${getMenuItemActive('/agreement', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/agreement'>
                        <span className='svg-icon menu-icon'>
                            <SVG title='สัญญาแต่งตั้งดีลเลอร์' src={toAbsoluteUrl('/media/svg/icons/Files/File.svg')} />
                        </span>
                        <span className='menu-text'>สัญญาแต่งตั้งดีลเลอร์</span>
                    </NavLink>
                </li>
            )}
            {!SubscriberList.includes(user.main_position) && (
                <li className={`menu-item ${getMenuItemActive('/tw50', false)}`} aria-haspopup='true'>
                    <NavLink className='menu-link' to='/tw50'>
                        <span className='svg-icon menu-icon'>
                            <SVG
                                title='หนังสือรับรองการหักภาษี'
                                src={toAbsoluteUrl('/media/svg/icons/Files/File.svg')}
                            />
                        </span>
                        <span className='menu-text'>หนังสือรับรองการหักภาษี</span>
                    </NavLink>
                </li>
            )}
            <li className='menu-item' aria-haspopup='true'>
                <a className='menu-link' href={`https://tutorial.simk4.com/`} target='_blank' rel='noopener noreferrer'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='คู่มือการใช้งาน' src={toAbsoluteUrl('/media/svg/icons/Home/Book.svg')} />
                    </span>
                    <span className='menu-text'>คู่มือการใช้งาน</span>
                </a>
            </li>
            <li className='menu-item' aria-haspopup='true'>
                <a
                    className='menu-link'
                    href={`https://line.me/ti/p/~@dealersimsupport`}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <span className='svg-icon menu-icon'>
                        <SVG
                            title='ติดต่อเจ้าหน้าที่'
                            src={toAbsoluteUrl('/media/svg/icons/Communication/Chat1.svg')}
                        />
                    </span>
                    <span className='menu-text'>ติดต่อเจ้าหน้าที่</span>
                </a>
            </li>
            <br />
            <li className='menu-item' aria-haspopup='true'>
                <div className='menu-link' onClick={logoutClick}>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ออกจากระบบ' src={toAbsoluteUrl('/media/svg/icons/Navigation/Sign-out.svg')} />
                    </span>
                    <span className='menu-text'>ออกจากระบบ</span>
                </div>
            </li>
        </ul>
    );
}
