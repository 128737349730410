import React from 'react';

import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';

import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../_metronic/_helpers';

import { SubscriberList } from '../Setting';

export function MobileWithdrawMenu() {
    const { user } = useSelector((state) => state.auth);

    return (
        <div className='row p-0 m-0 mb-15'>
            {!SubscriberList.includes(user.main_position) && (
                <NavLink className='col-12 p-0' to='/withdraw/mainwallet'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                {/* <div className="col-12"> */}
                                <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                    <SVG
                                        title='ถอนเงินคอมมิชชั่น'
                                        src={toAbsoluteUrl('/media/svg/icons/Shopping/Bitcoin.svg')}
                                    />
                                </span>
                                {/* </div> */}
                                <h5 className='my-auto font-weight-bolder text-dark'>ถอนเงินคอมมิชชั่น</h5>
                            </div>
                        </div>
                    </div>
                </NavLink>
            )}
            <NavLink className='col-12 p-0' to='/withdraw/subwallet'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG title='ถอนเงินอื่นๆ' src={toAbsoluteUrl('/media/svg/icons/Shopping/Money.svg')} />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>ถอนเงินอื่นๆ</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
}
