import React, { useState, useEffect } from "react";

import NumberFormat from "react-number-format";

import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Select, { components } from "react-select";

import DatePicker, { utils } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import $ from "jquery";

import config from "../../environments/config";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { SubscriberList } from "../../../Setting";

const myCustomLocale = {
    // months list by order
    months: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
    ],

    // week days by order
    weekDays: [
        {
            name: "อาทิตย์", // used for accessibility
            short: "อา", // displayed at the top of days' rows
            isWeekend: true, // is it a formal weekend or not?
        },
        {
            name: "จันทร์",
            short: "จ",
        },
        {
            name: "อังคาร",
            short: "อ",
        },
        {
            name: "พุธ",
            short: "พ",
        },
        {
            name: "พฤหัสบดี",
            short: "พฤ",
        },
        {
            name: "ศุกร์",
            short: "ศ",
        },
        {
            name: "เสาร์",
            short: "ส",
            isWeekend: true,
        },
    ],

    // just play around with this number between 0 and 6
    weekStartingIndex: 0,

    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
        return gregorainTodayObject;
    },

    // return a native JavaScript date here
    toNativeDate(date) {
        return new Date(date.year, date.month - 1, date.day);
    },

    // return a number for date's month length
    getMonthLength(date) {
        return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
        return digit;
    },

    // texts in the date picker
    nextMonth: "Next Month",
    previousMonth: "Previous Month",
    openMonthSelector: "Open Month Selector",
    openYearSelector: "Open Year Selector",
    closeMonthSelector: "Close Month Selector",
    closeYearSelector: "Close Year Selector",
    defaultPlaceholder: "Select...",

    // for input range value
    from: "from",
    to: "to",

    // used for input value when multi dates are selected
    digitSeparator: ",",

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
};

const minimumDate = {
    year: 2020,
    month: 12,
    day: 31,
};

const controlStyles = {
    borderRadius: "0.85rem",
    padding: "5px",
    background: "#F3F6F9",
    color: "white",
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#F3F6F9",
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
    }),
    // singleValue: (provided, state) => {
    //     const opacity = state.isDisabled ? 0.5 : 1;
    //     const transition = "opacity 300ms";
    //     return { ...provided, opacity, transition };
    // },
};

const selectList = [
    {
        label: "ค่าแนะนำ",
        value: "faststart",
    },
    {
        label: "การเติมเงิน",
        value: "topup",
    },
    {
        label: "One Time Reward",
        value: "onetime",
    },
    {
        label: "Pool Bonus",
        value: "pool",
    },
    {
        label: "โบนัสพิเศษ",
        value: "extra_promotion",
    },
    {
        label: "โปรโมชั่น",
        value: "promotion",
    },
    {
        label: "Box Set",
        value: "boxset"
    }
];

export function CommissionReport() {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState({
        from: null,
        to: null,
    });
    const [numberlist, setNumberList] = useState([]);
    const [phonenumber, setPhonenumber] = useState("");
    const [commissiontype, setCommissionType] = useState("");
    const [comtotal, setComTotal] = useState(0);
    const [dailycomtotal, setDailyComTotal] = useState(0);
    const [title, setTitle] = useState("");
    const [rawdata, setRawData] = useState(null);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (SubscriberList.includes(user.main_position)) {
            history.push('/');
        } else {
            getNumberList(user.id);
        }
    }, []);

    useEffect(() => {
        $(document).ready(function () {
            if (commissiontype.value === "faststart") {
                $("#kt_datatable").DataTable({
                    data: rawdata,
                    lengthChange: false,
                    responsive: true,
                    dom: "Bfrtip",
                    buttons: ["excel"],
                    order: [[0, "asc"]],
                    language: {
                        paginate: {
                            previous: "ก่อนหน้า",
                            next: "ถัดไป",
                        },
                        info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                        infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                        zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                    },
                    oLanguage: {
                        sSearch: "คำสำคัญ",
                    },
                    columns: [
                        {
                            title: "วันที่ได้รับค่าคอมมิชชั่น",
                            data: "date",
                        },
                        {
                            title: "สถานะ",
                            data: function (value) {
                                if (value.comtype === "accumulated") {
                                    return '<span class="badge badge-success mx-auto my-auto">คำนวณแล้ว</span>';
                                } else if (value.comtype === "estimated") {
                                    return '<span class="badge badge-secondary mx-auto my-auto">รอคำนวณ</span>';
                                }
                            },
                        },
                        {
                            title: "หมายเลข",
                            data: function (value) {
                                if (
                                    value.child !== null &&
                                    value.child !== ""
                                ) {
                                    return (
                                        value.child.slice(0, 3) +
                                        "-" +
                                        value.child.slice(3, 6) +
                                        "-" +
                                        value.child.slice(6)
                                    );
                                } else {
                                    return "-";
                                }
                            },
                        },
                        {
                            title: "ชื่อสมาชิก",
                            data: function (value) {
                                if (value.name !== null && value.name !== "") {
                                    return value.name;
                                } else {
                                    return "-";
                                }
                            },
                        },
                        {
                            title: "ขนาดสัญญา",
                            data: "pv",
                        },
                        {
                            title: "เปอร์เซ็นต์",
                            data: "percent",
                        },
                        {
                            title: "คอมมิชชั่น",
                            data: "comm",
                        },
                    ],
                });
            } else if (commissiontype.value === "topup") {
                $("#kt_datatable").DataTable({
                    data: rawdata,
                    lengthChange: false,
                    responsive: true,
                    dom: "Bfrtip",
                    buttons: ["excel"],
                    order: [[0, "asc"]],
                    language: {
                        paginate: {
                            previous: "ก่อนหน้า",
                            next: "ถัดไป",
                        },
                        info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                        infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                        zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                    },
                    oLanguage: {
                        sSearch: "คำสำคัญ",
                    },
                    columns: [
                        {
                            title: "วันที่ได้รับค่าคอมมิชชั่น",
                            data: "date",
                        },
                        {
                            title: "สถานะ",
                            data: function (value) {
                                if (value.comtype === "accumulated") {
                                    return '<span class="badge badge-success mx-auto my-auto">คำนวณแล้ว</span>';
                                } else if (value.comtype === "estimated") {
                                    return '<span class="badge badge-secondary mx-auto my-auto">รอคำนวณ</span>';
                                }
                            },
                        },
                        {
                            title: "หมายเลข",
                            data: function (value) {
                                if (
                                    value.child !== null &&
                                    value.child !== ""
                                ) {
                                    return (
                                        value.child.slice(0, 3) +
                                        "-" +
                                        value.child.slice(3, 6) +
                                        "-" +
                                        value.child.slice(6)
                                    );
                                } else {
                                    return "-";
                                }
                            },
                        },
                        {
                            title: "ชื่อสมาชิก",
                            data: function (value) {
                                if (value.name !== null && value.name !== "") {
                                    return value.name;
                                } else {
                                    return "-";
                                }
                            },
                        },
                        {
                            title: "ราคา",
                            data: "pv",
                        },
                        {
                            title: "เปอร์เซ็นต์",
                            data: "percent",
                        },
                        {
                            title: "คอมมิชชั่น",
                            data: "comm",
                        },
                    ],
                });
            } else if (commissiontype.value === "onetime") {
                $("#kt_datatable").DataTable({
                    data: rawdata,
                    lengthChange: false,
                    responsive: true,
                    dom: "Bfrtip",
                    buttons: ["excel"],
                    order: [[0, "asc"]],
                    language: {
                        paginate: {
                            previous: "ก่อนหน้า",
                            next: "ถัดไป",
                        },
                        info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                        infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                        zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                    },
                    oLanguage: {
                        sSearch: "คำสำคัญ",
                    },
                    columns: [
                        {
                            title: "วันที่ได้รับค่าคอมมิชชั่น",
                            data: "date",
                        },
                        {
                            title: "สถานะ",
                            data: function (value) {
                                if (value.comtype === "accumulated") {
                                    return '<span class="badge badge-success mx-auto my-auto">คำนวณแล้ว</span>';
                                } else if (value.comtype === "estimated") {
                                    return '<span class="badge badge-secondary mx-auto my-auto">รอคำนวณ</span>';
                                }
                            },
                        },
                        {
                            title: "คอมมิชชั่น",
                            data: "comm",
                        },
                    ],
                });
            } else if (commissiontype.value === "pool") {
                $("#kt_datatable").DataTable({
                    data: rawdata,
                    lengthChange: false,
                    responsive: true,
                    dom: "Bfrtip",
                    buttons: ["excel"],
                    order: [[0, "asc"]],
                    language: {
                        paginate: {
                            previous: "ก่อนหน้า",
                            next: "ถัดไป",
                        },
                        info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                        infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                        zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                    },
                    oLanguage: {
                        sSearch: "คำสำคัญ",
                    },
                    columns: [
                        {
                            title: "วันที่ได้รับค่าคอมมิชชั่น",
                            data: "date",
                        },
                        {
                            title: "สถานะ",
                            data: function (value) {
                                if (value.comtype === "accumulated") {
                                    return '<span class="badge badge-success mx-auto my-auto">คำนวณแล้ว</span>';
                                } else if (value.comtype === "estimated") {
                                    return '<span class="badge badge-secondary mx-auto my-auto">รอคำนวณ</span>';
                                }
                            },
                        },
                        {
                            title: "คอมมิชชั่น",
                            data: "comm",
                        },
                    ],
                });
            } else if (commissiontype.value === "extra_promotion") {
                $("#kt_datatable").DataTable({
                    data: rawdata,
                    lengthChange: false,
                    responsive: true,
                    dom: "Bfrtip",
                    buttons: ["excel"],
                    order: [[0, "asc"]],
                    language: {
                        paginate: {
                            previous: "ก่อนหน้า",
                            next: "ถัดไป",
                        },
                        info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                        infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                        zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                    },
                    oLanguage: {
                        sSearch: "คำสำคัญ",
                    },
                    columns: [
                        {
                            title: "วันที่ได้รับค่าคอมมิชชั่น",
                            data: "date",
                        },
                        {
                            title: "สถานะ",
                            data: function (value) {
                                if (value.comtype === "accumulated") {
                                    return '<span class="badge badge-success mx-auto my-auto">คำนวณแล้ว</span>';
                                } else if (value.comtype === "estimated") {
                                    return '<span class="badge badge-secondary mx-auto my-auto">รอคำนวณ</span>';
                                }
                            },
                        },
                        {
                            title: "หมายเลข",
                            data: function (value) {
                                if (
                                    value.child !== null &&
                                    value.child !== ""
                                ) {
                                    return (
                                        value.child.slice(0, 3) +
                                        "-" +
                                        value.child.slice(3, 6) +
                                        "-" +
                                        value.child.slice(6)
                                    );
                                } else {
                                    return "-";
                                }
                            },
                        },
                        {
                            title: "ชื่อสมาชิก",
                            data: function (value) {
                                if (value.name !== null && value.name !== "") {
                                    return value.name;
                                } else {
                                    return "-";
                                }
                            },
                        },
                        {
                            title: "ขนาดสัญญา",
                            data: "pv",
                        },
                        {
                            title: "เปอร์เซ็นต์",
                            data: "percent",
                        },
                        {
                            title: "คอมมิชชั่น",
                            data: "comm",
                        },
                    ],
                });
            } else if (commissiontype.value === "promotion") {
                $("#kt_datatable").DataTable({
                    data: rawdata,
                    lengthChange: false,
                    responsive: true,
                    dom: "Bfrtip",
                    buttons: ["excel"],
                    order: [[0, "asc"]],
                    language: {
                        paginate: {
                            previous: "ก่อนหน้า",
                            next: "ถัดไป",
                        },
                        info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                        infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                        zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                    },
                    oLanguage: {
                        sSearch: "คำสำคัญ",
                    },
                    columns: [
                        {
                            title: "วันที่ได้รับค่าคอมมิชชั่น",
                            data: "date",
                        },
                        {
                            title: "สถานะ",
                            data: function (value) {
                                if (value.comtype === "accumulated") {
                                    return '<span class="badge badge-success mx-auto my-auto">คำนวณแล้ว</span>';
                                } else if (value.comtype === "estimated") {
                                    return '<span class="badge badge-secondary mx-auto my-auto">รอคำนวณ</span>';
                                }
                            },
                        },
                        {
                            title: "คอมมิชชั่น",
                            data: "comm",
                        },
                    ],
                });
            } else if (commissiontype.value === "boxset") {
                $("#kt_datatable").DataTable({
                    data: rawdata,
                    lengthChange: false,
                    responsive: true,
                    dom: "Bfrtip",
                    buttons: ["excel"],
                    order: [[0, "asc"]],
                    language: {
                        paginate: {
                            previous: "ก่อนหน้า",
                            next: "ถัดไป",
                        },
                        info: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        infoEmpty: "แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ",
                        emptyTable: "ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ",
                        infoFiltered: "(ค้นหาจากทั้งหมด _MAX_ รายการ)",
                        zeroRecords: "ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ",
                    },
                    oLanguage: {
                        sSearch: "คำสำคัญ",
                    },
                    columns: [
                        {
                            title: "วันที่ได้รับค่าคอมมิชชั่น",
                            data: "date",
                        },
                        {
                            title: "เบอร์",
                            data: "phonenumber",
                        },
                        {
                            title: "หมายเลข Box Set",
                            data: "simpack_id",
                        },
                        {
                            title: "ประเภทค่าคอมมิชชั่น",
                            data: function (value) {
                                if (value.type === "registerd") {
                                    return '<span class="badge badge-dark mx-auto my-auto">ค่าสมัคร</span>';
                                } else if (value.type === "ordered") {
                                    return '<span class="badge badge-dark mx-auto my-auto">ค่าสั่งซื้อ</span>';
                                }
                            },
                        },
                        {
                            title: "สถานะ",
                            data: function (value) {
                                if (value.comtype === "accumulated") {
                                    return '<span class="badge badge-success mx-auto my-auto">คำนวณแล้ว</span>';
                                } else if (value.comtype === "estimated") {
                                    return '<span class="badge badge-secondary mx-auto my-auto">รอคำนวณ</span>';
                                }
                            },
                        },
                        {
                            title: "คอมมิชชั่น",
                            data: "comm",
                        },
                    ],
                });
            }
        });
    }, [rawdata]);

    const getNumberList = (memberid) => {
        fetch(config.py_url + config.version + "/profile/phonenumber_list", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify({
                userid: memberid,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    let numbers = [];
                    // let numbers = [
                    //     {
                    //         value: "0976935555",
                    //         label: "0976935555",
                    //     },
                    // ];
                    let index = 0;
                    // let index = 1;
                    for (const prop in result.numbers) {
                        numbers[index] = {
                            value: result.numbers[prop]["phonenumber"],
                            label: result.numbers[prop]["phonenumber"],
                        };
                        index += 1;
                    }
                    setNumberList(numbers);
                    // console.log(numberlist);
                    // setNumberList
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const selectAccount = (item) => {
        if (item) {
            setPhonenumber(item);
        }
    };

    const selectType = (item) => {
        if (item) {
            setCommissionType(item);
        }
    };

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to
                    ? date.from.year +
                      "/" +
                      ("0" + date.from.month).slice(-2) +
                      "/" +
                      ("0" + date.from.day).slice(-2) +
                      " - " +
                      date.to.year +
                      "/" +
                      ("0" + date.to.month).slice(-2) +
                      "/" +
                      ("0" + date.to.day).slice(-2)
                    : ""
            }
            className="form-control form-control-lg form-control-solid"
            type="text"
            id="dateperiod"
            name="dateperiod"
        />
    );

    const getCommission = () => {
        if (phonenumber === "") {
            Swal.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกเบอร์ที่ต้องการดูคอมมิชชั่น",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        if (commissiontype === "") {
            Swal.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: "กรุณาเลือกประเภทคอมมิชชั่น",
                icon: "warning",
                // showCancelButton: true,
                confirmButtonText: "รับทราบ",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });
            return;
        }
        let postparam = {
            type: commissiontype.value,
            userid: phonenumber.value === "0976935555" ? "0000001" : user.id,
            phonenumbers: [phonenumber.value],
            options: ["detail"],
        };
        if (date.from !== null && date.to !== null) {
            postparam["filters"] = {
                start:
                    date.from.year +
                    "-" +
                    ("0" + date.from.month).slice(-2) +
                    "-" +
                    ("0" + date.from.day).slice(-2),
                end:
                    date.to.year +
                    "-" +
                    ("0" + date.to.month).slice(-2) +
                    "-" +
                    ("0" + date.to.day).slice(-2),
            };
        }
        setLoading(true);
        setRawData(null);
        fetch(config.py_url + config.version + "/commission/get_commission", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                secret: config.secret,
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    // console.log(result[postparam.type]);
                    let array = [];
                    let thisjson = {};
                    for (let prop in result[postparam.type].estimated) {
                        for (let prop2 in Object.keys(
                            result[postparam.type].estimated[prop]
                        )) {
                            if (prop2 !== "total") {
                                if (
                                    result[postparam.type].estimated[prop][
                                        prop2
                                    ] !== undefined
                                ) {
                                    thisjson =
                                        result[postparam.type].estimated[prop][
                                            prop2
                                        ];
                                    thisjson["comtype"] = "estimated";
                                    array.push(thisjson);
                                }
                            }
                        }
                        setDailyComTotal(
                            result[postparam.type].estimated[prop]["total"]
                        );
                    }

                    for (let prop in result[postparam.type].accumulated) {
                        for (let prop2 in Object.keys(
                            result[postparam.type].accumulated[prop]
                        )) {
                            if (prop2 !== "total") {
                                if (
                                    result[postparam.type].accumulated[prop][
                                        prop2
                                    ] !== undefined
                                ) {
                                    thisjson =
                                        result[postparam.type].accumulated[
                                            prop
                                        ][prop2];
                                    thisjson["comtype"] = "accumulated";
                                    array.push(thisjson);
                                }
                            }
                        }
                        setComTotal(
                            result[postparam.type].accumulated[prop]["total"]
                        );
                    }
                    setRawData(array);
                    setTitle(commissiontype.label);
                    // if (commissiontype.value === "faststart") {
                    //     setTitle("ค่าแนะนำ");
                    // } else if (commissiontype.value === "topup") {
                    //     setTitle("การเติมเงิน");
                    // } else if (commissiontype.value === "onetime") {
                    //     setTitle("One Time Reward");
                    // }
                } else {
                    setRawData([]);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <>
            {!loading ? (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card card-custom">
                            <div className="card-header bg-danger text-white">
                                <div className="card-title">
                                    <span className="card-icon">
                                        <i className="fa fa-search text-white" />
                                    </span>
                                    <h3 className="card-label text-white">
                                        ค้นหา
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="name">บัญชี</label>
                                        <Select
                                            components={{
                                                Control: ControlComponent,
                                            }}
                                            noOptionsMessage={() =>
                                                "ไม่พบข้อมูล"
                                            }
                                            value={phonenumber}
                                            options={numberlist}
                                            placeholder="เลือก"
                                            id="account"
                                            onChange={selectAccount}
                                            // isClearable={true}
                                            menuPortalTarget={document.body}
                                            styles={customStyles}
                                        />
                                        {/* <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        onChange={this.handleChange}
                                                    // value={this.state.filter.name}
                                                    /> */}
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="filterorderstatus">
                                            ประเภทคอมมิชชั่น
                                        </label>
                                        {/* <input
                                            className="form-control form-control-lg form-control-solid"
                                            type="text"
                                            id="name"
                                            name="name"
                                            defaultValue=""
                                        /> */}
                                        <Select
                                            components={{
                                                Control: ControlComponent,
                                            }}
                                            noOptionsMessage={() =>
                                                "ไม่พบข้อมูล"
                                            }
                                            value={commissiontype}
                                            options={selectList}
                                            placeholder="เลือก"
                                            id="account"
                                            onChange={selectType}
                                            // isClearable={true}
                                            menuPortalTarget={document.body}
                                            styles={customStyles}
                                        />
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="filterorderdate">
                                            วันที่ทำธุรกรรม
                                        </label>
                                        {/* <input
                                    className="form-control form-control-lg form-control-solid"
                                    type="text"
                                    id="name"
                                    name="name"
                                    defaultValue=""
                                /> */}
                                        {/* <div className="col-12 p-0"> */}
                                        <DatePicker
                                            value={date}
                                            onChange={setDate}
                                            renderInput={renderCustomInput}
                                            minimumDate={minimumDate}
                                            maximumDate={utils().getToday()}
                                            locale={myCustomLocale}
                                            wrapperClassName="col-12 p-0"
                                            calendarClassName="responsive-calendar"
                                            calendarPopperPosition="bottom"
                                            shouldHighlightWeekends
                                        />
                                        <span className="form-text text-muted">
                                            หากไม่ได้เลือกจะดึงข้อมูลของวันนี้
                                        </span>

                                        {/* </div> */}
                                        {/* <DateRangePicker
                                    className="d-block"
                                    placeholder="เลือก"
                                    locale={{
                                        sunday: "อา",
                                        monday: "จ",
                                        tuesday: "อ",
                                        wednesday: "พ",
                                        thursday: "พฤ",
                                        friday: "ศ",
                                        saturday: "ส",
                                        ok: "ยืนยัน",
                                        today: "วันนี้",
                                        yesterday: "เมื่อวาน",
                                        last7Days: "7 วันที่แล้ว",
                                    }}
                                    showOneCalendar
                                    // disabledDate={afterToday}
                                    onChange={(item) => selectDate(item)}
                                /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-right">
                                <button
                                    type="button"
                                    onClick={getCommission}
                                    className="btn btn-sm btn-primary"
                                >
                                    <i className="far fa-dot-circle"></i> ค้นหา
                                </button>
                            </div>
                        </div>
                    </div>
                    {rawdata !== null && (
                        <div className="col-lg-12">
                            <div className="card card-custom">
                                <div className="card-header bg-danger text-white">
                                    <div className="card-title">
                                        <h3 className="card-label text-white">
                                            รายงาน {title}
                                        </h3>
                                    </div>
                                    <div className="card-toolbar">
                                        <div className="row">
                                            <h3 className="card-label text-white col-12 p-0 text-right">
                                                ยอดรวม{" "}
                                                <span>
                                                    <NumberFormat
                                                        value={parseFloat(
                                                            comtotal
                                                        )}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        // suffix={
                                                        //     " บาท"
                                                        // }
                                                    />
                                                </span>
                                            </h3>
                                            <p className="card-label text-white col-12 p-0 m-0 text-right">
                                                ยอดรวมที่คาดว่าจะได้วันนี้{" "}
                                                <span>
                                                    <NumberFormat
                                                        value={parseFloat(
                                                            dailycomtotal
                                                        )}
                                                        displayType={"text"}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        // suffix={
                                                        //     " บาท"
                                                        // }
                                                    />
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {rawdata.length > 0 ? (
                                        <table
                                            id="kt_datatable"
                                            className="table table-striped table-bordered"
                                        ></table>
                                    ) : (
                                        <div className="col-xl-12 text-center m-0 my-25 p-0">
                                            <i className="fas fa-exclamation-circle fa-5x mb-2 text-primary"></i>
                                            <h4 className="mt-3">
                                                ไม่พบข้อมูลที่ต้องการค้นหา
                                            </h4>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className="d-flex flex-column flex-root"
                    id="loading-section"
                >
                    <div
                        className="row justify-content-center align-items-center overlay p-0 m-0"
                        id="overlay"
                    >
                        <div className="col col-sm-6 col-lg-12 h-100 p-0">
                            <div className="bounce col-12">
                                <div className="bounce1" />
                                <div className="bounce2" />
                                <div className="bounce3" />
                            </div>
                            <h1 className="col loading-txt">กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
