import React, { useEffect, useState } from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import Select, { components } from 'react-select';

import NumberFormat from 'react-number-format';

import config from '../../../environments/config';

const controlStyles2 = {
    borderRadius: '0.85rem',
    // padding: '5px',
    background: '#fff',
    color: 'white'
};

const ControlComponent2 = (props) => (
    <div style={controlStyles2}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles2 = {
    menu: (provided, state) => ({
        ...provided
        // backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        '&:hover': {
            borderColor: '#F64E60'
        },
        backgroundColor: state.isDisabled ? '#F3F6F9' : 'transparent',
        boxShadow: 'none',
        borderRadius: '0.85rem',
        borderColor: state.isFocused ? '#F64E60' : '#E4E6EF',
        borderStyle: 'solid',
        borderWidth: '1px'
    })
};

export function ShopInsuranceSummary(props) {
    const [ couponloading, setCouponLoading ] = useState(false);

    useEffect(() => {
        getCoupons();
    }, []);

    const selectCoupon = (item) => {
        if (item) {
            props.setCoupon(item);
        } else {
            props.setCoupon('');
        }
    };

    const getCoupons = () => {
        setCouponLoading(true);
        fetch(config.punsook_shop_url + 'member/coupon/find/by-user', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: props.user['shop_token']
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setCouponLoading(false);
                // console.log(result)
                if (result.responsecode === 0) {
                    let couponlist = [];
                    for (const couponindex in result.info) {
                        // if (result.info[couponindex]['status'] === 'enable') {
                        couponlist.push({
                            label:
                                result.info[couponindex]['coupon_code'].toString() +
                                ' ' +
                                result.info[couponindex]['user_coupon_create_date'].toString(),
                            value: result.info[couponindex]['user_coupon_id'].toString()
                        });
                        // }
                    }
                    props.setCoupons(couponlist);
                }
            })
            .catch((error) => {
                setCouponLoading(false);
            });
    };

    return (
        <div className='col-lg-11 col-12 p-0'>
            <div className='card'>
                <div className='card-body'>
                    <div className='text-center mb-10'>
                        <h3 className='font-size-h1'>รายละเอียดผู้สมัครกรมธรรม์</h3>
                        {/* <p className='text-muted font-weight-bold'>ฌาปนกิจ</p> */}
                    </div>
                    <div className='row d-flex justify-content-center pb-5'>
                        <div className='col-lg-6 mb-5 mb-md-0'>
                            <OverlayScrollbarsComponent className='h-360px bg-light rounded-lg'>
                                <div className='p-5'>
                                    <div className='d-flex row m-0 p-2'>
                                        <div className='col-xl-12 d-flex'>
                                            <div>
                                                <h4 className='py-2'>
                                                    <i className='fas fa-truck mr-3' />
                                                    ข้อมูลผู้สมัครกรมธรรม์
                                                </h4>
                                            </div>
                                        </div>
                                        <div className='col-xl-12'>
                                            <div className='rounded bg-white row m-0 p-3'>
                                                <div className='d-flex col-xl-12'>
                                                    <div className='p-2'>ชื่อ</div>
                                                    <div className='ml-auto p-2'>{props.firstname}</div>
                                                </div>
                                                <div className='d-flex col-xl-12'>
                                                    <div className='p-2'>นามสกุล</div>
                                                    <div className='ml-auto p-2'>{props.lastname}</div>
                                                </div>
                                                <div className='d-flex col-xl-12'>
                                                    <div className='p-2'>หมายเลขบัตรประชาชน</div>
                                                    <div className='ml-auto p-2'>{props.citizenid}</div>
                                                </div>
                                                <div className='d-flex col-xl-12'>
                                                    <div className='p-2'>วัน/เดือน/ปีเกิด</div>
                                                    <div className='ml-auto p-2'>{props.birthdate}</div>
                                                </div>
                                                <div className='d-flex col-xl-12'>
                                                    <div className='p-2'>อาชืพ</div>
                                                    <div className='ml-auto p-2'>
                                                        {props.occupation === '1' ? 'ดีลเลอร์' : 'อื่นๆ'}
                                                    </div>
                                                </div>
                                                <div className='d-flex col-xl-12'>
                                                    <div className='p-2'>ผู้รับผลประโยชน์</div>
                                                    <div className='ml-auto p-2'>{props.beneficiary}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OverlayScrollbarsComponent>
                        </div>
                        <div className='col-lg-6'>
                            <div className='bg-light rounded d-flex flex-column p-3'>
                                <div className='p-2 ml-3'>
                                    <h4>รายละเอียดการสมัครกรมธรรม์</h4>
                                </div>
                                <div className='p-2 d-flex'>
                                    <div className='col-7'>แผนกรมธรรม์</div>
                                    <div className='ml-auto text-right'>{props.productname}</div>
                                </div>
                                <div className='p-2 d-flex'>
                                    <div className='col-8'>ราคากรมธรรม์</div>
                                    <div className='ml-auto'>
                                        <NumberFormat
                                            value={parseFloat(props.productprice)}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            suffix={' บาท'}
                                        />
                                    </div>
                                </div>
                                <div className='p-2 d-flex'>
                                    <div className='col-8'>ภาษีมูลค่าเพิ่ม</div>
                                    <div className='ml-auto'>
                                        <NumberFormat
                                            value={parseFloat(props.productvat)}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            suffix={' บาท'}
                                        />
                                    </div>
                                </div>
                                <div className='p-2 d-flex pt-3 row m-0'>
                                    <div className='col-12'>
                                        <b>คูปองที่คุณมี</b>
                                    </div>
                                    <div className='col-12 mt-5'>
                                        <Select
                                            components={{
                                                Control: ControlComponent2
                                            }}
                                            noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                            value={props.coupon}
                                            options={props.coupons}
                                            placeholder='เลือก'
                                            id='subdistrict'
                                            onChange={selectCoupon}
                                            isClearable={true}
                                            menuPortalTarget={document.body}
                                            styles={customStyles2}
                                            isDisabled={props.coupons.length === 0}
                                        />
                                    </div>
                                </div>
                                <div className='border-top px-4 mx-3' />
                                <div className='d-flex flex-row align-content-center p-4'>
                                    <div className='pt-2 pr-2 col-xl-12'>
                                        <div className='radio-inline'>
                                            <label className='radio radio-primary col-xl-12'>
                                                <input
                                                    type='radio'
                                                    name='radio'
                                                    value='2'
                                                    checked={props.paymentchannal === '1'}
                                                    onChange={() => props.setPaymentchannal('1')}
                                                />
                                                <span />
                                                <div className='rounded border d-flex w-100 px-2 bg-white'>
                                                    <div className='row p-0 m-0'>
                                                        <p className='my-auto'>
                                                            <i className='fas fa-qrcode text-primary pr-2' />
                                                            ชำระเงินผ่านคิวอาร์โค้ด
                                                        </p>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-content-center p-4'>
                                    <div className='pt-2 pr-2 col-xl-12'>
                                        <div className='radio-inline'>
                                            <label className='radio radio-primary col-xl-12'>
                                                <input
                                                    type='radio'
                                                    name='radio'
                                                    value='3'
                                                    checked={props.paymentchannal === '2'}
                                                    onChange={() => props.setPaymentchannal('2')}
                                                />
                                                <span />
                                                <div className='rounded border d-flex w-100 px-2 bg-white'>
                                                    <div className='row p-0 m-0'>
                                                        <p className='my-auto'>
                                                            <i className='fas fa-wallet text-primary pr-2' />
                                                            กระเป๋าซิมปันสุข
                                                        </p>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='border-top px-4 mx-3' />
                                <div className='p-2 d-flex pt-3'>
                                    <div className='col-8'>
                                        <b>ยอดรวม</b>
                                    </div>
                                    <div className='ml-auto'>
                                        <b className='green'>
                                            <NumberFormat
                                                value={parseFloat(props.productprice) + parseFloat(props.productvat)}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                suffix={' บาท'}
                                            />
                                        </b>
                                    </div>
                                </div>
                                <div className='m-3'>
                                    <button
                                        type='button'
                                        onClick={props.btnPayment}
                                        className='btn btn-primary btn-block'
                                    >
                                        ชำระเงิน
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
