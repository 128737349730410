import React, { useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';

import { injectIntl } from 'react-intl';

import { NavLink, useHistory } from 'react-router-dom';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { ToastContainer, toast } from 'react-toastify';

import NumberFormat from 'react-number-format';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import {
    ShopCartSwitchAccount,
    ShopCartCheckout,
    ShopCartOtpcode,
    ShopCartPhonenumber,
    ShopCartResult
} from './components';

import ShopCartAccountLogin from './components/Login';

import * as auth from '../../../modules/Auth/_redux/authRedux';

import config from '../../environments/config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

function ShopCartMain(props) {
    const history = useHistory();

    const { user } = useSelector((state) => state.auth);

    const [ page, setPage ] = useState('main');
    const [ loading, setLoading ] = useState(false);
    const [ carts, setCarts ] = useState({});
    const [ cartitems, setCartitems ] = useState([]);
    const [ total, setTotal ] = useState(0);
    const [ amount, setAmount ] = useState(0);
    const [ editval, setEditVal ] = useState({});
    const [ phonenumber, setPhonenumber ] = useState('');
    const [ shipid, setShipid ] = useState('');
    // checkout
    const [ payment, setPayment ] = useState('qrcode');
    const [ firstname, setFirstname ] = useState('');
    const [ lastname, setLastname ] = useState('');
    const [ contactnumber, setContactnumber ] = useState('');
    const [ province, setProvince ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ subdistrict, setSubdistrict ] = useState('');
    const [ zipcode, setZipcode ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ response, setResponse ] = useState({});
    const [ coupon, setCoupon ] = useState('');
    const [ coupons, setCoupons ] = useState([]);
    const [ remark, setRemark ] = useState('');
    // Phonenumber
    const [ walletnumber, setWalletnumber ] = useState('');
    // Otpcpde
    const [ otpcode, setOtpcode ] = useState('');
    const [ refcode, setRefcode ] = useState('');
    const [ expiretime, setExpiretime ] = useState('');
    const [ disablebtn, setDisableBtn ] = useState(false);
    const [ disableotp, setDisableOtp ] = useState(false);
    // const [ editval, setEditVal ] = useState({});

    const Completionist = () => {
        return (
            <div className='row justify-content-center col-12 p-0 m-0'>
                <button className='btn btn-primary rounded-lg' onClick={(e) => getOtpcode(true)} disabled={disableotp}>
                    ขอ OTP ใหม่
                </button>
            </div>
        );
    };

    const renderer = ({ minutes, seconds, completed }) => {
        // console.log(minutes);
        // console.log(seconds);
        // console.log(completed);
        if (completed) {
            // Render a complete state
            // ("2020-01-02 11:00:00");
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className='row col-12 p-0 m-0 text-center'>
                    <p className='col-12 m-0'>กรุณาลองใหม่ในอีก</p>
                    <span className='col-12'>
                        {(minutes < 10 ? '0' : '') + minutes}:
                        {(seconds < 10 ? '0' : '') + seconds}
                    </span>
                </div>
            );
        }
    };

    useEffect(() => {
        if (user.shop_account === undefined || user.shop_account === null || user.shop_account === '') {
            history.push('/shop/product?page=login');
        }
        getCarts();
    }, []);

    const handleOtp = (code) => {
        console.log(code);
        setOtpcode(code);
        if (code.length >= 6) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    };

    const btnNext = (selected = '') => {
        if (page === 'main') {
            let items = [];
            for (const thisitem in carts.info) {
                items.push(carts.info[thisitem]['id']);
            }
            setCartitems(items);
            setPage('checkout');
        } else if (page === 'checkout') {
            if (selected === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `กรุณาเลือกที่อยู่จัดส่งให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            if (selected !== 'new') {
                setShipid(selected.toString());
            }
            if (payment === 'qrcode') {
                let items = [];
                for (const thisitem in carts.info) {
                    items.push(carts.info[thisitem]['id']);
                }
                let param = {
                    token: user['shop_token'],
                    payment_channel: 'bay-qr',
                    cart_id: items
                };
                if (selected !== 'new') {
                    param['shipping_address_id'] = selected.toString();
                } else {
                    if (firstname === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกชื่อผู้รับสินค้าให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (lastname === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกนามสกุลผู้รับสินค้าให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (contactnumber === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกเบอร์ติดต่อผู้รับสินค้าให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (province === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณาเลือกจังหวัดให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (district === '') {
                        let keyword =
                            province === '' ? 'เขต/อำเภอ' : province.label === 'กรุงเทพมหานคร' ? 'เขต' : 'อำเภอ';
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณาเลือก${keyword}ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (subdistrict === '') {
                        let keyword =
                            province === '' ? 'แขวง/ตำบล' : province.label === 'กรุงเทพมหานคร' ? 'แขวง' : 'ตำบล';
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณาเลือก${keyword}ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (zipcode === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกรหัสไปรษณีย์ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    if (address === '') {
                        SwalBs.fire({
                            // title: 'ยกเลิกคำสั่ง',
                            html: `กรุณากรอกที่อยู่ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                            icon: 'warning',
                            // showCancelButton: true,
                            confirmButtonText: 'รับทราบ',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                    param['add_address'] = {
                        contact_firstname: firstname,
                        contact_lastname: lastname,
                        contact_phonenumber: contactnumber,
                        address1: address,
                        province: province.value,
                        district: district.value,
                        subdistrict: subdistrict.value,
                        postalcode: zipcode
                    };
                }
                if (coupon !== '') {
                    param['coupon_id'] = [ coupon.value ];
                }
                if (remark !== '') {
                    param['remark'] = remark;
                }
                checkOut(param);
            } else {
                setPage('phonenumber');
            }
        } else if (page === 'phonenumber') {
            if (walletnumber === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `กรุณากรอกเบอร์ซิมปันสุขให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
            getOtpcode();
        } else if (page === 'otpcode') {
            let items = [];
            for (const thisitem in carts.info) {
                items.push(carts.info[thisitem]['id']);
            }
            let param = {
                token: user['shop_token'],
                payment_channel: 'punsook-wallet',
                cart_id: items,
                phonenumber: walletnumber,
                otp: otpcode,
                ref: refcode
            };
            if (shipid !== '') {
                param['shipping_address_id'] = shipid;
            } else {
                if (firstname === '') {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `กรุณากรอกชื่อผู้รับสินค้าให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                if (lastname === '') {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `กรุณากรอกนามสกุลผู้รับสินค้าให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                if (contactnumber === '') {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `กรุณากรอกเบอร์ติดต่อผู้รับสินค้าให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                if (province === '') {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `กรุณาเลือกจังหวัดให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                if (district === '') {
                    let keyword = province === '' ? 'เขต/อำเภอ' : province.label === 'กรุงเทพมหานคร' ? 'เขต' : 'อำเภอ';
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `กรุณาเลือก${keyword}ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                if (subdistrict === '') {
                    let keyword = province === '' ? 'แขวง/ตำบล' : province.label === 'กรุงเทพมหานคร' ? 'แขวง' : 'ตำบล';
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `กรุณาเลือก${keyword}ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                if (zipcode === '') {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `กรุณากรอกรหัสไปรษณีย์ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                if (address === '') {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `กรุณากรอกที่อยู่ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                param['add_address'] = {
                    contact_firstname: firstname,
                    contact_lastname: lastname,
                    contact_phonenumber: contactnumber,
                    address1: address,
                    province: province.value,
                    district: district.value,
                    subdistrict: subdistrict.value,
                    postalcode: zipcode
                };
            }
            if (coupon !== '') {
                param['coupon_id'] = [ coupon.value ];
            }
            if (remark !== '') {
                param['remark'] = remark;
            }
            checkOut(param);
        }
    };

    const btnEdit = (index, amount) => {
        setAmount(amount);
        let edited = editval;
        edited[index] = true;
        setEditVal(edited);
    };

    const btnSet = (pid) => {
        editCart(pid, amount);
    };

    const btnRemove = (pid) => {
        removeCart(pid, amount);
    };

    const btnSwitchAccount = () => {
        setPage('account');
    };

    const memberLogin = (phonenumber) => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/user/login', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret
            },
            body: JSON.stringify({
                username: phonenumber,
                password: user.id + phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                // setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    let edited = user;
                    edited['shop_account'] = phonenumber;
                    edited['shop_token'] = result.info.token;
                    props.newLogin(edited);
                    setPage('main');
                    getCarts();
                } else if (result.responsecode === 101) {
                    setLoading(false);
                    setPhonenumber(phonenumber);
                    setPage('login');
                } else {
                    setLoading(false);
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถเข้าสู่ระบบได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    const getCarts = () => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/cart/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user['shop_token']
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                setCarts(result);
                if (result.responsecode === 0) {
                    let totalprice = 0;
                    let json = {};
                    for (const object in result.info) {
                        json[object] = false;
                        totalprice += parseFloat(result.info[object]['total_price']);
                    }
                    setTotal(totalprice);
                    setEditVal(json);
                }
            })
            .catch((error) => {
                setLoading(false);
                // console.log(error);
            });
    };

    const editCart = (pid, amount) => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/cart/set', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user['shop_token'],
                product_id: pid,
                quantity: amount
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    setAmount(0);
                    setEditVal({});
                    getCarts();
                } else {
                    setLoading(false);
                    toast.warn(`${result.responsecode}! ไม่สามารถแก้ไขสินค้าในตะกร้าได้ กรุณาลองใหม่อีกครั้ง`, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error('ไม่สามารถแก้ไขสินค้าในตะกร้าได้ กรุณาลองใหม่อีกครั้ง', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
                // console.log(error);
            });
    };

    const removeCart = (pid, amount) => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/cart/remove', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user['shop_token'],
                product_id: pid,
                quantity: amount
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    setAmount(0);
                    setEditVal({});
                    getCarts();
                } else {
                    setLoading(false);
                    toast.warn(`${result.responsecode}! ไม่สามารถแก้ไขสินค้าในตะกร้าได้ กรุณาลองใหม่อีกครั้ง`, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error('ไม่สามารถแก้ไขสินค้าในตะกร้าได้ กรุณาลองใหม่อีกครั้ง', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
                // console.log(error);
            });
    };

    const checkOut = (param) => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/cart/checkout', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
                setPage('result');
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getOtpcode = (retry = false) => {
        setOtpcode('');
        setLoading(true);
        setDisableOtp(true);
        fetch(config.punsook_shop_url + 'punsook/otp-generate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: walletnumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setDisableOtp(false);
                if (result.responsecode === 0) {
                    setRefcode(result.refcode);
                    // let d = new Date();
                    // // d.setSeconds(d.getSeconds() + 10);
                    // d.setSeconds(d.getSeconds() + 5);
                    // setExpiretime(d);
                    setExpiretime(result.timeout);
                    setDisableBtn(true);
                    if (!retry) {
                        setPage('otpcode');
                    }
                } else if (result.responsecode === 100) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `หมายเลข ${phonenumber} ไม่ใช่เบอร์ในโครงการซิมปันสุข ไม่สามารถเข้าร่วมกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขได้`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                setDisableOtp(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    return (
        <div className='row m-0 shop-cart-page'>
            <ToastContainer />
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='col-12 p-0'>
                    {page === 'main' ? (
                        <div className='col-12 p-0'>
                            {Object.keys(carts).length <= 0 ? (
                                <div className='ibox m-0'>
                                    <div className='ibox-title'>
                                        <h5 className='d-inline'>
                                            ตะกร้าของเบอร์ {user.shop_account}{' '}
                                            <span
                                                className='d-inline text-danger text-hover-danger cursor-pointer'
                                                onClick={btnSwitchAccount}
                                            >
                                                เปลี่ยน
                                            </span>
                                        </h5>
                                    </div>
                                    <div className='ibox-content'>
                                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                            <div className='col-12 text-center'>
                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {carts.responsecode !== 0 ? (
                                        <div className='ibox m-0'>
                                            <div className='ibox-title'>
                                                <h5 className='d-inline'>
                                                    ตะกร้าของเบอร์ {user.shop_account}{' '}
                                                    <span
                                                        className='d-inline text-danger text-hover-danger cursor-pointer'
                                                        onClick={btnSwitchAccount}
                                                    >
                                                        เปลี่ยน
                                                    </span>
                                                </h5>
                                            </div>
                                            <div className='ibox-content'>
                                                <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                                    <div className='col-12 text-center'>
                                                        <h3>คุณไม่มีสินค้าในตะกร้า</h3>
                                                        <NavLink
                                                            to='/shop/product'
                                                            className='btn btn-white rounded-pill'
                                                        >
                                                            <i className='fa fa-arrow-left' /> เลือกซื้อสินค้า
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='row'>
                                            <div className='col-md-9'>
                                                <div className='ibox'>
                                                    <div className='ibox-title'>
                                                        <span className='pull-right'>
                                                            (<strong> {carts.info.length} </strong>) รายการ
                                                        </span>
                                                        <h5>
                                                            ตะกร้าของเบอร์ {user.shop_account}{' '}
                                                            <span
                                                                className='d-inline text-danger text-hover-danger cursor-pointer'
                                                                onClick={btnSwitchAccount}
                                                            >
                                                                เปลี่ยน
                                                            </span>
                                                        </h5>
                                                    </div>
                                                    <div className='ibox-content d-none d-lg-block'>
                                                        <div className='row'>
                                                            <div className='col-md-2'>{''}</div>
                                                            <div className='col-md-4 my-auto'>สินค้า</div>
                                                            <div className='col-md-3 my-auto text-center'>จำนวน</div>
                                                            <div className='col-md-3 my-auto'>ราคาต่อชิ้น</div>
                                                        </div>
                                                        {carts.info.map((object, index) => {
                                                            return (
                                                                <div className='row my-5' key={index}>
                                                                    <div className='col-md-2'>
                                                                        <img
                                                                            src={
                                                                                Object.keys(object.gallery).length >
                                                                                    0 &&
                                                                                object.gallery.cover_image !== '' ? (
                                                                                    `data:image/png;base64,${object
                                                                                        .gallery.cover_image}`
                                                                                ) : (
                                                                                    toAbsoluteUrl(
                                                                                        `/media/products/${index +
                                                                                            1}.png`
                                                                                    )
                                                                                )
                                                                            }
                                                                            alt={`product-${object.code}`}
                                                                            className='w-100 h-100'
                                                                        />
                                                                    </div>
                                                                    <div className='col-md-4 my-auto'>
                                                                        <NavLink
                                                                            to={`/shop/productdetail/${object.code}`}
                                                                            target={'_blank'}
                                                                            className='text-navy font-size-h3'
                                                                        >
                                                                            {object.name}
                                                                        </NavLink>
                                                                    </div>
                                                                    <div className='col-md-3 my-auto'>
                                                                        <div className='input-group'>
                                                                            {editval[index] && (
                                                                                <div className='input-group-prepend'>
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            btnRemove(
                                                                                                object.product_id
                                                                                            )}
                                                                                        className='btn btn-danger'
                                                                                        type='button'
                                                                                    >
                                                                                        <i className='flaticon2-rubbish-bin p-0' />
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                            {editval[index] ? (
                                                                                <input
                                                                                    type='text'
                                                                                    inputMode='tel'
                                                                                    className='form-control text-center'
                                                                                    value={amount}
                                                                                    onChange={(e) =>
                                                                                        setAmount(e.target.value)}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            if (event.key !== 'Enter') {
                                                                                                event.preventDefault();
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <input
                                                                                    type='text'
                                                                                    className='form-control text-center'
                                                                                    value={object.quantity}
                                                                                    disabled='disabled'
                                                                                />
                                                                            )}
                                                                            <div className='input-group-append'>
                                                                                {!editval[index] ? (
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            btnEdit(
                                                                                                index,
                                                                                                object.quantity
                                                                                            )}
                                                                                        className='btn btn-secondary'
                                                                                        type='button'
                                                                                    >
                                                                                        <i className='flaticon2-pen p-0' />
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            btnSet(object.product_id)}
                                                                                        className='btn btn-success'
                                                                                        type='button'
                                                                                    >
                                                                                        <i className='flaticon2-check-mark p-0' />
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-3 my-auto'>
                                                                        <h4 className='m-0'>
                                                                            <NumberFormat
                                                                                value={parseFloat(object.price)}
                                                                                displayType={'text'}
                                                                                decimalScale={2}
                                                                                fixedDecimalScale={true}
                                                                                thousandSeparator={true}
                                                                                suffix={' บาท'}
                                                                            />
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <div className='ibox-content d-block d-lg-none'>
                                                        {carts.info.map((object, index) => {
                                                            return (
                                                                <div className='row my-5' key={index}>
                                                                    <div className='col-4'>
                                                                        <img
                                                                            src={
                                                                                Object.keys(object.gallery).length >
                                                                                    0 &&
                                                                                object.gallery.cover_image !== '' ? (
                                                                                    `data:image/png;base64,${object
                                                                                        .gallery.cover_image}`
                                                                                ) : (
                                                                                    toAbsoluteUrl(
                                                                                        `/media/products/${index +
                                                                                            1}.png`
                                                                                    )
                                                                                )
                                                                            }
                                                                            alt={`product-${object.code}`}
                                                                            className='w-100 h-100'
                                                                        />
                                                                    </div>
                                                                    <div className='col-8 desc'>
                                                                        <div className='row'>
                                                                            <div className='col-12 my-auto'>
                                                                                <NavLink
                                                                                    to={`/shop/productdetail/${object.code}`}
                                                                                    target={'_blank'}
                                                                                    className='text-navy font-size-h3'
                                                                                >
                                                                                    {object.name}
                                                                                </NavLink>
                                                                            </div>
                                                                            <div className='col-12 my-auto'>
                                                                                <h4 className='m-0'>
                                                                                    ราคาต่อชิ้น{' '}
                                                                                    <NumberFormat
                                                                                        value={parseFloat(object.price)}
                                                                                        displayType={'text'}
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale={true}
                                                                                        thousandSeparator={true}
                                                                                        suffix={' บาท'}
                                                                                    />
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mt-5'>
                                                                            <div className='col-12 my-auto'>
                                                                                <div className='input-group'>
                                                                                    {editval[index] && (
                                                                                        <div className='input-group-prepend'>
                                                                                            <button
                                                                                                onClick={() =>
                                                                                                    btnRemove(
                                                                                                        object.product_id
                                                                                                    )}
                                                                                                className='btn btn-danger'
                                                                                                type='button'
                                                                                            >
                                                                                                <i className='flaticon2-rubbish-bin p-0' />
                                                                                            </button>
                                                                                        </div>
                                                                                    )}
                                                                                    {editval[index] ? (
                                                                                        <input
                                                                                            type='text'
                                                                                            className='form-control text-center'
                                                                                            value={amount}
                                                                                            onChange={(e) =>
                                                                                                setAmount(
                                                                                                    e.target.value
                                                                                                )}
                                                                                        />
                                                                                    ) : (
                                                                                        <input
                                                                                            type='text'
                                                                                            className='form-control text-center'
                                                                                            value={object.quantity}
                                                                                            disabled='disabled'
                                                                                        />
                                                                                    )}
                                                                                    <div className='input-group-append'>
                                                                                        {!editval[index] ? (
                                                                                            <button
                                                                                                onClick={() =>
                                                                                                    btnEdit(
                                                                                                        index,
                                                                                                        object.quantity
                                                                                                    )}
                                                                                                className='btn btn-secondary'
                                                                                                type='button'
                                                                                            >
                                                                                                <i className='flaticon2-pen p-0' />
                                                                                            </button>
                                                                                        ) : (
                                                                                            <button
                                                                                                onClick={() =>
                                                                                                    btnSet(
                                                                                                        object.product_id
                                                                                                    )}
                                                                                                className='btn btn-success'
                                                                                                type='button'
                                                                                            >
                                                                                                <i className='flaticon2-check-mark p-0' />
                                                                                            </button>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className='ibox'>
                                                    <div className='ibox-title'>
                                                        <h5>สรุปค่าสินค้า</h5>
                                                    </div>
                                                    <div className='ibox-content'>
                                                        <span>ราคารวม</span>
                                                        <h2 className='font-bold'>
                                                            <NumberFormat
                                                                value={parseFloat(total)}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                                suffix={' บาท'}
                                                            />
                                                        </h2>
                                                        <hr />
                                                        {/* <span className='text-muted small'>
                                                    *For United States, France and Germany applicable sales tax will be
                                                    applied
                                                </span> */}
                                                        <div className='m-t-sm'>
                                                            <div className='row justify-content-end'>
                                                                {carts.info.length > 0 && (
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-primary rounded-pill'
                                                                        onClick={btnNext}
                                                                    >
                                                                        <i className='fa fa-shopping-cart' />{' '}
                                                                        สั่งซื้อสินค้า
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : page === 'account' ? (
                        <ShopCartSwitchAccount
                            memberLogin={memberLogin}
                            setPage={setPage}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    ) : page === 'login' ? (
                        <ShopCartAccountLogin
                            setPage={setPage}
                            phonenumber={phonenumber}
                            setLoading={setLoading}
                            user={user}
                            getCarts={getCarts}
                        />
                    ) : page === 'checkout' ? (
                        <ShopCartCheckout
                            shoptoken={user['shop_token']}
                            cartitems={cartitems}
                            total={total}
                            carts={carts}
                            payment={payment}
                            setPayment={setPayment}
                            firstname={firstname}
                            setFirstname={setFirstname}
                            lastname={lastname}
                            setLastname={setLastname}
                            contactnumber={contactnumber}
                            setContactnumber={setContactnumber}
                            province={province}
                            setProvince={setProvince}
                            district={district}
                            setDistrict={setDistrict}
                            subdistrict={subdistrict}
                            setSubdistrict={setSubdistrict}
                            zipcode={zipcode}
                            setZipcode={setZipcode}
                            address={address}
                            setAddress={setAddress}
                            coupon={coupon}
                            setCoupon={setCoupon}
                            coupons={coupons}
                            setCoupons={setCoupons}
                            remark={remark}
                            setRemark={setRemark}
                            btnNext={btnNext}
                        />
                    ) : page === 'phonenumber' ? (
                        <ShopCartPhonenumber
                            walletnumber={walletnumber}
                            setWalletnumber={setWalletnumber}
                            btnNext={btnNext}
                        />
                    ) : page === 'otpcode' ? (
                        <ShopCartOtpcode
                            Completionist={Completionist}
                            renderer={renderer}
                            otpcode={otpcode}
                            refcode={refcode}
                            expiretime={expiretime}
                            handleOtp={handleOtp}
                            disablebtn={disablebtn}
                            btnNext={btnNext}
                        />
                    ) : page === 'result' ? (
                        <ShopCartResult payment={payment} response={response} />
                    ) : null}
                </div>
            )}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(ShopCartMain));
