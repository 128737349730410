import React from "react";

import Select, { components } from "react-select";

import TextareaAutosize from "react-textarea-autosize";

const controlStyles = {
    borderRadius: "0.85rem",
    padding: "5px",
    background: "#F3F6F9",
    color: "white",
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#F3F6F9",
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
    }),
};

export function NCAddress(prop) {
    return (
        <>
            <div className="form-group fv-plugins-icon-container">
                <label>เลือกที่อยู่จัดส่ง</label>
                <div className="row">
                    <div className="col-lg-6">
                        <label className="option">
                            <span className="option-control">
                                <span className="radio">
                                    <input
                                        type="radio"
                                        name="shippingtype"
                                        value="ST1"
                                        checked={prop.shippingtype === "ST1"}
                                        onChange={() =>
                                            prop.handleShippingType("ST1")
                                        }
                                    />
                                    <span />
                                </span>
                            </span>
                            <span className="option-label">
                                <span className="option-head">
                                    <span className="option-title">
                                        ตามที่อยู่ที่ติดต่อได้
                                    </span>
                                </span>
                            </span>
                        </label>
                    </div>
                    <div className="col-lg-6">
                        <label className="option">
                            <span className="option-control">
                                <span className="radio">
                                    <input
                                        type="radio"
                                        name="shippingtype"
                                        value="ST2"
                                        checked={prop.shippingtype === "ST2"}
                                        onChange={() =>
                                            prop.handleShippingType("ST2")
                                        }
                                    />
                                    <span />
                                </span>
                            </span>
                            <span className="option-label">
                                <span className="option-head">
                                    <span className="option-title">
                                        ที่อยู่อื่นนอกจากที่อยู่ที่ติดต่อได้
                                    </span>
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="form-group fv-plugins-icon-container">
                <label>ที่อยู่จัดส่ง</label>
                <div className="row">
                    {prop.shippingtype === "ST1" ? (
                        <div className="col-lg-12">
                            <label className="option">
                                <span className="option-label">
                                    <span className="option-head">
                                        <span className="option-title">
                                            ชื่อผู้รับสินค้า
                                        </span>
                                        <span className="option-focus">
                                            {prop.ogname}
                                        </span>
                                    </span>
                                    <span className="option-head">
                                        <span className="option-title">
                                            เบอร์โทรศัพท์ติดต่อ
                                        </span>
                                        <span className="option-focus">
                                            {prop.ogcontactnumber}
                                        </span>
                                    </span>
                                    <span className="option-head">
                                        <span className="option-title">
                                            ภาค
                                        </span>
                                        <span className="option-focus">
                                            {prop.oggeo}
                                        </span>
                                    </span>
                                    <span className="option-head">
                                        <span className="option-title">
                                            จังหวัด
                                        </span>
                                        <span className="option-focus">
                                            {prop.ogprovince}
                                        </span>
                                    </span>
                                    <span className="option-head">
                                        <span className="option-title">
                                            {prop.ogprovince === "กรุงเทพมหานคร"
                                                ? "เขต"
                                                : "อำเภอ"}
                                        </span>
                                        <span className="option-focus">
                                            {prop.ogdistrict}
                                        </span>
                                    </span>
                                    <span className="option-head">
                                        <span className="option-title">
                                            {prop.ogprovince === "กรุงเทพมหานคร"
                                                ? "แขวง"
                                                : "ตำบล"}
                                        </span>
                                        <span className="option-focus">
                                            {prop.ogsubdistrict}
                                        </span>
                                    </span>
                                    <span className="option-head">
                                        <span className="option-title">
                                            รหัสไปรษณีย์
                                        </span>
                                        <span className="option-focus">
                                            {prop.ogzipcode}
                                        </span>
                                    </span>
                                    <span className="option-head">
                                        <span className="option-title">
                                            บ้านเลขที่ ถนน ซอย
                                        </span>
                                        <span className="option-focus text-right">
                                            {prop.ogaddr}
                                        </span>
                                    </span>
                                </span>
                            </label>
                        </div>
                    ) : (
                        <div className="pb-5 col-xl-12">
                            <label className="option">
                                <span className="option-label">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="form-group fv-plugins-icon-container has-danger">
                                                <label>
                                                    ชื่อผู้รับสินค้า
                                                    <span className="required-text">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-solid form-control-lg"
                                                    defaultValue={prop.name}
                                                    onChange={(e) =>
                                                        prop.handleName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {prop.name === "" && (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            กรุณากรอกชื่อผู้รับสินค้า
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group fv-plugins-icon-container has-danger">
                                                <label>
                                                    เบอร์โทรศัพท์ติดต่อ
                                                    <span className="required-text">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="tel"
                                                    className="form-control form-control-solid form-control-lg"
                                                    defaultValue={
                                                        prop.contactnumber
                                                    }
                                                    onChange={(e) =>
                                                        prop.handleContactnumber(
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyPress={(event) => {
                                                        if (
                                                            !/[0-9]/.test(
                                                                event.key
                                                            )
                                                        ) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxLength="10"
                                                    onInput={
                                                        prop.maxLengthCheck
                                                    }
                                                />
                                                {prop.contactnumber === "" ? (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            กรุณากรอกเบอร์โทรศัพท์ติดต่อ
                                                        </div>
                                                    </div>
                                                ) : (
                                                    (prop.contactnumber.charAt(
                                                        0
                                                    ) !== "0" ||
                                                        prop.contactnumber
                                                            .length < 9) && (
                                                        <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">
                                                                กรุณากรอกเบอร์โทรศัพท์ติดต่อให้ถูกต้อง
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label>
                                                    ภูมิภาค
                                                    <span className="required-text">
                                                        *
                                                    </span>
                                                </label>
                                                <div>
                                                    <Select
                                                        components={{
                                                            Control: ControlComponent,
                                                        }}
                                                        noOptionsMessage={() =>
                                                            "ไม่พบข้อมูล"
                                                        }
                                                        value={prop.geo}
                                                        options={prop.maingeo}
                                                        placeholder="เลือก"
                                                        id="geo"
                                                        onChange={
                                                            prop.selectGeo
                                                        }
                                                        isClearable={true}
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        styles={customStyles}
                                                    />
                                                </div>
                                                {prop.geo === "" && (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            กรุณาเลือกภูมิภาค
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label>
                                                    จังหวัด
                                                    <span className="required-text">
                                                        *
                                                    </span>
                                                </label>
                                                <div>
                                                    <Select
                                                        components={{
                                                            Control: ControlComponent,
                                                        }}
                                                        noOptionsMessage={() =>
                                                            "ไม่พบข้อมูล"
                                                        }
                                                        value={prop.province}
                                                        options={
                                                            prop.mainprovince
                                                        }
                                                        placeholder="เลือก"
                                                        id="province"
                                                        onChange={
                                                            prop.selectProvince
                                                        }
                                                        isClearable={true}
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        styles={customStyles}
                                                        isDisabled={
                                                            prop.mainprovince ===
                                                                undefined ||
                                                            prop.mainprovince
                                                                .length === 0
                                                        }
                                                    />
                                                </div>
                                                {prop.province === "" && (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            กรุณาเลือกจังหวัด
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label>
                                                    {prop.province.label ===
                                                    "กรุงเทพมหานคร"
                                                        ? "เขต"
                                                        : "อำเภอ"}
                                                    <span className="required-text">
                                                        *
                                                    </span>
                                                </label>
                                                <div>
                                                    <Select
                                                        components={{
                                                            Control: ControlComponent,
                                                        }}
                                                        noOptionsMessage={() =>
                                                            "ไม่พบข้อมูล"
                                                        }
                                                        value={prop.district}
                                                        options={
                                                            prop.maindistrict
                                                        }
                                                        placeholder="เลือก"
                                                        id="geo"
                                                        onChange={
                                                            prop.selectDistrict
                                                        }
                                                        isClearable={true}
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        styles={customStyles}
                                                        isDisabled={
                                                            prop.maindistrict ===
                                                                undefined ||
                                                            prop.maindistrict
                                                                .length === 0
                                                        }
                                                    />
                                                </div>
                                                {prop.district === "" && (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            กรุณาเลือก
                                                            {prop.province
                                                                .label ===
                                                            "กรุงเทพมหานคร"
                                                                ? "เขต"
                                                                : "อำเภอ"}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label>
                                                    {prop.province.label ===
                                                    "กรุงเทพมหานคร"
                                                        ? "แขวง"
                                                        : "ตำบล"}
                                                    <span className="required-text">
                                                        *
                                                    </span>
                                                </label>
                                                <div>
                                                    <Select
                                                        components={{
                                                            Control: ControlComponent,
                                                        }}
                                                        noOptionsMessage={() =>
                                                            "ไม่พบข้อมูล"
                                                        }
                                                        value={prop.subdistrict}
                                                        options={
                                                            prop.mainsubdistrict
                                                        }
                                                        placeholder="เลือก"
                                                        id="province"
                                                        onChange={
                                                            prop.selectSubDistrict
                                                        }
                                                        isClearable={true}
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        styles={customStyles}
                                                        isDisabled={
                                                            prop.mainsubdistrict ===
                                                                undefined ||
                                                            prop.mainsubdistrict
                                                                .length === 0
                                                        }
                                                    />
                                                </div>
                                                {prop.subdistrict === "" && (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            กรุณาเลือก
                                                            {prop.province
                                                                .label ===
                                                            "กรุงเทพมหานคร"
                                                                ? "แขวง"
                                                                : "ตำบล"}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="form-group fv-plugins-icon-container">
                                                <label>
                                                    รหัสไปรษณีย์
                                                    <span className="required-text">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-solid form-control-lg"
                                                    defaultValue={prop.zipcode}
                                                    onChange={(e) =>
                                                        prop.handleZipcode(
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyPress={(event) => {
                                                        if (
                                                            !/[0-9]/.test(
                                                                event.key
                                                            )
                                                        ) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxLength="5"
                                                    onInput={
                                                        prop.maxLengthCheck
                                                    }
                                                />
                                                {prop.zipcode === "" && (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">
                                                            กรุณากรอกรหัสไปรษณีย์
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group fv-plugins-icon-container">
                                        <label>
                                            บ้านเลขที่ ถนน ซอย
                                            <span className="required-text">
                                                *
                                            </span>
                                        </label>
                                        <TextareaAutosize
                                            className="form-control form-control-solid form-control-lg textarea-min-h"
                                            defaultValue={prop.addr}
                                            onChange={(e) =>
                                                prop.handleAddress(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        {prop.addr === "" && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">
                                                    กรุณากรอกบ้านเลขที่ ถนน ซอย
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </span>
                            </label>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
