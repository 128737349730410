import React from 'react';

import { useSelector } from 'react-redux';

import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../_metronic/_helpers';

import { NavLink } from 'react-router-dom';

import { SubscriberList } from '../Setting';

export function MobileMenu() {
    const { user } = useSelector((state) => state.auth);

    return (
        <div className='row p-0 m-0 mb-15'>
            <NavLink className='col-12 p-0' to='/profile/edit'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='แก้ไขโปรไฟล์'
                                    src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
                                />
                            </span>
                            <h5 className='my-auto font-weight-bolder text-dark'>แก้ไขโปรไฟล์</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            {!SubscriberList.includes(user.main_position) && (
                <NavLink className='col-12 p-0' to='/agreement'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                {/* <div className="col-12"> */}
                                <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                    <SVG
                                        title='สัญญาแต่งตั้งดีลเลอร์'
                                        src={toAbsoluteUrl('/media/svg/icons/Files/File.svg')}
                                    />
                                </span>
                                {/* </div> */}
                                <h5 className='my-auto font-weight-bolder text-dark'>สัญญาแต่งตั้งดีลเลอร์</h5>
                            </div>
                        </div>
                    </div>
                </NavLink>
            )}
            {!SubscriberList.includes(user.main_position) && (
                <NavLink className='col-12 p-0' to='/tw50'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                {/* <div className="col-12"> */}
                                <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                    <SVG
                                        title='หนังสือรับรองการหักภาษี ณ ที่จ่าย'
                                        src={toAbsoluteUrl('/media/svg/icons/Files/File.svg')}
                                    />
                                </span>
                                {/* </div> */}
                                <h5 className='my-auto font-weight-bolder text-dark'>
                                    หนังสือรับรองการหักภาษี ณ ที่จ่าย
                                </h5>
                            </div>
                        </div>
                    </div>
                </NavLink>
            )}
            {!SubscriberList.includes(user.main_position) && (
                <NavLink className='col-12 p-0' to='/payment/report'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                {/* <div className="col-12"> */}
                                <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                    <SVG
                                        title='ประวัติธุรกรรม'
                                        src={toAbsoluteUrl('/media/svg/icons/Navigation/Exchange.svg')}
                                    />
                                </span>
                                {/* </div> */}
                                <h5 className='my-auto font-weight-bolder text-dark'>ประวัติธุรกรรม</h5>
                            </div>
                        </div>
                    </div>
                </NavLink>
            )}
            <NavLink className='col-12 p-0' to='/order/report'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='ประวัติรายการสั่งซื้อ'
                                    src={toAbsoluteUrl('/media/svg/icons/General/Clipboard.svg')}
                                />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>ประวัติรายการสั่งซื้อ</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            <a className='col-12 p-0' href={`https://tutorial.simk4.com/`} target='_blank' rel='noopener noreferrer'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG title='คู่มือการใช้งาน' src={toAbsoluteUrl('/media/svg/icons/Home/Book.svg')} />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>คู่มือการใช้งาน</h5>
                        </div>
                    </div>
                </div>
            </a>
            <a
                className='col-12 p-0'
                href={`https://line.me/ti/p/~@dealersimsupport`}
                target='_blank'
                rel='noopener noreferrer'
            >
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='ติดต่อเจ้าหน้าที่'
                                    src={toAbsoluteUrl('/media/svg/icons/Communication/Chat1.svg')}
                                />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>ติดต่อเจ้าหน้าที่</h5>
                        </div>
                    </div>
                </div>
            </a>
            <a
                className='col-12 p-0 mb-3'
                href={`https://cooperative.simk4.com/`}
                target='_blank'
                rel='noopener noreferrer'
            >
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <i className='fas fa-university fa-2x text-danger' />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>สหกรณ์ปันสุข</h5>
                        </div>
                    </div>
                </div>
            </a>
            <NavLink className='col-12 p-0' to='/logout'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='ออกจากระบบ'
                                    src={toAbsoluteUrl('/media/svg/icons/Navigation/Sign-out.svg')}
                                />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>ออกจากระบบ</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
}
