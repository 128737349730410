import React, { useEffect, useState } from 'react';

import NumberFormat from 'react-number-format';

import config from '../../environments/config';

import { SubscriberList, UpgradeList } from '../../../Setting';

export function UCPackages(prop) {
    const [ phonenumbers, setPhonenumbers ] = useState([]);
    const [ pos, setPos ] = useState('');
    const [ packages, setPackages ] = useState({});
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getDealerPackage();
    }, []);

    const getPhonenumbers = () => {
        fetch(config.py_url + config.version + '/profile/phonenumber_list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: prop.userid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    setLoading(false);
                    let avilableList = [];
                    for (const index in result.numbers) {
                        if (
                            SubscriberList.includes(result.numbers[index]['position'])
                        ) {
                            avilableList.push({
                                'phonenumber' : result.numbers[index]['phonenumber'],
                                'pos' : result.numbers[index]['position']
                            });
                        }
                    }
                    setPhonenumbers(avilableList);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getDealerPackage = () => {
        fetch(config.py_url + config.version + '/product/getproduct', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                // userid: prop.userid,
                packs_id: [ '5' ]
            })
        })
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);
                // setInit(true);
                if (result.responsecode === 0) {
                    setPackages(result.product);
                    getPhonenumbers();
                    //     setNumberList(result.numbers);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                // setInit(true);
            });
    };
    return (
        <>
            {!loading ? (
                <>
                    <div className='form-group fv-plugins-icon-container'>
                        <label>เบอร์เข้าร่วมโครงการ</label>
                        <div className='row'>
                            {/* <div className='col-lg-6'>
                                <label className='option'>
                                    <span className='option-control'>
                                        <span className='radio'>
                                            <input
                                                type='radio'
                                                name='packagetype'
                                                value={prop.phonenumber}
                                                defaultChecked
                                            />
                                            <span />
                                        </span>
                                    </span>
                                    <span className='option-label'>
                                        <span className='option-head'>
                                            <span className='option-title'>{prop.phonenumber}</span>
                                        </span>
                                    </span>
                                </label>
                            </div> */}
                            {phonenumbers.length > 0 ? (
                                phonenumbers.map((object, index) => {
                                    return (
                                        <div className='col-lg-6' key={index}>
                                            <label className='option'>
                                                <span className='option-control'>
                                                    <span className='radio'>
                                                        <input
                                                            type='radio'
                                                            name='packagetype'
                                                            value={object.phonenumber}
                                                            checked={prop.phonenumber === object.phonenumber}
                                                            onChange={() => {
                                                                prop.setPhonenumber(object.phonenumber);
                                                                setPos(object.pos);
                                                            }}
                                                        />
                                                        <span />
                                                    </span>
                                                </span>
                                                <span className='option-label'>
                                                    <span className='option-head'>
                                                        <span className='option-title'>{object.phonenumber}</span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className='col-lg-12 text-center py-15'>
                                    <p>ไม่มีเบอร์ที่สามารถอัพเกรดได้ในตอนนี้</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='form-group fv-plugins-icon-container'>
                        <label>เลือกขนาดสัญญาดีลเลอร์</label>
                        <div className='row'>
                            {phonenumbers.length > 0 && Object.keys(packages).length > 0 ? (
                                Object.keys(packages).map((object, index) => {
                                    return (
                                        <div className='col-lg-6' key={index}>
                                            <label className={`option${packages[object]['ignore_pos'].includes(pos) || prop.phonenumber === '' ? ' bg-light' : ''}`}>
                                                <span className='option-control'>
                                                    <span className={`radio${packages[object]['ignore_pos'].includes(pos) || prop.phonenumber === '' ? ' cursor-disable' : ''}`}>
                                                        <input
                                                            type='radio'
                                                            name='packageprice'
                                                            value={object}
                                                            checked={prop.price === object}
                                                            onChange={() => {
                                                                prop.handlePrice(object);
                                                                prop.handlePackages(packages[object]);
                                                            }}
                                                            disabled={packages[object]['ignore_pos'].includes(pos) || prop.phonenumber === ''}
                                                        />
                                                        <span />
                                                    </span>
                                                </span>
                                                <span className='option-label'>
                                                    <span className='option-head'>
                                                        <span className='option-title'>{packages[object].title}</span>
                                                        <span className='option-focus'>
                                                            <NumberFormat
                                                                value={parseFloat(packages[object].price)}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                            />
                                                        </span>
                                                    </span>
                                                    <span className='option-body'>{packages[object].detail}</span>
                                                </span>
                                            </label>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className='col-lg-12 text-center py-15'>
                                    <p>ไม่มีแพ็กเกจในตอนนี้</p>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <div className='row'>
                    <div className='spinner-border mx-auto text-success mt-25 mb-10 p-5' role='status'>
                        <span className='sr-only'>Loading...</span>
                    </div>
                    <h1 className='d-block col-12 text-center mb-15'>กรุณารอสักครู่</h1>
                </div>
            )}
        </>
    );
}
