import React from 'react';
import NumberFormat from 'react-number-format';

export function SubWalletWithdrawSummary(props) {
    return (
        <div className='row col-12 p-0 m-0'>
            <div className='flex-row-fluid col-12 p-0 m-0'>
                <div className='d-flex row align-items-center justify-content-center'>
                    <h1 className='col-xl-12 text-center'>ตรวจสอบข้อมูลการถอนเงิน</h1>
                    <div className='col-xl-12'>
                        <div className='row m-0 p-3'>
                            <div className='d-flex col-xl-12 p-0'>
                                <div className='p-2'>ชื่อธนาคาร</div>
                                <div className='ml-auto p-2'>{props.bank}</div>
                            </div>
                            <div className='d-flex col-xl-12 p-0'>
                                <div className='p-2'>ชื่อบัญชี</div>
                                <div className='ml-auto p-2'>{props.account}</div>
                            </div>
                            <div className='d-flex col-xl-12 p-0'>
                                <div className='p-2'>เลขที่บัญชี</div>
                                <div className='ml-auto p-2'>{props.bankno}</div>
                            </div>
                            <div className='d-flex col-xl-12 p-0'>
                                <div className='p-2'>จำนวนเงินที่ต้องการถอน</div>
                                <div className='ml-auto p-2'>
                                    <NumberFormat
                                        value={parseFloat(props.amount)}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                    />
                                </div>
                            </div>
                            <div className='d-flex col-xl-12 p-0'>
                                <div className='p-2'>ค่าธรรมเนียม</div>
                                <div className='ml-auto p-2'>
                                    <NumberFormat
                                        value={parseFloat(props.fee)}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                    />
                                </div>
                            </div>
                            <div className='d-flex col-xl-12 p-0'>
                                <div className='p-2'>ภาษีหัก ณ ที่จ่าย {parseInt(props.tax)} %</div>
                                <div className='ml-auto p-2'>
                                    <NumberFormat
                                        value={parseFloat(parseFloat(props.amount) * parseFloat(props.tax) / 100)}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                    />
                                </div>
                            </div>
                            <div className='d-flex col-xl-12 p-0'>
                                <div className='p-2'>จำนวนเงินที่ได้รับ</div>
                                <div className='ml-auto p-2'>
                                    <NumberFormat
                                        value={parseFloat(
                                            parseFloat(props.amount) -
                                                (props.fee +
                                                    parseFloat(parseFloat(props.amount) * parseFloat(props.tax) / 100))
                                        )}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 d-flex justify-content-end border-top mt-5 pt-5 pt-md-10'>
                <button
                    type='button'
                    className='btn btn-success btn-cus-rounded font-weight-bold text-uppercase px-9 py-4'
                    onClick={props.btnNext}
                >
                    ยืนยัน
                </button>
            </div>
        </div>
    );
}
