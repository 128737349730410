import React from 'react';

import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';

import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../_metronic/_helpers';

import { SubscriberList } from '../Setting';

export function MobileDealerMenu() {
    const { user } = useSelector((state) => state.auth);

    return (
        <div className='row p-0 m-0 mb-15'>
            <NavLink className='col-12 p-0' to='/dealer/upgrade-contact'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='อัพเกรดดีลเลอร์'
                                    src={toAbsoluteUrl('/media/svg/icons/Files/Uploaded-file.svg')}
                                />
                            </span>
                            <h5 className='my-auto font-weight-bolder text-dark'>อัพเกรดเป็นดีลเลอร์</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            {!SubscriberList.includes(user.main_position) && (
                <NavLink className='col-12 p-0' to='/dealer/newcontact'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                    <SVG
                                        title='เปิดสิทธิดีลเลอร์ใหม่'
                                        src={toAbsoluteUrl('/media/svg/icons/Files/File-plus.svg')}
                                    />
                                </span>
                                <h5 className='my-auto font-weight-bolder text-dark'>เปิดสิทธิดีลเลอร์ใหม่</h5>
                            </div>
                        </div>
                    </div>
                </NavLink>
            )}
            {!SubscriberList.includes(user.main_position) && (
                <NavLink className='col-12 p-0' to='/dealer/subscriber/newcontact'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                    <SVG
                                        title='ปักหมุดดีลเลอร์'
                                        src={toAbsoluteUrl('/media/svg/icons/Files/File-plus.svg')}
                                    />
                                </span>
                                <h5 className='my-auto font-weight-bolder text-dark'>เปิดสิทธิผู้ใช้งานใหม่</h5>
                            </div>
                        </div>
                    </div>
                </NavLink>
            )}
        </div>
    );
}
