import React from 'react';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import SVG from 'react-inlinesvg';

export function ShopInsurancePackage(props) {
    return (
        <div className='col-12 p-0'>
            <div className='card bg-danger d-lg-none d-block'>
                <div className='card-body insurance-button'>
                    <div className='form-group'>
                        <div className='selector'>
                            <div
                                className={`cursor-pointer selection${props.plan === 'แผน 1' ? ' selected' : ''}`}
                                onClick={() => props.setPlan('แผน 1')}
                            >
                                แผน 1
                            </div>
                            <div
                                className={`cursor-pointer selection${props.plan === 'แผน 2' ? ' selected' : ''}`}
                                onClick={() => props.setPlan('แผน 2')}
                            >
                                แผน 2
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center m-0 position-relative'>
                        <div className='d-none d-lg-block bg-danger position-absolute w-100 h-100 rounded-card-top' />
                        <div className='col-12 p-0'>
                            <div className='row'>
                                {props.plan === 'แผน 1' ? (
                                    <div className='offset-lg-4 col-12 col-lg-4 bg-white p-0'>
                                        <div className='py-15 px-0 px-lg-5 text-center'>
                                            <div className='d-flex flex-center mb-7'>
                                                <span className='svg-icon svg-icon-5x svg-icon-primary'>
                                                    <SVG
                                                        title='แผน 1'
                                                        src={toAbsoluteUrl('/media/svg/icons/Text/H1.svg')}
                                                    />
                                                </span>
                                            </div>
                                            <h4 className='font-size-h3 mb-10 text-dark'>แผน 1</h4>
                                            {/* <div className='d-flex flex-column pb-7 text-dark-50'>
                                        <span>Lorem ipsum dolor adipiscing</span>
                                        <span>do eiusmod tempors</span>
                                    </div> */}
                                            <span className='font-size-h1 font-weight-boldest text-dark'>
                                                {parseInt(props.productprices[0])}
                                                <sub className='font-size-h3 font-weight-normal pl-1'>บาท</sub>
                                            </span>
                                            <div className='d-lg-none mt-5'>
                                                <div className='bg-gray-100 py-3'>
                                                    <span className='font-weight-boldest mr-2'>
                                                        กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา หรือทุพพลภาพถาวรสิ้นเชิง
                                                        เนื่องจากอุบัติเหตุทั่วไป (อบ.1)
                                                        (ไม่รวมการถูกฆาตกรรมหรืถูกทำร้ายร่างกาย
                                                        และ/หรือการขับขี่หรือโดยสารรถจักรยานยนต์)
                                                    </span>
                                                    <span>200,000</span>
                                                </div>
                                                <div className='py-3'>
                                                    <span className='font-weight-boldest mr-2'>
                                                        กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                        หรือทุพพลภาพถาวรสิ้นเชิงจากการถูกฆาตกรรมหรือถูกลอบทำร้ายร่างกาย
                                                    </span>
                                                    <span>200,000</span>
                                                </div>
                                                <div className='bg-gray-100 py-3'>
                                                    <span className='font-weight-boldest mr-2'>
                                                        กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                        หรือทุพพลภาพถาวรสิ้นเชิงจากการขับขี่ หรือโดยสารรถจักรยานยนต์
                                                    </span>
                                                    <span>100,000</span>
                                                </div>
                                                <div className='py-3'>
                                                    <span className='font-weight-boldest mr-2'>
                                                        ค่ารักษาพยาบาล (ต่ออุบัติเหตุแต่ละครั้ง)
                                                        เนื่องจากอุบัติเหตุทั่วไป ไม่รวมการขับขี่หรือโดยสารถจักรยานยนต์
                                                    </span>
                                                    <span>15,000</span>
                                                </div>
                                                <div className='bg-gray-100 py-3'>
                                                    <span className='font-weight-boldest mr-2'>
                                                        ค่าปลงศพหรือค่าใช้จ่ายในการจัดการงานศพ
                                                        กรณีเสียชีวิตจากการเจ็บป่วย
                                                    </span>
                                                    <span>20,000</span>
                                                </div>
                                                {/* <div className='py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                เบี้ยประกัน (ก่อนภาษีมูลค่าเพิ่ม)
                                            </span>
                                            <span>990</span>
                                        </div> */}
                                            </div>
                                            <div className='mt-7'>
                                                <button
                                                    type='button'
                                                    className='btn btn-primary text-uppercase font-weight-bolder px-15 py-3'
                                                    onClick={() =>
                                                        props.btnPlan(
                                                            'แผน 1',
                                                            props.productprices[0],
                                                            props.productvats[0],
                                                            props.productcodes[0]
                                                        )}
                                                >
                                                    เลือก
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : props.plan === 'แผน 2' ? (
                                    <div className='col-12 col-lg-4 bg-white border-x-0 border-x-lg border-y border-y-lg-0 p-0'>
                                        <div className='py-15 px-0 px-lg-5 text-center'>
                                            <div className='d-flex flex-center mb-7'>
                                                <span className='svg-icon svg-icon-5x svg-icon-primary'>
                                                    <SVG
                                                        title='แผน 2'
                                                        src={toAbsoluteUrl('/media/svg/icons/Text/H2.svg')}
                                                    />
                                                </span>
                                            </div>
                                            <h4 className='font-size-h3 mb-10 text-dark'>แผน 2</h4>
                                            {/* <div className='d-flex flex-column pb-7 text-dark-50'>
                                        <span>Lorem ipsum dolor adipiscing</span>
                                        <span>do eiusmod tempors</span>
                                    </div> */}
                                            <span className='font-size-h1 font-weight-boldest text-dark'>
                                                {parseInt(props.productprices[1])}
                                                <sub className='font-size-h3 font-weight-normal pl-1'>บาท</sub>
                                            </span>
                                            <div className='d-lg-none mt-5'>
                                                <div className='bg-gray-100 py-3'>
                                                    <span className='font-weight-boldest mr-2'>
                                                        กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา หรือทุพพลภาพถาวรสิ้นเชิง
                                                        เนื่องจากอุบัติเหตุทั่วไป (อบ.1)
                                                        (ไม่รวมการถูกฆาตกรรมหรืถูกทำร้ายร่างกาย
                                                        และ/หรือการขับขี่หรือโดยสารรถจักรยานยนต์)
                                                    </span>
                                                    <span>500,000</span>
                                                </div>
                                                <div className='py-3'>
                                                    <span className='font-weight-boldest mr-2'>
                                                        กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                        หรือทุพพลภาพถาวรสิ้นเชิงจากการถูกฆาตกรรมหรือถูกลอบทำร้ายร่างกาย
                                                    </span>
                                                    <span>500,000</span>
                                                </div>
                                                <div className='bg-gray-100 py-3'>
                                                    <span className='font-weight-boldest mr-2'>
                                                        กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                        หรือทุพพลภาพถาวรสิ้นเชิงจากการขับขี่ หรือโดยสารรถจักรยานยนต์
                                                    </span>
                                                    <span>250,000</span>
                                                </div>
                                                <div className='py-3'>
                                                    <span className='font-weight-boldest mr-2'>
                                                        ค่ารักษาพยาบาล (ต่ออุบัติเหตุแต่ละครั้ง)
                                                        เนื่องจากอุบัติเหตุทั่วไป ไม่รวมการขับขี่หรือโดยสารถจักรยานยนต์
                                                    </span>
                                                    <span>30,000</span>
                                                </div>
                                                <div className='bg-gray-100 py-3'>
                                                    <span className='font-weight-boldest mr-2'>
                                                        ค่าปลงศพหรือค่าใช้จ่ายในการจัดการงานศพ
                                                        กรณีเสียชีวิตจากการเจ็บป่วย
                                                    </span>
                                                    <span>30,000</span>
                                                </div>
                                                {/* <div className='py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                เบี้ยประกัน (ก่อนภาษีมูลค่าเพิ่ม)
                                            </span>
                                            <span>2,500</span>
                                        </div> */}
                                            </div>
                                            <div className='mt-7'>
                                                <button
                                                    type='button'
                                                    className='btn btn-primary text-uppercase font-weight-bolder px-15 py-3'
                                                    onClick={() =>
                                                        props.btnPlan(
                                                            'แผน 2',
                                                            props.productprices[1],
                                                            props.productvats[1],
                                                            props.productcodes[1]
                                                        )}
                                                >
                                                    เลือก
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-body position-relative p-0 rounded-card-top d-lg-block d-none'>
                <div className='row justify-content-center m-0 position-relative'>
                    <div className='d-none d-lg-block bg-danger position-absolute w-100 h-100 rounded-card-top' />
                    <div className='col-11'>
                        <div className='row'>
                            <div className='offset-lg-4 col-12 col-lg-4 bg-white p-0'>
                                <div className='py-15 px-0 px-lg-5 text-center'>
                                    <div className='d-flex flex-center mb-7'>
                                        <span className='svg-icon svg-icon-5x svg-icon-primary'>
                                            <SVG title='แผน 1' src={toAbsoluteUrl('/media/svg/icons/Text/H1.svg')} />
                                        </span>
                                    </div>
                                    <h4 className='font-size-h3 mb-10 text-dark'>แผน 1</h4>
                                    {/* <div className='d-flex flex-column pb-7 text-dark-50'>
                                        <span>Lorem ipsum dolor adipiscing</span>
                                        <span>do eiusmod tempors</span>
                                    </div> */}
                                    <span className='font-size-h1 font-weight-boldest text-dark'>
                                        {parseInt(props.productprices[0])}
                                        <sub className='font-size-h3 font-weight-normal pl-1'>บาท</sub>
                                    </span>
                                    <div className='d-lg-none mt-5'>
                                        <div className='bg-gray-100 py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา หรือทุพพลภาพถาวรสิ้นเชิง
                                                เนื่องจากอุบัติเหตุทั่วไป (อบ.1) (ไม่รวมการถูกฆาตกรรมหรืถูกทำร้ายร่างกาย
                                                และ/หรือการขับขี่หรือโดยสารรถจักรยานยนต์)
                                            </span>
                                            <span>200,000</span>
                                        </div>
                                        <div className='py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                หรือทุพพลภาพถาวรสิ้นเชิงจากการถูกฆาตกรรมหรือถูกลอบทำร้ายร่างกาย
                                            </span>
                                            <span>200,000</span>
                                        </div>
                                        <div className='bg-gray-100 py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                หรือทุพพลภาพถาวรสิ้นเชิงจากการขับขี่ หรือโดยสารรถจักรยานยนต์
                                            </span>
                                            <span>100,000</span>
                                        </div>
                                        <div className='py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                ค่ารักษาพยาบาล (ต่ออุบัติเหตุแต่ละครั้ง) เนื่องจากอุบัติเหตุทั่วไป
                                                ไม่รวมการขับขี่หรือโดยสารถจักรยานยนต์
                                            </span>
                                            <span>15,000</span>
                                        </div>
                                        <div className='bg-gray-100 py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                ค่าปลงศพหรือค่าใช้จ่ายในการจัดการงานศพ กรณีเสียชีวิตจากการเจ็บป่วย
                                            </span>
                                            <span>20,000</span>
                                        </div>
                                        {/* <div className='py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                เบี้ยประกัน (ก่อนภาษีมูลค่าเพิ่ม)
                                            </span>
                                            <span>990</span>
                                        </div> */}
                                    </div>
                                    <div className='mt-7'>
                                        <button
                                            type='button'
                                            className='btn btn-primary text-uppercase font-weight-bolder px-15 py-3'
                                            onClick={() =>
                                                props.btnPlan(
                                                    'แผน 1',
                                                    props.productprices[0],
                                                    props.productvats[0],
                                                    props.productcodes[0]
                                                )}
                                        >
                                            เลือก
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 bg-white border-x-0 border-x-lg border-y border-y-lg-0 p-0'>
                                <div className='py-15 px-0 px-lg-5 text-center'>
                                    <div className='d-flex flex-center mb-7'>
                                        <span className='svg-icon svg-icon-5x svg-icon-primary'>
                                            <SVG title='แผน 2' src={toAbsoluteUrl('/media/svg/icons/Text/H2.svg')} />
                                        </span>
                                    </div>
                                    <h4 className='font-size-h3 mb-10 text-dark'>แผน 2</h4>
                                    {/* <div className='d-flex flex-column pb-7 text-dark-50'>
                                        <span>Lorem ipsum dolor adipiscing</span>
                                        <span>do eiusmod tempors</span>
                                    </div> */}
                                    <span className='font-size-h1 font-weight-boldest text-dark'>
                                        {parseInt(props.productprices[1])}
                                        <sub className='font-size-h3 font-weight-normal pl-1'>บาท</sub>
                                    </span>
                                    <div className='d-lg-none mt-5'>
                                        <div className='bg-gray-100 py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา หรือทุพพลภาพถาวรสิ้นเชิง
                                                เนื่องจากอุบัติเหตุทั่วไป (อบ.1) (ไม่รวมการถูกฆาตกรรมหรืถูกทำร้ายร่างกาย
                                                และ/หรือการขับขี่หรือโดยสารรถจักรยานยนต์)
                                            </span>
                                            <span>500,000</span>
                                        </div>
                                        <div className='py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                หรือทุพพลภาพถาวรสิ้นเชิงจากการถูกฆาตกรรมหรือถูกลอบทำร้ายร่างกาย
                                            </span>
                                            <span>500,000</span>
                                        </div>
                                        <div className='bg-gray-100 py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                หรือทุพพลภาพถาวรสิ้นเชิงจากการขับขี่ หรือโดยสารรถจักรยานยนต์
                                            </span>
                                            <span>250,000</span>
                                        </div>
                                        <div className='py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                ค่ารักษาพยาบาล (ต่ออุบัติเหตุแต่ละครั้ง) เนื่องจากอุบัติเหตุทั่วไป
                                                ไม่รวมการขับขี่หรือโดยสารถจักรยานยนต์
                                            </span>
                                            <span>30,000</span>
                                        </div>
                                        <div className='bg-gray-100 py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                ค่าปลงศพหรือค่าใช้จ่ายในการจัดการงานศพ กรณีเสียชีวิตจากการเจ็บป่วย
                                            </span>
                                            <span>30,000</span>
                                        </div>
                                        {/* <div className='py-3'>
                                            <span className='font-weight-boldest mr-2'>
                                                เบี้ยประกัน (ก่อนภาษีมูลค่าเพิ่ม)
                                            </span>
                                            <span>2,500</span>
                                        </div> */}
                                    </div>
                                    <div className='mt-7'>
                                        <button
                                            type='button'
                                            className='btn btn-primary text-uppercase font-weight-bolder px-15 py-3'
                                            onClick={() =>
                                                props.btnPlan(
                                                    'แผน 2',
                                                    props.productprices[1],
                                                    props.productvats[1],
                                                    props.productcodes[1]
                                                )}
                                        >
                                            เลือก
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center mx-0 pb-15 d-none d-lg-flex bg-danger rounded-card-bottom'>
                    {/* <div className='d-none d-lg-block bg-danger position-absolute w-100 h-100 rounded-card-bottom' /> */}
                    <div className='col-11'>
                        {/* {Object.keys(props.products).length < 0 ? (
                            <div>test</div>
                        ) : (
                            <div>
                                {props.products.responsecode !== 0 ? (
                                    <div>test2</div>
                                ) : (
                                    <div>
                                        {props.products.products.map((object) => {
                                            for (const item in object.attribute) {
                                                console.log(object.attribute[item]);
                                                if (item % 2 === 0) {
                                                    return (
                                                        <div className='row bg-gray-100 py-5 font-weight-bold text-center'>
                                                            <div className='col-4 text-left px-5 font-weight-boldest'>
                                                                ข้อ {parseInt(item) + 1}{' '}
                                                                {object.attribute[item]['title']}
                                                            </div>
                                                            <div className='col-4 my-auto'>200,000</div>
                                                            <div className='col-4 my-auto'>500,000</div>
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div className='row bg-white py-5 font-weight-bold text-center'>
                                                            <div className='col-4 text-left px-5 font-weight-boldest'>
                                                                ข้อ {parseInt(item) + 1}{' '}
                                                                {object.attribute[item]['title']}
                                                            </div>
                                                            <div className='col-4 my-auto'>200,000</div>
                                                            <div className='col-4 my-auto'>500,000</div>
                                                        </div>
                                                    );
                                                }
                                            }
                                        })}
                                    </div>
                                )}
                            </div>
                        )} */}
                        <div className='row bg-gray-100 py-5 font-weight-bold text-center'>
                            <div className='col-4 text-left px-5 font-weight-boldest'>
                                ข้อ 1 กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา หรือทุพพลภาพถาวรสิ้นเชิง
                                เนื่องจากอุบัติเหตุทั่วไป (อบ.1) (ไม่รวมการถูกฆาตกรรมหรืถูกทำร้ายร่างกาย
                                และ/หรือการขับขี่หรือโดยสารรถจักรยานยนต์)
                            </div>
                            <div className='col-4 my-auto'>200,000</div>
                            <div className='col-4 my-auto'>500,000</div>
                        </div>
                        <div className='row bg-white py-5 font-weight-bold text-center'>
                            <div className='col-4 text-left px-5 font-weight-boldest'>
                                ข้อ 2 กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                หรือทุพพลภาพถาวรสิ้นเชิงจากการถูกฆาตกรรมหรือถูกลอบทำร้ายร่างกาย
                            </div>
                            <div className='col-4 my-auto'>200,000</div>
                            <div className='col-4 my-auto'>500,000</div>
                        </div>
                        <div className='row bg-gray-100 py-5 font-weight-bold text-center'>
                            <div className='col-4 text-left px-5 font-weight-boldest'>
                                ข้อ 3 กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา หรือทุพพลภาพถาวรสิ้นเชิงจากการขับขี่
                                หรือโดยสารรถจักรยานยนต์
                            </div>
                            <div className='col-4 my-auto'>100,000</div>
                            <div className='col-4 my-auto'>250,000</div>
                        </div>
                        <div className='row bg-white py-5 font-weight-bold text-center'>
                            <div className='col-4 text-left px-5 font-weight-boldest'>
                                ข้อ 4 ค่ารักษาพยาบาล (ต่ออุบัติเหตุแต่ละครั้ง) เนื่องจากอุบัติเหตุทั่วไป
                                ไม่รวมการขับขี่หรือโดยสารถจักรยานยนต์
                            </div>
                            <div className='col-4 my-auto'>15,000</div>
                            <div className='col-4 my-auto'>30,000</div>
                        </div>
                        <div className='row bg-gray-100 py-5 font-weight-bold text-center'>
                            <div className='col-4 text-left px-5 font-weight-boldest'>
                                ข้อ 5 ค่าปลงศพหรือค่าใช้จ่ายในการจัดการงานศพ กรณีเสียชีวิตจากการเจ็บป่วย
                            </div>
                            <div className='col-4 my-auto'>20,000</div>
                            <div className='col-4 my-auto'>30,000</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
