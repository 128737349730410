import React, { useState, useEffect, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { RFSummary } from '../refill/step/Summary';
import config from '../environments/config';
import { SubscriberList } from '../../Setting';

export function RefillAnotherFunction() {
    const history = useHistory();
    const [ step, setStep ] = useState(1);
    const [ loading, setLoading ] = useState(false);
    const [ producttype, setProducttype ] = useState('1');
    const [ active, setActive ] = useState(null);
    const [ topupprofile, setTopupprofile ] = useState({});
    const [ amount, setAmount ] = useState(null);
    const [ packages, setPackages ] = useState({});
    const [ simstatus, setSimstatus ] = useState({});
    const [ phonenumber, setPhonenumber ] = useState('');
    const [ paymentchannel, setPaymentchannel ] = useState('2');
    const [ productcode, setProductcode ] = useState('');
    const [ orderresult, setOrderresult ] = useState({});
    const [ showModal, setShow ] = useState(false);

    const handleClose = () => {
        setStep(1);
        setAmount(null);
        setPaymentchannel('2');
        setProductcode('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const ref = useRef(null);

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (SubscriberList.includes(user.main_position)) {
            history.push('/');
        }
    }, []);

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    const backStep = () => {
        setStep(step - 1);
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const scroll = (size, option) => {
        const osInstance = ref.current.osInstance();
        if (osInstance) {
            osInstance.scrollStop().scroll(
                {
                    x: `${option}= ${size}px`
                },
                1000,
                'easeOutCubic'
            );
        }
    };

    const getProducts = (producttype) => {
        fetch(config.py_url + config.version + '/product/getproduct', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                packs_id: [ producttype ]
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    if (producttype === '2') {
                        setTopupprofile(result.product);
                    } else if (producttype === '3') {
                        setPackages(result.product);
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleCheck = () => {
        setActive(null);
        checkPunsookSim();
    };

    const getNumberStatus = () => {
        fetch(config.py_url + config.version + '/product/get-sim-profile', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                phonenumber: phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.simprofile !== undefined) {
                    setActive(true);
                    setSimstatus(result.simprofile);
                    getProducts('2');
                    getProducts('3');
                    setLoading(false);
                } else {
                    setLoading(false);
                    Swal.fire({
                        html: 'เบอร์ ' + phonenumber + ' ไม่พร้อมทำรายการเติมเงินหรือสมัครแพ็กเกจ',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const checkPunsookSim = () => {
        setLoading(true);
        fetch(config.py_url + config.version + '/product/get-sim-punsook', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                phonenumber: phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    getNumberStatus();
                } else {
                    setLoading(false);
                    Swal.fire({
                        html: 'เบอร์ ' + phonenumber + ' ไม่ใช่เบอร์ในโครงการซิมปันสุข',
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const createOrder = () => {
        setShow(false);
        setLoading(true);
        fetch(config.py_url + config.version + '/order/create', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: user.id,
                phonenumber: phonenumber,
                payment: paymentchannel,
                order_items: [
                    {
                        productcode: productcode,
                        quantity: '1'
                    }
                ]
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setOrderresult(result);
                setStep(step + 1);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <div>
            {!loading ? (
                <div>
                    {step < 3 ? (
                        <div className='d-flex flex-row row m-0'>
                            <div className='flex-row-fluid ml-lg-8 col-12 p-0 mb-5'>
                                <div className='card card-custom card-stretch' id='wallet-info'>
                                    <div className='card-header bg-danger text-white'>
                                        <div className='card-title'>
                                            <span className='card-icon'>
                                                <i className='fas fa-sim-card text-white' />
                                            </span>
                                            <h3 className='card-label text-white'>เบอร์ที่ต้องการเติมให้</h3>
                                        </div>
                                    </div>
                                    <div className='card-body pt-4 pb-4'>
                                        <div className='row justify-content-center my-5'>
                                            <div className='row col-xl-9 col-7 justify-content-center'>
                                                <div className='col-xl-12'>
                                                    <div className='form-group fv-plugins-icon-container my-auto'>
                                                        <input
                                                            type='tel'
                                                            className='form-control form-control-solid form-control-lg border-lg border-dark'
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            value={phonenumber}
                                                            maxLength='10'
                                                            onInput={maxLengthCheck}
                                                            onChange={(e) => setPhonenumber(e.target.value)}
                                                            placeholder='กรอกเบอร์ที่ต้องการทำรายการ'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-3 col-5 d-flex justify-content-center align-items-center'>
                                                <button
                                                    type='button'
                                                    className='btn btn-success font-weight-bold text-uppercase px-9 py-4'
                                                    onClick={handleCheck}
                                                >
                                                    ตรวจสอบ
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {active !== null && (
                                <div className='flex-row-fluid col-12 p-0 ml-lg-8'>
                                    <div className='card card-custom'>
                                        <div className='card-body'>
                                            <div className='form-group row justify-content-center'>
                                                <label className='col-xl-9 col-12 p-0 font-weight-bolder'>
                                                    ประเภทการทำรายการ
                                                </label>
                                                <div className='col-xl-9 col-12 p-0'>
                                                    <div className='row'>
                                                        <div className='col-lg-6'>
                                                            <label className='option'>
                                                                <span className='option-control'>
                                                                    <span className='radio'>
                                                                        <input
                                                                            type='radio'
                                                                            name='packagetype'
                                                                            value='1'
                                                                            checked={producttype === '1'}
                                                                            onChange={() => setProducttype('1')}
                                                                        />
                                                                        <span />
                                                                    </span>
                                                                </span>
                                                                <span className='option-label'>
                                                                    <span className='option-head'>
                                                                        <span className='option-title font-weight-normal'>
                                                                            เติมเงิน
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <label
                                                                className={`option ${simstatus.responsecode !== 0 ||
                                                                simstatus['sim state'] !== 'ACTIVE'
                                                                    ? 'bg-gray-300'
                                                                    : ''}`}
                                                            >
                                                                <span className='option-control'>
                                                                    <span className='radio'>
                                                                        <input
                                                                            type='radio'
                                                                            name='packagetype'
                                                                            value='2'
                                                                            checked={producttype === '2'}
                                                                            onChange={() => setProducttype('2')}
                                                                            disabled={
                                                                                simstatus.responsecode !== 0 ||
                                                                                simstatus['sim state'] !== 'ACTIVE'
                                                                            }
                                                                        />
                                                                        <span
                                                                            className={
                                                                                simstatus.responsecode !== 0 ||
                                                                                simstatus['sim state'] !== 'ACTIVE' ? (
                                                                                    'bg-gray-300'
                                                                                ) : (
                                                                                    ''
                                                                                )
                                                                            }
                                                                        />
                                                                    </span>
                                                                </span>
                                                                <span className='option-label'>
                                                                    <span className='option-head'>
                                                                        <span className='option-title font-weight-normal col-12 p-0'>
                                                                            <div className='row col-12'>
                                                                                สมัครแพ็กเกจ
                                                                                {simstatus.responsecode !== 0 ||
                                                                                    (simstatus['sim state'] !==
                                                                                        'ACTIVE' && (
                                                                                        <div className='ml-auto'>
                                                                                            <span className='menu-label'>
                                                                                                <span className='label label-danger label-inline'>
                                                                                                    ไม่พร้อมใช้งาน
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-group row justify-content-center'>
                                                <div className='col-xl-9 col-12'>
                                                    <div className='row'>
                                                        <div className='card col-12'>
                                                            <div className='card-body'>
                                                                <div className='row justify-content-center'>
                                                                    <div className='col-12 text-center'>
                                                                        {producttype === '1' ? (
                                                                            <div>
                                                                                <label className='font-weight-bolder'>
                                                                                    จำนวนเงินที่ต้องการเติม
                                                                                </label>
                                                                                <div className='row'>
                                                                                    {Object.keys(topupprofile).length >
                                                                                    0 ? (
                                                                                        Object.keys(
                                                                                            topupprofile
                                                                                        ).map((object, index) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className='col-lg-4'
                                                                                                    key={index}
                                                                                                >
                                                                                                    <label className='option'>
                                                                                                        <span className='option-control'>
                                                                                                            <span className='radio'>
                                                                                                                <input
                                                                                                                    type='radio'
                                                                                                                    name='amount'
                                                                                                                    value={
                                                                                                                        topupprofile[
                                                                                                                            object
                                                                                                                        ]
                                                                                                                            .pv
                                                                                                                    }
                                                                                                                    checked={
                                                                                                                        amount ===
                                                                                                                        topupprofile[
                                                                                                                            object
                                                                                                                        ]
                                                                                                                            .pv
                                                                                                                    }
                                                                                                                    onChange={() =>
                                                                                                                        setAmount(
                                                                                                                            topupprofile[
                                                                                                                                object
                                                                                                                            ]
                                                                                                                                .pv
                                                                                                                        )}
                                                                                                                    onClick={() => {
                                                                                                                        setProductcode(
                                                                                                                            topupprofile[
                                                                                                                                object
                                                                                                                            ]
                                                                                                                                .code
                                                                                                                        );
                                                                                                                        handleShow();
                                                                                                                    }}
                                                                                                                />
                                                                                                                <span />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <span className='option-label cursor-pointer'>
                                                                                                            <span className='option-head'>
                                                                                                                <span className='option-title font-weight-normal'>
                                                                                                                    <NumberFormat
                                                                                                                        value={parseFloat(
                                                                                                                            topupprofile[
                                                                                                                                object
                                                                                                                            ]
                                                                                                                                .pv
                                                                                                                        )}
                                                                                                                        displayType={
                                                                                                                            'text'
                                                                                                                        }
                                                                                                                        decimalScale={
                                                                                                                            2
                                                                                                                        }
                                                                                                                        fixedDecimalScale={
                                                                                                                            true
                                                                                                                        }
                                                                                                                        thousandSeparator={
                                                                                                                            true
                                                                                                                        }
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                    ) : (
                                                                                        <div className='col-lg-12 text-center py-15'>
                                                                                            <p>ไม่มีแพ็กเกจในตอนนี้</p>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <label className='font-weight-bolder'>
                                                                                    แพ็กเกจที่ต้องการสมัคร
                                                                                </label>
                                                                                <div className='testimonial-group'>
                                                                                    <div className='inline-scroll'>
                                                                                        <OverlayScrollbarsComponent
                                                                                            ref={ref}
                                                                                        >
                                                                                            <div className='row flex-nowrap'>
                                                                                                {Object.keys(
                                                                                                    packages
                                                                                                ).map(
                                                                                                    (object, index) => {
                                                                                                        return (
                                                                                                            <div
                                                                                                                className='col-xl-6 col-12'
                                                                                                                key={
                                                                                                                    index
                                                                                                                }
                                                                                                            >
                                                                                                                <div className='refill'>
                                                                                                                    <div className='brand'>
                                                                                                                        <h2
                                                                                                                        >
                                                                                                                            {
                                                                                                                                packages[
                                                                                                                                    object
                                                                                                                                ]
                                                                                                                                    .title
                                                                                                                            }
                                                                                                                        </h2>
                                                                                                                    </div>
                                                                                                                    <div className='discount alizarin'>
                                                                                                                        <NumberFormat
                                                                                                                            value={parseFloat(
                                                                                                                                packages[
                                                                                                                                    object
                                                                                                                                ]
                                                                                                                                    .pv
                                                                                                                            )}
                                                                                                                            displayType={
                                                                                                                                'text'
                                                                                                                            }
                                                                                                                            decimalScale={
                                                                                                                                2
                                                                                                                            }
                                                                                                                            fixedDecimalScale={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            thousandSeparator={
                                                                                                                                true
                                                                                                                            }
                                                                                                                        />
                                                                                                                        <div className='type'>
                                                                                                                            บาท
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className='descr row'>
                                                                                                                        <div className='col-12'>
                                                                                                                            <strong
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    packages[
                                                                                                                                        object
                                                                                                                                    ]
                                                                                                                                        .detail
                                                                                                                                }
                                                                                                                            </strong>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className='ends row'>
                                                                                                                        <small className='col-12 text-right text-danger'>
                                                                                                                            *
                                                                                                                            ราคาไม่รวมภาษีมูลค่าเพิ่ม
                                                                                                                        </small>
                                                                                                                    </div>
                                                                                                                    <div className='coupon midnight-blue'>
                                                                                                                        <div
                                                                                                                            className='cursor-pointer open-code'
                                                                                                                            onClick={() => {
                                                                                                                                setAmount(
                                                                                                                                    packages[
                                                                                                                                        object
                                                                                                                                    ]
                                                                                                                                        .price
                                                                                                                                );
                                                                                                                                setProductcode(
                                                                                                                                    packages[
                                                                                                                                        object
                                                                                                                                    ]
                                                                                                                                        .code
                                                                                                                                );
                                                                                                                                handleShow();
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            ซื้อเลย
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                            </div>
                                                                                        </OverlayScrollbarsComponent>
                                                                                    </div>
                                                                                    <div className='mt-5 d-none d-md-block'>
                                                                                        <button
                                                                                            className='btn btn-secondary mr-3'
                                                                                            onClick={() =>
                                                                                                scroll('200', '-')}
                                                                                        >
                                                                                            <i className='fas fa-chevron-left' />
                                                                                        </button>
                                                                                        <button
                                                                                            className='btn btn-secondary'
                                                                                            onClick={() =>
                                                                                                scroll('200', '+')}
                                                                                        >
                                                                                            <i className='fas fa-chevron-right' />
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='flex-row-fluid ml-lg-8 col-12 p-0 mb-5'>
                            <div className='card card-custom card-stretch'>
                                <div className='card-header bg-danger text-white'>
                                    <div className='card-title'>
                                        <h3 className='card-label text-white'>สรุปการทำรายการ</h3>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <RFSummary
                                        response={orderresult}
                                        paymenttype={paymentchannel}
                                        producttype={producttype}
                                        phonenumber={phonenumber}
                                        amount={amount}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            <Modal show={showModal} onHide={handleClose} size='lg' centered aria-labelledby='unactive-number-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='unactive-number-modal' className='col-11 p-0'>
                        รายละเอียดเติมเงิน/แพ็กเกจ
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {step === 1 ? (
                        <div className='row'>
                            <div className='col-12'>
                                <div className='form-group'>
                                    <h2 className='text-center mb-5'>เลือกช่องทางการชำระเงิน</h2>
                                    <div className='row d-flex justify-content-center text-center mx-auto'>
                                        <div className='payment-icon col-xl-3 col-6 d-inline'>
                                            <label>
                                                <img
                                                    alt='thaiqr-logo'
                                                    src='https://cdn.simk4.com/payment/thatqr.png'
                                                    className={`payment-img${paymentchannel === '2'
                                                        ? '-active'
                                                        : ''} max-h-120px max-w-120px`}
                                                />
                                                <input
                                                    type='radio'
                                                    name='payment-method'
                                                    value='2'
                                                    checked={paymentchannel === '2'}
                                                    onChange={() => setPaymentchannel('2')}
                                                />
                                            </label>
                                        </div>
                                        <div className='payment-icon col-xl-3 col-6 d-inline'>
                                            <label>
                                                <img
                                                    alt='wallet-logo'
                                                    src='https://cdn.simk4.com/payment/wallet2.png'
                                                    className={`payment-img${paymentchannel === '3'
                                                        ? '-active'
                                                        : ''} max-h-120px max-w-120px`}
                                                />
                                                <input
                                                    type='radio'
                                                    name='payment-method'
                                                    value='3'
                                                    checked={paymentchannel === '3'}
                                                    onChange={() => setPaymentchannel('3')}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='row justify-content-center'>
                            <div className='col-10'>
                                <div className='form-group'>
                                    <h2 className='text-center mb-5'>สรุปการทำรายการ</h2>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='bg-light rounded d-flex flex-column p-3'>
                                                <div className='p-2 d-flex'>
                                                    <div className='col-7'>เบอร์ที่ทำรายการ</div>
                                                    <div className='ml-auto text-right'>{phonenumber}</div>
                                                </div>
                                                <div className='p-2 d-flex'>
                                                    <div className='col-8'>ประเภทการทำรายการ</div>
                                                    <div className='ml-auto'>
                                                        {producttype === '1' ? 'เติมเงิน' : 'สมัครแพ็กเกจ'}
                                                    </div>
                                                </div>
                                                <div className='p-2 d-flex'>
                                                    <div className='col-8'>ชำระเงินผ่าน</div>
                                                    <div className='ml-auto'>
                                                        {paymentchannel === '2' ? 'Thai QR Payment' : 'กระเป๋าปันสุข'}
                                                    </div>
                                                </div>
                                                <div className='border-top px-4 mx-3' />
                                                <div className='p-2 d-flex pt-3'>
                                                    <div className='col-8'>
                                                        <b>ยอดรวม</b>
                                                    </div>
                                                    <div className='ml-auto'>
                                                        <b className='green'>
                                                            <NumberFormat
                                                                value={parseFloat(amount)}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                                suffix={' บาท'}
                                                            />
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className='m-3'>
                                                    <button
                                                        type='button'
                                                        onClick={createOrder}
                                                        className='btn btn-primary btn-block'
                                                    >
                                                        ชำระเงิน
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between col-12'>
                        <div className='mr-2'>
                            {step > 1 && (
                                <button
                                    type='button'
                                    onClick={backStep}
                                    className='btn btn-light-primary btn-cus-rounded font-weight-bold text-uppercase px-9 py-4'
                                >
                                    ก่อนหน้า
                                </button>
                            )}
                        </div>
                        <div>
                            {step < 2 && (
                                <button
                                    type='button'
                                    onClick={nextStep}
                                    className='btn btn-success btn-cus-rounded font-weight-bold text-uppercase px-9 py-4'
                                >
                                    ถัดไป
                                </button>
                            )}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
