import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DropdownButton, Dropdown } from 'react-bootstrap';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { Tw50Template } from './components';

import config from '../environments/config';
import { SubscriberList } from '../../Setting';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNew-Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf'
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }
};

export function Tw50MainPage() {
    const history = useHistory();
    const { user } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(false);
    const [ pdftemplate, setPdfTemplate ] = useState({});
    const [ result, setResult ] = useState({});
    // const [ name, setName ] = useState('');
    // const [ personalid, setPersonalId ] = useState('');
    // const [ address, setAddress ] = useState('');

    useEffect(() => {
        if (SubscriberList.includes(user.main_position)) {
            history.push('/');
        } else {
            getInfo();
        }
    }, []);

    const getInfo = () => {
        setLoading(true);
        fetch(config.py_url + config.version + '/wallet/tw50', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                user_id: user.id,
                year: (new Date().getFullYear() - 1).toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResult(result);
                // if (result.responsecode === 0) {
                //     console.log(result.info.name);
                //     setName(result.info.name);
                //     setPersonalId(result.info.idcard);
                //     setAddress(result.info.address);
                // }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const downloadPdf = () => {
        pdfMake.createPdf(pdftemplate).download('tw50-' + user.main_phonenumber);
    };

    const printPdf = () => {
        pdfMake.createPdf(pdftemplate).print();
    };

    const handleSelect = (e) => {
        if (e === 'print') {
            printPdf();
        } else {
            downloadPdf();
        }
    };

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row'>
                    <div className='flex-row-fluid ml-lg-8 col-12 p-0 mb-5'>
                        <div className='card card-custom card-stretch'>
                            <div className='card-header bg-danger text-white py-5'>
                                <div className='card-title'>
                                    <h3 className='card-label text-white'>หนังสือรับรองการหักภาษี ณ ที่จ่าย</h3>
                                </div>
                                <div className='card-toolbar d-none d-md-flex'>
                                    <DropdownButton
                                        alignRight
                                        title='คำสั่งต่างๆ'
                                        variant='secondary'
                                        onSelect={handleSelect}
                                    >
                                        <Dropdown.Item eventKey='download'>ดาวน์โหลด</Dropdown.Item>
                                        <Dropdown.Item eventKey='print'>พิมพ์</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </div>
                            <div className='card-body pt-4'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-12 vh-100 d-none d-md-flex'>
                                                {result.responsecode === 0 && (
                                                    <Tw50Template setPdfTemplate={setPdfTemplate} result={result} />
                                                )}
                                            </div>
                                        </div>
                                        <div className='row h-500px align-content-center justify-content-center d-flex d-md-none'>
                                            <div className='col-12 text-center'>
                                                <h1>คำสั่งต่างๆ</h1>
                                                <div className='mt-15'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-primary btn-lg py-5 w-150px'
                                                        onClick={downloadPdf}
                                                    >
                                                        ดาวน์โหลด
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
