import React, { useEffect, useState } from "react";

import config from "../../environments/config";

export function NCNumbers(prop) {
    const [numberlist, setNumberlist] = useState({});
    const [loading, setLoading] = useState(true);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(11);
    const [filter, setFilter] = useState("");
    // const [selected, setSelected] = useState("");
    useEffect(() => {
        if (prop.type === "PT2") {
            let postparam = {
                type: "sim",
                qty: "48",
            };
            if (prop.price === 'P3' || prop.price === 'P9' || prop.price === 'P10') {
                postparam["package"] = "P1";
            } else if (prop.price === 'P15') {
                postparam["package"] = "P2";
            }
            // else if (prop.price === 'P8') {
            //     postparam["package"] = "P3";
            // }
            getPhonenumber(postparam);
        }
        //     // effect
        //     // return () => {
        //     //     cleanup
        //     // }
    }, []);

    const getPhonenumber = (postparam) => {
        prop.handlePhonenumber("");
        setLoading(true);
        setNumberlist({});
        setStart(0);
        setEnd(11);
        // fetch("https://api.simk4.com/api/register/getsim", {
        fetch(config.laravel_url + "/api/register/getsim", {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postparam),
        })
            .then((response) => response.json())
            .then((result) => {
                // prop.handleLoading(false);
                // console.log(result);
                // setInit(true);
                setLoading(false);
                if (result.responsecode === 0) {
                    setNumberlist(Object.values(result.info));
                }
            })
            .catch((error) => {
                setLoading(false);
                // prop.handleLoading(false);
                // setInit(true);
            });
    };

    const fecthNew = (e) => {
        e.preventDefault();
        let postparam = {
            type: "sim",
            qty: "48",
        };
        if (prop.price === 'P3' || prop.price === 'P9' || prop.price === 'P10') {
            postparam["package"] = "P1";
        } else if (prop.price === 'P15') {
            postparam["package"] = "P2";
        }
        // else if (prop.price === 'P8') {
        //     postparam["package"] = "P3";
        // }
        if (filter !== "") {
            postparam["filter"] = {
                number: filter,
            };
        }
        getPhonenumber(postparam);
    };

    const onPrevious = (e) => {
        e.preventDefault();
        setStart(start - 12);
        setEnd(end - 12);
    };

    const onNext = (e) => {
        e.preventDefault();
        setStart(start + 12);
        setEnd(end + 12);
    };
    // console.log(numberlist);
    return (
        <>
            {prop.type === "PT2" ? (
                <>
                    <div className="row justify-content-center">
                        <div className="row col-9 justify-content-center">
                            <div className="col-xl-12">
                                <div className="form-group fv-plugins-icon-container">
                                    <label>ค้นหาเลขที่สนใจ</label>
                                    <input
                                        type="tel"
                                        className="form-control form-control-solid form-control-lg"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        maxLength="10"
                                        onInput={prop.maxLengthCheck}
                                        onChange={(e) =>
                                            setFilter(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-3 d-flex justify-content-center align-items-center">
                            <button
                                type="button"
                                className="btn btn-secondary font-weight-bold text-uppercase px-9 py-4"
                                onClick={(e) => fecthNew(e)}
                            >
                                ค้นหา
                            </button>
                        </div>
                    </div>
                    <div className="row col-xl-12 m-0 justify-content-center">
                        {/* <div className="number-box loading-box">
                                    <div className="center">
                                        <div className="spinner-border"/>
                                    </div>
                                    <h4 className="text-black mt-5">กรุณารอสักครู่</h4>
                                </div> */}
                        <div className="number-box w-100">
                            <div>
                                <div className="col-xl-12 row form-group m-0 mt-5 mb-5 p-0">
                                    {!loading ? (
                                        Object.keys(numberlist).length > 0 ? (
                                            Object.keys(numberlist)
                                                .slice(start, end + 1)
                                                .map((object, index) => {
                                                    // console.log(object);
                                                    return (
                                                        <div
                                                            className="col-md-3 col-sm-4 col-6 pr-2 pl-2 mt-3"
                                                            key={index}
                                                        >
                                                            <button
                                                                type="button"
                                                                className={`btn pt-2 pb-2 pl-0 pr-0 fs-20 font-weight-normal w-100 ${
                                                                    prop.phonenumber ===
                                                                    numberlist[
                                                                        object
                                                                    ][
                                                                        "phonenumber"
                                                                    ]
                                                                        ? "btn-success"
                                                                        : "btn-outline-success"
                                                                }`}
                                                                onClick={() =>
                                                                    prop.handlePhonenumber(
                                                                        numberlist[
                                                                            object
                                                                        ][
                                                                            "phonenumber"
                                                                        ]
                                                                    )
                                                                }
                                                            >
                                                                {numberlist[
                                                                    object
                                                                ][
                                                                    "phonenumber"
                                                                ].slice(0, 3) +
                                                                    "-" +
                                                                    numberlist[
                                                                        object
                                                                    ][
                                                                        "phonenumber"
                                                                    ].slice(
                                                                        3,
                                                                        6
                                                                    ) +
                                                                    "-" +
                                                                    numberlist[
                                                                        object
                                                                    ][
                                                                        "phonenumber"
                                                                    ].slice(6)}
                                                            </button>
                                                        </div>
                                                    );
                                                })
                                        ) : (
                                            <div className="col-xl-12 text-center m-0 my-25 p-0">
                                                <i className="fas fa-box-open fa-5x mb-2 text-success"></i>
                                                <h4 className="mt-3">
                                                    ไม่พบเลขนี้ในระบบ
                                                </h4>
                                            </div>
                                        )
                                    ) : (
                                        <>
                                            <div
                                                className="spinner-border mx-auto text-success mt-25 mb-10 p-5"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                            <h1 className="d-block col-12 text-center mb-15">
                                                กรุณารอสักครู่
                                            </h1>
                                        </>
                                    )}
                                </div>
                                {/* <div className="col-xl-12 row form-group m-0 mt-5 mb-5 p-0">
                                    <div className="col-md-3 col-sm-4 col-6 pr-2 pl-2 mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-outline-success pt-2 pb-2 pl-0 pr-0 fs-20 font-weight-normal w-100"
                                        >
                                            097-695-4777
                                        </button>
                                    </div>
                                </div> */}
                                <div className="col-xl-12 text-center d-flex justify-content-center mt-3">
                                    <button
                                        type="button"
                                        className={`btn btn-success btn-sm font-weight-bold text-uppercase mr-3 ${
                                            Object.keys(numberlist).length > 12
                                                ? ""
                                                : "d-none"
                                        }`}
                                        disabled={start <= 0}
                                        onClick={(e) => onPrevious(e)}
                                    >
                                        ก่อนหน้า
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn btn-success btn-sm font-weight-bold text-uppercase ${
                                            end >=
                                            Object.keys(numberlist).length - 1
                                                ? "d-none"
                                                : ""
                                        }`}
                                        onClick={(e) => onNext(e)}
                                    >
                                        ถัดไป
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn btn-success btn-sm font-weight-bold text-uppercase ${
                                            end >=
                                                Object.keys(numberlist).length -
                                                    1 &&
                                            Object.keys(numberlist).length > 12
                                                ? ""
                                                : "d-none"
                                        }`}
                                        onClick={(e) => fecthNew(e)}
                                    >
                                        สุ่มใหม่
                                    </button>
                                </div>
                            </div>
                            {/* <div>
                                        <div className="col-xl-12 form-group text-center p-5">
                                            <i className="fas fa-box-open fa-5x mb-2 text-light-green-main"/>
                                            <h4 className="mt-3">ไม่พบเลขนี้ในระบบ</h4>
                                        </div>
                                    </div> */}
                        </div>
                    </div>
                </>
            ) : (
                <div className="row col-xl-12 col-sm-12 justify-content-center">
                    <div className="col-xl-12">
                        <div className="form-group fv-plugins-icon-container">
                            <label>เบอร์ Feels ที่ใช้งานอยู่</label>
                            <input
                                type="tel"
                                className="form-control form-control-solid form-control-lg"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                maxLength="10"
                                onInput={prop.maxLengthCheck}
                                onChange={(e) =>
                                    prop.handlePhonenumber(e.target.value)
                                }
                            />
                            {prop.phonenumber === "" ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        กรุณากรอกเบอร์ Feels ที่ใช้งานอยู่
                                    </div>
                                </div>
                            ) : (
                                (prop.phonenumber.charAt(0) !== "0" ||
                                    prop.phonenumber.length < 9) && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            กรุณากรอกเบอร์ Feels
                                            ที่ใช้งานอยู่ให้ถูกต้อง
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
