import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import 'moment/locale/th';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import DatePicker, { utils } from 'react-modern-calendar-datepicker';
import config from '../../../environments/config';
import { myCustomLocale, minimumDate, ShareOrderHistoryDetail } from './components';
import { moreThanToday } from '../../../../../_metronic/_helpers';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function ShareOrderHistoryMain() {
    const { user } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(false);
    const [ loading2, setLoading2 ] = useState(false);
    const [ page, setPage ] = useState('main');
    const [ date, setDate ] = useState({
        from: null,
        to: null
    });
    const [ pdfTemplate, setPdfTemplate ] = useState('');
    const initindex = 5;
    const [ startindex, setStartindex ] = useState(0);
    const [ endindex, setEndindex ] = useState(5);
    const [ orders, setOrders ] = useState({});
    const [ orderdetail, setOrderDetail ] = useState({});

    const btnPrevious = () => {
        setStartindex(startindex - initindex);
        setEndindex(endindex - initindex);
    };

    const btnNext = () => {
        setStartindex(startindex + initindex);
        setEndindex(endindex + initindex);
    };

    const btnBack = () => {
        setPage('main');
    };

    const btnView = (orderid) => {
        setLoading2(true);
        fetch(config.py_url + 'v1/share/get-order-detail', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: user.dealerid,
                orderid: orderid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading2(false);
                if (result.responsecode === 0) {
                    setOrderDetail(result);
                    setPage('detail');
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `ไม่สามารถดูรายละเอียดได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'error',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading2(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถดูรายละเอียดได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const btnEmpty = () => {
        setDate({
            from: null,
            to: null
        });
    };

    const btnSearch = () => {
        let param = {
            userid: user.dealerid
        };
        if (date.from !== null && date.to !== null) {
            param['ordered_date'] = {
                start: date.from.year + '-' + ('0' + date.from.month).slice(-2) + '-' + ('0' + date.from.day).slice(-2),
                end: date.to.year + '-' + ('0' + date.to.month).slice(-2) + '-' + ('0' + date.to.day).slice(-2)
            };
        }
        getOrder(param);
    };

    const getOrder = (postparam) => {
        setLoading(true);
        fetch(config.py_url + 'v1/share/get-order', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setOrders(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        let param = {
            userid: user.dealerid
        };
        getOrder(param);
    }, []);

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to ? (
                    date.from.year +
                    '/' +
                    ('0' + date.from.month).slice(-2) +
                    '/' +
                    ('0' + date.from.day).slice(-2) +
                    ' - ' +
                    date.to.year +
                    '/' +
                    ('0' + date.to.month).slice(-2) +
                    '/' +
                    ('0' + date.to.day).slice(-2)
                ) : (
                    ''
                )
            }
            className='col-12 form-control h-100 border-0 rounded-lg rounded-right-0'
            type='text'
            id='dateperiod'
            name='dateperiod'
        />
    );

    return (
        <div>
            {loading2 && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            {loading ? (
                <div>
                    <div className='d-flex flex-column flex-root' id='loading-section'>
                        <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                            <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                                <div className='bounce col-12'>
                                    <div className='bounce1' />
                                    <div className='bounce2' />
                                    <div className='bounce3' />
                                </div>
                                <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                            </div>
                        </div>
                    </div>
                    <div className='history-page'>
                        <div className='row m-0'>
                            <div className='col-12 clearfix bg-white p-5'>
                                <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px' />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {page === 'main' ? (
                        <div className='history-page'>
                            <div className='row m-0'>
                                <div className='col-12 col-md-4 my-5 ml-auto p-0'>
                                    <div className='input-group'>
                                        <DatePicker
                                            value={date}
                                            onChange={setDate}
                                            renderInput={renderCustomInput}
                                            minimumDate={minimumDate}
                                            maximumDate={utils().getToday()}
                                            locale={myCustomLocale}
                                            wrapperClassName='col-12 p-0 form-control rounded-lg rounded-right-0'
                                            calendarClassName='responsive-calendar'
                                            calendarPopperPosition='left'
                                            shouldHighlightWeekends
                                        />
                                        <div className='input-group-append'>
                                            {date.from !== null &&
                                            date.to !== null && (
                                                <button className='btn btn-danger' type='button' onClick={btnEmpty}>
                                                    <i className='fas fa-times p-0' />
                                                </button>
                                            )}
                                            <button
                                                className='btn btn-secondary rounded-lg rounded-left-0'
                                                type='button'
                                                onClick={btnSearch}
                                            >
                                                ค้นหา
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 clearfix bg-white p-5'>
                                    {Object.keys(orders).length <= 0 ? (
                                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                            <div className='col-12 text-center'>
                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {orders.responsecode !== 0 ? (
                                                <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                                    <div className='col-12 text-center'>
                                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                        <h3 className='col mt-5'>ไม่พบประวัติการซื้อหุ้นของคุณ</h3>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <ul className='xsearch-items'>
                                                        {orders.info
                                                            .slice(startindex, endindex)
                                                            .map((object, index, arr) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <li className='search-item'>
                                                                            <div className='search-item-content mx-7 pb-0'>
                                                                                <h3 className='search-item-caption'>
                                                                                    <div className='text-primary d-inline-block'>
                                                                                        # {object.id}
                                                                                    </div>
                                                                                </h3>
                                                                                <div className='search-item-meta mb-5'>
                                                                                    <ul className='list-inline'>
                                                                                        <li className='time d-none d-lg-block'>
                                                                                            <h6>
                                                                                                วันที่:{' '}
                                                                                                {Moment(
                                                                                                    new Date(
                                                                                                        object.created_date.replace(
                                                                                                            /-/g,
                                                                                                            '/'
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                                    .add(543, 'year')
                                                                                                    .locale('th')
                                                                                                    .format(
                                                                                                        'DD/MMMM/YYYY HH:mm:ss'
                                                                                                    )}{' '}
                                                                                                | การชำระเงิน:{' '}
                                                                                                <span>
                                                                                                    <strong
                                                                                                        className={`${moreThanToday(
                                                                                                            object.created_date
                                                                                                        ) &&
                                                                                                        object.status ===
                                                                                                            '0'
                                                                                                            ? 'text-danger'
                                                                                                            : object.status ===
                                                                                                              '-1'
                                                                                                              ? 'text-danger'
                                                                                                              : object.status ===
                                                                                                                '0'
                                                                                                                ? 'text-secondary'
                                                                                                                : object.status ===
                                                                                                                  '1'
                                                                                                                  ? 'text-success'
                                                                                                                  : null}`}
                                                                                                    >
                                                                                                        {moreThanToday(
                                                                                                            object.created_date
                                                                                                        ) &&
                                                                                                        object.status ===
                                                                                                            '0' ? (
                                                                                                            'หมดอายุ'
                                                                                                        ) : object.status ===
                                                                                                        '-1' ? (
                                                                                                            'ยกเลิก'
                                                                                                        ) : object.status ===
                                                                                                        '0' ? (
                                                                                                            'รอการชำระเงิน'
                                                                                                        ) : object.status ===
                                                                                                        '1' ? (
                                                                                                            'ชำระแล้ว'
                                                                                                        ) : null}
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </h6>
                                                                                        </li>
                                                                                        <li className='time d-block d-lg-none'>
                                                                                            <h6>
                                                                                                วันที่:{' '}
                                                                                                {Moment(
                                                                                                    new Date(
                                                                                                        object.created_date.replace(
                                                                                                            /-/g,
                                                                                                            '/'
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                                    .add(543, 'year')
                                                                                                    .locale('th')
                                                                                                    .format(
                                                                                                        'DD/MMMM/YYYY HH:mm:ss'
                                                                                                    )}
                                                                                            </h6>
                                                                                            <h6>
                                                                                                การชำระเงิน:{' '}
                                                                                                <span>
                                                                                                    <strong
                                                                                                        className={`${moreThanToday(
                                                                                                            object.created_date
                                                                                                        ) &&
                                                                                                        object.status ===
                                                                                                            '0'
                                                                                                            ? 'text-danger'
                                                                                                            : object.status ===
                                                                                                              '-1'
                                                                                                              ? 'text-danger'
                                                                                                              : object.status ===
                                                                                                                '0'
                                                                                                                ? 'text-secondary'
                                                                                                                : object.status ===
                                                                                                                  '1'
                                                                                                                  ? 'text-success'
                                                                                                                  : null}`}
                                                                                                    >
                                                                                                        {moreThanToday(
                                                                                                            object.created_date
                                                                                                        ) &&
                                                                                                        object.status ===
                                                                                                            '0' ? (
                                                                                                            'หมดอายุ'
                                                                                                        ) : object.status ===
                                                                                                        '-1' ? (
                                                                                                            'ยกเลิก'
                                                                                                        ) : object.status ===
                                                                                                        '0' ? (
                                                                                                            'รอการชำระเงิน'
                                                                                                        ) : object.status ===
                                                                                                        '1' ? (
                                                                                                            'ชำระแล้ว'
                                                                                                        ) : null}
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </h6>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div className='d-flex justify-content-end'>
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-outline-primary'
                                                                                        onClick={() =>
                                                                                            btnView(object.id)}
                                                                                        disabled={
                                                                                            moreThanToday(
                                                                                                object.created_date
                                                                                            ) && object.status !== '1'
                                                                                        }
                                                                                    >
                                                                                        ดูรายละเอียด
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                        {arr.length - 1 !== index && <hr />}
                                                                    </div>
                                                                );
                                                            })}
                                                    </ul>
                                                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                                        <div className='d-flex flex-wrap py-2 mr-3'>
                                                            {orders.info.length > initindex && (
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'
                                                                    onClick={btnPrevious}
                                                                    disabled={endindex === initindex}
                                                                >
                                                                    <i className='ki ki-bold-arrow-back icon-xs' />
                                                                </button>
                                                            )}
                                                            {orders.info.length > initindex && (
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'
                                                                    onClick={btnNext}
                                                                    disabled={endindex >= orders.info.length}
                                                                >
                                                                    <i className='ki ki-bold-arrow-next icon-xs' />
                                                                </button>
                                                            )}
                                                        </div>
                                                        <div className='d-flex align-items-center py-3'>
                                                            <span className='text-muted'>
                                                                แสดง{' '}
                                                                {endindex >= orders.info.length ? orders.info.length : endindex}{' '}
                                                                จาก {orders.info.length} รายการ
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : page === 'detail' ? (
                        <ShareOrderHistoryDetail
                            btnBack={btnBack}
                            pdfTemplate={pdfTemplate}
                            setPdfTemplate={setPdfTemplate}
                            result={orderdetail}
                        />
                    ) : null}
                </div>
            )}
        </div>
    );
}
