import React, {
    Suspense
    // lazy
} from 'react';
import {
    Redirect,
    Switch
    // Route
} from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';

import Dashboard from './pages/Dashboard';

// Delaer Function
import { MapSetMarker } from './pages/dealermap/SetMarker';

import { SubscriberNewContactMain } from './pages/Subscriber/newcontact/Main';
import { MainNewContactPage } from './pages/newcontact/MainPage';
import { MainUpgradeContactPage } from './pages/upgrade_contact/MainPage';

// Money Function
import { WalletWithdraw } from './pages/wallet/Withdraw';

import { RefillFunction } from './pages/refill/Refill';

import { RefillAnotherFunction } from './pages/refill_another/Refill';

// Report Function
import { CommissionReport } from './pages/report/commission/Report';

import { THReport } from './pages/report/transactionhistory/Report';

// import { SimReport } from "./pages/report/simcard/Report";

import { OrderReport } from './pages/report/order/Report';

import { DealerGroupReport } from './pages/report/dealer/Report';

// Help Function
import { AgreementPage } from './pages/agreement/Agreement';
import { Tw50MainPage } from './pages/tw50/Main';

// Delaer Support Function
import { DealerSupport } from './pages/support/Main';

// General Fuction
import { EditProfile } from './pages/profile/EditProfile';

// Ui
import { MobileMenu } from './pages/MobileMenu';
import { MobileRegisterMenu } from './pages/MobileRegisterMenu';
import { MobileShopMenu } from './pages/MobileShopMenu';
import { MobileDealerMenu } from './pages/MobileDealerMenu';
import { MobileShareMenu } from './pages/MobileShareMenu';
import { MobileWithdrawMenu } from './pages/MobileWithdrawMenu';

// Shop
import ShopProductMain from './pages/shop/product/Main';
import { ShopProductDetail } from './pages/shop/product/components/Detail';
import ShopCartMain from './pages/shop/cart/Main';
import { ShopHistoryMain } from './pages/shop/history/Main';
import { ShopCouponMain } from './pages/shop/coupon/Main';
import { NumberBoxMain } from './pages/shop/numberbox/Main';
import { ShopInsuranceMain } from './pages/shop/insurance/Main';
import { ShopInsuranceHistoryMain } from './pages/shop/insurancehistory/Main';
// import { ShopReservationMain } from './pages/shop/reservation/Main';
import { ShopSimkitMain } from './pages/shop/simkit/Main';

// Share
import { ShareOrderOperationMain } from './pages/share/order/operation/Main';
import { ShareOrderHistoryMain } from './pages/share/order/history/Main';
import { ShareDetailMain } from './pages/share/detail/Main';

// Sub Wallet
import { SubWalletWithdrawMain } from './pages/subwallet/Main';

export default function BasePage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Redirect exact from='/' to='/dashboard' />
                <ContentRoute path='/dashboard' component={Dashboard} />
                <ContentRoute path='/dealer/subscriber/newcontact' component={SubscriberNewContactMain} />
                <ContentRoute path='/dealermap/setmarker' component={MapSetMarker} />
                <ContentRoute path='/dealer/newcontact' component={MainNewContactPage} />
                <ContentRoute path='/dealer/upgrade-contact' component={MainUpgradeContactPage} />
                <ContentRoute path='/withdraw/mainwallet' component={WalletWithdraw} />
                <ContentRoute path='/withdraw/subwallet' component={SubWalletWithdrawMain} />
                <ContentRoute path='/self/refill' component={RefillFunction} />
                <ContentRoute path='/another/refill' component={RefillAnotherFunction} />
                <ContentRoute path='/commission/report' component={CommissionReport} />
                <ContentRoute path='/payment/report' component={THReport} />
                <ContentRoute path='/dealer/report' component={DealerGroupReport} />
                <ContentRoute path='/order/report' component={OrderReport} />
                <ContentRoute path='/agreement' component={AgreementPage} />
                <ContentRoute path='/tw50' component={Tw50MainPage} />
                <ContentRoute path='/dealer/support' component={DealerSupport} />
                <ContentRoute path='/profile/edit' component={EditProfile} />
                <ContentRoute path='/menu' component={MobileMenu} />
                <ContentRoute path='/registermenu' component={MobileRegisterMenu} />
                <ContentRoute path='/shopmenu' component={MobileShopMenu} />
                <ContentRoute path='/dealermenu' component={MobileDealerMenu} />
                <ContentRoute path='/sharemenu' component={MobileShareMenu} />
                <ContentRoute path='/withdrawmenu' component={MobileWithdrawMenu} />
                <ContentRoute path='/shop/product' component={ShopProductMain} />
                <ContentRoute path='/shop/productdetail/:id' component={ShopProductDetail} />
                <ContentRoute path='/shop/cart' component={ShopCartMain} />
                <ContentRoute path='/shop/history' component={ShopHistoryMain} />
                <ContentRoute path='/shop/coupon' component={ShopCouponMain} />
                <ContentRoute path='/shop/numberbox' component={NumberBoxMain} />
                <ContentRoute path='/shop/insurance' component={ShopInsuranceMain} />
                <ContentRoute path='/shop/insurancehistory' component={ShopInsuranceHistoryMain} />
                {/* <ContentRoute path='/shop/reservation' component={ShopReservationMain} /> */}
                <ContentRoute path='/shop/simkit' component={ShopSimkitMain} />
                <ContentRoute path='/share/operation' component={ShareOrderOperationMain} />
                <ContentRoute path='/share/history' component={ShareOrderHistoryMain} />
                {/* <ContentRoute path='/share/detail' component={ShareDetailMain} /> */}
                <Redirect to='error/error-v1' />
            </Switch>
        </Suspense>
    );
}
