import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { NCAddress, NCPackages, NCPhonenumber, NCResult, NCSummary } from './components';
import address from '../../environments/address';
import config from '../../environments/config';

const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
        object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
};

export function SubscriberNewContactMain() {
    const history = useHistory();
    const { user } = useSelector((state) => state.auth);
    useEffect(() => {
        getProfile(user.id);
    }, []);
    const [ loading, setLoading ] = useState(false);
    const [ step, setStep ] = useState(1);
    const backStep = () => {
        setStep(step - 1);
    };
    const nextStep = () => {
        if (step === 2) {
            if (type === 'PT2') {
                Swal.fire({
                    html: `<div class="alert-text">คุณได้เลือกเบอร์ <b>${phonenumber.slice(0, 3) +
                        '-' +
                        phonenumber.slice(3, 6) +
                        '-' +
                        phonenumber.slice(6)}</b> ใช่หรือไม่?</div>`,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'ยืนยัน',
                    cancelButtonText: 'ยกเลิก',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        let postparam = {};
                        if (selectpackages.sim_table === 'sim') {
                            postparam = {
                                type: 'sim',
                                number: phonenumber
                            };
                            bookPhonenumber(postparam);
                        } else if (selectpackages.sim_table === 'exsim') {
                            postparam = {
                                type: 'sim',
                                number: phonenumber
                            };
                            bookExPhonenumber(postparam);
                        }
                    }
                });
            } else {
                if (phonenumber === '') {
                    Swal.fire({
                        // title: 'ผิดพลาด!',
                        html: `<div class='alert-text'>กรุณากรอกเบอร์ที่ท่านเป็นเจ้าของอยู่</div>`,
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                } else {
                    if (phonenumber.length !== 10 || phonenumber.charAt(0) !== '0') {
                        Swal.fire({
                            // title: 'ผิดพลาด!',
                            html: `<div class='alert-text'>กรุณากรอกเบอร์ที่ท่านเป็นเจ้าของอยู่ให้ถูกต้อง</div>`,
                            icon: 'warning',
                            confirmButtonColor: '#ffa800',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                        return;
                    }
                }
                let postparam = {};
                if (selectpackages.sim_table === 'sim') {
                    postparam = {
                        type: 'feels',
                        filter: { number: phonenumber }
                    };
                    checkNumber(postparam);
                } else if (selectpackages.sim_table === 'exsim') {
                    postparam = {
                        type: 'sim',
                        package: 'P1',
                        qty: '1',
                        number: phonenumber
                    };
                    checkExNumber(postparam);
                }
            }
        } else if (step === 3) {
            if (shippingtype === 'ST1') {
                setShipname(ogname);
                setShipcontact(ogcontactnumber);
                setShipgeo(oggeo);
                setShipprovince(ogprovince);
                setShipdistrict(ogdistrict);
                setShipsubdistrict(ogsubdistrict);
                setShipzipcode(ogzipcode);
                setShipaddress(ogaddr);
            } else {
                setShipname(name);
                setShipcontact(contactnumber);
                setShipgeo(geo);
                setShipprovince(province);
                setShipdistrict(district);
                setShipsubdistrict(subdistrict);
                setShipzipcode(zipcode);
                setShipaddress(addr);
            }
            setStep(step + 1);
        } else if (step === 4) {
            let postparam = {
                userid: user.id,
                phonenumber: phonenumber,
                id_card: citizenid,
                package: price,
                payment: paymenttype,
                name_t: ownername,
                zip: shipzipcode,
                ship_name: shipname,
                ship_mobile: shipcontact,
                ship_address: shipaddress,
                ship_zip: shipzipcode
            };
            if (shippingtype === 'ST1') {
                postparam['geo_id'] = oggeoid;
                postparam['province_id'] = ogprovinceid;
                postparam['amphur_id'] = ogdistrictid;
                postparam['district_id'] = ogsubdistrictid;
                postparam['ship_geo_id'] = oggeoid;
                postparam['ship_province_id'] = ogprovinceid;
                postparam['ship_amphur_id'] = ogdistrictid;
                postparam['ship_district_id'] = ogsubdistrictid;
            } else {
                postparam['geo_id'] = oggeoid;
                postparam['province_id'] = ogprovinceid;
                postparam['amphur_id'] = ogdistrictid;
                postparam['district_id'] = ogsubdistrictid;
                postparam['ship_geo_id'] = shipgeo.value;
                postparam['ship_province_id'] = shipprovince.value;
                postparam['ship_amphur_id'] = shipdistrict.value;
                postparam['ship_district_id'] = shipsubdistrict.value;
            }
            createOrder(postparam);
        } else {
            setStep(step + 1);
        }
    };
    const [ citizenid, setCitizenid ] = useState('');
    const [ ownername, setOwnername ] = useState('');
    const [ selectpackages, setSelectPackages ] = useState({});
    const handlePackages = (pack) => {
        setSelectPackages(pack);
    };
    // step 1
    const [ type, setType ] = useState('PT2');
    const [ price, setPrice ] = useState('');
    const handleType = (type) => {
        setType(type);
    };
    const handlePrice = (price) => {
        setPrice(price);
    };
    // step 2
    const [ phonenumber, setPhonenumber ] = useState('');
    const handlePhonenumber = (phonenumber) => {
        setPhonenumber(phonenumber);
    };
    // step 3
    const [ shippingtype, setShippingType ] = useState('ST1');
    const handleShippingType = (shippingtype) => {
        setShippingType(shippingtype);
    };
    const [ ogname, setOgName ] = useState('');
    const [ name, setName ] = useState('');
    const handleName = (name) => {
        setName(name);
    };
    const [ ogcontactnumber, setOgContactnumber ] = useState('');
    const [ contactnumber, setContactnumber ] = useState('');
    const handleContactnumber = (contactnumber) => {
        setContactnumber(contactnumber);
    };
    const [ oggeo, setOggeo ] = useState('');
    const [ oggeoid, setOggeoid ] = useState('');
    const [ geo, setGeo ] = useState('');
    const [ maingeo, setMainGeo ] = useState([]);
    const selectGeo = (item) => {
        setGeo(item);
        setProvince('');
        setDistrict('');
        setMainDistrict([]);
        setSubDistrict('');
        setMainSubDistrict([]);
        setZipcode('');
        let thisProvince = [];
        let thisindex = 0;
        if (item) {
            for (const prop in address.province) {
                if (address.province[prop]['geoid'] === item['value']) {
                    thisProvince[thisindex] = address.province[prop];
                    thisindex += 1;
                }
            }
        }
        setMainProvince(thisProvince);
    };
    const [ ogprovince, setOgprovince ] = useState('');
    const [ ogprovinceid, setOgprovinceid ] = useState('');
    const [ province, setProvince ] = useState('');
    const [ mainprovince, setMainProvince ] = useState([]);
    const selectProvince = (item) => {
        setProvince(item);
        setDistrict('');
        setSubDistrict('');
        setMainSubDistrict([]);
        setZipcode('');
        let thisDistrict = [];
        let thisindex = 0;
        if (item) {
            for (const prop in address.district) {
                if (address.district[prop]['provinceid'] === item['value']) {
                    thisDistrict[thisindex] = address.district[prop];
                    thisindex += 1;
                }
            }
        }
        setMainDistrict(thisDistrict);
    };
    const [ ogdistrict, setOgdistrict ] = useState('');
    const [ ogdistrictid, setOgdistrictid ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ maindistrict, setMainDistrict ] = useState([]);
    const selectDistrict = (item) => {
        setDistrict(item);
        setSubDistrict('');
        setZipcode('');
        let thisSubDistrict = [];
        let thisindex = 0;
        if (item) {
            for (const prop in address.subdistrict) {
                if (address.subdistrict[prop]['districtid'] === item['value']) {
                    thisSubDistrict[thisindex] = address.subdistrict[prop];
                    thisindex += 1;
                }
            }
        }
        setMainSubDistrict(thisSubDistrict);
    };
    const [ ogsubdistrict, setOgsubdistrict ] = useState('');
    const [ ogsubdistrictid, setOgsubdistrictid ] = useState('');
    const [ subdistrict, setSubDistrict ] = useState('');
    const [ mainsubdistrict, setMainSubDistrict ] = useState([]);
    const selectSubDistrict = (item) => {
        setSubDistrict(item);
        let thisZipcode = '';
        if (item) {
            thisZipcode = item['zipcode'];
        }
        setZipcode(thisZipcode);
    };
    const [ ogzipcode, setOgzipcode ] = useState('');
    const [ zipcode, setZipcode ] = useState('');
    const handleZipcode = (zipcode) => {
        setZipcode(zipcode);
    };
    const [ ogaddr, setOgaddr ] = useState('');
    const [ addr, setAddr ] = useState('');
    const handleAddress = (address) => {
        setAddr(address);
    };
    // step 4
    const [ paymenttype, setPaymentType ] = useState('2');
    const handlePayment = (payment) => {
        setPaymentType(payment);
    };
    const [ shipname, setShipname ] = useState('');
    const [ shipcontact, setShipcontact ] = useState('');
    const [ shipgeo, setShipgeo ] = useState('');
    const [ shipprovince, setShipprovince ] = useState('');
    const [ shipdistrict, setShipdistrict ] = useState('');
    const [ shipsubdistrict, setShipsubdistrict ] = useState('');
    const [ shipzipcode, setShipzipcode ] = useState('');
    const [ shipaddress, setShipaddress ] = useState('');
    const [ response, setResponse ] = useState({});

    const getProfile = (memberid) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/profile/info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setMainGeo(address.geo);
                    setOwnername(result.profile.name);
                    setOgName(result.profile.name);
                    setOgContactnumber(result.profile.contact_mobile);
                    for (const prop in address.geo) {
                        if (address.geo[prop]['value'] === result.profile.geo_id.toString()) {
                            setOggeo(address.geo[prop]['label']);
                            setOggeoid(result.profile.geo_id.toString());
                        }
                    }
                    for (const prop in address.province) {
                        if (address.province[prop]['value'] === result.profile.province_id.toString()) {
                            setOgprovince(address.province[prop]['label']);
                            setOgprovinceid(result.profile.province_id.toString());
                        }
                    }
                    for (const prop in address.district) {
                        if (address.district[prop]['value'] === result.profile.amphur_id.toString()) {
                            setOgdistrict(address.district[prop]['label']);
                            setOgdistrictid(result.profile.amphur_id.toString());
                        }
                    }
                    for (const prop in address.subdistrict) {
                        if (address.subdistrict[prop]['value'] === result.profile.district_id.toString()) {
                            setOgsubdistrict(address.subdistrict[prop]['label']);
                            setOgsubdistrictid(result.profile.district_id.toString());
                        }
                    }
                    setOgzipcode(result.profile.zip);
                    setOgaddr(result.profile.address);
                    setCitizenid(result.profile.id_card);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const bookPhonenumber = (postparam) => {
        setLoading(true);
        fetch(config.laravel_url + '/api/register/booknumber', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setStep(step + 1);
                } else {
                    Swal.fire({
                        // title: 'ผิดพลาด!',
                        html: `<div class='alert-text'>ไม่สามารถจองเบอร์ ${phonenumber} ได้ <br/>เนื่องจากเบอร์นี้บุคคลอื่นจองอยู่</div>`,
                        icon: 'info',
                        confirmButtonColor: '#0bb7af',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    title: 'ผิดพลาด!',
                    html: `<div class='alert-text'>ไม่สามารถจองเบอร์ได้ชั่วคราว กรุณาลองใหม่อีกครั้ง</div>`,
                    icon: 'warning',
                    confirmButtonColor: '#0bb7af',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const bookExPhonenumber = (postparam) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/register/book-exnumber', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setStep(step + 1);
                } else {
                    Swal.fire({
                        // title: 'ผิดพลาด!',
                        html: `<div class='alert-text'>ไม่สามารถจองเบอร์ ${phonenumber} ได้ <br/>เนื่องจากเบอร์นี้บุคคลอื่นจองอยู่</div>`,
                        icon: 'info',
                        confirmButtonColor: '#0bb7af',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    title: 'ผิดพลาด!',
                    html: `<div class='alert-text'>ไม่สามารถจองเบอร์ได้ชั่วคราว กรุณาลองใหม่อีกครั้ง</div>`,
                    icon: 'warning',
                    confirmButtonColor: '#0bb7af',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const checkNumber = (postparam) => {
        setLoading(true);
        fetch(config.laravel_url + '/api/register/getsim', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0 || result.responsecode === 105) {
                    let postparam = {
                        type: 'feels',
                        number: phonenumber
                    };
                    bookPhonenumber(postparam);
                } else {
                    setLoading(false);
                    if (result.responsecode === 100) {
                        Swal.fire({
                            // title: 'ผิดพลาด!',
                            html: `<div class="alert-text">หมายเลข ${phonenumber} อยู่ระหว่างการจองอยู่</div>`,
                            icon: 'info',
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    } else if (result.responsecode === 103) {
                        Swal.fire({
                            // title: 'ผิดพลาด!',
                            html: `<div class="alert-text">ไม่พบหมายเลข ${phonenumber} ในระบบของ Feels</div>`,
                            icon: 'info',
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    } else if (result.responsecode === 104) {
                        Swal.fire({
                            // title: 'ผิดพลาด!',
                            html: `<div class="alert-text">หมายเลข ${phonenumber} ยังไม่ถูกเปิดใช้งาน</div>`,
                            icon: 'info',
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    } else if (result.responsecode === 106) {
                        Swal.fire({
                            // title: 'ผิดพลาด!',
                            html: `<div class="alert-text">หมายเลข ${phonenumber} หมดอายุแล้ว</div>`,
                            icon: 'info',
                            confirmButtonColor: '#0bb7af',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    } else {
                        Swal.fire({
                            title: 'ผิดพลาด!',
                            html: `<div class="alert-text">ไม่สามารถตรวจสอบหมายเลข Feels ได้ชั่วคราว กรุณาลองใหม่อีกครั้ง</div>`,
                            icon: 'warning',
                            confirmButtonColor: '#ffa800',
                            confirmButtonText: 'ตกลง',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false
                        });
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    title: 'ผิดพลาด!',
                    html:
                        '<div class="alert-text">ไม่สามารถตรวจสอบหมายเลข Feels ได้ชั่วคราว กรุณาลองใหม่อีกครั้ง</div>',
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const checkExNumber = (postparam) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/register/get-sim', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    let postparam = {};
                    if (selectpackages.sim_table === 'sim') {
                        postparam = {
                            type: 'feels',
                            number: phonenumber
                        };
                        bookPhonenumber(postparam);
                    } else if (selectpackages.sim_table === 'exsim') {
                        postparam = {
                            type: 'sim',
                            number: phonenumber
                        };
                        bookExPhonenumber(postparam);
                    }
                } else {
                    setLoading(false);
                    Swal.fire({
                        // title: 'ผิดพลาด!',
                        html: `<div class="alert-text">หมายเลข ${phonenumber} อยู่ระหว่างการจองอยู่หรือไม่พบหมายเลขนี้ในระบบ</div>`,
                        icon: 'info',
                        confirmButtonColor: '#0bb7af',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    title: 'ผิดพลาด!',
                    html: '<div class="alert-text">ไม่สามารถตรวจสอบหมายเลขได้ชั่วคราว กรุณาลองใหม่อีกครั้ง</div>',
                    icon: 'warning',
                    confirmButtonColor: '#ffa800',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const createOrder = (postparam) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/profile/create-subscriber', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(postparam)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0 || result.responsecode === '102') {
                    setResponse(result);
                    setStep(step + 1);
                } else if (result.responsecode === '100') {
                    Swal.fire({
                        title: 'ผิดพลาด!',
                        html: '<div class="alert-text">ไม่สามารถสร้างรายการได้ชั่วคราว กรุณาลองใหม่อีกครั้ง</div>',
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                } else if (result.responsecode === '101') {
                    Swal.fire({
                        html: '<div class="alert-text">ไม่สามารถสร้างรายการได้เนื่องจากยอดเงินไม่เพียงพอ</div>',
                        icon: 'warning',
                        confirmButtonColor: '#ffa800',
                        confirmButtonText: 'ตกลง',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <div>
            {loading && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
            <div className='card card-custom card-shadowless bg-white rounded'>
                <div className='wizard wizard-1' id='kt_wizard'>
                    <div className='wizard-nav border-bottom'>
                        <div className='wizard-steps p-8 p-lg-10'>
                            <div
                                className={`wizard-step d-md-flex ${step !== 1 && 'd-none'}`}
                                data-wizard-type='step'
                                data-wizard-state={`${step >= 1 ? 'current' : 'pending'}`}
                            >
                                <div className='wizard-label'>
                                    <i className='wizard-icon flaticon-interface-7' />
                                    <h3 className='wizard-title'>เลือกแพ็กเกจผู้ใช้งานทั่วไป</h3>
                                </div>
                                <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                    <SVG
                                        title='ขั้นตอนที่1'
                                        src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                    />
                                </span>
                            </div>
                            <div
                                className={`wizard-step d-md-flex ${step !== 2 && 'd-none'}`}
                                data-wizard-type='step'
                                data-wizard-state={`${step >= 2 ? 'current' : 'pending'}`}
                            >
                                <div className='wizard-label'>
                                    <i className='wizard-icon flaticon-open-box' />
                                    <h3 className='wizard-title'>เลือกเบอร์ที่เข้าร่วมโครงการ</h3>
                                </div>
                                <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                    <SVG
                                        title='ขั้นตอนที่2'
                                        src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                    />
                                </span>
                            </div>
                            <div
                                className={`wizard-step d-md-flex ${step !== 3 && 'd-none'}`}
                                data-wizard-type='step'
                                data-wizard-state={`${step >= 3 ? 'current' : 'pending'}`}
                            >
                                <div className='wizard-label'>
                                    <i className='wizard-icon flaticon-map-location' />
                                    <h3 className='wizard-title'>ที่อยู่จัดส่ง</h3>
                                </div>
                                <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                    <SVG
                                        title='ขั้นตอนที่3'
                                        src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                    />
                                </span>
                            </div>
                            <div
                                className={`wizard-step d-md-flex ${step !== 4 && 'd-none'}`}
                                data-wizard-type='step'
                                data-wizard-state={`${step >= 4 ? 'current' : 'pending'}`}
                            >
                                <div className='wizard-label'>
                                    <i className='wizard-icon flaticon-questions-circular-button' />
                                    <h3 className='wizard-title'>ยืนยันการทำรายการ</h3>
                                </div>
                                <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                    <SVG
                                        title='ขั้นตอนที่4'
                                        src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                    />
                                </span>
                            </div>
                            <div
                                className={`wizard-step d-md-flex ${step !== 5 && 'd-none'}`}
                                data-wizard-type='step'
                                data-wizard-state={`${step >= 5 ? 'current' : 'pending'}`}
                            >
                                <div className='wizard-label'>
                                    <i className='wizard-icon flaticon-statistics' />
                                    <h3 className='wizard-title'>สรุปรายการ</h3>
                                </div>
                                <span className='svg-icon svg-icon-xl wizard-arrow last'>
                                    <SVG
                                        title='ขั้นตอนที่5'
                                        src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body p-0'>
                    <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                        <div className={`col-xl-12 ${step === 4 || step === 5 ? 'col-xxl-12' : 'col-xxl-10'}`}>
                            <div className='mt-0 mt-lg-10'>
                                {step === 1 ? (
                                    <NCPackages
                                        type={type}
                                        handleType={handleType}
                                        price={price}
                                        handlePrice={handlePrice}
                                        handlePackages={handlePackages}
                                    />
                                ) : step === 2 ? (
                                    <NCPhonenumber
                                        maxLengthCheck={maxLengthCheck}
                                        type={type}
                                        selectpackages={selectpackages}
                                        phonenumber={phonenumber}
                                        handlePhonenumber={handlePhonenumber}
                                    />
                                ) : step === 3 ? (
                                    <NCAddress
                                        maxLengthCheck={maxLengthCheck}
                                        shippingtype={shippingtype}
                                        handleShippingType={handleShippingType}
                                        ogname={ogname}
                                        name={name}
                                        handleName={handleName}
                                        ogcontactnumber={ogcontactnumber}
                                        contactnumber={contactnumber}
                                        handleContactnumber={handleContactnumber}
                                        oggeo={oggeo}
                                        geo={geo}
                                        maingeo={maingeo}
                                        selectGeo={selectGeo}
                                        ogprovince={ogprovince}
                                        province={province}
                                        mainprovince={mainprovince}
                                        selectProvince={selectProvince}
                                        ogdistrict={ogdistrict}
                                        district={district}
                                        maindistrict={maindistrict}
                                        selectDistrict={selectDistrict}
                                        ogsubdistrict={ogsubdistrict}
                                        subdistrict={subdistrict}
                                        mainsubdistrict={mainsubdistrict}
                                        selectSubDistrict={selectSubDistrict}
                                        ogzipcode={ogzipcode}
                                        zipcode={zipcode}
                                        handleZipcode={handleZipcode}
                                        ogaddr={ogaddr}
                                        addr={addr}
                                        handleAddress={handleAddress}
                                    />
                                ) : step === 4 ? (
                                    <NCSummary
                                        backStep={backStep}
                                        nextStep={nextStep}
                                        type={type}
                                        price={price}
                                        phonenumber={phonenumber}
                                        shippingtype={shippingtype}
                                        shipname={shipname}
                                        shipcontact={shipcontact}
                                        shipgeo={shipgeo}
                                        shipprovince={shipprovince}
                                        shipdistrict={shipdistrict}
                                        shipsubdistrict={shipsubdistrict}
                                        shipzipcode={shipzipcode}
                                        shipaddress={shipaddress}
                                        paymenttype={paymenttype}
                                        handlePayment={handlePayment}
                                        selectpackages={selectpackages}
                                    />
                                ) : (
                                    <NCResult paymenttype={paymenttype} response={response} />
                                )}
                                <div className='d-flex justify-content-between border-top mt-5 pt-10'>
                                    <div className='mr-2'>
                                        {step > 1 &&
                                        step !== 3 &&
                                        step !== 5 && (
                                            <button
                                                type='button'
                                                onClick={backStep}
                                                className='btn btn-light-primary btn-cus-rounded font-weight-bold text-uppercase px-9 py-4'
                                            >
                                                ก่อนหน้า
                                            </button>
                                        )}
                                    </div>
                                    <div>
                                        {step <= 3 ? (
                                            <button
                                                type='button'
                                                onClick={nextStep}
                                                className='btn btn-success btn-cus-rounded font-weight-bold text-uppercase px-9 py-4'
                                                disabled={
                                                    step === 1 ? (
                                                        price === ''
                                                    ) : (
                                                        step === 2 &&
                                                        phonenumber.length !== 10 &&
                                                        phonenumber.charAt(0) !== '0'
                                                    )
                                                }
                                            >
                                                ถัดไป
                                            </button>
                                        ) : (
                                            step === 5 && (
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        history.push('/dashboard');
                                                    }}
                                                    className='btn btn-success btn-cus-rounded font-weight-bold text-uppercase px-9 py-4'
                                                >
                                                    หน้าหลัก
                                                </button>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
