/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
// import clsx from "clsx";
import { FormattedMessage } from 'react-intl';
import {
    //Link,
    Switch,
    Redirect
} from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
// import {
//     useLang, setLanguage
// } from "../../../../_metronic/i18n";
import { ContentRoute } from '../../../../_metronic/layout';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
// import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
// import { DropdownItemToggler } from "../../../../_metronic/_partials/dropdowns";
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';

import { Modal } from 'react-bootstrap';
// const languages = [
//     {
//         lang: "en",
//         name: "English",
//         flag: toAbsoluteUrl("/media/svg/flags/226-united-states.svg"),
//     },
//     {
//         lang: "th",
//         name: "ภาษาไทย",
//         flag: toAbsoluteUrl("/media/svg/flags/238-thailand.svg"),
//     },
// ];

export function AuthPage() {
    const today = new Date().getFullYear();

    const [ showModal, setShow ] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // const lang = useLang();
    // const currentLanguage = languages.find((x) => x.lang === lang);
    return (
        <div className='d-flex flex-column flex-root'>
            {/*begin::Login*/}
            <div
                className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white'
                id='kt_login'
            >
                {/*begin::Aside*/}
                <div
                    className='login-aside d-flex flex-row-auto bgi-size-cover d-lg-none'
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-home-mobile.png')})`
                    }}
                >
                    {/* // style={{
                //     backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-home-mobile.png')})`
                // }} */}
                    {/*begin: Aside Container*/}
                    <div className='flex-column-auto flex-column pt-lg-40 pt-15 w-100'>
                        {/* <div className="d-flex flex-row-fluid flex-column justify-content-between"> */}
                        {/* start:: Aside header */}
                        {/* <Link to="/" className="flex-column-auto mt-5"> */}
                        <div className='text-center mb-10'>
                            <img
                                alt='Logo'
                                className='max-h-150px'
                                src={toAbsoluteUrl('/media/logos/new-logo-long.png')}
                            />
                        </div>
                        {/* <h3 className='font-weight-bolder text-center font-size-h4 font-size-h1-lg text-brown'>
                            <FormattedMessage id='LOGIN.WELCOME' />
                        </h3> */}
                        {/* </Link> */}
                        {/* end:: Aside header */}

                        {/* start:: Aside content */}
                        <div className='flex-column-fluid d-flex flex-column justify-content-center'>
                            {/* <h3 className="font-size-h1 mb-5 text-white text-center">
                  ยินดีต้อนรับ
                </h3> */}
                            {/* <p className="font-weight-lighter text-white opacity-80">
                  The ultimate Bootstrap & React 16 admin theme framework for next
                  generation web apps.
                </p> */}
                        </div>
                        {/* end:: Aside content */}

                        {/* start:: Aside footer for desktop */}
                        {/* <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="font-weight-bold text-white">
                  {today} &copy; {` `}
                  <a
                    href="https://lin.ee/1ePfrsrbA"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-white text-hover-danger"
                  >
                    K4 Communication Co., LTD.
                  </a>
                </div>
                <div className="d-flex">
                  <a
                    href="http://line.me/ti/p/~@dealersimsupport"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white text-hover-danger"
                  >
                    Line
                  </a>
                  <a
                    href="https://www.facebook.com/DealerSimPunsook"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white text-hover-danger ml-10"
                  >
                    Facebook
                  </a>
                  <Link to="/terms" className="text-white ml-10">
                    Contact
                    </Link>
                </div>
              </div> */}
                        {/* <div
                            className='aside-img d-none d-md-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center'
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl('/media/svg/illustrations/login-visual-1.svg')})`
                            }}
                        /> */}
                        {/* end:: Aside footer for desktop */}
                    </div>
                    {/*end: Aside Container*/}
                </div>
                {/*begin::Aside*/}

                {/*begin::Aside*/}
                <div
                    className='login-aside flex-row-auto bgi-size-cover d-none d-lg-flex'
                    style={{
                        backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-home.png')})`
                    }}
                >
                    {/*begin: Aside Container*/}
                    <div className='flex-column-auto flex-column pt-lg-40 pt-15 w-100'>
                        {/* <div className="d-flex flex-row-fluid flex-column justify-content-between"> */}
                        {/* start:: Aside header */}
                        {/* <Link to="/" className="flex-column-auto mt-5"> */}
                        {/* <div className='text-center mb-10'>
                            <img
                                alt='Logo'
                                className='max-h-150px'
                                src={toAbsoluteUrl('/media/logos/new-logo-long.png')}
                            />
                        </div> */}
                        {/* <h3 className='font-weight-bolder text-center font-size-h4 font-size-h1-lg text-brown'>
                            <FormattedMessage id='LOGIN.WELCOME' />
                        </h3> */}
                        {/* </Link> */}
                        {/* end:: Aside header */}

                        {/* start:: Aside content */}
                        <div className='flex-column-fluid d-flex flex-column justify-content-center'>
                            {/* <h3 className="font-size-h1 mb-5 text-white text-center">
                  ยินดีต้อนรับ
                </h3> */}
                            {/* <p className="font-weight-lighter text-white opacity-80">
                  The ultimate Bootstrap & React 16 admin theme framework for next
                  generation web apps.
                </p> */}
                        </div>
                        {/* end:: Aside content */}

                        {/* start:: Aside footer for desktop */}
                        {/* <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="font-weight-bold text-white">
                  {today} &copy; {` `}
                  <a
                    href="https://lin.ee/1ePfrsrbA"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-white text-hover-danger"
                  >
                    K4 Communication Co., LTD.
                  </a>
                </div>
                <div className="d-flex">
                  <a
                    href="http://line.me/ti/p/~@dealersimsupport"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white text-hover-danger"
                  >
                    Line
                  </a>
                  <a
                    href="https://www.facebook.com/DealerSimPunsook"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white text-hover-danger ml-10"
                  >
                    Facebook
                  </a>
                  <Link to="/terms" className="text-white ml-10">
                    Contact
                    </Link>
                </div>
              </div> */}
                        {/* <div
                            className='aside-img d-none d-md-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center'
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl('/media/svg/illustrations/login-visual-1.svg')})`
                            }}
                        /> */}
                        {/* end:: Aside footer for desktop */}
                    </div>
                    {/*end: Aside Container*/}
                </div>
                {/*begin::Aside*/}

                {/*begin::Content*/}
                <div className='flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden'>
                    {/*begin::Content header*/}
                    {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                            <span className="font-weight-bold text-dark-50">
                                ต้องการเป็นหนึ่งในโครงการซิมปันสุข?
                            </span>
                            <Link
                                to="/auth/registration"
                                className="font-weight-bold ml-2"
                                id="kt_login_signup"
                            >
                                สมัครเลย!
                            </Link>
                        </div> */}
                    {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                            <Dropdown drop="down" alignRight>
                                <Dropdown.Toggle
                                    as={DropdownItemToggler}
                                    id="dropdown-toggle-my-cart"
                                    className="mb-2"
                                >
                                    <div className="btn btn-clean btn-lg my-auto">
                                        <h5 className="d-inline-block mr-3 text-dark">
                                            Language
                                            <FormattedMessage id="TRANSLATOR.SELECT" />
                                        </h5>
                                        <img
                                            className="w-25px h-25px w-lg-30px h-lg-30px rounded-circle"
                                            src={currentLanguage.flag}
                                            alt={currentLanguage.name}
                                        />
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-left">
                                    <ul className="navi navi-hover py-4">
                                        {languages.map((language) => (
                                            <li
                                                key={language.lang}
                                                className={clsx("navi-item", {
                                                    active:
                                                        language.lang ===
                                                        currentLanguage.lang,
                                                })}
                                            >
                                                <a
                                                    href="#"
                                                    onClick={() =>
                                                        setLanguage(
                                                            language.lang
                                                        )
                                                    }
                                                    className="navi-link"
                                                >
                                                    <span className="symbol symbol-20 mr-3">
                                                        <img
                                                            src={language.flag}
                                                            alt={language.name}
                                                        />
                                                    </span>
                                                    <span className="navi-text">
                                                        {language.name}
                                                    </span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> */}
                    {/*end::Content header*/}

                    {/* begin::Content body */}
                    <div className='d-flex flex-column-fluid flex-center mt-30 mt-lg-0'>
                        <Switch>
                            <ContentRoute path='/auth/login' component={Login} />
                            <ContentRoute path='/auth/registration' component={Registration} />
                            <ContentRoute path='/auth/forgot-password' component={ForgotPassword} />
                            <Redirect from='/auth' exact={true} to='/auth/login' />
                            <Redirect to='/auth/login' />
                        </Switch>
                    </div>
                    {/*end::Content body*/}

                    {/* begin::Mobile footer */}
                    <div className='d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5'>
                        <div className='text-dark-50 font-weight-bold order-2 order-sm-1 my-2'>
                            {today} &copy; {` `}
                            <a
                                href='https://lin.ee/1ePfrsrbA'
                                rel='noopener noreferrer'
                                target='_blank'
                                className='text-dark-75 text-hover-primary'
                            >
                                K4 Communication Co., LTD.
                            </a>
                        </div>
                        <div className='d-flex order-1 order-sm-2 my-2'>
                            <a
                                href='http://line.me/ti/p/~@dealersimsupport'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='text-dark-75 text-hover-primary'
                            >
                                Line
                            </a>
                            <a
                                href='https://www.facebook.com/DealerSimPunsook'
                                target='_blank'
                                rel='noopener noreferrer'
                                className='text-dark-75 text-hover-primary ml-4'
                            >
                                Facebook
                            </a>
                            {/* <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                  </Link> */}
                            {/* <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                  </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                  </Link> */}
                        </div>
                    </div>
                    {/* end::Mobile footer */}
                </div>
                {/*end::Content*/}
            </div>
            {/*end::Login*/}

            <Modal
                show={showModal}
                onHide={handleClose}
                size='lg'
                centered
                aria-labelledby='banner-modal'
                className='banner-modal'
                // contentClassName='bg-transparent shadow-none'
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title
                        id="banner-modal"
                        className="col-11 p-0"
                    >
                        เพิ่มข้อมูลหมายเลขพัสดุ
                    </Modal.Title> */}
                    <button type='button' className='close col-1 text-right pr-3 ml-auto' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        {/* <div class='circle-close' onClick={handleClose}>
                            &times;
                        </div> */}
                        {/* <a href='https://form.jotform.com/212912570478459' target='_blank' rel='noopener noreferrer'> */}
                        <img src={toAbsoluteUrl('/media/banners/banner-211119.jpg')} className='w-100 h-100' />
                        {/* </a> */}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
